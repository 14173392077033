<template>
	<v-footer color="#121212">
		<v-row>
			<v-col class="grey--text text--lighten-1 px-0" cols="12">
				<span>Copyright &copy; {{ copyright }}</span>
				<span><strong>Knight-Swift Logistics, LLC</strong> All rights reserved.</span>
			</v-col>
		</v-row>
	</v-footer>
</template>
<script>
export default {
	name: 'App-Footer',
	data() {
		return {
			copyright: null,
		};
	},
	methods: {
		setCopyrightYear() {
			const thisYear = new Date().getFullYear();
			const startYear = '2018';
			if (thisYear > startYear) {
				this.copyright = startYear + '-' + thisYear;
			} else {
				this.copyright = startYear;
			}
		},
	},
	created() {
		this.setCopyrightYear();
	},
};
</script>
<style scoped>
span {
	display: block;
	font-size: 11px;
}
</style>
