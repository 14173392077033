export default {
	methods: {
		labelFilter(val) {
			let text;
			switch (val) {
				// general
				case 'legalName':
					text = 'Company Name';
					break;
				case 'doingBusinessAsName':
					text = 'Doing Business As';
					break;
                case 'emailAddress':
                    text = 'Company Email';
                    break;
				case 'telephone':
					text = 'Company Phone';
					break;
				case 'extension':
					text = 'Ext.';
					break;
				case 'fax':
					text = 'Company Fax';
					break;
				case 'website':
					text = 'Company Website';
					break;
				case 'scacNo':
					text = 'SCAC';
					break;
				case 'taxId':
					text = 'Tax Identification #';
					break;
				case 'mcNumber':
					text = 'MC Number';
					break;
				case 'minority':
					text = 'Are you a Minority?';
					break;
				case 'intraState':
					text = 'Intrastate';
					break;
				// physical
				case 'address1':
					text = 'Street Address';
					break;
				case 'address2':
					text = 'Street Address Line 2';
					break;
                case 'city':
                    text = 'City';
                    break;
				case 'state':
					text = 'State';
					break;
				case 'zip':
					text = 'Postal Code';
					break;
				case 'country':
					text = 'Country';
					break;
				// fleet
				case 'power':
					text = 'Power Units'
					break;
				case 'drivers':
					text = 'Drivers'
					break;
				case 'dry':
					text = 'Dry'
					break;
				case 'flatbed':
					text = 'Flatbed'
					break;
				case 'reefer':
					text = 'Reefer'
					break;
				case 'carHaul':
					text = 'Car Haul'
					break;
				case 'highCube':
					text = 'High Cube Dray'
					break;
				// payments
				case 'name':
					text = 'Factoring Company Name'
					break;
				case 'postalCode':
					text = 'Postal Code';
					break;
				case 'isFactoring':
					text = 'Do you use a factoring company?';
					break;
				case 'phone':
					text = 'Telephone';
					break;
				case 'email':
					text = 'Company Email';
					break;
				default:
					text = val;
			}
			return text
		},
	}
}