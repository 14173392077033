<template>
	<div>
		<v-card-title>
			We need to gather some more information...
		</v-card-title>
		<v-card-text>
			<v-form v-model="docketValid" ref="docketValid">
				<!-- IDENTIFIER INFO -->
				<v-row
				 v-show="currentStep === 'docket'"
				 justify="center"
				 align="center">
					<v-col cols="12" class="pb-0">
						Please select your carrier indentifier:
					</v-col>
					<v-col cols="12">
						<v-radio-group
						 v-model="selectedIdentifier"
						 row
						 required
						 :rules="[$formRules.required]"
						 class="my-0">
							<v-radio
							 small
							 label="Interstate (hauling across state lines)"
							 value="interstate"
							 class="my-0"></v-radio>
							<v-radio
							 label="Intrastate US (hauling within a single state only)"
							 value="intrastate"
							 class="my-0"></v-radio>
						</v-radio-group>
					</v-col>
					<v-col cols="12">
						<v-row class="pb-2">
							US Docket# (MC, FF, MX):
						</v-row>
						<v-row justify="start">
							<v-col cols="12" md="8" class="pa-0">
								<v-row>
									<v-select
									 :items="['MC', 'FF', 'MX']"
									 v-model="docketPrefix"
									 dark
									 class="elevation-0"
									 style="width: 100px; max-width: 100px"
									 hide-details>
									</v-select>
									<v-text-field
									 v-model="docketNumber"
									 class="mx-3"
									 hide-details
									 prepend-inner-icon="mdi-pound"
									 @keypress="
													numOnly($event, 'USDOT')
												">
									</v-text-field>
								</v-row>
							</v-col>
						</v-row>
						<v-row class="pt-2 pl-2">
							* This docket number field is optional
						</v-row>
					</v-col>
				</v-row>
			</v-form>
			<!-- CREATE ACCOUNT FIELDS -->
			<v-row
			 v-show="currentStep === 'createAccount'"
			 justify="center"
			 align="center">
				<v-col cols="12">
					<v-form v-model="$formValid.account" ref="account">
						<v-col cols="12" class="pa-0 px-2 ma-0">
							<v-text-field
							 v-model="form.firstName"
							 maxlength="maxLength('firstName')"
							 label="First Name"
							 :rules="ruleMethod('firstName')">
							</v-text-field>
							<v-text-field
							 v-model="form.lastName"
							 maxlength="maxLength('lastName')"
							 label="Last Name"
							 :rules="ruleMethod('lastName')">
							</v-text-field>
							<v-select
							 v-model="selectedPosition"
							 :items="form.companyPosition"
							 label="Company Position"
							 item-text="text"
							 item-value="value"
							 :rules="[$formRules.required]">
							</v-select>
							<v-text-field
							 v-model="form.telephone"
							 maxlength="maxLength('telephone')"
							 label="Telephone"
							 :rules="ruleMethod('telephone')">
							</v-text-field>
							<v-text-field
							 v-model="form.email"
							 readonly
							 label="Your Select account username (Readonly)">
							</v-text-field>
							<v-text-field
							 v-model="form.password"
							 maxlength="maxLength('password')"
							 label="Confirm Your Select Password"
							 :rules="[$formRules.required]"
							 :type="showPassword ? 'text' : 'password'"
							 :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
							 @click:append="showPassword = !showPassword"
							 autocomplete="off">
							</v-text-field>
						</v-col>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions
		 class="cardActions"
		 :style="cardActionsStyle"
		 @click="verifyHandler">
			<v-row
			 justify="center"
			 align="center"
			 v-if="!createAccountLoading">
				<span v-if="currentStep != 'createAccount'">Continue</span>
				<span v-else>Create Account</span>
			</v-row>
			<v-row justify="center" align="center" v-else>
				<v-progress-circular
				 indeterminate
				 color="white"
				 size="16"></v-progress-circular>
			</v-row>
		</v-card-actions>
		<basicDialog
		 v-model="basicDialog.value"
		 :options="basicDialog"
		 @close="returnLandingPage" />
	</div>
</template>
<script>
// libraries
import jwt from "jsonwebtoken";

// mixins
import hashPassword from "@/mixins/hashPassword.js";

import basicDialogMixin from "@/mixins/basicDialogMixin";
import numOnly from "@/mixins/formBuilding/numOnly";

export default {
	name: "Existing_Signup_Form",
	components: {},
	props: [],
	mixins: [
		hashPassword,
		//
		basicDialogMixin,
		//
		numOnly,
	],
	data() {
		return {
			authToken: null,
			//
			selectedChoice: null,
			verifiedInfo: ["Yes", "No"],
			selectedIdentifier: null,
			currentStep: "docket",
			docketValid: false,
			confirmValid: false,
			docketPrefix: "MC",
			docketNumber: null,
			selectedPosition: null,
			show: false,
			createAccountLoading: false,
			form: {
				firstName: null,
				lastName: null,
				companyPosition: [
					{ text: "Operations Manager", value: "operationsManager" },
					{ text: "Corporate Manager", value: "corporateManager" },
					{ text: "Owner", value: "owner" },
					{ text: "Dispatcher", value: "dispatcher" },
					{ text: "Finance/Accounting", value: "finance" },
					{ text: "Other", value: "other" },
				],
				telephone: null,
				email: null,
				password: null
			},
			showPassword: false,
			fmcsaValidation: process.env.VUE_APP_FMCSA_VALIDATION
		};
	},
	methods: {
		docket() {
			if (this.preventAction) {
				this.currentStep = "createAccount";
			}
		},
		async getFmcsaData() {
			try {
				// INITIALIZE STOREOBJ TO USE LATER IN UPDATING THE STORE
				let storeObj

				// CHECK IF FMCSA VALIDATION IS TURNED ON
				if (this.fmcsaValidation === 'ON') {
					const response = await this.$axios({
						method: "get",
						url: `${this.$hostName.fmcsaPath}/carriers/${this.orgInfo.dotNumber}`,
						params: {
							webKey: process.env.VUE_APP_FMCSA_KEY
						},
					});

					let fmcsaInfo = response.data.content.carrier || null;

					// IF COUNTRY IS US, CHANGE TO USA SINCE THAT IS WHAT THE COUNTRY FIELD OPTIONS EXPECTS
					if (fmcsaInfo.phyCountry === 'US') {
						fmcsaInfo.phyCountry = 'USA';
					}

					// ACCOUNT FOR WHEN FMCSA RESPONSE IS NULL
					// TODO: DO NOT WANT TO REPLACE THE MAILING/PHYSICAL/GENERAL OBJECTS IF THEY ALREADY EXIST FOR THE CARRIER
					storeObj = {
						dotNumber: this.orgInfo.dotNumber,
						mcNumber: this.docketNumber,
						intraState: this.selectedIdentifier,
						mailing: {
							address1: fmcsaInfo.phyStreet || null,
							city: fmcsaInfo.phyCity || null,
							state: fmcsaInfo.phyState || null,
							zip: fmcsaInfo.phyZipcode || null,
							country: fmcsaInfo.phyCountry || null,
						},
						physical: {
							address1: fmcsaInfo.phyStreet || null,
							city: fmcsaInfo.phyCity || null,
							state: fmcsaInfo.phyState || null,
							zip: fmcsaInfo.phyZipcode || null,
							country: fmcsaInfo.phyCountry || null,
						},
						general: {
							doingBusinessAsName: fmcsaInfo.dbaName || null,
							legalName: fmcsaInfo.legalName || null
						},
					};
				} else {
					// ACCOUNT FOR WHEN FMCSA RESPONSE IS NULL
					// TODO: DO NOT WANT TO REPLACE THE MAILING/PHYSICAL/GENERAL OBJECTS IF THEY ALREADY EXIST FOR THE CARRIER
					storeObj = {
						dotNumber: this.orgInfo.dotNumber,
						mcNumber: this.docketNumber,
						intraState: this.selectedIdentifier,
						mailing: {
							address1: null,
							city: null,
							state: null,
							zip: null,
							country: null,
						},
						physical: {
							address1: null,
							city: null,
							state: null,
							zip: null,
							country: null,
						},
						general: {
							doingBusinessAsName: null,
							legalName: null
						},
					};
				}

				this.$store.commit("updateOnboardingInfo", storeObj);
			} catch (error) {
				//
				this.$background.blur = true;
				this.basicDialog = {
					value: true,
					title: "DOT Error",
					text: "There was an error validating your DOT#. Please try again later.",
					hasAction: false,
					width: "350",
				};
				setTimeout(() => {
					this.closeBasicDialog();
				}, 4000);
				console.log(error);
			} finally {
				// do nothing
			}
		},
		async createAccount() {
			if (this.preventAction) {
				this.createAccountLoading = true;
				const postObj = {
					firstName: this.form.firstName,
					lastName: this.form.lastName,
					username: this.form.email,
					password: this.hashPassword(this.form.password),
					dotNumber: this.orgInfo.dotNumber.trim(),
					identifier: this.selectedIdentifier,
					phone: this.form.telephone,
					companyRole: this.selectedPosition,
					info: {
						mcNumber: null,
						intraState: null,
					},
					mailing: this.onboardingInfo.mailing,
					physical: this.onboardingInfo.physical,
					contact: {
						doingBusinessAsName: this.onboardingInfo.general.doingBusinessAsName,
						legalName: this.onboardingInfo.general.legalName,
						emailAddress: this.onboardingInfo.general.emailAddress,
						telephone: this.onboardingInfo.general.telephone,
						fax: this.onboardingInfo.general.fax,
					},
					existingCarrier: true // TODO: TEST CREATING AN ACCOUNT WITH THIS PARAMETER TO AVOID THE EMAIL ALREADY EXISTS ERROR
				};

				if (this.docketNumber) {
					postObj.docketNumber =
						this.docketPrefix + this.docketNumber;
				}

				try {
					const response = await this.$axios({
						method: "post",
						url: `${this.$hostName.selectProfiles}/api/v0/unprotected/addUser/onboarding/signup`,
						data: postObj,
					});
					// this.$router.push("/loadinginformation");
					this.createAccountLoading = false;

					if (response.data) {
						this.loginCarrier(response.data);
					}
				} catch (error) {
					let text;

					if (error.response.status === 409) {
						text = `Email/Username already exists. Please login using your email address. If you have forgotten your password, please click the "forgot password" link on the login page to reset your password.`;
					} else {
						text =
							"An error occured while creating your account. Your information was not saved at this time. Please try again later.";
					}
					//
					this.basicDialog = {
						value: true,
						title: "We've encountered a problem",
						text,
						hasAction: true,
						actionButton: {
							action: "close",
							text: "Ok",
							color: "error",
							outlined: true,
						},
						width: "450",
					};
					console.log(error);
				} finally {
					// do nothing
				}
			}
		},
		verifyHandler() {
			this[`${this.currentStep}`]();
		},
		/////////////////////////////
		// PASSWORD FIELD RELATED  //
		/////////////////////////////
		hidePass(item) {
			let fieldType;
			switch (item) {
				case "password":
					if (this.show) fieldType = "text";
					else fieldType = "password";
					break;
				default:
					fieldType = "text";
			}
			return fieldType;
		},
		showPass(item) {
			let iconType;
			switch (item) {
				case "password":
					if (this.show) iconType = "far fa-eye";
					else iconType = "far fa-eye-slash";
					break;
				default:
					iconType = "";
			}
			return iconType;
		},
		////////////////////////////////////////////////////////////////
		// YOU PROBABLY DONT NEED THESE JUST ADD TO THE GLOBAL MIXINS //
		////////////////////////////////////////////////////////////////
		labelFilter(val) {
			let text;
			switch (val) {
				case "firstName":
					text = "First Name";
					break;
				case "lastName":
					text = "Last Name";
					break;
				case "companyPosition":
					text = "Company Position";
					break;
				case "telephone":
					text = "Telephone";
					break;
				case "email":
					text = "Email";
					break;
				case "password":
					text = "Password";
					break;
				default:
					text = val;
			}
			return text;
		},
		ruleMethod(val) {
			let rule;
			switch (val) {
				case "telephone":
					rule = [
						this.$formRules.required,
						this.$formRules.phoneValid,
					];
					break;
				case "email":
					rule = [this.$formRules.required, this.$formRules.email];
					break;
				case "password":
					rule = [this.$formRules.passwordValid];
					break;
				default:
					rule = [this.$formRules.required];
			}
			return rule;
		},
		maxLength(item) {
			let length;
			switch (item) {
				case "telephone":
					length = 12;
					break;
				case "password":
					length = 88;
					break;
				default:
					length = 100;
			}
			return length;
		},
		/////////////////////////
		// ALL THE LOGIN STEPS //
		/////////////////////////
		async loginCarrier({ user }) {
			this.loginError = false;
			this.loginErrorMsg = null;
			this.loading = true;

			const postObj = {
				username: user.username,
				password: this.hashPassword(this.form.password),
				source: "onboarding"
			};

			try {
				const response = await this.$axios({
					method: "post",
					url: `${this.$hostName.selectProfiles}/api/v0/unprotected/authUser/login`,
					data: postObj,
				});
				this.$router.push('/loadinginformation');

				const { authToken: token, user } = response.data;

				// parse JWT
				const decoded = jwt.decode(token);

				// build an auth
				const authObj = {
					token,
					expTime: decoded.exp,
					refreshTime: Date.now() / 1000 + 60 * 15,
					loggedIn: true,
				};

				this.authToken = token;

				// build a userObj
				const userObj = {
					username: user.username,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					companyRole: user.companyRole,
				};

				// put important stuff into the store.
				this.$store.commit("updateAuth", authObj);
				this.$store.commit("updateUser", userObj);
				this.$store.commit("updatePermissions", decoded.permissions);
				this.$store.commit("updateOrg", decoded.organization);

				// retrieve saved carrier info
				await this.getCarrierInfo();
				await this.getDocInfo();
				await this.getCarrierProgress();
				await this.getCarrierPayments();
				await this.getCarrierServices();
				await this.getCarrierNotifications();

				setTimeout(() => {
					this.$router.push("/dashboard");
				}, 2500);
			} catch (err) {
				console.log(err);
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: "There was an error logging you in. Please check username and password or try again later.",
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Ok",
						color: "error",
					},
					width: "350",
				};
			} finally {
				this.loading = false;
			}
		},
		async getCarrierInfo() {
			try {
				const response = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const formData = response.data;

				formData.general = { ...formData.contact, ...formData.info };
				delete formData.general.mcNumber;
				delete formData.general.intraState;

				const mailingData = {};
				const physicalData = {};
				const generalData = {};
				const companyFleetData = {};
				const ownerFleetData = {};

				Object.entries(formData.mailing).forEach(([key, value]) => {
					mailingData[key] = value;
				});
				Object.entries(formData.physical).forEach(([key, value]) => {
					physicalData[key] = value;
				});
				Object.entries(formData.general).forEach(([key, value]) => {
					generalData[key] = value;
				});

				if (formData.fleet) {
					if (formData.fleet.company) {
						Object.entries(formData.fleet.company).forEach(
							([key, value]) => {
								companyFleetData[key] = value;
							}
						);
					}
					if (formData.fleet.owner) {
						Object.entries(formData.fleet.owner).forEach(
							([key, value]) => {
								ownerFleetData[key] = value;
							}
						);
					}
				}
				const storeObj = {
					dotNumber: formData.dotNumber,
					carrierCode: formData.carrierCode,
					status: formData.status,
					addresses: {
						mailing: { ...mailingData },
						physical: { ...physicalData },
					},
					general: { ...generalData },
					fleet: {
						company: { ...companyFleetData },
						owner: { ...ownerFleetData },
					},
				};
				this.$store.commit("updateOnboardingInfo", storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getDocInfo() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/documents/list`,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (Object.keys(data).length) {
					this.$store.commit("updateDocuments", data);
				}
			} catch (err) {
				console.log("error", err);
				throw new Error(err);
			}
		},
		async getCarrierProgress() {
			///////////////////////////////////////////////////////
			// :) NEED TO ADD PHYSICAL, DOCS, FLEET TO PROGRESS API //
			///////////////////////////////////////////////////////
			try {
				const response = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/progress`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const formData = response.data;

				const storeObj = {
					general: formData.generalInfoComplete,
					addresses: formData.mailingInfoComplete,
					payments: formData.paymentInfoComplete,
					fleet: formData.fleetInfoComplete,
					documents: formData.documentsComplete,
					contract: formData.signContractComplete,
					// services: formData.serviceInfoComplete,
				};
				// console.log('getCarrierProgress line 455 --- storeObj: ', storeObj);
				this.$store.commit("updateProgress", storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierPayments() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/payment`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				if (Object.keys(data).length) {
					this.$store.commit("updatePayments", data);
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (data) this.$store.commit("updateServices", data);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierNotifications() {
			const { data } = await this.$axios({
				method: "get",
				url: `${this.$hostName.apiBase}/onboarding/notifications`,
				params: {
					limit: 15,
					skip: 0,
					sortby: "createdAt",
					descending: true,
				},
				headers: {
					Authorization: `Bearer ${this.authToken}`,
				},
			});
			this.$store.commit("updateNotifications", data);
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
		orgInfo() {
			return this.$store.state.orgInfo;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		cardActions() {
			let style;
			let clickAction;

			if (this.docketValid && this.currentStep === "docket") {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (!this.docketValid && this.currentStep === "docket") {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (
				this.confirmValid &&
				this.currentStep === "confirmDot" &&
				this.selectedChoice != "No"
			) {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (
				this.confirmValid &&
				this.currentStep === "confirmDot" &&
				this.selectedChoice === "No"
			) {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (!this.confirmValid && this.currentStep === "confirmDot") {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (
				!this.createAccountLoading &&
				this.$formValid.account &&
				this.currentStep === "createAccount"
			) {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (
				(!this.$formValid.account &&
					this.currentStep === "createAccount") ||
				this.createAccountLoading
			) {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}

			return { style, clickAction };
		},
		cardActionsStyle() {
			return this.cardActions.style;
		},
		preventAction() {
			return this.cardActions.clickAction;
		},
	},
	watch: {
		"form.telephone": {
			deep: true,
			immediate: true,
			handler(numberInput) {
				numberInput += "";
				numberInput = numberInput.replace(/[^\d\(\)]/g, "");
				numberInput = numberInput
					.replace(
						/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/,
						"$2-$3-$4"
					)
					.replace(/-+$/, "");
				this.form.telephone = numberInput;
			},
		},
	},
	mounted() {
		// get FMCSA data to use for onboardingInfo
		this.getFmcsaData();
		this.selectedChoice = null;
		this.$refs.account.validate();
		this.form.firstName = this.userInfo.firstName;
		this.form.lastName = this.userInfo.lastName;
		this.form.telephone = this.userInfo.phone;
		this.form.email = this.userInfo.username;
		this.form.password = null;
	},
};
</script>
<style scoped>
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}

.smFlex {
	flex-basis: 5%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 5%;
}

.lgFlex {
	flex-basis: 95%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 95%;
}
</style>