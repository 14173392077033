<template>
	<div>
		new component help2
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>
