import moment from 'moment'

const fn = dateString => {
	if (dateString) {
		var dateObject = new Date(dateString);
		var momentObj = moment(dateObject);
		var momentString = momentObj.format('MM-DD-YYYY');
		return momentString;
	}
	return '';
};

export default fn;