<template>
	<v-container fill-height class="justify-center">
		<v-col cols="12" md="8" lg="6" class="justify-center align-center pa-0">
			<v-card>
				<v-card-title class="headline">
					Create Your Knight-Swift Logistics Account
				</v-card-title>
				<v-card-text>
					<v-form v-model="docketValid" ref="docketValid">
						<!--

							IDENTIFIER INFO

						-->
						<v-row
							v-show="currentStep === 'docket'"
							justify="center"
							align="center"
						>
							<v-col cols="12" class="pb-0">
								Please select your carrier indentifier:
							</v-col>
							<v-col cols="12">
								<v-radio-group
									v-model="selectedIdentifier"
									row
									required
									:rules="[$formRules.required]"
									class="my-0"
								>
									<v-radio
										small
										label="Interstate (hauling across state lines)"
										value="interstate"
										class="my-0"
									></v-radio>
									<v-radio
										label="Intrastate US (hauling within a single state only)"
										value="intrastate"
										class="my-0"
									></v-radio>
								</v-radio-group>
							</v-col>
							<v-col cols="12">
								<v-row class="pb-2">
									US Docket# (MC, FF, MX):
								</v-row>
								<v-row justify="start">
									<v-col cols="12" md="8" class="pa-0">
										<v-row>
											<v-select
												:items="['MC', 'FF', 'MX']"
												v-model="docketPrefix"
												outlined
												filled
												dark
												class="elevation-0"
												style="width: 100px; max-width: 100px"
												hide-details
											>
											</v-select>
											<v-text-field
												v-model="docketNumber"
												class="mx-3"
												outlined
												filled
												hide-details
												prepend-inner-icon="mdi-pound"
												@keypress="
													numOnly($event, 'USDOT')
												"
											>
											</v-text-field>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="pt-2 pl-2">
									* This docket number field is optional
								</v-row>
							</v-col>
						</v-row>
					</v-form>
					<!--

						CONFIRM DOT INFO

					-->
					<v-row
						v-show="currentStep === 'confirmDot'"
						justify="center"
						align="center"
					>
						<v-col cols="12">
							<v-row align="center">
								<span style="color: #fff; font-size: 16px">
									You are signing up:
								</span>
							</v-row>
							<v-row align="center" class="pt-4">
								<v-col class="smFlex pa-0">
									<v-icon color="primary"
										>far fa-building</v-icon
									>
								</v-col>
								<v-col class="lgFlex pa-0">
									<v-row align="center" class="fill-height">
										<v-col cols="12" class="pa-0">
											<span
												style="color: #fff; font-size: 16px"
											>
												{{
													onboardingInfo.general
														.legalName
												}}
											</span>
										</v-col>
										<v-col
											cols="12"
											v-if="
												onboardingInfo.general
													.doingBusinessAsName
											"
											class="pa-0 pt-1"
										>
											<span
												style="color: #fff; font-size: 14px"
											>
												DBA:
												{{
													onboardingInfo.general
														.doingBusinessAsName
												}}
											</span>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12">
							<v-row align="center">
								<span style="color: #fff; font-size: 16px">
									Located in:
								</span>
							</v-row>
							<v-row align="center" class="pt-4">
								<v-col class="pa-0 smFlex">
									<v-icon color="primary"
										>fas fa-map-marked</v-icon
									>
								</v-col>
								<v-col class="pa-0 lgFlex">
									<v-row
										align="center"
										class="fill-height"
										v-if="onboardingInfo.mailing"
									>
										<span
											v-if="onboardingInfo.mailing.city"
											style="color: #fff; font-size: 16px"
										>
											{{ onboardingInfo.mailing.city }},
											{{ onboardingInfo.mailing.state }}
										</span>
										<span
											v-else
											style="color: #fff; font-size: 16px"
										>
											No location information on file with
											FMCSA
										</span>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12">
							<v-form v-model="confirmValid" ref="confirmValid">
								<v-row>
									<v-col class="pa-0" cols="12" sm="4">
										<v-select
											:items="verifiedInfo"
											v-model="selectedChoice"
											class="justify-center"
											label="Is this information correct?"
											outlined
											filled
											@input="confirmCompany"
											required
											:rules="[$formRules.required]"
										></v-select>
									</v-col>
								</v-row>
							</v-form>
						</v-col>
					</v-row>
					<!--

						CREATE ACCOUNT FIELDS

					-->
					<v-row
						v-show="currentStep === 'createAccount'"
						justify="center"
						align="center"
					>
						<v-col cols="12">
							<v-form v-model="$formValid.account" ref="account">
								<template
									v-for="[item, value] in Object.entries(
										form
									)"
								>
									<v-col
										:key="item"
										cols="12"
										class="pa-0 px-2 ma-0"
									>
										<v-select
											v-if="item === 'companyPosition'"
											v-model="selectedPosition"
											:items="value"
											:label="labelFilter(item)"
											outlined
											filled
											required
											item-text="text"
											item-value="value"
											:rules="[$formRules.required]"
										>
										</v-select>
										<v-text-field
											v-model="form[item]"
											v-else
											required
											outlined
											filled
											@keypress="numOnly($event, item)"
											:maxlength="maxLength(item)"
											:type="hidePass(item)"
											:label="labelFilter(item)"
											:rules="ruleMethod(item)"
											:append-icon="showPass(item)"
											@click:append="show = !show"
										>
										</v-text-field>
									</v-col>
								</template>
							</v-form>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions
					class="cardActions"
					:style="cardActionsStyle"
					@click="verifyHandler"
				>
					<v-row
						justify="center"
						align="center"
						v-if="!createAccountLoading"
					>
						<span v-if="currentStep != 'createAccount'"
							>Continue</span
						>
						<span v-else>Create Account</span>
					</v-row>
					<v-row justify="center" align="center" v-else>
						<v-progress-circular
							indeterminate
							color="white"
							size="16"
						></v-progress-circular>
					</v-row>
					<!-- <v-row
						justify="center"
						align="center"
					>
						<span>Cancel</span>
					</v-row> -->
				</v-card-actions>
				<v-card-actions
					class="cardActions"
					style="cursor: pointer;"
				>
				<v-row
					justify="center"
					align="center"
					@click="cancelDialog = true"
				>
					<v-dialog 
						v-model="cancelDialog"
						width="500"
					>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">Cancel</span>
						</template>
						<v-card class="dialogCard">
							<v-card-title class="titleContent headline">
								Cancel Onboarding Process
							</v-card-title>
							<v-card-text>
								<p class="textContent subheading">
									Are you sure you want to cancel the onboarding process?
								</p>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									to="/dashboard"
									outlined
									rounded
									color="primary"
								>
									Yes
								</v-btn>
								<v-btn
									@click="cancelDialog = false"
									outlined
									rounded
									color="primary"
								>
									No
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-row>
				</v-card-actions>
			</v-card>
		</v-col>
		<basicDialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="returnLandingPage"
		/>
	</v-container>
</template>
<script>
// libraries
import jwt from "jsonwebtoken";

// mixins
import hashPassword from "@/mixins/hashPassword.js";

import basicDialogMixin from "@/mixins/basicDialogMixin";
import numOnly from "@/mixins/formBuilding/numOnly";

export default {
	name: "Verify_Company",
	components: {},
	props: [],
	mixins: [
		hashPassword,
		//
		basicDialogMixin,
		//
		numOnly,
	],
	data() {
		return {
			authToken: null,
			//
			cancelDialog: false,
			selectedChoice: null,
			verifiedInfo: ["Yes", "No"],
			selectedIdentifier: null,
			currentStep: "docket",
			docketValid: false,
			confirmValid: false,
			docketPrefix: "MC",
			docketNumber: null,
			selectedPosition: null,
			show: false,
			createAccountLoading: false,
			form: {
				firstName: null,
				lastName: null,
				companyPosition: [
					{ text: "Operations Manager", value: "operationsManager" },
					{ text: "Corporate Manager", value: "corporateManager" },
					{ text: "Owner", value: "owner" },
					{ text: "Dispatcher", value: "dispatcher" },
					{ text: "Finance/Accounting", value: "finance" },
					{ text: "Other", value: "other" },
				],
				telephone: null,
				email: null,
				password: null,
				confirmPass: null,
			},
		};
	},
	methods: {
		confirmCompany(val) {
			if (val === "Yes") {
				this.$formValid.verify = true;
			}
			if (val === "No") {
				this.basicDialog = {
					value: true,
					title: "Let's get that USDOT corrected...",
					text:
						"You will be redirected to our homepage - please re-enter your USDOT number and verify your information again.",
					hasAction: true,
					actionButton: {
						action: "close",
						text: "Ok",
						color: "error",
						outlined: true,
					},
					width: "450",
				};
				this.$formValid.verify = false;
			}
		},
		docket() {
			if (this.preventAction) {
				this.currentStep = "confirmDot";
			}
		},
		confirmDot() {
			if (this.preventAction) {
				this.currentStep = "createAccount";
			}
		},
		async createAccount() {
			if (this.preventAction) {
				this.createAccountLoading = true;
				const postObj = {
					firstName: this.form.firstName,
					lastName: this.form.lastName,
					username: this.form.email,
					password: this.hashPassword(this.form.confirmPass),
					dotNumber: this.onboardingInfo.dotNumber.trim(),
					identifier: this.selectedIdentifier,
					phone: this.form.telephone,
					companyRole: this.selectedPosition,
					info: {
						mcNumber: null,
						intraState: null,
					},
					mailing: this.onboardingInfo.mailing,
					physical: this.onboardingInfo.physical,
					contact: {
						doingBusinessAsName: this.onboardingInfo.general
							.doingBusinessAsName,
						legalName: this.onboardingInfo.general.legalName,
						emailAddress: this.onboardingInfo.general.emailAddress,
						telephone: this.onboardingInfo.general.telephone,
						fax: this.onboardingInfo.general.fax,
					},
				};

				if (this.docketNumber) {
					postObj.docketNumber =
						this.docketPrefix + this.docketNumber;
				}

				try {
					const response = await this.$axios({
						method: "post",
						url: `${this.$hostName.selectProfiles}/api/v0/unprotected/addUser/onboarding/signup`,
						data: postObj,
					});
					this.$router.push("/loadinginformation");
					this.createAccountLoading = false;

					if (response.data) {
						this.loginCarrier(response.data);
					}
				} catch (error) {
					let text;

					if (error.response.status === 409) {
						text = error.response.data.message;
					} else {
						text =
							"An error occured while creating your account. Your information was not saved at this time. Please try again later.";
					}
					//
					this.basicDialog = {
						value: true,
						title: "We've encountered a problem",
						text,
						hasAction: true,
						actionButton: {
							action: "close",
							text: "Ok",
							color: "error",
							outlined: true,
						},
						width: "450",
					};
					console.log(error);
				} finally {
					// do nothing
				}
			}
		},
		verifyHandler() {
			this[`${this.currentStep}`]();
		},
		/////////////////////////////
		// PASSWORD FIELD RELATED  //
		/////////////////////////////
		passMatch() {
			return (
				this.form.password === this.form.confirmPass ||
				"Passwords Do Not Match"
			);
		},
		hidePass(item) {
			// "show1 ? 'text' : 'password'"
			let fieldType;
			switch (item) {
				case "password":
					if (this.show) fieldType = "text";
					else fieldType = "password";
					break;
				case "confirmPass":
					if (this.show) fieldType = "text";
					else fieldType = "password";
					break;
				default:
					fieldType = "text";
			}
			return fieldType;
		},
		showPass(item) {
			let iconType;
			switch (item) {
				case "confirmPass":
					if (this.show) iconType = "far fa-eye";
					else iconType = "far fa-eye-slash";
					break;
				default:
					iconType = "";
			}
			return iconType;
		},
		////////////////////////////////////////////////////////////////
		// YOU PROBABLY DONT NEED THESE JUST ADD TO THE GLOBAL MIXINS //
		////////////////////////////////////////////////////////////////
		labelFilter(val) {
			let text;
			switch (val) {
				case "firstName":
					text = "First Name";
					break;
				case "lastName":
					text = "Last Name";
					break;
				case "companyPosition":
					text = "Company Position";
					break;
				case "telephone":
					text = "Telephone";
					break;
				case "email":
					text = "Email";
					break;
				case "password":
					text = "Password";
					break;
				case "confirmPass":
					text = "Confirm Password";
					break;
				default:
					text = val;
			}
			return text;
		},
		ruleMethod(val) {
			let rule;
			switch (val) {
				case "telephone":
					rule = [
						this.$formRules.required,
						this.$formRules.phoneValid,
					];
					break;
				case "email":
					rule = [this.$formRules.required, this.$formRules.email];
					break;
				case "password":
					rule = [this.$formRules.passwordValid];
					break;
				case "confirmPass":
					rule = [this.$formRules.passwordValid, this.passMatch];
					break;
				default:
					rule = [this.$formRules.required];
			}
			return rule;
		},
		maxLength(item) {
			let length;
			switch (item) {
				case "telephone":
					length = 12;
					break;
				case "password":
					length = 88;
					break;
				case "confirmPass":
					length = 88;
					break;
				default:
					length = 100;
			}
			return length;
		},
		/////////////////////////
		// ALL THE LOGIN STEPS //
		/////////////////////////
		async loginCarrier({ user }) {
			this.loginError = false;
			this.loginErrorMsg = null;
			this.loading = true;

			const postObj = {
				username: user.username,
				password: this.hashPassword(this.form.password),
				source: "onboarding",
			};

			try {
				const response = await this.$axios({
					method: "post",
					url: `${this.$hostName.selectProfiles}/api/v0/unprotected/authUser/login`,
					data: postObj,
				});
				// this.$router.push('/loadinginformation');

				const { authToken: token, user } = response.data;

				// parse JWT
				const decoded = jwt.decode(token);

				// build an auth
				const authObj = {
					token,
					expTime: decoded.exp,
					refreshTime: Date.now() / 1000 + 60 * 15,
					loggedIn: true,
				};

				this.authToken = token;

				// build a userObj
				const userObj = {
					username: user.username,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					companyRole: user.companyRole,
				};

				// put important stuff into the store.
				this.$store.commit("updateAuth", authObj);
				this.$store.commit("updateUser", userObj);
				this.$store.commit("updatePermissions", decoded.permissions);
				this.$store.commit("updateOrg", decoded.organization);

				// console.log('this store: ', this.$store.state.auth);

				// retrieve saved carrier info
				await this.getCarrierInfo();
				await this.getDocInfo();
				await this.getCarrierProgress();
				await this.getCarrierPayments();
				await this.getCarrierServices();
				await this.getCarrierNotifications();

				setTimeout(() => {
					this.$router.push("/dashboard");
				}, 2500);
			} catch (err) {
				console.log(err);
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text:
						"There was an error logging you in. Please check username and password or try again later.",
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Ok",
						color: "error",
					},
					width: "350",
				};
			} finally {
				this.loading = false;
			}
		},
		async getCarrierInfo() {
			try {
				const response = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const formData = response.data;

				formData.general = { ...formData.contact, ...formData.info };
				delete formData.general.mcNumber;
				delete formData.general.intraState;

				const mailingData = {};
				const physicalData = {};
				const generalData = {};
				const companyFleetData = {};
				const ownerFleetData = {};

				Object.entries(formData.mailing).forEach(([key, value]) => {
					mailingData[key] = value;
				});
				Object.entries(formData.physical).forEach(([key, value]) => {
					physicalData[key] = value;
				});
				Object.entries(formData.general).forEach(([key, value]) => {
					generalData[key] = value;
				});

				if (formData.fleet) {
					if (formData.fleet.company) {
						Object.entries(formData.fleet.company).forEach(
							([key, value]) => {
								companyFleetData[key] = value;
							}
						);
					}
					if (formData.fleet.owner) {
						Object.entries(formData.fleet.owner).forEach(
							([key, value]) => {
								ownerFleetData[key] = value;
							}
						);
					}
				}
				const storeObj = {
					dotNumber: formData.dotNumber,
					carrierCode: formData.carrierCode,
					status: formData.status,
					addresses: {
						mailing: { ...mailingData },
						physical: { ...physicalData },
					},
					general: { ...generalData },
					fleet: {
						company: { ...companyFleetData },
						owner: { ...ownerFleetData },
					},
				};
				this.$store.commit("updateOnboardingInfo", storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getDocInfo() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/documents/list`,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (Object.keys(data).length) {
					this.$store.commit("updateDocuments", data);
				}
			} catch (err) {
				console.log("error", err);
				throw new Error(err);
			}
		},
		async getCarrierProgress() {
			///////////////////////////////////////////////////////
			// :) NEED TO ADD PHYSICAL, DOCS, FLEET TO PROGRESS API //
			///////////////////////////////////////////////////////
			try {
				const response = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/progress`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const formData = response.data;

				const storeObj = {
					general: formData.generalInfoComplete,
					addresses: formData.mailingInfoComplete,
					payments: formData.paymentInfoComplete,
					fleet: formData.fleetInfoComplete,
					documents: formData.documentsComplete,
					contract: formData.signContractComplete,
					// services: formData.serviceInfoComplete,
				};
				// console.log('getCarrierProgress line 455 --- storeObj: ', storeObj);
				this.$store.commit("updateProgress", storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierPayments() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/payment`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				if (Object.keys(data).length) {
					this.$store.commit("updatePayments", data);
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (data) this.$store.commit("updateServices", data);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierNotifications() {
			const { data } = await this.$axios({
				method: "get",
				url: `${this.$hostName.apiBase}/onboarding/notifications`,
				params: {
					limit: 15,
					skip: 0,
					sortby: "createdAt",
					descending: true,
				},
				headers: {
					Authorization: `Bearer ${this.authToken}`,
				},
			});
			this.$store.commit("updateNotifications", data);
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
		cardActions() {
			let style;
			let clickAction;

			if (this.docketValid && this.currentStep === "docket") {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (!this.docketValid && this.currentStep === "docket") {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (
				this.confirmValid &&
				this.currentStep === "confirmDot" &&
				this.selectedChoice != "No"
			) {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (
				this.confirmValid &&
				this.currentStep === "confirmDot" &&
				this.selectedChoice === "No"
			) {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (!this.confirmValid && this.currentStep === "confirmDot") {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}
			if (
				!this.createAccountLoading &&
				this.$formValid.account &&
				this.currentStep === "createAccount"
			) {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}
			if (
				(!this.$formValid.account &&
					this.currentStep === "createAccount") ||
				this.createAccountLoading
			) {
				style = { "background-color": "#282828", color: "#424242" };
				clickAction = false;
			}

			return { style, clickAction };
		},
		cardActionsStyle() {
			return this.cardActions.style;
		},
		preventAction() {
			return this.cardActions.clickAction;
		},
	},
	watch: {
		"form.telephone": {
			deep: true,
			immediate: true,
			handler(numberInput) {
				numberInput += "";
				numberInput = numberInput.replace(/[^\d\(\)]/g, "");
				numberInput = numberInput
					.replace(
						/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/,
						"$2-$3-$4"
					)
					.replace(/-+$/, "");
				this.form.telephone = numberInput;
			},
		},
	},
	mounted() {
		this.selectedChoice = null;
	},
};
</script>

<style scoped>
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
.smFlex {
	flex-basis: 5%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 5%;
}
.lgFlex {
	flex-basis: 95%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 95%;
}
</style>
