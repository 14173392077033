<template>
	<v-container fill-height>
		<HeroImage></HeroImage>
		<v-row align="center" class="mt-0" justify="center">
			<v-col cols="12" sm="10" lg="8" style="z-index:5;">
				<img src="../../assets/blackWhiteLogo.svg" />
			</v-col>
			<v-col cols="12" sm="10" lg="8" justify-self="center">
				<v-card class="elevation-12 pb-4">
					<ResetCard />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import HeroImage from '../HeroImage';
import ResetCard from './components/ResetCard';

export default {
	data: () => ({
		//
	}),
    components: {
        HeroImage, ResetCard
    },
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>

<style scoped>
/* */
img {
	z-index: 10;
	height: 75px;
}
.pointer {
	cursor: pointer;
}
</style>
