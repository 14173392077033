<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="Document Management"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>
		<v-col cols="12" lg="10" class="justify-center pa-0 mt-5" align-self="start">
			<v-card class="px-4">
				<v-col cols="12">
					<v-row class="px-4 pt-4">
						My Documents
					</v-row>
					<v-row class="mb-2" justify="end">
						<div style="position: relative" class="pb-1 mx-2">
							<v-menu offset-y max-width="220px">
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" small text color="secondary">
										View Document Selection
									</v-btn>
								</template>
								<v-card class="scrollyMenu">
									<v-row>
										<template v-for="(item, index) in viewMenuItems">
											<v-col
												:key="`menu${index}`"
												cols="12"
												class="py-0"
												style="cursor: pointer"
											>
												<v-row>
													<v-col
														cols="12"
														class="pa-0"
														@click="selectedViewType = item.value"
													>
														<v-row class="py-2">
															{{ item.text }}
														</v-row>
													</v-col>
													<v-col
														cols="12"
														class="pa-0"
														v-if="index != viewMenuItems.length - 1"
													>
														<v-divider></v-divider>
													</v-col>
												</v-row>
											</v-col>
										</template>
									</v-row>
								</v-card>
							</v-menu>
						</div>
					</v-row>
					<v-row>
						<v-col cols="12" class="pa-0 px-3 pr-4">
							<v-row style="color: #3bc1e6">
								{{ docFilter(selectedViewType) }} Documents
							</v-row>
						</v-col>
					</v-row>
					<v-row class="myDocumentsScroll px-3">
						<v-col cols="12" class="pa-0">
							<v-row justify="space-between" v-if="documentData[selectedViewType]">
								<template v-if="documentData[selectedViewType].documentId">
									<ViewDocumentCard
										:rowData="documentData[selectedViewType]"
										@viewDocument="viewDocument"
									></ViewDocumentCard>
									<template
										v-for="(item, index) in documentData[selectedViewType]
											.archives"
									>
										<ViewDocumentCard
											:key="`archive${index}`"
											:rowData="item"
											@viewDocument="viewDocument"
										></ViewDocumentCard>
									</template>
								</template>
								<template v-else>
									<v-col cols="12" class="text-center pa-0 mt-4">
										No - {{ docFilter(selectedViewType) }} - Documents Uploaded
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-col>
		<v-col cols="12" lg="10" class="justify-center align-center ma-0 pa-0 mt-5">
			<v-row class="px-4 py-4">
				<v-col cols="12" class="pa-0">
					Upload Documents
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6" class="pa-0">
					<v-row>
						<v-col class="documentColors text-center">
							Required Documents
						</v-col>
					</v-row>
					<v-row>
						<template v-for="(item, index) in documentData">
							<v-col
								:key="`doc${index}`"
								v-if="requiredDocs(item.value)"
								cols="6"
								md="4"
								:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUp' : ''"
							>
								<DocumentCard
									:documentObject="item"
									@openUploadDialog="openUploadDialog"
									@selectAssistance="selectAssistance"
								></DocumentCard>
							</v-col>
						</template>
					</v-row>
				</v-col>
				<v-col cols="12" md="6" class="pa-0">
					<v-row class="documentColors text-center">
						<v-col>
							<span>
								Optional Documents
							</span>
						</v-col>
					</v-row>
					<v-row justify="end">
						<template v-for="(item, index) in documentData">
							<v-col
								:key="`doc${index}`"
								v-if="otherDocs(item.value)"
								cols="6"
								md="4"
								:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUp' : ''"
							>
								<DocumentCard
									:documentObject="item"
									@openUploadDialog="openUploadDialog"
									@selectAssistance="selectAssistance"
								></DocumentCard>
							</v-col>
						</template>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<UploadBox
			v-model="uploadDialog"
			:docType="docType"
			:addDocAnimation="addDocAnimation"
			:docInfoSuccess="docInfoSuccess"
			@closeUploadDialog="closeUploadDialog"
			@uploadButton="addDocument"
		>
		</UploadBox>
		<AssistanceDialog
			v-model="openAssistanceDialog"
			:assistDocument="assistDocument"
			@viewDocument="viewDocument"
			@closeAssistanceDialog="closeAssistanceDialog"
		>
		</AssistanceDialog>
	</v-row>
</template>
<script>
import UploadBox from './UploadBox.vue'
import AssistanceDialog from './AssistanceDialog.vue'
import DocumentCard from './DocumentCard.vue'
import ViewDocumentCard from './ViewDocumentCard.vue'
import getCarrierProgress from '@/mixins/getCarrierProgress'

export default {
	name: 'Documents',
	components: {
		UploadBox,
		AssistanceDialog,
		DocumentCard,
		ViewDocumentCard
	},
	mixins: [getCarrierProgress],
	data() {
		return {
			viewMenuItems: [
				{ text: 'Affidavit', value: 'aff' },
				{ text: 'CARB', value: 'carb' },
				{ text: 'Certificate of Insurance', value: 'insur' },
				{ text: 'DOT Inspections', value: 'dotInsp' },
				{ text: 'Diversity Certificate', value: 'minorCert' },
				// { text: 'FSMA Addendum', value: 'fsma' },
				{ text: 'General Contract', value: 'generalContract' },
				{ text: 'HAZMAT', value: 'hazm' },
				{ text: 'Letter of Authority', value: 'auth' },
				{ text: 'Notice of Assignment', value: 'noa' },
				{ text: 'Other', value: 'other' },
				{ text: 'Power Only Agreement', value: 'poAg' },
				{ text: 'Reefer TRU', value: 'reef' },
				{ text: 'Revised Contract', value: 'rev' },
				{ text: 'Scheduled Autos', value: 'auto' },
				{ text: 'W8 Tax Form', value: 'wEight' },
				{ text: 'W9 Tax Form', value: 'wNine' }
			],
			selectedViewType: 'auth',
			documentData: {
				aff: { value: 'aff' },
				auth: { value: 'auth' },
				auto: { value: 'auto' },
				carb: { value: 'carb' },
				dotInsp: { value: 'dotInsp' },
				// fsma: { value: 'fsma' },
				insur: { value: 'insur' },
				noa: { value: 'noa' },
				other: { value: 'other' },
				poAg: { value: 'poAg' },
				reef: { value: 'reef' },
				rev: { value: 'rev' },
				wEight: { value: 'wEight' },
				wNine: { value: 'wNine' },
			},
			uploadDialog: false,
			docType: null,
			selectedFile: null,
			openAssistanceDialog: false,
			assistDocument: null,
			//Animations
			addDocAnimation: false,
			docInfoSuccess: false,
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null
				},
				width: null
			}
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status
		},
		docInfo() {
			return this.$store.state.documents
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'documents',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.docsValid // Controls enable/disable for Continue button
			}
		},
		docsValid() {
			return this.$store.state.progress.documents.valid
		},
		confirmMinority() {
			return this.$store.state.onboardingInfo.general.minority
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo
		},
		userInfo() {
			return this.$store.state.userInfo
		}
	},
	methods: {
		async saveSignupHandler(saveType) {
			const pageType = 'documents'
			const nextPage = 'contract'

			if (this.docsValid) {
				// IF SUCCESS PUSH FWD
				if (saveType === 'continue') {
					this.$router.push(`/${nextPage}`)
				} else if (saveType === 'nothing') {
					// do nothing
				} else {
					this.$router.push('/dashboard')
				}
			}
		},
		requiredDocs(item) {
			let identifier

			if (
				item === 'MC Letter of Authority' ||
				item === 'W9 Tax Form' ||
				item === 'Certificate of Insurance'
			) {
				identifier = true
			}

			return identifier
		},
		otherDocs(item) {
			return (
				item === 'W8 Tax Form' ||
				item === 'Scheduled Autos' ||
				item === 'DOT Inspections' ||
				item === 'Other' ||
				item === 'Reefer TRU' ||
				// item === 'FSMA Addendum' ||
				item === 'Affidavit' ||
				(item === 'Minority Certification' && this.confirmMinority !== 'notMinority')
			)
		},
		closeUploadDialog() {
			this.$background.blur = false
			this.uploadDialog = false
		},
		openUploadDialog(val) {
			this.$background.blur = true
			this.docType = val
			this.uploadDialog = true
		},
		selectFileType(item) {
			this.selectedFile = item
		},
		docFilter(item) {
			let text
			switch (item) {
				case 'auth':
					text = 'Letter of Authority'
					break
				case 'wNine':
					text = 'W9 Tax Form'
					break
				case 'insur':
					text = 'Certificate of Insurance'
					break
				case 'noa':
					text = 'Notice of Assignment'
					break
				case 'carb':
					text = 'CARB'
					break
				case 'hazm':
					text = 'HAZMAT'
					break
				case 'wEight':
					text = 'W8 Tax Form'
					break
				case 'poAg':
					text = 'Power Only Agreement'
					break
				case 'auto':
					text = 'Scheduled Autos'
					break
				// case 'fsma':
				// 	text = 'FSMA Addendum'
				// 	break
				case 'generalContract':
					text = 'General Contract'
					break
				case 'minorCert':
					text = 'Diversity Certificate'
					break
				case 'dotInsp':
					text = 'DOT Inspections'
					break
				case 'aff':
					text = 'Affidavit'
					break
				case 'other':
					text = 'Other'
					break
				case 'reef':
					text = 'Reefer TRU'
					break
				default:
					text = item
			}
			return text
		},
		async addDocument(val) {
			const formData = new FormData()

			formData.append(this.docType, val)

			try {
				const response = await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/documents/add`,
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						Authorization: 'Bearer ' + this.auth.token
					}
				})
				// IF CARRIER HAS ALREADY SIGNED CONTRACT
				// SEND A NOTIFICATION TO THE CRD UPDATE QUEUE IN NEXUS
				if (this.carrierStatus !== 'incomplete') {
					try {
						formData.append('priority', '1')
						formData.append('carrierName', this.onboardingInfo.general.legalName)
						formData.append('dotNumber', this.onboardingInfo.dotNumber)
						formData.append('tmwCode', this.onboardingInfo.carrierCode)
						formData.append('mcNumber', this.onboardingInfo.mcNumber)
						formData.append('uploadedSAMAccount', 'EXTERNAL')
						formData.append('external', true)
						formData.append('uploadedBy', this.userInfo.email.toUpperCase())
						formData.append(
							'notes',
							JSON.stringify({
								team: 'NA',
								author: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
								text:
									'New update request from Carrier Onboarding site. Please see attached document(s).',
								date: new Date().toISOString()
							})
						)

						const { data } = await this.$axios({
							method: 'post',
							url: `${this.$hostName.carrierRelationsAPI}/RequestDocUpdate`,
							data: formData
						})
					} catch (err) {
						console.error(err)
						throw new Error(err)
					}
				}

				this.addDocAnimation = true
				await this.getCarrierProgress()

				setTimeout(() => {
					this.addDocAnimation = false
					this.docInfoSuccess = true
				}, 2000)

				setTimeout(() => {
					this.docInfoSuccess = false
					this.closeUploadDialog()
				}, 5000)

				this.retrieveDocs()
			} catch (error) {
				console.log(error)
			}
		},
		async retrieveDocs() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/list`,
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: `Bearer ${this.auth.token}`
					}
				})
				// REMOVE 'fsma' DOCTYPE PER ROXANNE - 09/15/2022 - ALLISON
				const allowed = ['aff', 'auth', 'auto', 'dotInsp', 'insur', 'noa', 'other', 'poAg', 'reef', 'rev', 'wEight', 'wNine'];

				if (this.confirmMinority !== 'notMinority') allowed.push('minorCert')

				// DON'T OVERWRITE DOCUMENTDATA IF THERE ARE NO DOCS RETURNED
				if (data && Object.keys(data).length !== 0) {
					Object.keys(data).forEach(key => {
						this.$set(data[key], 'file', data[key].documentId ? data[key].value : null)
					})

					this.$store.commit('updateDocuments', data)

					Object.keys(data).forEach(key => {
						if (allowed.includes(key)) {
							this.documentData[key] = Object.assign(
								this.documentData[key],
								this.docInfo[key]
							)
							if (this.documentData[key].documentId) {
								this.$set(
									this.documentData[key],
									'file',
									this.documentData[key].value
								)
							}
						}
					})
				}
			} catch (err) {
				console.log('error', err)
				throw new Error(err)
			}
		},
		async viewDocument(documentId) {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId
					},
					headers: {
						Authorization: 'Bearer ' + this.auth.token
					},
					responseType: 'blob'
				})

				this.documentBlob = URL.createObjectURL(response.data)

				const link = document.createElement('a')
				link.setAttribute('type', 'hidden')
				link.target = '_blank'
				link.href = this.documentBlob
				document.body.appendChild(link)
				link.click()
				link.remove()
			} catch (error) {
				console.error('viewDocument error:', error)
			}
		},
		selectAssistance(val) {
			this.assistDocument = val.value
			this.$background.blur = true
			this.openAssistanceDialog = true
		},
		closeAssistanceDialog() {
			this.$background.blur = false
			this.openAssistanceDialog = false
		}
	},
	async mounted() {
		// ONLY UPDATE DOCUMENTDATA IF DOCINFO IS NOT EMPTY - OTHERWISE LEAVE IT AS BASE OBJECT
		if (Object.keys(this.docInfo).length !== 0) {
			this.documentData = Object.assign({}, this.docInfo);
		}
	}
};
</script>
<style scoped>
.lgAndUp {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	max-width: 30%;
}

.scrollyMenu {
	min-height: 140px;
	max-height: 140px;
	overflow-y: scroll;
}

.scrollyMenu::-webkit-scrollbar {
	width: 10px;
	background: transparent;
	border-radius: 10px;
}

.scrollyMenu::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}

.scrollyMenu::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-darken2);
	border-radius: 10px;
}

.myDocumentsScroll {
	min-height: 140px;
	max-height: 140px;
	overflow-y: scroll;
	border-radius: 10px;
	background: repeating-linear-gradient(
		-55deg,
		#121212,
		#121212 2.5px,
		#1e1e1e 2.5px,
		#1e1e1e 5px
	);
}

.myDocumentsScroll::-webkit-scrollbar {
	width: 8px;
	background: transparent;
	border-radius: 10px;
}

.myDocumentsScroll::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}

.myDocumentsScroll::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-base);
	border-radius: 10px;
}

.documentColors {
	color: #d8a1ff;
	font-weight: 600;
}
</style>
