<template>
	<v-col cols="12" class="pa-0">
		<v-row align="center">
			<v-col cols="9">
				<v-row>
					<v-col cols="12" md="4" class="py-0">
						<span class="truncate">
							{{ subTitleFilter(rowKey) }}
						</span>
					</v-col>
					<v-col cols="12" md="4" class="py-0">
						{{ statusFilter(rowData.currentStatus) }}
					</v-col>
					<v-col cols="12" md="4" class="py-0">
						{{ rowData.lastUpdateTime | momentShortDate }}
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="3" class="py-0">
				<v-row justify="end">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								color="primary darken-2"
								class="mx-1"
								v-on="on"
								@click="$emit('viewServiceNotes', { rowData, rowKey })"
							>
								<v-icon>mdi-notebook-multiple</v-icon>
							</v-btn>
						</template>
						<span>View Notes</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								color="primary darken-2"
								class="mx-1"
								v-on="on"
								:disabled="!serviceType"
								@click="$emit('selectService', { rowData, rowKey })"
							>
								<v-icon>mdi-file-sign</v-icon>
							</v-btn>
						</template>
						<span v-if="rowKey === 'powerOnly'">Sign New Contract</span>
						<span v-else>Update Request</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								color="primary darken-2"
								class="mx-1"
								v-on="on"
								:disabled="!serviceDoc"
								@click="$emit('viewService', { rowData, rowKey })"
							>
								<v-icon>mdi-file-eye</v-icon>
							</v-btn>
							</template>
						<span>View Document</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								color="primary darken-2"
								class="mx-1"
								v-on="on"
								@click="$emit('stopService', { rowData, rowKey })">
								<v-icon>mdi-cog-off</v-icon>
							</v-btn>
							</template>
						<span>Stop Service</span>
					</v-tooltip>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	name: 'Service_MyServices_RowCard',
	props: {
		rowData: { type: Object, default: () => {} },
		rowKey: { type: String, default: null },
		index: { type: Number, default: 0 },
	},
	data() {
		return {};
	},
	methods: {
		statusFilter(item) {
			let text;
			switch (item) {
				case 'int':
					text = 'Pending Approval';
					break;
				case 'apr':
					text = 'Service Active';
					break;
				case 'dny':
					text = 'Service Denied';
					break;
				case 'nmi':
					text = 'Need More Info';
					break;
				default:
					text = item;
			}
			return text;
		},
		titleFilter(item) {
			let text;
			switch (item) {
				case 'sc':
					text = 'SPECIAL CERTIFICATIONS';
					break;
				case 'ss':
					text = 'SPECIAL SERVICES';
					break;
				case 'cert':
					text = 'CERTIFICATIONS';
					break;
				case 'intnl':
					text = 'INTERNATIONAL';
					break;
				case 'tracking':
					text = 'TRACKING';
					break;
				default:
					text = item;
			}
			return text;
		},
		subTitleFilter(item) {
			let text;
			switch (item) {
				// case 'fsmaRegulated':
				// 	text = 'FSMA';
				// 	break;
				case 'hazm':
					text = 'Hazmat';
					break;
				case 'liftGate':
					text = 'Lift Gate';
					break;
				case 'tanker':
					text = 'Tanker';
					break;
				case 'dropTrailers':
					text = 'Drop Trailer';
					break;
				case 'intermodal':
					text = 'Intermodal';
					break;
				case 'powerOnly':
					text = 'Power Only';
					break;
				case 'teamExpedited':
					text = 'Expedited';
					break;
				case 'carb':
					text = 'CARB';
					break;
				case 'smartway':
					text = 'Smartway';
					break;
				case 'canada':
					text = 'Canada';
					break;
				case 'mexico':
					text = 'Mexico';
					break;
				case 'gps':
					text = 'GPS';
					break;
				case 'truckerTools':
					text = 'Trucker Tools';
					break;
				case 'twic':
					text = 'TWIC';
					break;
				case 'macropoint':
					text = 'Macropoint';
					break;
				case 'reefer':
					text = 'Refrigerated Trailer';
					break;
				default:
					text = item;
			}
			return text;
		},
		async viewDocument(item) {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId: this.documentInfo[item].documentId,
					},
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
					responseType: 'blob',
				});

				this.documentBlob = URL.createObjectURL(response.data);

				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.target = '_blank';
				link.href = this.documentBlob;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (error) {
				console.error('viewDocument error:', error);
			}
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		serviceInfo() {
			return this.$store.state.services;
		},
		documentInfo() {
			return this.$store.state.documents;
		},
		rowCardStyle() {
			let color;
			if (this.index % 2 === 0) color = '#1c1c1c';
			else color = '#1E1E1E';
			return color;
		},
		serviceType() {
			let bool;
			if (this.rowKey === 'carb' || this.rowKey === 'hazm' || this.rowKey === 'powerOnly')
				bool = true;
			else bool = false;

			return bool;
		},
		serviceDoc() {
			let bool;
			if (this.rowData.documentId) bool = true;
			else bool = false;

			return bool;
		},
	},
};
</script>

<style scoped>
.cardAction {
	background-color: #282828;
	border-radius: 0 0 10px 10px;
}
.cardActionContent {
	font-size: 12px;
	border-right: 1px solid #1e1e1e;
}
.active {
	cursor: pointer;
}
</style>
