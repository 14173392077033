//////////////////////////////////////////////////////////////////////////////////////////////////////////
// IF ANYTHING IN STATE IS UPDATED THAT WOULD BE RESET ON LOGOUT YOU NEED TO UPDATE THE RESET MUTATIONS //
//////////////////////////////////////////////////////////////////////////////////////////////////////////

export default {
	auth: {
		// loggedIn: null,
		// token: null,
		// expTime: null,
	},
	userInfo: {},
	orgInfo: {},
	permissions: [],
	notifications: {
		list: [],
		total: 0
	},
	////////////////////////////////////
	// Carrier Initial Onboarding obj //
	////////////////////////////////////
	onboardingInfo: {
		dotNumber: null,
		carrierCode: null,
		mcNumber: null,
		intraState: null,
		status: null,
		addresses: {
			mailing: {
				address1: null,
				address2: null,
				city: null,
				state: null,
				zip: null,
				country: null,
			},
			physical: {
				address1: null,
				address2: null,
				city: null,
				state: null,
				zip: null,
				country: null,
			}
		},
		general: {
			legalName: null,
			doingBusinessAsName: null,
			emailAddress: null,
			telephone: null,
			extension: null,
			website: null,
			fax: null,
			scacNo: null,
			taxId: null,
			minority: null,
			alternates: [],
		},
		fleet: {
			company: {
				power: 0,
				drivers: 0,
				numTrailers: 0,
				dry: {
					amount: 0,
					hasCurtainSide: false,
					hasOpentop: false,
					hasAirRide: false,
					hasPlate: false,
					hasDry: false,
					hasLiftGate: false,
				},
				flatbed: {
					amount: 0,
					has53: false,
					has48: false,
					hasStepDeck: false,
					hasLowboy: false,
					hasConestoga: false,
					hasDropTrailers: false,
				},
				reefer: {
					amount: 0,
					hasMultiTemp: false,
					hasRemoteTemp: false,
					hasRgn: false,
				},
				other: {
					description: null,
					amount: 0,
				},
				carHaul: {
					amount: 0
				},
				highCube: {
					amount: 0
				},
			},
			owner: {
				drivers: 0,
				power: 0,
				numTrailers: 0,
				dry: {
					amount: 0,
					hasCurtainSide: false,
					hasOpentop: false,
					hasAirRide: false,
					hasPlate: false,
					hasDry: false,
					hasLiftGate: false,
				},
				flatbed: {
					amount: 0,
					has53: false,
					has48: false,
					hasStepDeck: false,
					hasLowboy: false,
					hasConestoga: false,
					hasDropTrailers: false,
				},
				reefer: {
					amount: 0,
					hasMultiTemp: false,
					hasRemoteTemp: false,
					hasRgn: false,
				},
				other: {
					description: null,
					amount: 0,
				},
				carHaul: {
					amount: 0
				},
				highCube: {
					amount: 0
				},
			},
		}
	},
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Carrier Documents - not in line with initial onboarding needs to handle returning users mult doc uploads of same class //
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	documents: {
		// "value" must be as is - used for jon's api
		auth: {
			createdAt: null,
			value: 'MC Letter of Authority',
			requirement: 'all',
			documentId: null,
			file: null,
			archives: []

		},
		aff: {
			createdAt: null,
			value: 'Affidavit',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []

		},
		wNine: {
			createdAt: null,
			value: 'W9 Tax Form',
			requirement: 'all',
			documentId: null,
			file: null,
			archives: []
		},
		insur: {
			createdAt: null,
			value: 'Certificate of Insurance',
			requirement: 'all',
			documentId: null,
			file: null,
			archives: []
		},
		minorCert: {
			createdAt: null,
			value: 'Minority Certification',
			requirement: 'all',
			documentId: null,
			file: null,
			archives: []
		},
		noa: {
			createdAt: null,
			value: 'Notice of Assignment',
			requirement: 'cond',
			documentId: null,
			file: null,
			archives: []
		},
		carb: {
			createdAt: null,
			value: 'CARB',
			requirement: 'cond',
			documentId: null,
			file: null,
			archives: []
		},
		hazm: {
			createdAt: null,
			value: 'HAZMAT',
			requirement: 'cond',
			documentId: null,
			file: null,
			archives: []
		},
		dotInsp: {
			createdAt: null,
			value: 'DOT Inspections',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		},
		wEight: {
			createdAt: null,
			value: 'W8 Tax Form',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		},
		poAg: {
			createdAt: null,
			value: 'Power Only Agreement',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		},
		auto: {
			createdAt: null,
			value: 'Scheduled Autos',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		},
		// fsma: {
		// 	createdAt: null,
		// 	value: 'FSMA Addendum',
		// 	requirement: 'opt',
		// 	documentId: null,
		// 	file: null,
		// 	archives: []
		// },
		generalContract: {
			createdAt: null,
			value: 'General Contract',
			requirement: 'cond',
			documentId: null,
			file: null,
			archives: []
		},
		other: {
			createdAt: null,
			value: 'Other',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		},
		reef: {
			createdAt: null,
			value: 'Reefer TRU',
			requirement: 'opt',
			documentId: null,
			file: null,
			archives: []
		}
	},
	////////////////////////////////////////////////////////////////////////////////
	// Carrier Payments - not sure why this is broken out from the onboarding obj //
	////////////////////////////////////////////////////////////////////////////////
	payments: {
		address1: '',
		address2: '',
		city: '',
		state: '',
		postalCode: '',
		isFactoring: false,
		name: '',
		email: '',
		phone: '',
		country: '',
		extension: '',
		usePhysical: false,
	},
	////////////////////////////////////////////////////////////
	// Carrier Services - not in line with initial Onboarding //
	////////////////////////////////////////////////////////////
	services: {},


	////////////////////////////////////////////
	// Carrier Progress on initial Onboarding //
	////////////////////////////////////////////
	currentPage: null,
	progress: {
		general: {
			title: 'Contact and Identification',
			description: 'General Company information.',
			descriptionComp: 'Manage your general company information.',
			valid: false,
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-account-box',
			requirement: true
		},
		addresses: {
			title: 'Addresses',
			description: 'Company physical and mailing address information.',
			descriptionComp: 'Manage your company addresses.',
			valid: false,
			primaryIcon: 'fa-solid fa-spinner',
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-map-marker',
			requirement: true
		},
		payments: {
			title: 'Payments',
			description: 'Payment information and upload a *Notice of Assignment.',
			descriptionComp: 'Manage your payment information and upload a *Notice of Assignment.',
			valid: false,
			primaryIcon: 'fa-solid fa-spinner',
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-credit-card-outline',
			requirement: true
		},
		fleet: {
			title: 'Fleet Details',
			description: 'Required fleet details such as number of power units and drivers.',
			descriptionComp: 'Manage your fleet information, add/remove power units, drivers and more.',
			valid: false,
			primaryIcon: 'fa-solid fa-spinner',
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-account-group',
			requirement: true
		},
		documents: {
			title: 'Documents',
			description: 'Required documentation such as a Letter of Authority, W9 tax form, and Certificate of Insurance.',
			descriptionComp: 'Manage your documents, upload new and view previous uploads.',
			valid: false,
			primaryIcon: 'fa-solid fa-spinner',
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-file-multiple',
			requirement: true
		},
		contract: {
			title: 'Sign Contract',
			description: 'Once all of above steps are complete, you may proceed our Carrier Contract.',
			descriptionComp: 'Resign our Carrier Contract.',
			valid: false,
			primaryIcon: 'fa-solid fa-spinner',
			secondaryIcon: 'fa-solid fa-circle-check',
			onboardingCompIcon: 'mdi-draw',
			requirement: true
		},
		// services: {
		// 	title: 'Services',
		// 	description: 'Details on special services you offer such as HAZMAT, CARB, and Power Only.',
		// 	valid: false,
		// 	primaryIcon: 'mdi-cog-outline',
		// 	secondaryIcon: 'mdi-cog-outline',
		// 	requirement: true
		// },
	},
	/////////////////////////////
	// US States, Provinces of Mexico and Canada for v-selects //
	/////////////////////////////
	states: [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
	],
	mexicoProvinces: [ 'AG', 'BN', 'BS', 'CH', 'CI', 'CL', 'CP', 'CS', 'DF', 'DG', 'GE', 'GJ', 'HD', 'JA', 'MC', 'MR', 'MX', 'NA', 'NL', 'OA', 'PU', 'QE', 'QI', 'SI', 'SL', 'SO', 'TA', 'TB', 'TL', 'VC', 'YU', 'ZA' 
	],
	canadaProvinces: [ 'AB', 'BC', 'MB', 'NB', 'NF', 'NS', 'NT', 'ON', 'PE', 'QC', 'SK', 'YT' ],
};