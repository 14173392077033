<template>
	<v-row class="fill-height" align="center">
		<v-col cols="12" class="pa-0">
			<HeroImage></HeroImage>
			<v-row class="fill-height topLevel mt-12" justify="center">
				<!-- LEFT HAND COLUMN -->
				<v-col cols="12" xs="12" sm="12" md="6" lg="7" xl="7" class="pa-0">
					<v-row justify="center" justify-sm="start">
						<v-col cols="12">
							<v-row class="align-center justify-start">
								<v-col
								 cols="12"
								 sm="11"
								 offset-lg="1"
								 class="align-self-end"
								 :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''">
									<v-row justify="start">
										<v-col cols="12" class="pa-0">
											<span
											 class="headingOne font-weight-bold"
											 >
												Haul freight with
												<br />
												Knight-Swift Logistics
											</span>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12">
							<v-row class="justify-center" align="start">
								<v-col
								 cols="12"
								 sm="11"
								 md="8"
								 class="align-self-start">
									<v-row class="headingTwo">
										<v-col cols="12" class="px-0" :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''">
											<span>
												Freight as easy as 1, 2, 3.
											</span>
										</v-col>
									</v-row>
									<v-row class="my-0" style="font-size: 18px">
										<v-col cols="4" class="px-0">
											<v-row
											 class="align-center"
											 v-if="
													$vuetify.breakpoint.smAndUp
												">
												<div class="numberDiv mr-2">
													<i class="fa-solid fa-calendar-day"></i>
												</div>
												Quick <br />
												booking.
											</v-row>
											<v-row class="align-center" v-else>
												<v-col cols="12">
													<v-row
													 class="justify-center">
														<div class="numberDiv">
															<i class="fa-solid fa-calendar-day"></i>
														</div>
													</v-row>
												</v-col>
												<v-col
												 cols="12"
												 class="text-center">
													Quick booking.
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="4">
											<v-row
											 class="align-center"
											 v-if="
													$vuetify.breakpoint.smAndUp
												">
												<div class="numberDiv mr-2">
													<i class="fa-solid fa-sack-dollar"></i>
												</div>
												Fast <br />
												payments.
											</v-row>
											<v-row class="align-center" v-else>
												<v-col cols="12">
													<v-row
													 class="justify-center">
														<div class="numberDiv">
															<i class="fa-solid fa-sack-dollar"></i>
														</div>
													</v-row>
												</v-col>
												<v-col
												 cols="12"
												 class="text-center">
													Fast payments.
												</v-col>
											</v-row>
										</v-col>
										<v-col cols="4">
											<v-row
											 class="align-center"
											 v-if="
													$vuetify.breakpoint.smAndUp
												">
												<div class="numberDiv mr-2">
													<i class="fa-solid fa-truck-fast"></i>
												</div>
												More <br />
												freight.
											</v-row>
											<v-row class="align-center" v-else>
												<v-col cols="12">
													<v-row
													 class="justify-center">
														<div class="numberDiv">
															<i class="fa-solid fa-truck-fast"></i>
														</div>
													</v-row>
												</v-col>
												<v-col
												 cols="12"
												 class="text-center">
													More freight.
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" class="pa-0"></v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
				<!-- RIGHT HAND COLUMN -->
				<v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="5" class="pa-10">
					<v-form>
						<v-card class="elevation-12">
							<v-card-text class="py-6">
								<v-row
								 class="justify-center align-center">
									<v-col cols="12" lg="10" class="my-0 py-0">
										<span style="font-size: 20px; font-weight: bold;">Create your account</span>
										<v-text-field
										 v-model="dotNumber"
										 placeholder="Enter your USDOT to start"
										 dark
										 filled
										 dense
										 color="primary"
										 prepend-inner-icon="fas fa-hashtag"
										 class="pt-2 pb-0"
										 @keypress="
																numOnly(
																	$event,
																	'USDOT'
																)
															"
										 @keydown.enter="
																verifyEnter(
																	'USDOT'
																)
															"
										 autocomplete="nope"></v-text-field>
									</v-col>
									<v-col cols="12" lg="10" class="my-0 pt-0">
										<v-btn
										 :disabled="
																!dotNumber
															"
										 dark
										 color="primary darken-3"
										 block
										 @click="
																validateUSDOT
															">
											Sign Up
										</v-btn>
									</v-col>
									<v-col cols="12" lg="10">
										<v-row align="center">
											<v-divider class="mr-2"></v-divider>OR<v-divider class="ml-2"></v-divider>
										</v-row>
									</v-col>
									<v-col cols="12" lg="10">
										<v-btn
										 dark
										 block
										 outlined
										 @click="handleExistingCarrier">
											Connect your SELECT account
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-form>
				</v-col>
				<v-col
				 cols="12"
				 offset-lg="1"
				 v-if="$vuetify.breakpoint.smAndUp">
					<v-row class="mx-0" align="center">
						<span class="mx-12">
							Before you start, we recommend having this documentation available:
						</span>
						<v-col class="flex-grow-0 flex-shrink-1">
							<img
							 height="100px"
							 src="../../assets/LETTER_OF_AUTHORITY.svg" />
						</v-col>
						<v-col class="flex-grow-0 flex-shrink-1">
							<img
							 height="100px"
							 src="../../assets/W9.svg" />
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" v-else class="my-0">
					<v-row class="mx-0" align="center">
						<v-col cols="12" class="text-center">
							Before you start, we recommend having this documentation available:
						</v-col>
						<v-col cols="6">
							<v-row class="justify-center align-center">
								<img
								 height="100px"
								 src="../../assets/LETTER_OF_AUTHORITY.svg" />
							</v-row>
						</v-col>
						<v-col cols="6">
							<v-row class="justify-center align-center">
								<img
								 height="100px"
								 src="../../assets/W9.svg" />
							</v-row>
						</v-col>
					</v-row>
				</v-col>
				<!-- <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12">
					<v-row class="justify-end">
						<img
						 src="../../assets/logo.svg"
						 height="450px" />
					</v-row>
				</v-col> -->
			</v-row>
		</v-col>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" />
	</v-row>
</template>
<script>
import HeroImage from "../HeroImage";
//Mixins
import basicDialogMixin from "../../mixins/basicDialogMixin";
import numOnly from "../../mixins/formBuilding/numOnly.js";

export default {
	name: "Landing_Page",
	components: {
		HeroImage,
	},
	mixins: [basicDialogMixin, numOnly],
	data() {
		return {
			dotNumber: null,
			fmcsaValidation: process.env.VUE_APP_FMCSA_VALIDATION
		};
	},
	methods: {
		async validateUSDOT() {
			try {
				// INITIALIZE STOREOBJ TO USE LATER IN UPDATING THE STORE
				let storeObj

				// CHECK IF FMCSA VALIDATION IS TURNED ON
				if (this.fmcsaValidation === 'ON') {
					const response = await this.$axios({
						method: "get",
						url: `${this.$hostName.fmcsaPath}/carriers/${this.dotNumber}`,
						params: {
							webKey: process.env.VUE_APP_FMCSA_KEY
						},
					});

					let fmcsaInfo = response.data.content.carrier || null;

					// IF COUNTRY IS US, CHANGE TO USA SINCE THAT IS WHAT THE COUNTRY FIELD OPTIONS EXPECTS
					if (fmcsaInfo.phyCountry === 'US') {
						fmcsaInfo.phyCountry = 'USA';
					}

					storeObj = {
						dotNumber: this.dotNumber,
						mcNumber: null,
						intraState: null,
						mailing: {
							address1: fmcsaInfo.phyStreet || null,
							city: fmcsaInfo.phyCity || null,
							state: fmcsaInfo.phyState || null,
							zip: fmcsaInfo.phyZipcode || null,
							country: fmcsaInfo.phyCountry || null,
						},
						physical: {
							address1: fmcsaInfo.phyStreet || null,
							city: fmcsaInfo.phyCity || null,
							state: fmcsaInfo.phyState || null,
							zip: fmcsaInfo.phyZipcode || null,
							country: fmcsaInfo.phyCountry || null,
						},
						general: {
							doingBusinessAsName: fmcsaInfo.dbaName || null,
							legalName: fmcsaInfo.legalName || null
						}
					};
				} else {
					storeObj = {
						dotNumber: this.dotNumber,
						mcNumber: null,
						intraState: null,
						mailing: {
							address1: null,
							city: null,
							state: null,
							zip: null,
							country: null,
						},
						physical: {
							address1: null,
							city: null,
							state: null,
							zip: null,
							country: null,
						},
						general: {
							doingBusinessAsName: null,
							legalName: null
						}
					};
				}

				this.$store.commit("updateOnboardingInfo", storeObj);

				this.$router.push({ path: "/createaccount" });
			} catch (error) {
				//
				this.$background.blur = true;
				this.basicDialog = {
					value: true,
					title: "DOT Error",
					text: "There was an error validating your DOT#. Please try again later.",
					hasAction: false,
					width: "350",
				};
				setTimeout(() => {
					this.closeBasicDialog();
				}, 4000);
				console.log(error);
			} finally {
				// do nothing
			}
		},
		verifyEnter(string) {
			if (string === "USDOT" && this.dotNumber) this.validateUSDOT();
		},
		handleExistingCarrier() {
			// For carriers with an existing Select account
			this.$router.push({ path: "/existingCarrier" });
		}
	},
};
</script>
<style scoped>
.logoSize {
	width: 50%;
	height: auto;
	display: block;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
}

.headerContainer {
	position: relative;
	height: 50vh;
}

.header__bg {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-end;
	align-items: flex-end;
	background-color: #121212;
	transform: skewY(-19deg);
	transform-origin: top left;
}

.thickStripe {
	height: 75px;
}

.thinStripe {
	height: 37.5px;
}

.primaryBorder {
	border: 3px solid #bb86fc;
}

.numberDiv {
	border: 3px solid #fff;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: "no-wrap";
	align-content: center;
	align-items: center;
	justify-content: center;
}

.topLevel {
	position: relative;
	z-index: 2;
	width: 100%;
}
</style>