import store from '../store';

const beforeEach = (to, from, next) => {
	const route = to.matched[0];

	// empty object for the next arg means continue without issue
	let nextPath = {};

	// if route exists, check for logged in and public meta
	// otherwise, route doesn't exist, and bounce them to lander
	if (!!route) {
		route.from = from.path;
		if (to.matched.some((record) => !record.meta.public)) {
			// this route requires auth, check if logged in
			// if not, redirect to login page.
			if (!store.state.auth.loggedIn) nextPath.path = '/';
		}
	} else nextPath.path = '/'

	next(nextPath);
};

export default beforeEach;
