<template>
	<v-col>
		<v-row>
			<v-col cols="12">
				<span>
					Please follow instructions below to move onto the next section.
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					Required Fields:
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<ul>
					<li class="mt-1">Must have at least one driver and one power unit (truck) registered, these can be company and/or owner operator.</li>
					<li class="mt-2">If you are registering trailers, you must provide the total number of trailers and trailer types.</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'Fleet_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>
