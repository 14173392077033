<template>
	<v-toolbar v-if="$route.name !== 'SignUp'" height="64px" color="#070707">
		<v-row align="center" justify="space-between">
			<v-col cols="6" class="pa-0" @click="clickLogo">
				<img alt="Knight Swift Transportation Logo" src="../../assets/blackWhiteLogo.svg" style="cursor: pointer" />
			</v-col>
			<v-col cols="6" class="pa-0">
				<v-row justify="end" align="center">
					<v-btn v-if="auth.loggedIn" @click="openNotifications" icon>
						<v-icon dark v-if="notifications.total === 0">mdi-bell</v-icon>
						<v-icon color="primary" v-else>mdi-bell</v-icon>
						<span style="margin-top: -15px; font-size: 10px">{{notifications.total}}</span>
					</v-btn>
					<v-btn @click="openNav" icon class="ml-5">
						<v-icon dark>mdi-menu</v-icon>
					</v-btn>
				</v-row>
			</v-col>
		</v-row>
		<Drawer v-model="navOpen"></Drawer>
		<Notifications
			v-model="notificationDialog"
			:notifications="notifications"
			@updateNotifications="updateNotifications"
			@clear="clear"
			@updateRead="updateRead"
		></Notifications>

		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog"></basic-dialog>
	</v-toolbar>
</template>

<script>
import Drawer from './Drawer';
import Notifications from './Notifications';

import basicDialogMixin from '@/mixins/basicDialogMixin.js';

export default {
	name: 'Nav-Bar',
	components: { Drawer, Notifications },
	mixins: [basicDialogMixin],
	data: () => ({
		//
		navOpen: false,
		notificationDialog: false
	}),
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		notifications() {
			return this.$store.state.notifications;
		},
	},
	methods: {
		openNotifications() {
			this.$background.blur = true;
			this.notificationDialog = true;
		},
		openNav() {
			this.navOpen = !this.navOpen;
		},
		clickLogo() {
			if (this.auth.token) {
				if (this.$route.path !== "/dashboard") this.$router.push('/dashboard');
			} else {
				this.$router.push('/');
			}
		},
		async updateNotifications(val) {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/notifications`,
					params: {
						externalRead: val,
						limit: 15,
						skip: 0,
						sortby: 'createdAt',
						descending: true
					},
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					}

				})

				this.$store.commit('updateNotifications', data)

			} catch (error) {
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: 'Unable to get notifications at this time. Please try again later.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}

		},
		async clear(activityUUID) {
			try {
				await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/notifications/clear`,
					data: { activityUUID },
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				})

				const findIdx = this.notifications.list.findIndex((element) => element.activityUUID === activityUUID)

				this.notifications.list.splice(findIdx, 1)

			} catch (error) {
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: 'Unable to clear notifications at this time. Please try again later.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		async updateRead() {
			try {
				await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/notifications/readable`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				})
				this.$store.commit('notificationsRead')

			} catch (error) {
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: 'Unable to mark notifications as read at this time. Please try again later.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		}
	},
	mounted() {
	},
	watch: {
		//
		async notificationDialog(val) {
			if(val === true) {
				try {
					await this.updateNotifications(false);
					//Updates all unread messages to externalRead true in the backend,  UUID is used in the backend and found by decoding the jwt
				} catch (error) {
					console.log(error)
				}
			}
		}
	},
};
</script>

<style scoped>
img {
	height: 40px;
	margin-left: 6px;
}
.menu-icon {
	margin-left: 16px;
}
</style>
