import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import router from './router';
import store from './store';
import moment from 'moment';
import './plugins/sockets.js';
const crypto = require('crypto');

// setup global components
const requireComponent = require.context(
	"../global_components",
	true,
	/\w+\.(vue|js)$/
);


// format component names and add to vue
requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName);

	const componentName = fileName
		.split("/")
		.pop()
		.split(".")
		.shift();

	Vue.component(componentName, componentConfig.default || componentConfig);
});

/////////////////
// OBSERVABLES //
/////////////////
import formValidPropsObj from './observableObjects/formValidProps';
const formValidProps = Vue.observable(formValidPropsObj);

const blur = Vue.observable({blur: false});

////////////////
// PROTOTYPES //
////////////////
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$crypto = crypto;

Vue.prototype.$hostName = {
	apiBase: process.env.VUE_APP_API_BASE,
	fmcsaPath: process.env.VUE_APP_FMCSA_PATH,
	autocompletePath: process.env.VUE_APP_AUTOCOMPLETE,
	selectProfiles: process.env.VUE_APP_SELECT_PROFILES,
	tmwCarrierCode: process.env.VUE_APP_TMW_CARRIER_CODE,
	carrierRelationsAPI: process.env.VUE_APP_CARRIER_RELATIONS_API
};

Vue.prototype.$formRules = {
	required: valid => { return !!valid || 'Required.' },
	boolRule: valid => { return !valid != null || 'Required.' },
	passwordValid: (valid) => {
		return /^(?=.*[0-9])(?=.*[A-Z]).{8,}$/.test(valid) ||
			'Password must be at least 8 characters, contain at least one capital letter, and at least one number.'
	},
	email: valid => {
		return (
			/^[a-zA-Z0-9\.!#$%&''*+\-\/=?^_`{|}~;]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*\.[a-zA-Z0-9]+$/.test(
				valid
			) || 'Email must be valid'
		);
	},
	mcValid: (valid) => { return /\d{6}/.test(valid) || 'McNumber must be atleast six digits.' },
	phoneValid: (valid) => { return /\d{3}-\d{3}-\d{4}/.test(valid) || 'Invalid number.' },
	// zipValid: (valid) => { return /\d{5}/.test(valid) || 'Must be five digits.' },
	// canadaPostCodeValid: (valid) => { return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(valid) || 'Must be six alphanumeric characters.' },
	tinValid: (valid) => { return /\d{9}/.test(valid) || 'Tax ID must be nine digits.' },
	yearValid: (valid) => { return valid > 1900 || 'Invalid year.'},
	fleetValid: (valid) => { return (!!valid || valid == 0) || 'Required.' },
	docValid: value => !value || value.size < 21000000 || 'File size should be less than 2 MB!'
}

Vue.prototype.$formValid = formValidProps;
Vue.prototype.$background = blur;

/////////////
// FILTERS //
/////////////
import filters from "./filters";
for (let filter of Object.keys(filters)) {
	Vue.filter(filter, filters[filter]);
}

//////////////////
// ROUTER SETUP //
//////////////////
import beforeEach from "./router/beforeEach";
router.beforeEach(beforeEach);

//////////
// INIT //
//////////
Vue.config.productionTip = false;
new Vue({
	router,
	vuetify,
	store,
	render: (h) => h(App),
}).$mount('#app');
