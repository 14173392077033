export default {
	fleetHasAtleastOneDriverOrPowerUnit: ({ onboardingInfo: { fleet } }) => {
		return (
			(fleet.company.power > 0 || fleet.owner.power > 0) &&
			(fleet.company.drivers > 0 || fleet.owner.drivers > 0)
		);
	},

	selectedAtleastOneTrailer: ({ onboardingInfo: { fleet } }) => {
		let totalAmountNotPowerOrDriver = 0;
		const trailerTypes = ['company', 'owner'];

		trailerTypes.forEach(type => (totalAmountNotPowerOrDriver += fleet[type].numTrailers));

		// See if trailer flatbed, dryvan and highCube (company or owner) is atleast 1
		if (totalAmountNotPowerOrDriver >= 1) return true;

		// also check for car haul if there was not atleast 1 trailer
		trailerTypes.forEach(type => (totalAmountNotPowerOrDriver += fleet[type].carHaul.amount));
		if (totalAmountNotPowerOrDriver >= 1) return true;

		// check for reefer amounts as well
		trailerTypes.forEach(type => (totalAmountNotPowerOrDriver += fleet[type].reefer.amount));
		if (totalAmountNotPowerOrDriver >= 1) return true;

		//also check for other options as well
		trailerTypes.forEach(type => (totalAmountNotPowerOrDriver += fleet[type].other.amount));
		if (totalAmountNotPowerOrDriver >= 1) return true;

		return false;
	},
};
