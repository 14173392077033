<template>
	<v-row>
		<v-col cols="12" class="pa-0">
			<v-row v-if="selectedKey === 'power'">
				At least 1 Power Unit, either company or non-owned, is required in order to complete the onboarding process.
			</v-row>
			<v-row v-if="selectedKey === 'drivers'">
				At least 1 Driver, either company or hired-leased, is required in order to complete the onboarding process.
			</v-row>
			<v-row v-if="selectedKey != 'power' && selectedKey != 'drivers'">
				Providing your number of trailers, and additional trailer details is optional. When trailer information is provided you will have access to more freight options.
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Fleet_Dialog_Add_Message',
	props: {
		selectedKey: { type: String, default: null }
	},
	data() {
		return {
			//
		}
	},
	methods: {
		//
	},
	computed: {
		//
	},
};
</script>

<style scoped>
/**/
</style>