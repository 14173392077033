<template>
	<v-dialog :value="value" persistent transition="dialog-transition" width="500">
		<v-card>
			<v-card-title>
				<v-row>
					<v-col cols="12" class="pb-0" style="font-size: 15px">
						<!-- Please Confirm Your Password to Continue -->
						Your session has expired.
						<br />
						Please enter your password and try again.
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text class="pb-2">
				<v-row class="justify-center align-center">
					<v-col cols="12">
						<v-form>
							<v-row>
								<v-col cols="12" class="px-0"> Password </v-col>
							</v-row>
							<v-text-field
								:type="showPassword ? 'text' : 'password'"
								dark
								color="primary"
								v-model="password"
								:append-icon="
									showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
								"
								@click:append="showPassword = !showPassword"
								@keypress.enter="loginCarrier"
								autocomplete="off"
							></v-text-field>
						</v-form>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-row class="justify-center align-center">
					<v-col cols="12">
						<v-btn
							dark
							color="primary darken-3"
							:disabled="!password || loading"
							block
							@click="loginCarrier"
						>
							Continue
						</v-btn>
					</v-col>
					<v-col cols="12">
						<v-btn dark color="secondary" text block @click="cancelBtn"> Logout </v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// libraries
import jwt from 'jsonwebtoken';
//mixin
import hashMixin from '../src/mixins/hashPassword';
import basicDialogMixin from '../src/mixins/basicDialogMixin';

export default {
	name: 'Interceptor_Dialog',
	props: { value: Boolean },
	mixins: [hashMixin, basicDialogMixin],
	data() {
		return {
			password: null,
			showPassword: false,
			loading: false,
			emailValid: [this.$formRules.required, this.$formRules.email],
			passwordValid: [this.$formRules.required, this.$formRules.passwordValid],
		};
	},
	methods: {
		async loginCarrier() {
			this.loginError = false;
			this.loginErrorMsg = null;
			this.loading = true;

			const postObj = {
				username: this.userInfo.email,
				password: this.hashPassword(this.password),
			};

			try {
				const response = await this.$axios({
					method: 'post',
					url: `${this.$hostName.selectProfiles}/api/v0/unprotected/authUser/login`,
					data: postObj,
				});

				const { authToken: token, user, companyRole } = response.data;

				// parse JWT
				const decoded = jwt.decode(token);
				// build an auth
				const authObj = {
					token,
					companyRole: decoded.companyRole,
					expTime: decoded.exp,
					refreshTime: Date.now() / 1000 + 60 * 15,
					loggedIn: true,
				};

				// build a userObj
				const userObj = {
					username: user.username,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					companyRole: user.companyRole,
				};

				// put important stuff into the store.
				this.$store.commit('updateAuth', authObj);
				this.$store.commit('updateUser', userObj);
				this.$store.commit('updatePermissions', decoded.permissions);
				this.$store.commit('updateOrg', decoded.organization);

				// retrieve saved carrier info
				await this.getCarrierInfo();
				await this.getDocInfo();
				await this.getCarrierProgress();
				await this.getCarrierPayments();
				await this.getCarrierServices();
				await this.getCarrierNotifications();

				if (this.$background.blur) this.$background.blur = false;
				this.$emit('input');
			} catch (err) {
				console.log(err);
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: 'There was an error logging you in. Please check username and password or try again later.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Ok',
						color: 'error',
					},
					width: '350',
				};
			} finally {
				this.loading = false;
			}
		},
		async getCarrierNotifications() {
			const { data } = await this.$axios({
				method: 'get',
				url: `${this.$hostName.apiBase}/onboarding/notifications`,
				params: {
					limit: 15,
					skip: 0,
					sortby: 'createdAt',
					descending: true,
					externalRead: false,
				},
				headers: {
					Authorization: `Bearer ${this.auth.token}`,
				},
			});
			this.$store.commit('updateNotifications', data);
		},
		async getCarrierInfo() {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});

				const formData = response.data;
				formData.general = { ...formData.contact, ...formData.info };
				delete formData.general.mcNumber;
				delete formData.general.intraState;

				const mailingData = {};
				const physicalData = {};
				const generalData = {};
				const companyFleetData = {};
				const ownerFleetData = {};

				Object.entries(formData.mailing).forEach(([key, value]) => {
					mailingData[key] = value;
				});
				Object.entries(formData.physical).forEach(([key, value]) => {
					physicalData[key] = value;
				});
				Object.entries(formData.general).forEach(([key, value]) => {
					if (key != 'alternates') {
						generalData[key] = value;
					}
				});

				if (formData.fleet) {
					if (formData.fleet.company) {
						Object.entries(formData.fleet.company).forEach(([key, value]) => {
							companyFleetData[key] = value;
						});
					}
					if (formData.fleet.owner) {
						Object.entries(formData.fleet.owner).forEach(([key, value]) => {
							ownerFleetData[key] = value;
						});
					}
				}
				const storeObj = {
					dotNumber: formData.dotNumber,
					carrierCode: formData.carrierCode,
					status: formData.status,
					addresses: {
						mailing: { ...mailingData },
						physical: { ...physicalData },
					},
					general: { ...generalData },
					fleet: {
						company: { ...companyFleetData },
						owner: { ...ownerFleetData },
					},
				};
				this.$store.commit('updateOnboardingInfo', storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getDocInfo() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/list`,
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: `Bearer ${this.auth.token}`,
					},
				});

				if (data) {
					this.$store.commit('updateDocuments', data);
				}
			} catch (err) {
				console.log('error', err);
				throw new Error(err);
			}
		},
		async getCarrierPayments() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/payment`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});
				if (Object.keys(data).length) {
					this.$store.commit('updatePayments', data);
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});

				if (data) this.$store.commit('updateServices', data);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierProgress() {
			///////////////////////////////////////////////////////
			// :) NEED TO ADD PHYSICAL, DOCS, FLEET TO PROGRESS API //
			///////////////////////////////////////////////////////
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/progress`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});
				const formData = response.data;

				const storeObj = {
					general: formData.generalInfoComplete,
					addresses: formData.mailingInfoComplete,
					payments: formData.paymentInfoComplete,
					fleet: formData.fleetInfoComplete,
					documents: formData.documentsComplete,
					contract: formData.signContractComplete,
					// services: formData.serviceInfoComplete,
				};

				this.$store.commit('updateProgress', storeObj);
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async cancelBtn() {
			this.$background.blur = false;
			this.$emit('input');
			this.$router.push('/logout');
		},
	},
	computed: {
		accountInfo() {
			return this.$store.state.accountInfo;
		},
		auth() {
			return this.$store.state.auth;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		docInfo() {
			return this.$store.state.documents;
		},
		paymentInfo() {
			return this.$store.state.payments;
		},
		serviceInfo() {
			return this.$store.state.services;
		},
	},
	watch: {
		value(bool) {
			if (bool) this.$background.blur = true;
		},
	},
};
</script>

<style>
.v-overlay--active:before {
	opacity: 0.625;
}
/* */
</style>
