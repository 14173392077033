<template>
	<v-dialog
	 :value="value"
	 persistent
	 :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
	 :width="$vuetify.breakpoint.mdAndUp ? '50vw' : ''">
		<v-row style="background-color: #1e1e1e" class="fill-height">
			<v-col cols="12" align-self="start">
				<v-row justify="space-between" align="center">
					<v-col cols="11" :style="titleFontSize">
						<span v-if="!onboardingInfo.firstTimeVisited">Thanks for creating an account!</span>
						<span v-else-if="onboardingInfo.firstTimeVisited && !needsToUpdateContract">Your account is active, but the onboarding process is incomplete!</span>
						<span v-else-if="onboardingInfo.firstTimeVisited && needsToUpdateContract">Attention Partner Carriers!</span>
						<span v-else-if="onboardingInfo.status === 'signed'">Your application has been submitted successfully!</span>
						<span v-else>Onboarding Completed!</span>
					</v-col>
					<v-col cols="1" class="pa-0 ma-0 text-right">
						<v-btn class="pa-0 ma-0" @click="closeHelpDialog" icon>
							<v-icon color="primary darken-1">mdi-close-thick</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12">
				<ProgressTracker v-if="!needsToUpdateContract" :currentPage="currentPage" :carrierProgress="carrierProgress"/>
			</v-col>
			<v-col cols="12" v-if="!onboardingInfo.firstTimeVisited">
				<v-row justify="center" align="center" class="text-center">
					<v-col cols="12" class="py-0" align-self="center">
						<v-btn
							x-large
							color="#673aa7"
							block
							@click="routeGeneral"
						>
							Complete your application now
						</v-btn>
					</v-col>
					<v-col cols="12" class="my-1 py-0">
						<v-btn
							x-large
							block
							style="border: 1px solid #673aa7; color: #A1A2A3;"							
							@click="routeDashboard"
						>
							Complete later
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" v-else-if="onboardingInfo.status === 'incomplete' && !previouslySignedContract">
				<v-row justify="center" align="center" class="text-center">
					<v-col cols="12" class="py-0" align-self="center">
						<v-btn
							x-large
							color="#673aa7"
							block
							@click="routeContinue"
						>
							Continue the onboarding process
						</v-btn>
					</v-col>
				</v-row>				
			</v-col>
			<!-- NEW SECTION FOR CARRIERS NEEDING TO SIGN THE NEW CONTRACTS FOR THE YEAR -->
			<v-col cols="12" v-else-if="needsToUpdateContract">
				<v-row justify="center" align="center" class="text-center">
					<v-col cols="12" class="py-0" align-self="center">
						<v-row justify="center" align="center">
							Our new 2023 General Contract is now available. Please review and sign by January 31, 2023.
						</v-row>
						<v-row justify="center" align="center" class="mt-3">
							For Power Only carriers, please sign both the General Contract and our new 2023 Power Only - Equipment Lease Agreement.
						</v-row>
						<v-row justify="center" align="center" class="mt-3">
							If you have any questions, please contact us at carriers@knightswiftlogistics.com .
						</v-row>
					</v-col>
				</v-row>
				<v-row justify="center" align="center" class="text-center mt-3">
					<v-col xl="6" lg="6" md="12" sm="12" xs="12" class="py-0" align-self="center">
						<v-btn
							x-large
							color="#673aa7"
							block
							@click="routeContract"
						>
							Sign 2023 General Contract
						</v-btn>
					</v-col>
					<v-col xl="6" lg="6" md="12" sm="12" xs="12" class="my-1 py-0">
						<v-btn
							x-large
							color="#673aa7"
							block
							@click="routeServices"
						>
							Sign 2023 Power Only - <br/>
							Equipment Lease Agreement
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" v-else-if="onboardingInfo.status === 'signed'">
				<v-row>
					<v-col cols="12">
						<v-row justify="center" align="center" class="mt-3">
							We have received your application! While our review process is taking place, please head back to the dashboard where you will now have access to manage your offered services. Once we have finished reviewing your application, you will receive a notification via email and in your notifications panel.
						</v-row>
						<v-row justify="center" align="center" class="mt-3">
							*Please note that this DOT is not confirmed eligible to haul freight or equipment with Knight or Swift until you receive a rate confirmation and confirmation from a Knight or Swift representative.
						</v-row>
					</v-col>
				</v-row>
				<v-col cols="12">
					<v-row justify="center" align="center" class="ma-2 text-center">
						<v-col cols="12" class="py-0" align-self="center">
							<v-btn
								x-large
								color="#673aa7"
								block
								@click="routeGeneral"
							>
								Update your information
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-col>
			<v-col cols="12" v-else-if="!needsToUpdateContract">
				<v-row justify="center" align="center" class="ma-2 text-center">
						<v-col cols="12" class="py-0" align-self="center">
							<v-btn
								x-large
								color="#673aa7"
								block
								@click="routeGeneral"
							>
								Update your information
							</v-btn>
						</v-col>
					</v-row>
			</v-col>
			<v-col cols="12" align-self="end" v-if="onboardingInfo.status !== 'incomplete'">
				<v-row justify="center" align="center" class="text-center">
					<v-col cols="12">
						<v-icon>mdi-lightbulb</v-icon>
						For detailed information on our onboarding process, please view one of the videos below:
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="text-center">
						<v-btn x-large href="https://youtu.be/ZYUhLjg5FpU" target="_blank" class="ma-3" style="border: 1px solid #A1A2A3;">Carrier Onboarding (English)</v-btn>
					</v-col>
					<v-col cols="6" class="text-center">
						<v-btn x-large href="https://youtu.be/IwlDYkANE5g" target="_blank" class="ma-3" style="border: 1px solid #A1A2A3;">Carrier Onboarding (Spanish)</v-btn>
					</v-col>
				</v-row>
				<v-row justify="center" align="center" class="text-center">
					<v-col cols="12">
						If you have any issues or questions, please
						check out the FAQ card on the dashboard or
						<span
						style="color: #bb86fc; cursor: pointer; text-decoration: underline"
						@click="routeContact">contact us</span>
						directly.
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-dialog>
</template>
<script>
import HelpOne from './helpOne.vue';
import HelpTwo from './helpTwo.vue';
import HelpThree from './helpThree.vue';
import ProgressTracker from '../ProgressTracker.vue';
export default {
	props: {
		value: { type: Boolean, default: false },
	},
	components: {
		ProgressTracker
	},
	data() {
		return {
			helpDialogs: ['helpOne', 'helpTwo', 'helpThree'],
			carouselModel: 0,
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
		carrierProgress() {
			return this.$store.state.progress;
		},
		currentPage() {
			return this.$store.state.currentPage;
		},
		titleFontSize() {
			let style = {};
			if (this.$vuetify.breakpoint.xsOnly) style['font-size'] = '18px';
			if (this.$vuetify.breakpoint.smOnly) style['font-size'] = '28px';
			if (this.$vuetify.breakpoint.mdOnly) style['font-size'] = '30px';
			if (this.$vuetify.breakpoint.lgAndUp) style['font-size'] = '32px';

			return style;
		},
		previouslySignedContract() {
			// check if they already have a signed contract in their progress info
			return this.$store.state.progress.contract.valid;
		},
		needsToUpdateContract() {
			return this.$store.state.onboardingInfo.status === 'incomplete' && this.previouslySignedContract;
		}
	},
	methods: {
		async closeHelpDialog() {
			try {
				if (!this.onboardingInfo.firstTimeVisited) {
					const { data } = await this.$axios({
						method: 'put',
						url: `${this.$hostName.apiBase}/onboarding/carrier`,
						data: {
							firstTimeVisited: true,
						},
						headers: {
							Authorization: 'Bearer ' + this.auth.token,
						},
					});
					this.$store.commit('updateFirstTimeVisited', data.firstTimeVisited);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.$background.blur = false;
				this.$emit('input');
			}
		},
		routeContact() {
			this.closeHelpDialog();
			this.$router.push('/contact');
		},
		routeGeneral() {
			this.closeHelpDialog();
			this.$router.push('/general');
		},
		routeDashboard() {
			this.closeHelpDialog();
		},
		routeContinue() {
			if(this.currentPage) {
				this.closeHelpDialog();
				this.$router.push(`/${this.currentPage}`);
			} else {
				this.closeHelpDialog();
				this.$router.push(`/general`);
			}
		},
		routeContract() {
			this.closeHelpDialog();
			this.$router.push(`/contract`);
		},
		routeServices() {
			this.closeHelpDialog();
			this.$router.push(`/services`);
		}
	},
	mounted() {
		//	
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>