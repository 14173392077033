<template>
	<v-card flat>
		<v-card-text class="list-scroll">
			An authority letter is the initial official letter issued by FMCSA to set up authorizing haul status. This is NOT the same as a "reinstatement" letter.
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'MCLetter_Assistance'
};
</script>