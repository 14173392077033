<template>
	<v-dialog
		:value="value"
		persistent
		transition="dialog-transition"
		scrollable
		:width="$vuetify.breakpoint.smAndDown ? '80vw' : '30vw'"
	>
		<v-card
			v-show="!serviceAnimation && !serviceSuccess"
			id="serviceCardId"
		>
			<v-card-title>
				<v-row justify="space-between" class="fill-height">
					<v-col cols="11">
						<span v-if="serviceDialogType === 'notes'">
							Notes for {{ subTitleFilter(selectedServiceKey) }}
						</span>
						<span v-if="serviceDialogType === 'add'">
							Add / Update Service
						</span>
						<span v-if="serviceDialogType === 'view'">
							View
							{{ subTitleFilter(selectedServiceKey) }} Details
						</span>
						<span v-if="serviceDialogType === 'stop'">
							Stop
							{{ subTitleFilter(selectedServiceKey) }} Service
						</span>
						<span
							v-if="serviceDialogType === 'assistance'"
							class="text-md-body-1"
						>
							{{ subTitleFilter(selectedServiceKey) }} Service
							Description
						</span>
					</v-col>
					<v-col cols="1">
						<v-btn @click="$emit('closeServiceDialog')" icon>
							<v-icon color="primary darken-1"
								>mdi-close-thick</v-icon
							>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-row v-if="serviceDialogType === 'notes'">
					<v-col cols="12">
						<ViewNotes
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
						></ViewNotes>
					</v-col>
				</v-row>
				<v-row v-if="serviceDialogType === 'add'">
					<v-col cols="12">
						<AddService></AddService>
					</v-col>
				</v-row>
				<v-row v-if="serviceDialogType === 'assistance'">
					<v-col cols="12">
						<AssistancePrompt
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
						>
						</AssistancePrompt>
					</v-col>
				</v-row>
				<v-row
					v-if="
						serviceDialogType === 'add' ||
							serviceDialogType === 'update'
					"
				>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
					<!--
						depending on selectedServiceKey show diff fields
					 -->
					<v-col cols="12" v-if="selectedServiceKey === 'carb'">
						<CarbPrompt
							@selectedFile="selectedFile"
							@handleCarb="handleCarbSelection"
							@fileTooLarge="fileTooLargeCarb = true"
							:serviceDialogType="serviceDialogType"
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
						></CarbPrompt>
					</v-col>
					<v-col cols="12" v-else-if="selectedServiceKey === 'hazm'">
						<HazmatPrompt
							:serviceDialogType="serviceDialogType"
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
							@fileTooLarge="fileTooLargeHazm = true"
							@selectedFile="selectedFile"
							@updateHazmatFields="updateHazmatFields"
						></HazmatPrompt>
					</v-col>
					<v-col
						cols="12"
						v-else-if="selectedServiceKey === 'powerOnly'"
					>
						<PowerOnlyPrompt
							:authorized="authorized"
							:serviceDialogType="serviceDialogType"
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
							@fileTooLarge="fileTooLargePower = true"
							@selectedFile="selectedFile"
							@viewPowerOnly="viewDocument"
							@updatePowerOnlyFields="updatePowerOnlyFields"
						></PowerOnlyPrompt>
					</v-col>
					<v-col
						cols="12"
						v-else-if="selectedServiceKey === 'reefer'"
					>
						<p class="text-body-2">
							In order to be eligible for this service, you must
							be TRU-Arb reefer trailer compliant.
						</p>
					</v-col>
					<v-col cols="12" v-else>
						No additional information is required to apply for this
						service offering.
					</v-col>
				</v-row>

				<v-row v-if="serviceDialogType === 'view'">
					<!--
						depending on selectedServiceKey show diff fields
					 -->
					<v-col
						cols="12"
						v-if="
							selectedServiceKey === 'carb' ||
								selectedServiceKey === 'hazm' ||
								selectedServiceKey === 'powerOnly'
						"
					>
						<ServiceDetails
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
							@viewDocument="viewDocument"
						></ServiceDetails>
					</v-col>
				</v-row>

				<v-row v-if="serviceDialogType === 'stop'">
					<!--
						depending on selectedServiceKey show diff fields
					 -->
					<v-col cols="12">
						<StopService
							:selectedServiceData="selectedServiceData"
							:selectedServiceKey="selectedServiceKey"
						></StopService>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions
				v-if="serviceDialogType !== 'assistance'"
				class="cardActions"
				:style="cardActionsStyle"
				@click="serviceHandler"
			>
				<span v-if="serviceDialogType === 'add'">
					Apply for {{ subTitleFilter(selectedServiceKey) }}
				</span>
				<span v-if="serviceDialogType === 'update'">
					Update {{ subTitleFilter(selectedServiceKey) }} Application
				</span>
				<span v-if="serviceDialogType === 'stop'">
					Stop {{ subTitleFilter(selectedServiceKey) }} Service
				</span>
			</v-card-actions>
		</v-card>
		<!-- ANIMATION -->
		<v-card v-show="serviceAnimation" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Updating Information</p>
					<v-progress-circular
						indeterminate
						color="primary darken-2"
						size="44"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-card>
		<v-card v-show="serviceSuccess" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Success!</p>
					<p>
						{{ subTitleFilter(selectedServiceKey) }} service
						{{ actionFilter(serviceDialogType) }}.
					</p>
				</v-col>
			</v-row>
		</v-card>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		/>
	</v-dialog>
</template>

<script>
// mixins
import basicDialogMixin from "@/mixins/basicDialogMixin.js";

// components
import ViewNotes from "./ServiceDialog/ViewNotes";
import AddService from "./ServiceDialog/AddService";
import UpdateService from "./ServiceDialog/UpdateService";
import StopService from "./ServiceDialog/StopService";
import AssistancePrompt from "./ServiceDialog/AssistancePrompt";

import CarbPrompt from "./ServiceDialog/CarbPrompt";
import HazmatPrompt from "./ServiceDialog/HazmatPrompt";
import PowerOnlyPrompt from "./ServiceDialog/PowerOnlyPrompt";

import ServiceDetails from "./ServiceDialog/ServiceDetails";

export default {
	name: "Service_Manager_Dialog",
	props: {
		value: { type: Boolean, default: false },
		serviceAnimation: { type: Boolean, default: false },
		serviceSuccess: { type: Boolean, default: false },
		serviceDialogType: { type: String, default: null },
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
	},
	components: {
		ViewNotes,
		AddService,
		StopService,
		CarbPrompt,
		HazmatPrompt,
		PowerOnlyPrompt,
		ServiceDetails,
		AssistancePrompt,
	},
	mixins: [basicDialogMixin],
	data() {
		return {
			runCali: false,
			documentId: null,
			hazmatRegNo: null,
			hazmatExpDate: null,
			firstName: null,
			middleInit: null,
			lastName: null,
			acceptedTerms: false,
			powerOnlyFile: null,
			dialogHeight: 0,
			fileTooLargeCarb: false,
			fileTooLargeHazm: false,
			fileTooLargePower: false,
		};
	},
	methods: {
		async addService() {
			try {
				// ALWAYS SEND SERVICE TYPE (CARB, HAZM, ETC) AND STATUS STRING OF 'int'
				const payload = {};
				payload.serviceType = this.selectedServiceKey;
				payload.status = "int";

				// FOR AUTH'D USERS
				if (this.selectedServiceKey === "powerOnly") {
					await this.signTrailerContract();
				}
				// IF A DOC WAS UPLOADED && KEYS ARE HAZM, CARB, OR POWERONLY W/ UNAUTH ADD IT TO PAYLOAD
				if (
					this.selectedServiceKey === "hazm" ||
					this.selectedServiceKey === "carb" ||
					(this.selectedServiceKey === "powerOnly" &&
						!this.authorized)
				) {
					if (this.documentId) payload.documentId = this.documentId;
				}
				// IF KEY IS HAZM GRAB THE EXTRA FIELDS AND ADD TO PAYLOAD
				if (this.selectedServiceKey === "hazm") {
					payload.hazmatRegNo = this.hazmatRegNo;
					payload.hazmatExpDate = this.hazmatExpDate;
				}
				// IF KEY IS CARB GRAB THE EXTRA FIELD AND ADD TO PAYLOAD
				if (this.selectedServiceKey === "carb") {
					payload.runCali = this.runCali;
				}

				// EMIT TO PARENT
				this.$emit("updateService", payload);
			} catch (error) {
				let message = `Adding ${this.subTitleFilter(
					this.selectedServiceKey
				)} service has failed. Please try again later.`;

				if (
					error.response &&
					error.response.data &&
					error.response.data.Error
				) {
					message = error.response.data.Error;
				}

				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: message,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Close",
						color: "#CF6679",
					},
					width: "350",
				};
			}
		},
		stopService() {
			// ALWAYS SEND SERVICE TYPE (CARB, HAZM, ETC) AND STATUS STRING OF 'int'
			const payload = {};
			payload.serviceType = this.selectedServiceKey;
			payload.status = "nint";

			// EMIT TO PARENT
			this.$emit("updateService", payload);
		},
		async serviceHandler() {
			if (this.preventAction) {
				// GRAB DIALOG HEIGHT FOR THE ANIMATION
				const dialog = document.querySelector("#serviceCardId");
				this.dialogHeight = dialog.clientHeight;
				// USING DIALOG TYPE CALL EITHER ADD, UPDATE, OR STOP
				this[`${this.serviceDialogType}Service`]();
			}
		},
		//////////////////////////////////////////
		// BELOW ARE THE CHILD SPECIFIC METHODS //
		//////////////////////////////////////////
		handleCarbSelection(val) {
			this.runCali = val;
		},
		updateHazmatFields({ hazmatRegNo, hazmatExpDate }) {
			this.hazmatRegNo = hazmatRegNo;
			this.hazmatExpDate = hazmatExpDate;
		},
		updatePowerOnlyFields({
			firstName,
			middleInit,
			lastName,
			acceptedTerms,
		}) {
			this.firstName = firstName;
			this.middleInit = middleInit;
			this.lastName = lastName;
			this.acceptedTerms = acceptedTerms;
		},
		// THIS IS FOR AUTH'D USERS (OWNER, MANAGER, ETC)
		async signTrailerContract() {
			try {
				let postObj = {
					firstName: this.firstName,
					lastName: this.lastName,
					acceptedTerms: this.acceptedTerms,
					signDate: new Date().toISOString(),
				};
				if (this.middleInit) {
					postObj.middleInit = this.middleInit;
				}

				if (this.authorized) {
					// AUTH'D USERS
					await this.$axios({
						method: "post",
						url: `${this.$hostName.apiBase}/onboarding/contract/trlrInterchange`,
						data: postObj,
						headers: {
							Authorization: "Bearer " + this.auth.token,
						},
					});
				} else {
					// UNAUTH'D USERS
					const formData = new FormData();
					formData.append("file", this.powerOnlyFile);

					await this.$axios({
						method: "post",
						url: `${this.$hostName.apiBase}/onboarding/contract/unauthTrlrInterchange`,
						data: formData,
						headers: {
							"Content-Type": "multipart/form-data",
							Accept: "application/json",
							Authorization: "Bearer " + this.auth.token,
						},
					});
				}
			} catch (error) {
				throw error;
			}
		},
		//////////////////////
		// HANDLE DOCUMENTS //
		//////////////////////
		async selectedFile(val) {
			if (this.selectedServiceKey === "carb") {
				this.fileTooLargeCarb = false;
			}
			if (this.selectedServiceKey === "hazm") {
				this.fileTooLargeHazm = false;
			}
			if (this.selectedServiceKey === "powerOnly") {
				this.fileTooLargePower = false;
				this.powerOnlyFile = val;
			}
			// Don't upload the poweronly doc here - that is handled in the contract sign endpoint
			if (this.selectedServiceKey != "powerOnly") {
				await this.addDocument(val);
			}
		},
		async addDocument(val) {
			const formData = new FormData();

			// THESE DOC CLASS NAMES HAVE TO BE AS IS
			if (this.selectedServiceKey === "carb") {
				formData.append("CARB", val);
			}
			if (this.selectedServiceKey === "hazm") {
				formData.append("HAZMAT", val);
			}

			try {
				const { data } = await this.$axios({
					method: "post",
					url: `${this.$hostName.apiBase}/onboarding/documents/add`,
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "application/json",
						Authorization: "Bearer " + this.auth.token,
					},
				});

				this.documentId = data.documentId;
			} catch (error) {
				console.log(error);
			}
		},
		async viewDocument(documentId) {
			try {
				const response = await this.$axios({
					method: "get",
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId,
					},
					headers: {
						Authorization: "Bearer " + this.auth.token,
					},
					responseType: "blob",
				});

				this.documentBlob = URL.createObjectURL(response.data);

				const link = document.createElement("a");
				link.setAttribute("type", "hidden");
				link.target = "_blank";
				link.href = this.documentBlob;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (error) {
				console.error("view power only doc error:", error);
			}
		},
		subTitleFilter(item) {
			let text;
			switch (item) {
				// case "fsmaRegulated":
				// 	text = "FSMA";
				// 	break;
				case "hazm":
					text = "Hazmat";
					break;
				case "liftGate":
					text = "Lift Gate";
					break;
				case "tanker":
					text = "Tanker";
					break;
				case "dropTrailers":
					text = "Drop Trailer";
					break;
				case "intermodal":
					text = "Intermodal";
					break;
				case "powerOnly":
					text = "Power Only";
					break;
				case "teamExpedited":
					text = "Expedited";
					break;
				case "carb":
					text = "CARB";
					break;
				case "smartway":
					text = "Smartway";
					break;
				case "canada":
					text = "Canada";
					break;
				case "mexico":
					text = "Mexico";
					break;
				case "gps":
					text = "GPS";
					break;
				case "truckerTools":
					text = "Trucker Tools";
					break;
				case "twic":
					text = "TWIC";
					break;
				case "macropoint":
					text = "Macropoint";
					break;
				case "reefer":
					text = "Reefer";
					break;
				default:
					text = item;
			}
			return text;
		},
		actionFilter(item) {
			let text;
			switch (item) {
				case "add":
					text = "application submitted";
					break;
				case "update":
					text = "application updated";
					break;
				case "stop":
					text = "offering stopped";
					break;
				default:
					text = "";
			}
			return text;
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		fleetSize() {
			return this.$store.state.onboardingInfo.fleet.company.power;
		},

		companyRole() {
			return this.$store.state.userInfo.companyRole;
		},
		authorized() {
			if (this.companyRole === "owner") return true;
			const secondaryRoles = ["corporateManager", "operationsManager"];
			if (
				secondaryRoles.includes(this.companyRole) &&
				this.fleetSize >= 20
			)
				return true;
			return false;
		},
		cardActions() {
			let style;
			let clickAction;

			const specialServices = ["powerOnly", "hazm", "carb"];

			///////////////////////////////////////////////////////////
			// Validation styling for the add / update dialog button //
			///////////////////////////////////////////////////////////
			if (
				this.serviceDialogType === "add" ||
				this.serviceDialogType === "update"
			) {
				/////////////////
				// CARB CHECKS //
				/////////////////
				if (
					(this.selectedServiceKey === "carb" &&
						!this.$formValid.carb) ||
					(this.selectedServiceKey === "carb" &&
						this.fileTooLargeCarb)
				) {
					style = { "background-color": "#282828", color: "#424242" };
					clickAction = false;
				}
				if (
					this.selectedServiceKey === "carb" &&
					this.$formValid.carb &&
					!this.fileTooLargeCarb
				) {
					style = {
						"background-color": "#673aa7",
						cursor: "pointer",
						color: "#FFF",
					};
					clickAction = true;
				}
				/////////////////
				// HAZM CHECKS //
				/////////////////
				if (
					(this.selectedServiceKey === "hazm" &&
						!this.$formValid.hazm) ||
					(this.selectedServiceKey === "hazm" &&
						this.fileTooLargeHazm)
				) {
					style = { "background-color": "#282828", color: "#424242" };
					clickAction = false;
				}
				if (
					this.selectedServiceKey === "hazm" &&
					this.$formValid.hazm &&
					!this.fileTooLargeHazm
				) {
					style = {
						"background-color": "#673aa7",
						cursor: "pointer",
						color: "#FFF",
					};
					clickAction = true;
				}
				//////////////////////
				// POWERONLY CHECKS //
				//////////////////////
				if (
					(this.selectedServiceKey === "powerOnly" &&
						!this.$formValid.powerOnly) ||
					(this.selectedServiceKey === "powerOnly" &&
						this.fileTooLargePower)
				) {
					style = { "background-color": "#282828", color: "#424242" };
					clickAction = false;
				}
				if (
					this.selectedServiceKey === "powerOnly" &&
					this.$formValid.powerOnly &&
					!this.fileTooLargePower
				) {
					style = {
						"background-color": "#673aa7",
						cursor: "pointer",
						color: "#FFF",
					};
					clickAction = true;
				}
				//////////////////////////////////////////////////////////////////////////////////////////
				// if its any service other than powerOnly, carb, hazm -- is always the validated class //
				//////////////////////////////////////////////////////////////////////////////////////////
				if (!specialServices.includes(this.selectedServiceKey)) {
					style = {
						"background-color": "#673aa7",
						cursor: "pointer",
						color: "#FFF",
					};
					clickAction = true;
				}
			}

			////////////////////////////////////
			// Validation for the stop dialog //
			////////////////////////////////////
			if (this.serviceDialogType === "stop") {
				style = {
					"background-color": "#673aa7",
					cursor: "pointer",
					color: "#FFF",
				};
				clickAction = true;
			}

			return { style, clickAction };
		},
		cardActionsStyle() {
			return this.cardActions.style;
		},
		preventAction() {
			return this.cardActions.clickAction;
		},
	},
};
</script>
<style scoped>
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
</style>
