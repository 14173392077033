export default {
	methods: {
		maxLength(item) {
			let length;
			switch (item) {
				// general
				case 'taxId':
					length = 9
					break;
				case 'telephone':
					length = 12
					break;
				case 'phone':
					length = 12
					break;
				case 'fax':
					length = 12
					break;
				// payments
				case 'postalCode':
					length = 6
					break;
				// addresses
				case 'zip':
					length = 6
					break;
				default:
					length = 100
			}
			return length
		},
	}
}