<template>
	<v-card
		class="elevation-8 mt-2"
		:img="require('../../../../assets/service.svg')"
	>
		<v-row>
			<v-col cols="12">
				<v-row justify="center">
					<v-col cols="12" class="pa-0">
						<v-row justify="space-between">
							<v-col cols="6" class="pa-0 text-left">
								<v-btn
									fab
									small
									:color="setColor(documentObject)"
									@click="
										$emit(
											'openUploadDialog',
											documentObject.value
										)
									"
								>
									<v-icon>
										{{
											hasDocumentUploaded
												? "mdi-pencil"
												: "mdi-plus"
										}}
									</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="6" class="pa-0 text-right">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											:color="uploadedColor"
											large
											v-on="on"
											v-bind="attrs"
										>
											mdi-checkbox-marked-circle
										</v-icon>
									</template>
									<span>{{
										hasDocumentUploaded
											? "Doc Uploaded"
											: "Doc not uploaded"
									}}</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" class="pa-0 text-center">
						<v-icon style="font-size: 45px;" color="#BDBDBD">
							mdi-file-outline
						</v-icon>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" class="px-2 py-1 pb-3">
				<v-row
					justify="center"
					style="font-size: 80%; text-align: center"
				>
					{{ documentObject.value | shortDocClass }}
				</v-row>
			</v-col>

			<v-col cols="12" class="pa-0 py-2 cardAction">
				<v-row justify="space-between" align="center">
					<v-col cols="12" class="cardActionContent text-center pa-0">
						<v-row
							align="center"
							justify="center"
							@click="$emit('selectAssistance', documentObject)"
						>
							<span style="color: #FFA12F">More Info</span>
							<v-icon color="warning" class="ml-2" small
								>mdi-help-circle-outline</v-icon
							>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
export default {
	name: "Documents_Card",
	props: {
		documentObject: Object,
	},
	data() {
		return {
			archiveMenu: {
				aff: false,
				auth: false,
				auto: false,
				carb: false,
				dotInsp: false,
				// fsma: false,
				insur: false,
				minorCert: false,
				noa: false,
				other: false,
				poAg: false,
				reef: false,
				rev: false,
				wEight: false,
				wNine: false,
			},
		};
	},
	computed: {
		//file that has been uploaded has createdAt and documentID set
		hasDocumentUploaded() {
			return (
				this.documentObject["createdAt"] &&
				this.documentObject["documentId"]
			);
		},
		uploadedColor() {
			return this.hasDocumentUploaded ? "success" : "grey";
		},
	},
	methods: {
		setColor(item) {
			let color = "#757575";
			if (
				item.value === "MC Letter of Authority" ||
				item.value === "W9 Tax Form" ||
				item.value === "Certificate of Insurance"
			) {
				color = "#8353c3";
			} else {
				color = "#434343";
			}
			return color;
		},
		setActionIconColor(item) {
			let color = "#424242";
			if (!item) {
				color = "#424242";
			} else {
				color = "#BDBDBD";
			}
			return color;
		},
		setActionTextColor(item) {
			let style = { color: "#424242" };
			if (!item) {
				style = { color: "#424242" };
			} else {
				style = { color: "#BDBDBD" };
			}
			return style;
		},
	},
	filters: {
		shortDocClass(val) {
			let docClass;
			switch (val) {
				case "Certificate of Insurance":
					docClass = "Certificate of Insurance";
					break;
				case "Scheduled Autos":
					docClass = "Scheduled Autos";
					break;
				case "Revised Contract":
					docClass = "Revised Contract";
					break;
				case "CARB":
					docClass = "CARB";
					break;
				case "PO Agreement":
					docClass = "PO Agreement";
					break;
				case "MC Letter of Authority":
					docClass = "Letter of Authority";
					break;
				case "W9 Tax Form":
					docClass = "W9 Tax Form";
					break;
				case "Reefer TRU":
					docClass = "Reefer TRU";
					break;
				// case "FSMA Addendum":
				// 	docClass = "FSMA Addendum";
				// 	break;
				case "W8 Tax Form":
					docClass = "W8 Tax Form";
					break;
				case "Affadavit":
					docClass = "Affadavit";
					break;
				case "NOA":
					docClass = "NOA";
					break;
				case "HAZMAT":
					docClass = "HAZMAT";
					break;
				case "Other":
					docClass = "Other";
					break;
				case "Minority Certification":
					docClass = "Diversity Certificate";
					break;
				default:
					docClass = val;
			}
			return docClass;
		},
	},
};
</script>

<style scoped>
.cardAction {
	background-color: #282828;
	border-radius: 0 0 10px 10px;
}
.cardActionContent {
	font-size: 12px;
	cursor: pointer;
}
.active {
	cursor: pointer;
}
</style>
