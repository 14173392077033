<template>
	<v-card flat>
		<v-card-text class="list-scroll">
			This is an insurance vehicle list issued by the insurance agent that details: year, make, model, vin# of truck/vehicle &amp; trailer insured under an Auto &amp; Cargo liability. This document is often used to prove
			Insurance of all trucks running for a fleet as well as Broker due diligence review to meet CARB (California Air Resource Board) and TRU (Transport Refrigeration Unit) requirements of make year of a truck/vehicle or trailer.
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'WNine_Assistance'
};
</script>