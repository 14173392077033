export default {
	data() {
		return {
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: null,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
		}
	},
    methods: {
        closeBasicDialog() {
			this.basicDialog.value = false;
			this.$background.blur = false;
			setTimeout(() => {
				this.basicDialog.title = null;
				this.basicDialog.text = null;
				this.basicDialog.hasAction = false;
				this.basicDialog.actionButton = {};
				this.basicDialog.width = null;
			}, 500);
		},
        returnLandingPage() {
			this.basicDialog.value = false;
			this.$background.blur = false;
			this.$router.push('/')
			setTimeout(() => {
				this.basicDialog.title = null;
				this.basicDialog.text = null;
				this.basicDialog.hasAction = false;
				this.basicDialog.actionButton = {};
				this.basicDialog.width = null;
			}, 500);
		},
    }
}