<!--
	This Animation Component accepts the following props:
	value: Boolean - the v-model from the parent component value:
-->
<template>
	<v-col cols="12" class="starContainer" v-if="value">
		<v-row align="center" justify="center" class="rowCenter">
			<v-col cols="12" class="pa-0">
				<v-row align="center" justify="center">
					<div class="icon miniIcon hide" id="littleStarIcon">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
							 viewBox="0 0 79.1 35" xml:space="preserve">
							<g id="rightMiniStar">
								<path d="M78.4,24.6c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7l-3.4-0.5c-0.1,0-0.3-0.1-0.3-0.2L72.6,19
									c-0.2-0.3-0.5-0.6-0.9-0.6c-0.4,0-0.7,0.2-0.9,0.6l-1.5,3.1c-0.1,0.1-0.2,0.2-0.3,0.2l-3.4,0.5c-0.4,0.1-0.7,0.3-0.8,0.7
									c-0.1,0.4,0,0.8,0.3,1l2.5,2.4c0.1,0.1,0.2,0.3,0.1,0.4l-0.6,3.4c-0.1,0.3,0,0.6,0.2,0.8c0.3,0.4,0.8,0.5,1.3,0.2l3.1-1.6
									c0.1-0.1,0.3-0.1,0.4,0l3.1,1.6c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.2-0.8l-0.6-3.4
									c0-0.1,0-0.3,0.1-0.4L78.4,24.6z"/>
							</g>
							<g id="leftMiniStar">
								<path d="M14.3,24.6c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7l-3.4-0.5c-0.1,0-0.3-0.1-0.3-0.2L8.4,19
									c-0.2-0.3-0.5-0.6-0.9-0.6c-0.4,0-0.7,0.2-0.9,0.6l-1.5,3.1c-0.1,0.1-0.2,0.2-0.3,0.2l-3.4,0.5c-0.4,0.1-0.7,0.3-0.8,0.7
									c-0.1,0.4,0,0.8,0.3,1L3.2,27c0.1,0.1,0.2,0.3,0.1,0.4l-0.6,3.4c-0.1,0.3,0,0.6,0.2,0.8c0.3,0.4,0.8,0.5,1.3,0.2l3.1-1.6
									c0.1-0.1,0.3-0.1,0.4,0l3.1,1.6c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.2-0.8l-0.6-3.4
									c0-0.1,0-0.3,0.1-0.4L14.3,24.6z"/>
							</g>
							<g id="topMiniStar">
								<path d="M46.5,6.6c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7l-3.4-0.5c-0.1,0-0.3-0.1-0.3-0.2L40.6,1
									c-0.2-0.3-0.5-0.6-0.9-0.6c-0.4,0-0.7,0.2-0.9,0.6l-1.5,3.1c-0.1,0.1-0.2,0.2-0.3,0.2l-3.4,0.5c-0.4,0.1-0.7,0.3-0.8,0.7
									c-0.1,0.4,0,0.8,0.3,1L35.4,9c0.1,0.1,0.2,0.3,0.1,0.4l-0.6,3.4c-0.1,0.3,0,0.6,0.2,0.8c0.3,0.4,0.8,0.5,1.3,0.2l3.1-1.6
									c0.1-0.1,0.3-0.1,0.4,0l3.1,1.6c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.2-0.8l-0.6-3.4
									c0-0.1,0-0.3,0.1-0.4L46.5,6.6z"/>
							</g>
						</svg>
					</div>
				</v-row>
			</v-col>
			<v-col cols="12" class="pa-0">
				<v-row align="center" justify="center">
					<div class="icon star hide" id="mainStarIcon">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
							 viewBox="0 17 79.1 52" xml:space="preserve">
						<g id="mainStar">
							<path d="M63.5,42.2c1-1,1.3-2.4,0.9-3.7c-0.4-1.3-1.5-2.2-2.9-2.4l-12.1-1.8c-0.5-0.1-1-0.4-1.2-0.9l-5.4-11c-0.6-1.2-1.8-2-3.2-2
								c-1.4,0-2.6,0.8-3.2,2l-5.4,11c-0.2,0.5-0.7,0.8-1.2,0.9L17.7,36c-1.4,0.2-2.5,1.1-2.9,2.4c-0.4,1.3-0.1,2.7,0.9,3.7l8.7,8.5
								c0.4,0.4,0.5,0.9,0.5,1.4l-2.1,12c-0.2,1.1,0.1,2.1,0.8,2.9c1.1,1.3,2.9,1.7,4.4,0.9l10.8-5.7c0.5-0.2,1-0.2,1.5,0l10.8,5.7
								c0.5,0.3,1.1,0.4,1.7,0.4c1.1,0,2.1-0.5,2.7-1.3c0.7-0.8,1-1.8,0.8-2.9l-2.1-12c-0.1-0.5,0.1-1,0.5-1.4L63.5,42.2z"/>
						</g>
						</svg>
					</div>
				</v-row>
			</v-col>
			<v-col cols="12" class="pa-0">
				<v-row align="center" justify="center">
					<div class="successText hide" id="successText">
						Success!
					</div>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	name: 'Success_Star_Animated',
	props: {
		value: Boolean
	},
	data: () => ({
		//
	}),
	methods: {
		//
	},
	watch: {
		value(val) {
			if (val) {
				this.$nextTick(() => {
					const input = document.getElementById('mainStarIcon');
					const text = document.getElementById('successText');
					const miniIcon = document.getElementById('littleStarIcon');
					input.classList.remove('hide')
					text.classList.remove('hide')
					miniIcon.classList.remove('hide')
					if (val) {
						input.classList.add('animate')
					} else {
						input.classList.remove('animate')
						input.classList.add('hide')
						text.classList.add('hide')
						miniIcon.classList.add('hide')
					}
				})
			}
		}
	}
};
</script>

<style>
.hide {
	display: none;
}
.starContainer {
	position: relative;
	min-height: 170px;
}
.starContainer .icon {
	width: 30%;
}
.rowCenter {
	position: absolute;
	min-height: 120px;
	width: 100%;
	bottom: 0;
	left: 0;
}
.starContainer .miniIcon {
	position: absolute;
	top: -35px;
	width: 30%;
}
.starContainer .icon path {
	fill: transparent;
	stroke-width: 1;
	stroke: #59b200;
}
.starContainer .icon.star path {
	stroke-dasharray: 200;
	stroke-dashoffset: 0;
}
.starContainer .icon.star.animate path {
	animation: animate-star 2s linear forwards;
}
@keyframes animate-star
{
	0% {
		stroke-dashoffset: 0;
	}
	40% {
		stroke-dashoffset: 200;
	}
	80% {
		stroke-dashoffset: 400;
		fill: transparent;
	}
	100% {
		stroke-dashoffset: 400;
		fill: #59b200;
	}
}

.miniIcon {
	animation: fade-in 3s;
}
.successText {
	font-size: 26px;
	font-weight: 600;
	color: #BDBDBD;
	animation: fade-in 3s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  80% {
  	opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>