export default {
	methods: {
		numOnly(event, item) {
			// general
			if (item === 'telephone') {
				/\D/.test(event.key) && event.preventDefault()
			}
			if (item === 'phone') {
				/\D/.test(event.key) && event.preventDefault()
			}
			if (item === 'fax') {
				/\D/.test(event.key) && event.preventDefault()
			}
			if (item === 'extension') {
				/\D/.test(event.key) && event.preventDefault()
			}
			else if (item === 'taxId') {
				/\D/.test(event.key) && event.preventDefault()
			}
			// payments
			// else if (item === 'postalCode') {
			// 	/\D/.test(event.key) && event.preventDefault()
			// }
			// addresses
			// else if (item === 'zip') {
			// 	/\D/.test(event.key) && event.preventDefault()
			// }
			else if (item === 'USDOT') {
				/\D/.test(event.key) && event.preventDefault()
			}
			else {
				return
			}
		},
	}
}