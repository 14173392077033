<template>
	<v-container class="fill-height">
		<v-row class="fill-height" align="center">
			<v-col cols="12" class="text-center">
				<p class="mb-2">LOGGING OUT</p>
				<v-progress-circular indeterminate color="primary darken-2" size="44"></v-progress-circular>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data: () => ({


	}),
	computed: {
		auth() {
			return this.$store.state.auth;
		},
	},
	methods: {
		async logoutUser() {
			try {
				// resets store objects to empty objects
				this.$store.commit('updateAuth', {});
				this.$store.commit('updateUser', {});
				this.$store.commit('updateOrg', {});
				this.$store.commit('updatePermissions', {});

				// reset all of the inital onboarding fields, documents, services, progress, payments
				this.$store.commit('resetOnboarding');
				this.$store.commit('resetDocuments');
				this.$store.commit('resetPayments');
				this.$store.commit('resetServices');
				this.$store.commit('resetProgress');


				// clears localStorage and sets logged in to false
				localStorage.removeItem('knight-swift-logistics');
				this.auth.loggedIn = false;

				// pushes user back to splash page
				this.$router.push('/');
			} catch (error) {
				//
				console.log(error);
			} finally {
				//
			}
		},
	},
	mounted() {
		//
		setTimeout(() => {
			this.logoutUser();
		}, 1500);
	},
};
</script>

<style scoped>
/* */
</style>
