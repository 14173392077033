<template>
	<v-col style="overflow-y: scroll; max-height: 50vh;">
		<v-row>
			<v-col cols="12">
				<span class="font-weight-bold">
					Supplier Diversity FAQ
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					Knight-Swift Transportation believes in high ethical standards across its operations, including its supply chain.  Supplier diversity programs are an important way to demonstrate these standards and combat social injustice in the United States and abroad.  It’s people, their culture, and their stories that empower a supply chain to become diverse, innovative and resilient.
					<br><br>
					A diverse supplier is a business that is at least 51% owned and operated by an individual or group that is part of a traditionally underrepresented or underserved group.  Common classifications are small-businesses (SBEs), minority-owned enterprises (MBEs), and women-owned enterprises (WBEs).  Over time, the definition of diversity has come to include other minority groups such as LGBTQ+, veterans, and proprietors with disabilities.
					<br><br>
					Diverse suppliers are important to local economies, and local economies are important to the empowerment of people of all backgrounds.  Ideally, supplier diversity combines the best of our desires for a better, more equitable world and the drive to be a competitive leader in the marketplace.  Help Knight Swift Transportation showcase this effort by providing your diverse supplier certificate.  If you do not already have this, please see below (or appropriate location) for information on how to acquire your certification and the benefits of certifying your status.
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					If you are a diverse supplier and have not yet applied for your certificate, please consider following the appropriate link to begin the process to certifying your status.  Benefits of becoming a certified diverse business are many and include:
				</span>
				<br><br>
				<ul>
					<li>
						<span class="font-weight-bold">Loans:</span> If you’re just starting out as a small business, obtaining a Women’s Business Enterprise (WBE) or Minority Business Enterprise (MBE) certification is an effective way to receive assistance for securing loans. Initial funding will provide you with capital to get your business up and running. Existing small businesses also benefit from obtaining loans to acquire additional equipment, expand their employee base or open another location.
					</li>
					<li>
						<span class="font-weight-bold">Databases:</span> Certified diverse business databases allow you to search for prospective customers and clients who will benefit from the product or service your business provides. Likewise, your company’s information will be available so that other diverse businesses, major corporations, government entities, or customers who are looking for your product or service can connect with you.  Many organizations have goals surrounding spend on diverse businesses.
					</li>
					<li>
						<span class="font-weight-bold">Networking opportunities:</span> Events and conferences enable you to gain valuable face time with potential purchasers to increase awareness of your brand, develop relationships and increase the likelihood of establishing contracts with purchasers. In addition, certifying-organization sponsored events are an excellent venue to connect with other diverse suppliers to share insight, compare experiences and discuss future business opportunities or partnerships.
					</li>
					<li>
						<span class="font-weight-bold">Educational opportunities and training:</span> Certified diverse businesses have access to training programs, educational workshops and mentorship programs. Such workshops include strategies for selling to large corporations, ways to establish supplier diversity programs within your company and best practices for obtaining government contracts.
					</li>
				</ul>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<span>
					Links to certification organizations for diverse suppliers:
				</span>
				<ul>
					<li>
						<span class="font-weight-bold"><a href="https://nmsdc.org/mbes/mbe-certification/">National Minority Supplier Development Council:</a></span> More than 12,000 minority-owned businesses belong to the NMSDC, along with 1,750 corporate members that rely on the organization to find diverse suppliers.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://www.wbenc.org/certification/">Women's Business Enterprise National Council:</a></span> The WBENC is the largest third-party certifier of woman-owned businesses in the United States.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://www.nwbc.gov/content/what-certification/">National Women's Business Council:</a></span> Certification with the NWBC is required for WBEs to be eligible to work on federal contracts.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://certify.sba.gov/">Small Business Administration:</a></span> Although the SBA is primarily known as an agency that gives loans to small businesses, it also certifies companies to be eligible to work on federal contracts.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://www.nglcc.org/get-certified">National LGBT Chamber of Commerce:</a></span> The NGLCC grants its LGBT Business Enterprise designation to eligible business that are at least 51 percent owned by LGBT individuals.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://www.va.gov/osdbu/verification/">Vets First:</a></span> The Vets First Verification Program places the highest priority on service-disabled veteran-owned small businesses and then veteran-owned small businesses for Veterans Administration purchasing and procurement.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://nvbdc.org/certification-landing-page/">National Veteran Business Development Council:</a></span> The NVBDC is the largest certifier of veteran-owned businesses.
					</li>
					<li>
						<span class="font-weight-bold"><a href="https://disabilityin.org/what-we-do/supplier-diversity/get-certified/"> Disability:IN:</a></span> This nonprofit resource offers its certification to disability-owned business enterprises and service-disabled veteran-owned business enterprises.
					</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'General_Information_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>