<template>
	<v-row align="center" justify="center">

		<v-col cols="12" lg="10" class="px-0 mx-0">
			<v-card class="px-0 mx-0">
				<v-row align="center">
					<v-col xs="12" sm="12" md="5" lg="7" class="d-flex justify-start">
						<span class="px-2">
							<v-btn @click="backButton" icon>
								<v-icon medium>fa-arrow-left</v-icon>
							</v-btn>
						</span>
						<span class="px-2" :style="titleFont">Sign and Complete</span>
						<!-- step counter -->
						<span class="mt-2 px-2" style="color: #BB86FC">6 / 6</span>
					</v-col>
					<v-col xs="12" sm="12" md="7" lg="5" class="pa-0 text-right">
						<v-btn
							class="ma-1"
							color="primary"
							:outlined="true"
							rounded
							@click="$router.push('/dashboard')"
						>
							Return Later
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-col>
		<v-col cols="12" lg="10" class="justify-center pa-0" align-self="start">
			<v-card>
				<AuthToSign v-if="authorized" @viewContract="viewDocument" @signedDialog="signedDialog = true"></AuthToSign>
				<UnauthToSign v-else @signedDialog="signedDialog = true"></UnauthToSign>
			</v-card>
		</v-col>
		<ContractSignedDialog v-model="signedDialog"/>
	</v-row>
</template>

<script>

import moment from 'moment'
import AuthToSign from './authToSign.vue';
import UnauthToSign from './unauthToSign.vue';
import ContractSignedDialog from './contractSignedDialog'

export default {
	name: 'Contract',
	components: {
		AuthToSign,
		UnauthToSign,
		ContractSignedDialog
	},
	data(){
		return {
			formValid: false,
			acceptTerms: false,
			title: null,
			firstName: null,
			middleInitial: null,
			lastName: null,
			today: moment().format('MM/DD/YYYY'),
			signedDialog: false,
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status
		},
		fleetSize() {
			return this.$store.state.onboardingInfo.fleet.company.power
		},
		authorized() {
			if (this.userInfo.companyRole === 'owner') return true;
			const secondaryRoles = [ 'corporateManager', 'operationsManager'] 
			if (secondaryRoles.includes(this.userInfo.companyRole) && this.fleetSize >= 20 ) return true;
			return false;
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'contract',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.$formValid.general
			}
		},
		titleFont() {
			let style;
			if (this.$vuetify.breakpoint.smAndDown) style = {'font-size': '20px'};
			if (this.$vuetify.breakpoint.mdOnly) style = {'font-size': '22px'};
			if (this.$vuetify.breakpoint.lgAndUp) style = {'font-size': '24px'};
			return style;
		},
	},
	methods: {
		backButton() {
			console.log("this is the page: ", this.navButtonData.currPage);
			if(this.navButtonData.currPage === 'contract') {
				this.$router.push('/documents')
			}
		},
		async viewDocument(documentId) {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId
					},
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
					responseType: 'blob',
				});

				this.documentBlob = URL.createObjectURL(response.data);

				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.target = '_blank';
				link.href = this.documentBlob;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (error) {
				console.error('view power only doc error:', error);
			}
		},
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
.contractScroll::-webkit-scrollbar {
	width: 11px;
	background: transparent;
}
.contractScroll::-webkit-scrollbar-track {
	background: #121212;
}
.contractScroll::-webkit-scrollbar-thumb {
	background-color: #4c228c;
}
.contractScroll {
	background: #151515;
	height: 300px;
	overflow-y: scroll;
	padding: 10px;
	color: #BDBDBD;
}
</style>
