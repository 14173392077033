<template>
	<v-row>
		This action will turn off your service offering, you will no longer be able to run {{subTitleFilter(selectedServiceKey)}} loads. Are you sure you want to turn off this service?
	</v-row>
</template>

<script>
export default {
	name: 'Service_Dialog_StopServiceText',
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null }
	},
	data() {
		return {
			//
		}
	},
	methods: {
		subTitleFilter(item) {
			let text;
			switch(item) {
				// case 'fsmaRegulated':
				// 	text = 'FSMA';
				// 	break;
				case 'hazm':
					text = 'Hazmat';
					break;
				case 'liftGate':
					text = 'Lift Gate';
					break;
				case 'tanker':
					text = 'Tanker';
					break;
				case 'dropTrailers':
					text = 'Drop Trailer';
					break;
				case 'intermodal':
					text = 'Intermodal';
					break;
				case 'powerOnly':
					text = 'Power Only';
					break;
				case 'teamExpedited':
					text = 'Expedited';
					break;
				case 'carb':
					text = 'CARB';
					break;
				case 'smartway':
					text = 'Smartway';
					break;
				case 'canada':
					text = 'Canada';
					break;
				case 'mexico':
					text = 'Mexico';
					break;
				case 'gps':
					text = 'GPS';
					break;
				case 'truckerTools':
					text = 'Trucker Tools';
					break;
				case 'twic':
					text = 'TWIC';
					break;
				case 'macropoint':
					text = 'Macropoint';
					break;
				default:
					text = item;
			}
			return text;
		},
	},
	computed: {
		//
	},
};
</script>

<style scoped>
/**/
</style>