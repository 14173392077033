const fn = value => {
	if (value != null) {
		return value.replace(/^\s*\(?(\d{3})\D*(\d{3})\D*(\d{4})\s*$/, '$1-$2-$3');
	} else {
		return 'N/A';
	}
}

export default fn;
// ^^^^^^^^^^^^^
// phoneNumber: function(numberInput) {
// 	numberInput += '';
// 	numberInput = numberInput.replace(/[^\d\(\)]/g, '');
// 	numberInput = numberInput.replace(/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/, '$2-$3-$4').replace(/-+$/, '');
// 	this.phoneNumber = numberInput;
// }