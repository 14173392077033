<template>
	<v-dialog
		:value="value"
		scrollable
		persistent
		:width="$vuetify.breakpoint.smAndDown ? '70vw' : '30vw'"
		transition="dialog-transition"
	>
		<v-card v-show="!addDocAnimation && !docInfoSuccess" id="paymentCardId">
			<v-card-title>
				<v-row justify="end" align="center" class="fill-height">
					<v-btn @click="$emit('closeUploadDialog')" icon>
						<v-icon color="primary darken-1">mdi-close-thick</v-icon>
					</v-btn>
				</v-row>
			</v-card-title>
			<v-card-text class="px-5 pb-8">
				<v-row justify="center" align="center">
					<v-col cols="12">
						If you are using a factoring company to process payments you are required to
						upload a Notice of Assignment Document.
					</v-col>

					<v-col cols="12" class="text-center pt-7">
						<v-btn
							@click="initFileUpload()"
							rounded
							dark
							color="primary darken-3"
							:disabled="addDocAnimation"
						>
							Select your Document
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="py-3" justify="center" v-if="file && file.size > 19922944">
					<v-icon color="#FFA12F" class="mr-2">mdi-alert</v-icon>
					<span>Selected File is too Large - Max File size 19MB</span>
				</v-row>

				<input
					@input="selectFile()"
					type="file"
					accept="image/png, image/jpeg, application/pdf"
					id="uploadNewDoc"
					required
					style="display: none"
					name="TEST"
				/>
			</v-card-text>
			<v-card-actions class="cardActions" :style="cardActionsStyle" @click="uploadButton">
				Upload
			</v-card-actions>
		</v-card>
		<v-card v-show="addDocAnimation" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Updating Information</p>
					<v-progress-circular
						indeterminate
						color="primary darken-2"
						size="44"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-card>
		<v-card v-show="docInfoSuccess" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Success!</p>
					<p>Notice of Assignment Document Submitted.</p>
				</v-col>
			</v-row>
		</v-card>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-dialog>
</template>

<script>
import basicDialogMixin from '@/mixins/basicDialogMixin';

export default {
	props: ['value', 'docType', 'addDocAnimation', 'docInfoSuccess'],
	mixins: [basicDialogMixin],
	data() {
		return {
			file: null,
			selectedFile: false,
			checkbox: false,
			dialogHeight: 0,
		};
	},
	methods: {
		initFileUpload() {
			const input = document.getElementById('uploadNewDoc');
			input.click();
		},
		selectFile() {
			const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
			this.file = document.getElementById('uploadNewDoc').files[0];

			if (!acceptedFileTypes.includes(this.file.type)) {
				this.file = null;
				this.selectedFile = false;
				document.getElementById('uploadNewDoc').value = '';
				return (this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: 'Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				});
			}

			if (this.file.size < 19922944) {
				this.selectedFile = true;
			} else {
				this.selectedFile = false;
			}
		},
		cancelButton() {
			this.checkbox = false;
			this.selectedFile = false;
			this.$emit('closeUploadDialog');
		},
		uploadButton() {
			const dialog = document.querySelector('#paymentCardId');
			this.dialogHeight = dialog.clientHeight;
			if (this.selectedFile) {
				this.$emit('addDocument', this.file);
				document.getElementById('uploadNewDoc').value = '';
			}
		},
	},
	computed: {
		cardActionsStyle() {
			let style;
			if (!this.selectedFile) {
				style = { 'background-color': '#282828', color: '#424242' };
			} else {
				style = { 'background-color': '#673aa7', cursor: 'pointer', color: '#FFF' };
			}

			return style;
		},
	},
	watch: {
		value(val) {
			if (!val) {
				this.checkbox = false;
				this.selectedFile = false;
				this.file = null;
			}
		},
	},
};
</script>

<style scoped>
#uploadNewDoc {
	position: absolute !important;
	width: 0.1px !important;
	height: 0.1px !important;
}
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
</style>
