export default {
	methods: {
		ruleMethod(val) {
			let rule;
			switch (val) {
				// general
				case 'emailAddress':
					rule = [this.$formRules.required, this.$formRules.email];
                    break;
                    //email for alt Contact
				case 'email':
					rule = [this.$formRules.required, this.$formRules.email];
					break;
                case 'telephone':
                    rule = [this.$formRules.required, this.$formRules.phoneValid];
                    break;
                     //phone for alt Contact
                case 'phone':
                    rule = [this.$formRules.required, this.$formRules.phoneValid];
                    break;
                case 'extension':
                    rule = [];
                    break;
                case 'fax':
                    rule = [];
                    break;
                case 'doingBusinessAsName':
                    rule = [];
                    break;
                case 'scacNo':
                    rule = [];
                    break;
                case 'taxId':
                    rule = [this.$formRules.required, this.$formRules.tinValid];
                    break;
                case 'website':
                    rule = [];
                    break;
                // addresses
                // case 'zip':
                //     rule = [this.$formRules.required, this.$formRules.zipValid];
                //     break;
                // payments
                // case 'postalCode':
                //     rule = [this.$formRules.required, this.$formRules.zipValid];
                //     break;
                case 'address2':
                    rule = [];
                    break;
                // fleet
                case 'power':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'drivers':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'dry':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'flatbed':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'reefer':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'carHaul':
                    rule = [this.$formRules.fleetValid]
                    break;
                case 'highCube':
                    rule = [this.$formRules.fleetValid]
                    break;
                // documents
                case 'document':
                    rule = [this.$formRules.docValid]
                    break;
				default:
					rule = [this.$formRules.required];
			}

			return rule
		},
	}
}
