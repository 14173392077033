<!--
	This Dialog accepts the following props:
	value: Boolean - the v-model from the parent component value:
	options: object - which has the following props
		title: String - text for the title of the dialog
		text: String - the text for the body of the dialog
		hasAction: Boolean - defines whether dialog has action button - if using a setTimeout to close dialog, no need to have action
		actionButton: Object:
			{
				action: String - the string that the button will emit onClick to be listened for in parent
				text: String - text for the action button
				color: String - color for the button
			}
		width: String - defines the width of the dialog
-->
<template>
	<v-dialog v-model="value" :width="options.width || '275'"  persistent>
		<v-card>
			<v-card-text class="text-xs-center py-4">
				<p class="headline">
					{{ options.title }}
				</p>
				<p class="subheading" v-if="options.text">
					{{ options.text }}
				</p>
			</v-card-text>
			<v-card-actions v-if="options.hasAction">
				<v-spacer></v-spacer>
				<v-btn
					:color="options.actionButton.color"
					:outlined="options.actionButton.outlined"
					@click="$emit(`${options.actionButton.action}`)"
				>
					{{ options.actionButton.text }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		options: Object
	},
	data: () => ({
		//
	}),
	methods: {
		//
	},
	watch: {
		value(bool) {
			if (bool) this.$background.blur = true;
			else this.$background.blur = false;
		}
	}
};
</script>

<style>
.v-overlay--active:before {
	opacity: 0.625;
}
/* */
</style>
