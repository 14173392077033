<template>
	<v-app>
		<v-main v-if="!isIE" id="pageContentWrapper">
			<NavBar style="z-index: 15" v-if="showNavbar" />
			<v-btn v-if="$route.fullPath === '/'" x-large id="loginButton" color="primary darken-3" :to="'/login'">
				Login
			</v-btn>
			<router-view :style="blurTheBackground" class="py-10"></router-view>
		</v-main>
		<v-main v-else id="pageContentWrapper">
			<v-row class="ma-0" style="height: 100%">
				<v-col cols="12" align-self="center">
					<v-row class="ma-0" justify="center">
						<v-col cols="6">
							<v-row class="ma-0">
								<v-col cols="6" align-self="start">
									<v-row class="ma-0" justify="end">
										<img src="./assets/outdated.svg" width="200px" />
									</v-row>
								</v-col>
								<v-col cols="6" align-self="center">
									<v-row class="ma-0">
										<img src="./assets/KnightLogoBlackText.png" width="200px" />
									</v-row>
									<v-row class="ma-0 mt-3">
										It appears that you are using an out of date browser...<br />
										Try one of these browsers instead
									</v-row>
									<v-row class="ma-0 mt-3">
										<v-icon class="mx-2">fab fa-firefox</v-icon>
										<v-icon class="mx-2">fab fa-safari</v-icon>
										<v-icon class="mx-2">fab fa-edge</v-icon>
										<v-icon class="mx-2">fab fa-chrome</v-icon>
									</v-row>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-main>
		<InterceptorDialog v-model="interceptorsBool"></InterceptorDialog>
	</v-app>
</template>

<script>
import NavBar from './components/NavBar';
import InterceptorDialog from '../global_components/InterceptorDialog'

export default {
	name: 'App',
	components: {
		NavBar,
		InterceptorDialog
	},
	mixins: [],
	data() {
		return {
			root: null,
			scrolled: false,
			contactDialog: false,
			isIE: false,
			interceptorsBool: false,
			expiration: null,
		};
	},
	methods: {
		handleScroll() {
			if (this.$vuetify.breakpoint.lgAndUp) {
				this.scrolled = window.scrollY > 0;
			} else {
				this.scrolled = true;
			}
		},
		toggleContact() {
			this.contactDialog = !this.contactDialog;
		},
		checkBrowser() {
			// if user is using IE
			if (window.document.documentMode) {
				this.isIE = true;
			}
		},
		headingOne() {
			let text;
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					this.root.style.setProperty('--headingOne', '30px');
					break;
				case 'sm':
					this.root.style.setProperty('--headingOne', '34px');
					break;
				case 'md':
					this.root.style.setProperty('--headingOne', '36px');
					break;
				case 'lg':
					this.root.style.setProperty('--headingOne', '48px');
					break;
				case 'xl':
					this.root.style.setProperty('--headingOne', '50px');
					break;
				default:
					this.root.style.setProperty('--headingOne', '15px');
			}
			return text;
		},
		headingTwo() {
			let text;
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					this.root.style.setProperty('--headingTwo', '18px');
					break;
				case 'sm':
					this.root.style.setProperty('--headingTwo', '20px');
					break;
				case 'md':
					this.root.style.setProperty('--headingTwo', '22px');
					break;
				case 'lg':
					this.root.style.setProperty('--headingTwo', '24px');
					break;
				case 'xl':
					this.root.style.setProperty('--headingTwo', '25px');
					break;
				default:
					this.root.style.setProperty('--headingTwo', '16px');
			}
			return text;
		},
		getFonts() {
			this.headingOne();
			this.headingTwo();
		},
		async logoutUser() {
			try {
				// resets store objects to empty objects
				this.$store.commit('updateAuth', {});
				this.$store.commit('updateUser', {});
				this.$store.commit('updateOrg', {});
				this.$store.commit('updatePermissions', {});

				// reset all of the inital onboarding fields, documents, services, progress, payments
				this.$store.commit('resetOnboarding');
				this.$store.commit('resetDocuments');
				this.$store.commit('resetPayments');
				this.$store.commit('resetServices');
				this.$store.commit('resetProgress');


				// clears localStorage and sets logged in to false
				localStorage.removeItem('knight-swift-logistics');
				this.auth.loggedIn = false;

				// pushes user back to splash page
				this.$router.push('/');
			} catch (error) {
				//
				console.log(error);
			} finally {
				//
			}
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth
		},
		exptime: {
			get: function() {
				return this.$store.state.auth.expTime
			},
			set: function(val) {
				this.expiration = val
			}
		},
		currentPage() {
			return this.$store.state.currentPage
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo
		},
		documents() {
			return this.$store.state.documents
		},
		payments() {
			return this.$store.state.payments
		},
		services() {
			return this.$store.state.services
		},
		progress() {
			return this.$store.state.progress
		},
		userInfo() {
			return this.$store.state.userInfo
		},
		permissions() {
			return this.$store.state.permissions
		},
		notifications() {
			return this.$store.state.notifications;
		},
		showNavbar() {
			const path = this.$route.fullPath;
			const routesToHideNav = ['/', '/login', '/logout', '/loadinginformation'];
			if (routesToHideNav.includes(path)) return false;
			return true;
		},
		blurTheBackground() {
			let style;
			if (this.$background.blur) {
				style = {
					'-webkit-filter': 'blur(10px)',
					'-moz-filter': 'blur(10px)',
					'-o-filter': 'blur(10px)',
					'-ms-filter': 'blur(10px)',
					'filter': 'blur(10px)'
				}
			} else {
				style = {}
			}
			return style;
		}
	},
	created() {
		const interceptorBlacklist = ['/', '/login', '/createAccount']
		this.checkBrowser();
		this.root = document.documentElement;
		window.addEventListener('resize', this.getFonts);
		window.addEventListener('scroll', this.handleScroll);

		// intercept axios calls and log out - ONLY IF USER IS ALREADY LOGGED IN
		if (this.auth.loggedIn) {
			this.$axios.interceptors.response.use(
				null,
				function(error) {
					// jwt has expired -- test with line below or hardcode an expired jwt into any axios call except for login
					// if ( 100 >= this.expiration * 1000) {
					// EXPIRED JWT TEST CASE
					// Authorization: 'Bearer ' + "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiNjMyMjI3OWYtYmY5Mi00YjM3LThjNWUtMWMwYjlmODBmMmRiIiwidXNlcm5hbWUiOiJ0ZXN0NUB0ZXN0LmNvbSIsImNvbXBhbnlSb2xlIjoib3duZXIiLCJvcmdhbml6YXRpb24iOnsidHlwZSI6ImNhcnJpZXIiLCJ1dWlkIjoiZmFmOTJjMzgtM2FmZC00YThiLTllYzEtMmI5MzRiMmFjM2E0IiwibmFtZSI6bnVsbCwic3ViVHlwZSI6ImdlbmVyaWNDYXJyaWVyIiwiY2FycmllckNvZGUiOm51bGwsImV4cGlyZUNvZGVzIjpbXSwic2VsZWN0QWN0aXZlIjp0cnVlfSwiZmlyc3ROYW1lIjoiQnJvb2tlIiwibGFzdE5hbWUiOiJDb2JiIiwicGVybWlzc2lvbnMiOlt7Il9pZCI6IjVkNzY5MGU1NjBkZWE2NDcwYzc0NTRjMSIsIm5hbWUiOiJPbmJvYXJkaW5nLVN0YXR1cy1JbmNvbXBsZXRlIiwidWlkIjoiMjEwMDUxYTktNjE3Mi00NWZiLTgwYzEtOGRkNTI5MTk1NmVhIiwiaW5Sb2xlIjpmYWxzZX0seyJfaWQiOiI1ZDc2YTVlNDYwZGVhNjQ3MGM3NDU0ZGYiLCJuYW1lIjoiT25ib2FyZGluZy1HZW5lcmFsIiwidWlkIjoiOTAyYzVlZDktMzBhZS00YWI0LTkyZTctMDhhOGVjYTU0ODA1IiwiaW5Sb2xlIjpmYWxzZX1dLCJpYXQiOjE2MTk0NjYwNTUsImV4cCI6MTYxOTUwOTI1NX0.OloHYEJt2m0M3wkP1na9SwrO56YqyajuTJthag4EnjPYDksvOEJKpmQtCsJIVq6cXnNsE67rU9u23WK7pZR-9V_hzQlO_nXpxUEhaNIiZkMMEb0BA2C69uXxUO9ygHsm5z1K-nwg6eFpOn51PyL4iY-wIuxRvAxj0yodwhMF7vvetnL6cqOnIJfXq0svjscDIN9z2hJYaeym4Ta7LPU3v9FvXda03i2LnvKqX5cNJ7d9dsFGXcxKO2SOebq_UmPB4ZsAULctdKMy3yY54GNKBu3t45B9JrM7miFrJ-8zWBgpKPJCxz03TxOpfTPGB14cLytYK43AsdV9yLbTwTjtc6Nhmzt4yZ6TdQZBbdzWmL25IEjA166M6-RlBosRJVbAPUiMO3dR8r7d6RHNsenF5B1E5zVgXPIzupuO85KVBnz_cXsPFgZ9sNJWZK3zWW56yf9650Nt_0G0_ht_IHWhJ5kB2mCyqul24N-j3sodvBWMH4-PPSLK8jDmkMEtaqzaAg5d9F3ahYu5lsfORMlRrwaLln3KbNl7fdWZtf0bm1kroumesOCsl6Q5aldHUz5rP1dg22bBU71pzrS8A-bHGQ3MZxkFyOEqEDEzzXWywL6KTZbSCdz7DhopDNzqoowual44iy9-udqrNwuXMeiHZnN0TJ09Kq9BJqLmuEcLrfY",
					//
					if (Date.now() >= this.exptime * 1000) {
						this.interceptorsBool = true;
					}
					if (!interceptorBlacklist.includes(this.$route.path) && error.response && error.response.status === 401) {
						this.interceptorsBool = true;
					}
					if (this.$route.path === '/login' && error.response && error.response.status === 401) {
						// do nothing
					}
					return Promise.reject(error);
				}.bind(this)
			);
		}
	},
	mounted() {
		this.getFonts();
	},
	destroyed() {
		window.removeEventListener('resize', this.getFonts);
		window.removeEventListener('scroll', this.handleScroll);
	},
};
</script>

<style>
#app {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#app .v-menu__content.theme--dark.v-menu__content--fixed.menuable__content__active{
	min-height: 140px;
	max-height: 140px;
}
#app .v-menu__content.theme--dark.v-menu__content--fixed.menuable__content__active::-webkit-scrollbar {
	width: 10px;
	background: transparent;
	border-radius: 10px;
}
#app .v-menu__content.theme--dark.v-menu__content--fixed.menuable__content__active::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}
#app .v-menu__content.theme--dark.v-menu__content--fixed.menuable__content__active::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-darken2);
	border-radius: 10px;
}

body {
	background: #121212;
}
body::-webkit-scrollbar {
	/*width: 11px;*/
	width: 0px !important;
	background: transparent;
}
body::-webkit-scrollbar-track {
	background: #121212;
}
body::-webkit-scrollbar-thumb {
	background-color: #4c228c;
}
a:hover {
	text-decoration: none !important;
	color: inherit;
}
.v-card {
	border-radius: 10px !important;
}
.v-btn {
	text-transform: none !important;
}

:root {
	--headingOne: 15px;
	--headingTwo: 15px;
}

.headingOne {
	font-size: var(--headingOne);
}
.headingTwo {
	font-size: var(--headingTwo);
}
.row {
	margin: 0px !important;
}

#loginButton {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 4;
	border: 2px solid white !important;
}
</style>
