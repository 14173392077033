import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0;

		if (to.hash) {
			return { selector: to.hash };
			//   scrollTo = to.hash
		} else if (savedPosition) {
			//   scrollTo = savedPosition.y
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}

		// return goTo(scrollTo)
	},
});
