<template>
	<v-row>
		<span v-html="assistanceFilter(selectedServiceKey)"></span>
	</v-row>
</template>

<script>
import Carb from './AssistanceText/carb';
// import FsmaRegulated from './AssistanceText/fsmaRegulated';
import Hazmat from './AssistanceText/hazmat';
import LiftGate from './AssistanceText/liftGate';
import Tanker from './AssistanceText/tanker';
import DropTrailers from './AssistanceText/dropTrailers';
import Intermodal from './AssistanceText/intermodal';
import PowerOnly from './AssistanceText/powerOnly';
import TeamExpedited from './AssistanceText/teamExpedited';
import Reefer from './AssistanceText/reefer';
import Smartway from './AssistanceText/smartway';
import Canada from './AssistanceText/canada';
import Mexico from './AssistanceText/mexico';
import GPS from './AssistanceText/gps';
import TruckerTools from './AssistanceText/truckerTools';
import TWIC from './AssistanceText/twic';
import Macropoint from './AssistanceText/macropoint';

export default {
	name: 'Service_Dialog_Assistance',
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
	},
	data() {
		return {
			assistanceText: {
				// fsmaRegulated: FsmaRegulated,
				hazm: Hazmat,
				liftGate: LiftGate,
				tanker: Tanker,
				dropTrailers: DropTrailers,
				intermodal: Intermodal,
				powerOnly: PowerOnly,
				teamExpedited: TeamExpedited,
				carb: Carb,
				smartway: Smartway,
				canada: Canada,
				mexico: Mexico,
				gps: GPS,
				truckerTools: TruckerTools,
				twic: TWIC,
				macropoint: Macropoint,
				reefer: Reefer,
			},
		};
	},
	methods: {
		assistanceFilter(item) {
			return this.assistanceText[item];
		},
	},
	computed: {
		//
	},
};
</script>

<style scoped>
/**/
</style>
