<template>
	<v-col>
		<v-row class="mb-2">
			<v-col cols="12">
				<span>
					Managing your offered services will become an available option once you've finished our onboarding process, which includes signing our carrier contract.
				</span>
			</v-col>
		</v-row>
		<v-row class="mb-2">
			<v-col cols="12">
				<span>
					Service management allows you to update which services you provide, such as CARB, Hazmat, Power Only, and more. Updating your offered services gives you access to more freight options. Certain services require additional verfication information as detailed below:
				</span>
			</v-col>		
		</v-row>
		<v-row class="mt-1">
			<v-col cols="12">
				<ul>
					<li class="pt-1">
						CARB: Proof of CARB Compliance, only required when you haul freight in the state of California.
					</li>
					<li class="pt-1">
						Hazmat: Proof of HAZMAT certification, as well as your registration number and certification expiration date.
					</li>
					<li class="pt-1">
						Power Only: Signing our Power Only Trailer Interchange Agreement.
					</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'Service_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>