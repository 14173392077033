<template>
	<v-form v-model="$formValid.carb" ref="carbValid">
		<v-row>
			<v-col cols="12">
				<v-row> Do you haul freight in the state of California? </v-row>
				<v-row>
					<v-select
						:items="[
							{ text: 'Yes', value: true },
							{ text: 'No', value: false },
						]"
						item-text="text"
						item-value="value"
						v-model="carbData.runCali"
						required
						:rules="[$formRules.boolRule]"
						@input="handleSelection"
					></v-select>
				</v-row>
			</v-col>
			<v-col cols="12" class="pt-1" v-if="carbData.runCali">
				<v-row>
					* In order to be eligible for this service you must provide proof of CARB
					certification.
				</v-row>
				<br />
				<v-row>
					CARB Compliance is required for all loads going in and out of California and
					California-based fleets are required to be 100% carb compliant.
				</v-row>
				<v-row class="pt-3" justify="end">
					<v-btn @click="initFileUpload()" rounded small dark color="secondary" outlined>
						Select Document
					</v-btn>
				</v-row>
				<v-row class="pt-3" justify="start" v-if="file && file.size < 19922944">
					<v-icon v-if="!selectedFile" small color="#BDBDBD"
						>mdi-checkbox-blank-circle-outline</v-icon
					>
					<v-icon v-if="selectedFile" small color="secondary">mdi-check-circle</v-icon>
					<span class="ml-2">Selected CARB Document</span>
				</v-row>
				<v-row class="pt-3" justify="start" v-if="file && file.size > 19922944">
					<v-icon color="#FFA12F" class="mr-2">mdi-alert</v-icon>
					<span>Selected File is too Large - Max File size 19MB</span>
				</v-row>
			</v-col>
			<v-col cols="12" v-if="carbData.runCali === false">
				CARB compliance is only required when running freight in the state of California.
				You may close this dialog.
			</v-col>
		</v-row>
		<input
			@input="selectFile()"
			type="file"
			accept="image/png, image/jpeg, application/pdf"
			id="uploadNewDoc"
			style="display: none"
			name="TEST"
		/>
		<v-text-field
			v-model="selectedFile"
			style="display: none"
			required
			:rules="[$formRules.required]"
		></v-text-field>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-form>
</template>

<script>
import basicDialogMixin from '@/mixins/basicDialogMixin';

export default {
	name: 'Service_Dialog_CarbPrompt',
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
		serviceDialogType: { type: String, default: null },
	},
	mixins: [basicDialogMixin],
	data() {
		return {
			file: null,
			selectedFile: false,
			carbData: {},
		};
	},
	methods: {
		initFileUpload() {
			const input = document.getElementById('uploadNewDoc');
			input.click();
		},
		selectFile() {
			const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
			this.file = document.getElementById('uploadNewDoc').files[0];

			if (!acceptedFileTypes.includes(this.file.type)) {
				this.file = null;
				this.selectedFile = false;
				document.getElementById('uploadNewDoc').value = '';
				return (this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: 'Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				});
			}

			if (this.file.size < 19922944) {
				this.selectedFile = true;
				this.$emit('selectedFile', this.file);
			} else {
				this.selectedFile = false;
				this.$emit('fileTooLarge');
			}
		},
		handleSelection(val) {
			this.$emit('handleCarb', val);
		},
	},
	computed: {
		activeCarbInfo() {
			return this.$store.state.services.active.carb;
		},
		inactiveCarbInfo() {
			return this.$store.state.services.inactive.carb;
		},
	},
	mounted() {
		if (this.serviceDialogType === 'add')
			this.carbData = Object.assign({}, this.inactiveCarbInfo);
		if (this.serviceDialogType === 'update')
			this.carbData = Object.assign({}, this.activeCarbInfo);
		if (this.carbData.documentId) this.selectedFile = true;
	},
};
</script>

<style scoped>
.carbDocText {
	color: var(--v-secondary-base);
}
</style>
