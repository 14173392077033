
<template>
	<v-row align="center" justify="center" class="px-2 py-1 rowBackground">
		<v-col cols="10" class="pa-0 pl-2">
			<v-row align="center" style="font-size: 14px; color: #d1d1d1">
				<v-chip color="#1E1E1E" class="mr-2">
					{{data.amount}}
				</v-chip>
				{{keyFilter(dataKey)}}
			</v-row>
		</v-col>
		<v-col cols="2" lg="2" class="pa-0 text-right" style="border-left: 1px solid #757575">
			<v-btn icon class="ma-0 pa-0">
				<v-icon
					@click="$emit('deleteTrailer', { dataKey, selectedType})"
					color="#c94040"
				>
					mdi-minus-circle
				</v-icon>
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Fleet_RowCard',
	props: {
		selectedType: { type: String, default: null },
		dataKey: { type: String, default: null },
		data: { type: Object, default: () => {} },
	},
	data() {
		return {

		}
	},
	methods: {
		keyFilter(item) {
			let text;
			switch(item) {
				case 'power':
					text = 'Power Unit';
					break
				case 'drivers':
					text = 'Driver';
					break
				case 'dry':
					text = 'Dry Van';
					break
				case 'flatbed':
					text = 'Flatbed';
					break
				case 'reefer':
					text = 'Refrigerated';
					break
				case 'carHaul':
					text = 'Car Hauler';
					break
				case 'highCube':
					text = 'High Cube Dray';
					break
				case 'other':
					text = 'Other Trailer';
					break
				default:
					text = item;
			}
			return text;
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
	},
};
</script>

<style scoped>
.cardAction {
	background-color: #282828;
	border-radius: 0 0 10px 10px;
}
.cardActionContent {
	font-size: 12px;
	border-right: 1px solid #1E1E1E;
}
.active {
	cursor: pointer;
}
.rowBackground {
	background-color: #121212;
	border-radius: 10px;
}
</style>