<template>
	<v-dialog :value="value" persistent :width="$vuetify.breakpoint.smAndDown ? '100vw' : '50vw'" @input="$emit('input')">
		<v-card>
			<v-card-title class="px-9">
				My Notifications
			</v-card-title>

			<v-card-text>
				<v-row class="px-3">
					<v-btn
						:color="!read ? 'primary darken-2' : '#757575'"
						text
						@click="updateNotifications(false)"
						class="mr-1"
					>
						<v-row>
							<v-col cols="12" class="pa-0">
								New
							</v-col>
							<v-col cols="12" class="pa-0 pt-1">
								<v-row justify="center">
									<div style="border-radius: 2px; height: 5px; width: 45px;" :style="!read ? 'background-color: #8353c3' : ''"></div>
								</v-row>
							</v-col>
						</v-row>
					</v-btn>
					<v-btn
						:color="read ? 'primary darken-2' : '#757575'"
						text
						@click="updateNotifications(true)"
						class="ml-1"
					>
						<v-row>
							<v-col cols="12" class="pa-0">
								Read
							</v-col>
							<v-col cols="12" class="pa-0 pt-1">
								<v-row justify="center">
									<div style="border-radius: 2px; height: 5px; width: 45px;" :style="read ? 'background-color: #8353c3' : ''"></div>
								</v-row>
							</v-col>
						</v-row>
					</v-btn>
				</v-row>
				<v-row>
					<!-- do something with notifications.total -->
					<!-- also need to setup pagination over here -->
					<List :notifications="notifications" @clear="clear"></List>
				</v-row>
			</v-card-text>
			<v-card-actions class="px-6 py-3">
				<v-row justify="end">
					<v-btn @click="close" color="primary" outlined>
						Close
					</v-btn>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import List from './list.vue';

export default {
	name: 'Notification_Dialog',
	props: {
		value: Boolean,
		notifications: Object
	},
	components: {
		List
	},
	data: () => ({
		//
		read: false,
	}),
	methods: {
		close() {
			this.$background.blur = false;
			this.$emit('input');
			this.$emit('updateRead');
		},
		async updateNotifications(bool) {
			this.read = bool;
			this.$emit('updateNotifications', bool);
		},
		clear(val) {
			this.$emit('clear', val)
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		}
	},
	watch: {
		value(val) {
			if (val) this.read = false;
		}
	}
};
</script>