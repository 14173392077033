<template>
	<v-col>
		<v-row>
			<v-col cols="12">
				<span>
					You must fill out all <b>required</b> fields to save and move onto the next section.
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					Required Fields:
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<ul>
					<li>Company Email</li>
					<li>Company Name</li>
					<li>Company Phone</li>
					<li>Tax Identification #</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'General_Information_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>