export default {
	methods: {
		// Hash password before being sent to the server
		hashPassword(pass) {
			const hash = this.$crypto.createHash('sha512');
			hash.update(pass)
			const hashString = hash.digest('base64');
			return hashString
		}
	}
}