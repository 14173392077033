<template>
	<v-row>
		<v-col
			cols="12"
			class="contractScroll text-xs-left pa-0 px-2"
		>
			<v-row justify="end" class="px-2 pb-1">
				<v-btn
					small
					text
					color="secondary"
					@click="$emit('viewPowerOnly', '37fc3180-a6b8-11eb-914f-5512f6103a9c')"
				>
					View in new window
				</v-btn>
			</v-row>
			<v-row>
				<v-col cols="12" class="pa-0 pb-4">
					<v-row>
					    <span>EQUIPMENT LEASE AGREEMENT</span>
					</v-row>
				</v-col>

			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
					    <span style="font-style: italic;">This Equipment Lease Agreement ("Lease Agreement") is made this {{day}} day of {{month}}, {{year}} ("Effective Date"), between {{carrierInfo.general.legalName}} located at {{carrierInfo.addresses.physical.address1}}, {{carrierInfo.addresses.physical.city}}, {{carrierInfo.addresses.physical.state}}, {{carrierInfo.addresses.physical.zip}} ("Lessee") and Swift Leasing Co LLC and the entities listed on Schedule 1 (collectively "Lessor") with a corporate address at 2002 West Wahalla Lane Phoenix, AZ 85027.  Lessee and Lessor may each be referred to individually as a "Party" and are referred to collectively as the "Parties."
					    </span>
					</v-row>
			    </v-col>

			    <!-- <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
					    <span style="font-style: italic; text-decoration: underline;">TO VERIFY WHETHER INTERCHANGEE IS APPROVED AND
					        ACTIVE WITHIN INTERCHANGER'S NETWORK, PLEASE CALL INTERCHANGER'S PHONE NUMBER LISTED ON THE BOOKING
					        CONFIRMATION/TENDER.
					    </span>
					</v-row>
				</v-col> -->

				<v-col cols="12" class="pa-0 pb-4">
					<v-row><span><b><u>Recitals.</u></b></span></v-row>
						<v-col>
							<span>
								Lessor owns various pieces of equipment and is in the business of leasing that equipment.  Lessee is a motor carrier licensed by the Federal Department of transportation ("DOT") that wishes to rent Equipment (as hereinafter defined) from Lessor.  The Parties are entering into this Lease Agreement to govern Lessee's rental of quipment from the lessor.								
							</span>
							<span>
								Now therefore the good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, Lessee and Lessee agree as follows:
							</span>
						</v-col>
				</v-col>

			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>1. <u>Equipment & Equipment Condition.</u></b></span></v-row>
					    <span>
							Lessor agrees to rent certain trailers to Lessee (“Equipment”) solely for the movement of freight tendered to Lessee by Lessor’s affiliates (“Affiliates”) or as otherwise authorized in writing by Affiliates. Lessee agrees to thoroughly inspect the Equipment prior to taking possession or control of the Equipment and notify Lessor of any defects discovered during the inspection. If Lessee fails to notify Lessor of any defects Lessee accepts exclusive responsibility for any loss, damage, or liability arising from any defect. Lessee shall not allow any garbage, poisons, pesticides, medical waste or hazardous waste to be placed in the Equipment.
					    </span>
				</v-col>

			    <v-col cols="12" class="pa-0 pb-4">
					<v-row><span><b>2. <u>Possession of Equipment.</u></b></span></v-row>
						<v-col>
							<span>
								Lessee shall only use the Equipment for the movement of freight tendered by Affiliates or as otherwise authorized in writing by Affiliates (the "Authorized Use").  Lessee will arrange to pick up Equipment at the locations specified by Affiliates.  Lessee agrees to return the Equipment to a location specified by Affiliates.  Lesssee agrees to return the Equipment to a location specified by Affiliates, in the same condition as when the Equipment was accepted, less ordinary wear and tear, the earlier of (i) 24 hours after a request by Lessor to return the Equipment or (ii) as otherwise authorized by Affiliates in writing.
							</span>
						</v-col>	    
			    </v-col>


			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>3. <u>Equipment Use.</u></b></span></v-row>
				    	<span>
							Lessee assumes sole responsibility of for the Equipment during the time the Equipment is in Lessee's possession or control.  Lessee shal keep the Equipment free and clear of any lien or encumbrance and shall insure that the Equipment is only used for the Authuorized Use.  If Lessee fails to return the Equipment as provided in Section 2 of this Lease Agreement or Uses the Equipment for any purpose not authorized by Affiliates, Lessee agrees to pay charges listed in Schedule 2.
				    	</span>
				    
			    </v-col>

			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>4. <u>Term & Termination.</u></b></span></v-row>
				    	<span>
							The term of this Lease Agreement for each piece of Equipment shall commence on the date Lessee picks up the Equipment for an Authorized Use and shall continue the earlier of: (i) 24 hours after a request by Lessor to return the piece of Equipment or (ii) as otherwise authorized by Affiliates in writing (the “Term”). This Lease Agreement will automatically renew for successive one-month terms until terminated by either Party upon five (5) days prior written notice. Either Party may terminate the rental of each piece of Equipment without terminating the entire Lease Agreement.
						</span>
			    </v-col>

			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>B. Termination for Convenience.</b></span></v-row>
				    	<span>
							Either Party may terminate this Lease Agreement, or the rental of any piece of Equipment, for any reason or no reason, upon five (5) days prior written notice to the other Party.
						</span>
						<span>
							In the event this Lease Agreement is terminated, Lessee agrees to deliver the Equipment to a location designated by Lessor.
				    	</span>
			    </v-col>

			    <v-col cols="12" class="pa-0 pb-4">
					<v-row><span><b>5. <u>INDEMNIFICATION</u>.</b></span></v-row>
			    	<v-col>
				    	<span>
							<b>A. Indemnity by Lessee.</b> Lessee agrees to indemnify, defend, and hold harmless Lessor and its affiliates and their directors, officers, employees, and independent contractors from and against any and all claims, actions, issues, liabilities, judgments, civil or criminal fines, damages, costs and expenses (including reasonable attorney's fees) (“Claims”) incurred by or asserted against Lessor to the extent any Claims are caused by Lessee’s: (i) use, operation, maintenance, possession or misuse of the Equipment, (i) acts, omissions or willful misconduct; or (ii) breach of this Lease Agreement.
						</span>
						<span>
							<b>B. Termination for Convenience.</b> Lessee agrees to indemnify, defend, and hold harmless Lessor and its affiliates and their directors, officers, employees, and independent contractors from and against any and all claims, actions, issues, liabilities, judgments, civil or criminal fines, damages, costs and expenses (including reasonable attorney's fees) (“Claims”) incurred by or asserted against Lessor to the extent any Claims are caused by Lessee’s: (i) use, operation, maintenance, possession or misuse of the Equipment, (i) acts, omissions or willful misconduct; or (ii) breach of this Lease Agreement.
						</span>
					</v-col>
				</v-col>

			    <v-col cols="12" class="pa-0 pb-2">
			    	<v-row>
				    	<span><b>6. <u>Insurance and Risk of Loss</u>.</b></span>
				    </v-row>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-2 pl-4">
			    	<v-row>
				    	<span>
							Lessee assumes and shall bear the risk of loss or damage to Equipment while the Equipment is in Lessee’s possession or control. Lessee agrees to immediately notify Lessor at REPORT NEW CLAIM (800-467-2793) of any accident or incident during the time the Equipment is in Lessee’s possession or control. Lessee, at its cost, shall maintain equipment interchange insurance or physical damage insurance covering physical damage or loss to the Equipment, from any cause in an amount of not less than $25,000 per any dry van piece of Equipment and in an amount of not less than $35,000 per any refrigerated piece of Equipment. Lessee shall provide Lessor proof of insurance coverage and at least thirty (30) prior written notice of any change or cancellation in the insurance coverage required by this Section.
				    	</span>
				    </v-row>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-2 pl-4">
					<v-row>
						<span><b>7. <u>DISCLAIMER OF DAMAGES</u>.</b></span>
					</v-row>
			    	<v-col>
				    	<span>
							EXCEPT FOR THIRD PARTY CLAIMS ARISING UNDER THE INDEMNIFICATION OBLIGATIONS IN SECTION 5 OF THIS LEASE AGREEMENT, NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR ANY INDIRECT, INCIDENTIAL, SPECIAL, PUNITIVE, LIQUIDATED, OR CONSEQUENTIAL DAMAGES OR LOST PROFITS.
						</span>
					</v-col>
				</v-col>
			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>8. <u>Lessor Property</u>.</b></span></v-row>
				    	<span>
							All Equipment is and shall at all times be and remain the sole and exclusive property of Lessor. Except for the right to possess, operate, and use the Equipment, Lessee shall not have any right, title or interest in the Equipment.
						</span>
			    </v-col>

			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row><span><b>9. <u>Independent Contractors</u>.</b></span></v-row>
				    	<span>
							Neither Party shall have any express, implied or apparent authority to create or assume, in the other Party’s name or on its behalf, any obligation or otherwise act on its behalf. Nothing contained in this Lease Agreement shall be construed to create the relationship of partnership or joint venture or in any way make either Party a co-principal with the other. The Parties are independent contractors.
						</span>
			    </v-col>
			    <!-- <v-col cols="12" class="pa-0 pb-4 pl-4">
			    	<v-row>
					    <span>(c) Interchangee agrees to immediately notify Interchanger and to furnish complete details of any accident or
					    	incident involving the Equipment or any related equipment while in its possession pursuant to this Addendum.
					    </span>
					</v-row>
				</v-col> -->
			    <v-col cols="12" class="pa-0 pb-4">
					<v-row><span><b>10. <u>Equipment Repairs and Replacement</u>.</b></span></v-row>
			    	<v-col>
				    	<span>
							<b>A. Repairs.</b> Lessor shall not make any repairs or perform any maintenance on any Equipment without Lessor’s prior written consent. If any repair or maintenance is required Lessee shall promptly notify KNIGHT BREAKDOWN 1-800-489-7467 OR SWIFT BREAKDOWN 1-800-467-6017 for instruction and authorization. If Equipment is damaged while in Lessee’s possession or control Lessee shall be solely responsible any repairs or maintenance including but not limited to tubes and tires. Lessee authorizes Lessor to deduct the cost of any repair or maintenance from any amounts due Lessee from Affiliates.
						</span>
						<span>
							<b>B. Equipment Cleaning.</b> Upon return of the Equipment Lessee shall be responsible for all costs related to cleaning or decontaminating the Equipment, all costs for removing any dunnage or debris, including but not limited to removing all nails from the Equipment floor in an amount equal to the greater of $100 or Lessor’s actual cost.
						</span>
						<span>
							<b>C. Lost Stolen or Destroyed Equipment.</b> If the Equipment is lost, stolen or damaged beyond repair while in Lessee’s possession or control Lessee shall pay Lessor the fair market value of the Equipment as of the date of loss.
						</span>
				    </v-col>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
				    	<span><b>11. <u>No Warranties.</u></b></span>
				    </v-row>
					<span>
						LESSOR NEITHER MAKES NOR SHALL BE DEEMED TO HAVE MADE ANY WARRANTY OR REPRESENTATION, EXPRESS OR IMPLIED, CONCERNING THE EQUIPMENT, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OR REPRESENTATION AS TO DESIGN, QUALITY, OR CONDITION OF THE EQUIPMENT, OR AS TO THE MERCHANTABILITY OR FITNESS OF THE EQUIPMENT FOR ANY PARTICULAR PURPOSE OR TO ANY OTHER MATTER RELATING TO THE EQUIPMENT. LESSEE AGREES THAT LESSOR HAS MADE NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE SUITABILITY OR DURABILITY OF THE EQUIPMENT FOR THE PURPOSES OR USES OF LESSEE, OR ANY OTHER REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED WITH RESPECT TO THE EQUIPMENT.
					</span>
			    </v-col>
			    <!-- <v-col cols="12" class="pa-0 pb-4 pl-4">
			    	<v-row>
				    	<span>(d) Lost, Stolen or Destroyed Equipment. If the Equipment is lost, stolen, irreparably damaged or destroyed, then
				    		settlement for the Equipment shall be made upon the fair market value. In the event that lost or stolen equipment is recovered after settlement has occurred, Interchangee may recover from Interchanger the settlement value after Interchangee has returned the equipment to Interchanger in the same condition as received by Interchangee, ordinary wear and tear excepted.
				    	</span>
				    </v-row>
			    </v-col> -->
			    <v-col cols="12" class="pa-0 pb-4">
					<v-row>
						<span><b>12. <u>Default.</u></b></span>
					</v-row>
			    	<span>
						A default shall be deemed to have occurred under this Lease if either Party breaches any provision of this Lease. Upon default, in addition to any other remedy provided by law or equity, the non-defaulting party shall be entitled to immediately terminate this Lease Agreement.
					</span>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-4">
					<v-row>
						<span><b>13. <u>Inspection.</u></b></span>
					</v-row>
			    	<span>
						Lessor shall have the right, upon reasonable notice, to enter Lessee’s premises where the Equipment may be located for the purpose of inspecting the Equipment.
					</span>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
						<span><b>14. <u>Force Majeure.</u></b></span>
					</v-row>
			    	<span>
						Neither Party shall incur any liability to the other for a failure to perform any obligation hereunder that is caused by events beyond such Party’s reasonable control, such as (but not limited to) war, terrorist attack, fire, pandemics, governmental regulations, Acts of God or labor disputes.
					</span>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
						<span><b>15. <u>Assignment & Subletting.</u></b></span>
					</v-row>
			    	<span>
						Lessee shall not rent, sublet or otherwise permit any third party, excluding any subcontractor operating under Lessee’s DOT motor carrier authority, to use the Equipment without the prior written consent of Lessor.
					</span>
			    </v-col>
			    <v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
						<span><b>16. <u>Waiver of Jury Trial.</u></b></span>
					</v-row>
			    	<span>
						THE PARTIES HEREBY KNOWINGLY, VOLUNTARILY, AND IRREVOCABLY WAIVE ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS LEASE AGREEMENT, OR THE SUBJECT MATTER OF THIS LEASE AGREEMENT, REGARDLESS OF WHETHER THE CLAIM IS BASED ON CONTRACT, TORT, STATUTE, OR OTHERWISE.
					</span>
			    </v-col>
				<v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
						<span><b>17. <u>Full Agreement.</u></b></span>
					</v-row>
			    	<span>
						This Lease Agreement, and any Schedule or attachment, constitutes the entire agreement and understanding between the Parties relating to Lessee’s rental of Equipment from Lessor. There have been no representations or promises made between the Parties other than what is set forth herein. This Lease Agreement shall not be modified or altered except in writing signed by the Parties. The Parties represent that this Lease Agreement is binding, and the signatory on behalf of each Party has express authority to sign this Lease Agreement and bind the Party thereto.
					</span>
			    </v-col>
				<v-col cols="12" class="pa-0 pb-4">
			    	<v-row>
						<span><b>18. <u>Miscellaneous.</u></b></span>
					</v-row>
			    	<span>
						Each Party acknowledges they have had an opportunity to review this Lease Agreement with counsel and the interpretation hereof shall be neutral with no presumption against either Party. All notices under this Lease Agreement shall be in writing and sent via certified mail, return receipt requested, or overnight courier, addressed to either Party, at the address listed in this Lease Agreement or as otherwise directed in writing by the Parties, and shall be deemed delivered upon deposit in the United States mail or confirmed receipt by the overnight courier. This Lease Agreement shall be construed and enforced according to Arizona law without regard to its conflict of law provisions and the Parties submit to the jurisdiction of any appropriate court within Maricopa County, Arizona for adjudication of disputes arising from this Lease Agreement. This Lease Agreement may be executed in multiple counterparts, each of which shall be deemed to be an original and all of which, when taken together, will be deemed to constitute one and the same agreement.
					</span>
			    </v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Service_Dialog_PowerOnlyText',
	props: {
		day: { type: String, default: null },
		month: { type: String, default: null },
		year: { type: String, default: null },
		carrierInfo: { type: Object, default: () => {} },
		
	},
};
</script>

<style scoped>
.contractScroll::-webkit-scrollbar {
	width: 8px;
	background: transparent;
	border-radius: 10px;
}
.contractScroll::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}
.contractScroll::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-base);
	border-radius: 10px;
}
.contractScroll {
	height: 300px;
	overflow-y: scroll
}
</style>