<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="Address Information"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>
		<v-col cols="12" md="10" class="pa-0" align-self="start">
			<v-card>
				<v-card-text>
					<v-row justify="center" align="center">
						<v-form v-model="$formValid.physical" ref="physical">
							<v-row justify="center" align="start">
								<v-col cols="12" md="6">
									<v-row>
										<v-col cols="12">
											<span style="color: #FFF; font-size: 20px">
												Physical Address
											</span>
										</v-col>
										<v-col cols="12">
											<v-row>
												<template v-for="(item, index) in Object.keys(physicalData)">
													<v-col
														:key="index"
														:cols="columnSize(item)"
														class="py-2 px-2"
													>
														<v-select
															v-if="item === 'state'"
															v-model="physicalData[item]"
															:items="physicalStates"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															clearable
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-select>
														<v-select
															v-else-if="item === 'country'"
															v-model="physicalData[item]"
															:items="['USA','CA','MX']"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-select>
														<v-combobox
															v-else-if="item === 'city'"
															v-model="physicalData[item]"
															:items="physicalCityItems"
															:search-input.sync="physicalCitySync"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-combobox>
														<v-text-field
															v-else-if="item === 'zip'"
															v-model="physicalData[item]"
															:maxlength="maxLength(item)"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															:required="item === 'address2' ? false : true"
															:hint="item === 'address2' ? '' : 'Required'"
															@keypress="numOnly($event, item)"
															outlined
															filled
															autocomplete="off"
															persistent-hint
														></v-text-field>
														<v-text-field
															v-else
															v-model="physicalData[item]"
															:maxlength="maxLength(item)"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															:required="item === 'address2' ? false : true"
															:hint="item === 'address2' ? '' : 'Required'"
															@keypress="numOnly($event, item)"
															outlined
															filled
															autocomplete="off"
															persistent-hint
														></v-text-field>
													</v-col>
												</template>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" md="6">
									<v-row>
										<v-col cols="12">
											<v-row justify="space-between">
												<span style="color: #FFF; font-size: 20px">
													Mailing Address
												</span>

												<v-checkbox
													v-model="selectAddress"
													@change="updateMailing"
													class="ma-0 pa-0"
													hide-details
												>
													<div slot="label">
														Mailing Address Same as Physical?
													</div>
												</v-checkbox>
											</v-row>
										</v-col>
										<v-col cols="12">
											<v-row>
												<template v-for="(item, index) in Object.keys(mailingData)">
													<v-col
														:key="index"
														:cols="columnSize(item)"
														class="py-2 px-2"
													>
														<v-select
															v-if="item === 'state'"
															v-model="mailingData[item]"
															:items="mailingStates"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															clearable
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-select>
														<v-select
															v-else-if="item === 'country'"
															v-model="mailingData[item]"
															:items="['USA','CA','MX']"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-select>
														<v-combobox
															v-else-if="item === 'city'"
															v-model="mailingData[item]"
															:items="mailingCityItems"
															:search-input.sync="mailingCitySync"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															required
															outlined
															filled
															autocomplete="off"
															persistent-hint
															:hint="'Required'"
														>
														</v-combobox>
														<v-text-field
															v-else
															v-model="mailingData[item]"
															:maxlength="maxLength(item)"
															:label="labelFilter(item)"
															:rules="ruleMethod(item)"
															:required="item === 'address2' ? false : true"
															:hint="item === 'address2' ? '' : 'Required'"
															@keypress="numOnly($event, item)"
															outlined
															filled
															autocomplete="off"
															persistent-hint
														></v-text-field>
													</v-col>
												</template>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-form>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog"></basic-dialog>
	</v-row>
</template>

<script>
import basicDialogMixin from '@/mixins/basicDialogMixin.js';
import getCarrierProgress from '@/mixins/getCarrierProgress.js';

import numOnly from '@/mixins/formBuilding/numOnly.js'
import labelFilter from '@/mixins/formBuilding/labelFilter.js'
import columnSize from '@/mixins/formBuilding/columnSize.js'
import maxLength from '@/mixins/formBuilding/maxLength.js'
import ruleMethod from '@/mixins/formBuilding/ruleMethod.js'

export default {
	name: 'PhysicalAddress',
	mixins: [
		basicDialogMixin,
		getCarrierProgress,
		numOnly,
		labelFilter,
		columnSize,
		maxLength,
		ruleMethod,
	],
	data() {
		return {
			physicalData: {
				address1: null,
				address2: null,
				city: null,
				state: null,
				zip: null,
				country: null,
			},
			physicalCitySync: null,
			physicalCityItems:[],
			//
			mailingData: {
				address1: null,
				address2: null,
				city: null,
				state: null,
				zip: null,
				country: null,
			},
			sameAddress: ['Yes','No'],
			selectAddress: null,
			mailingCityItems: [],
			mailingCitySync: null,
			//
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
			
		}

	},
	computed: {
		states() {
			return this.$store.state.states;
		},
		physicalStates() {
			if(this.physicalData.country === 'MX') return this.$store.state.mexicoProvinces;
			else if(this.physicalData.country === 'CA') return this.$store.state.canadaProvinces;
			else return this.states;
		},
		mailingStates() {
			if(this.mailingData.country === 'MX') return this.$store.state.mexicoProvinces;
			else if(this.mailingData.country === 'CA') return this.$store.state.canadaProvinces;
			else return this.states;
		},
		auth() {
			return this.$store.state.auth;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status
		},
		physicalInfo() {
			return this.$store.state.onboardingInfo.addresses.physical
		},
		mailingInfo() {
			return this.$store.state.onboardingInfo.addresses.mailing
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'physical',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.$formValid.physical
			}
		},
	},
	methods: {
		async saveSignupHandler(saveType) {
			let pageType = 'address';
			let nextPage = 'payments';

			const payload = {
				physical: this.physicalData,
				mailing: this.mailingData
			};

			const zipCode = /^\d{5}$/;
			const canadianPostcode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

			try {

				if(this.physicalData.country !== 'CA' && !zipCode.test(this.physicalData.zip) || this.mailingData.country !== 'CA' && !zipCode.test(this.mailingData.zip)) {
					throw new Error('For addresses in the USA or Mexico, zip codes must be 5 digits long and only include numbers.');
				}

				if(this.physicalData.country === 'CA' && !canadianPostcode.test(this.physicalData.zip) || this.mailingData.country === 'CA' && !canadianPostcode.test(this.mailingData.zip)) {
					throw new Error('For addresses in Canada, postal codes must be 6 alphanumeric characters long in the following format - A1B2C3.');
				}

				const { data } = await this.$axios({
					method: 'put',
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token
					},
				});
				

				if (data) {
					data.addresses = { physical: data.physical, mailing: data.mailing };

					this.$store.commit('updateAddressInfo', data.addresses);

					// GET CARRIER PROGRESS SHOULD BE A MIXIN
					await this.getCarrierProgress();

					// IF SUCCESS PUSH FWD
					if (saveType === 'continue') {
						this.$router.push(`/${nextPage}`)
					} else if (saveType === 'nothing') {
						// do nothing
					}
					else {
						this.$router.push('/dashboard')
					}
				}

			} catch (error) {
				let errorMsg = error.message || `Unabled to save ${pageType} information - please try again later.`;
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: errorMsg,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}

		},
		async querySelections(v, type) {
			var response = await this.$axios({
				method: 'get',
				url: `${this.$hostName.autocompletePath}/api/v0/locations/city?chars=${v}`,
			});
			if (type === 'physical') this.physicalCityItems = response.data.result;
			if (type === 'mailing')  this.mailingCityItems = response.data.result;
		},
		updateMailing(value) {
			// console.log(value);
			if (value) {
				this.mailingData = Object.assign({}, this.physicalData);
				this.mailingCityItems.push(this.physicalData.city);
			} else {
				Object.keys(this.mailingData).forEach((key) => {
					this.mailingData[key] = null;
				})
			}
		},
		requiredHint(item) {
			if (item === 'address2') {
				return ''
			} else {
				return 'Required'
			}
		},		
	},
	watch: {
		physicalCitySync(val) {
			val && val !== this.physicalData.city && this.querySelections(val, 'physical');
		},
		mailingCitySync(val) {
			val && val !== this.mailingData.city && this.querySelections(val, 'mailing');
		},
	},
	mounted() {
		this.physicalData = Object.assign({}, this.physicalInfo);
		this.mailingData = Object.assign({}, this.mailingInfo);
		if (this.physicalData.city) this.physicalCityItems.push(this.physicalData.city);
		if (this.mailingData.city) this.mailingCityItems.push(this.mailingData.city);
	}
};
</script>