export default {
	verify: false,
	account: false,
	general: false,
	physical: false,
	mailing: false,
	fleet: false,
	documents: false,
	contract: false,
	payments: false,
	services: false,
	carb: false,
	hazm: false,
	powerOnly: false
};