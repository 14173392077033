module.exports = `
	<h3>CARB Compliance</h3>
	<p>
		Starting in 2020, the California Department of Motor Vehicles (DMV) 
		begins compliance verification to ensure that vehicles subject to 
		the California Air Resources Board’s (CARB) Truck and Bus Regulation 
		meet the requirements prior to obtaining DMV registration.
	</p>
	<p>
		If you haul freight in California, you must be CARB compliant.
	</p>
`;
