import capitalize from './capitalize';
import formatPhone from './formatPhone';
import momentDate from './momentDate';
import momentShortDate from './momentShortDate';
import usdFormat from './usdFormat';
// import titleCasing from './titleCasing';

export default {
	capitalize,
	formatPhone,
	momentDate,
	momentShortDate,
	usdFormat,
    // titleCasing,
};
