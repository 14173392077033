<template>
	<v-form v-model="$formValid.hazm" ref="hazmValid">
		<v-row>
			<v-col cols="12" class="pt-1">
				<v-row>
					* In order to be eligible for this service you must provide proof of HAZMAT certification, as well as your registration number and certification expiration date.
				</v-row>
				<v-row class="pt-3 pb-1" justify="end">
					<v-col cols="12" class="pa-0">
						<v-text-field
							label="Registration Number"
							v-model="hazmData.hazmatRegNo"
							required
							:rules="[$formRules.required]"
							color="primary"
							prepend-inner-icon="mdi-pound"
							@input="updateHazmatFields($event, 'hazmatRegNo')"
						></v-text-field>
					</v-col>
					<v-col cols="12" class="pa-0">
						<v-menu
							v-model="datePicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
							max-height="unset"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="computedDateFormatted"
									label="Expiration Date"
									persistent-hint
									prepend-inner-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
									color="primary"
									required
									:rules="[$formRules.required]"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="hazmData.hazmatExpDate"
								color="primary"
								no-title
								:min="new Date().toISOString()"
								@input="updateHazmatFields($event, 'computedDateFormatted'); datePicker = false"
							></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>
				<v-row justify="end">
					<v-btn
						@click="initFileUpload()"
						rounded
						small
						dark
						color="secondary"
						outlined
					>
						Select Document
					</v-btn>
				</v-row>
				<v-row class="pt-3" justify="start" v-if="file && file.size < 19922944">
					<v-icon v-if="!selectedFile" small color="#BDBDBD">mdi-checkbox-blank-circle-outline</v-icon>
					<v-icon v-if="selectedFile" small color="secondary">mdi-check-circle</v-icon>
					<span class="ml-2">Selected HAZMAT Document</span>
				</v-row>
				<v-row class="pt-3" justify="start" v-if="file && file.size > 19922944">
					<v-icon color="#FFA12F" class="mr-2">mdi-alert</v-icon>
					<span>Selected File is too Large - Max File size 19MB</span>
				</v-row>
			</v-col>
		</v-row>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog"></basic-dialog>
		<input
			@input="selectFile()"
			type="file"
			accept="image/png, image/jpeg, application/pdf"
			id="uploadNewDoc"
			style="display: none"
			name="TEST"
		/>
		<v-text-field
			v-model="selectedFile"
			style="display: none"
			required
			:rules="[$formRules.required]"
		></v-text-field>
	</v-form>
</template>

<script>
import moment from 'moment';
import basicDialogMixin from '@/mixins/basicDialogMixin';

export default {
	name: 'Service_Dialog_HazmatPrompt',
	mixins: [basicDialogMixin],
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
		serviceDialogType: { type: String, default: null }
	},
	data() {
		return {
			hazmData: {},
			// hazmData.hazmatRegNo: null,
			// hazmData.hazmatExpDate: null,
			datePicker: false,
			file: null,
			selectedFile: false
		}
	},
	methods: {
		initFileUpload() {
			const input = document.getElementById('uploadNewDoc');
			input.click();
		},
		selectFile() {
			const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
			this.file = document.getElementById('uploadNewDoc').files[0];

			if (!acceptedFileTypes.includes(this.file.type)) {
				this.file = null
				this.selectedFile = false;
				document.getElementById('uploadNewDoc').value = "";
				return this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: 'Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}

			if (this.file.size < 19922944) {
				this.selectedFile = true;
				this.$emit('selectedFile', this.file);
			} else {
				this.selectedFile = false;
				this.$emit('fileTooLarge')
			}
		},
		formatDate(date) {
			if (!date) return null;
			return moment(date).format('MM/DD/YYYY');
		},
		updateHazmatFields() {
			this.$emit('updateHazmatFields', {
				hazmatRegNo: this.hazmData.hazmatRegNo,
				hazmatExpDate: this.hazmData.hazmatExpDate
			})
		}
	},
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.hazmData.hazmatExpDate);
		},
		activeHazmInfo() {
			return this.$store.state.services.active.hazm
		},
		inactiveHazmInfo() {
			return this.$store.state.services.inactive.hazm
		}
	},
	mounted() {
		if (this.serviceDialogType === 'add') this.hazmData = Object.assign({}, this.inactiveHazmInfo);
		if (this.serviceDialogType === 'update') this.hazmData = Object.assign({}, this.activeHazmInfo);
		if (this.hazmData.documentId) this.selectedFile = true;
	}
};
</script>

<style scoped>
.carbDocText {
	color: var(--v-secondary-base);
}
</style>