<template>
	<v-col cols="12" class="pa-0">
		<!--
			CARRIER STATUS IS INCOMPLETE
		-->
		<v-row v-if="carrierStatus === 'incomplete'">
			<template v-for="(section, index) in Object.keys(carrierProgress)">
				<v-col cols="12" :key="`section${index}`" class="pa-0" v-if="carrierProgress[section].requirement">
					<v-row>
						<v-col cols="12" class="pa-0" v-if="index === 0">
							<v-divider></v-divider>
						</v-col>

						<v-col class="xsFlex text-center px-0">
							<v-row align="start" justify="center">
								<!-- GREY LOADING ICON-->
								<v-icon
									v-if="!carrierProgress[section].valid && currentPage != section"
									color="#BDBDBD"
									style="font-size: 26px"
									class="mt-2"
								>
									{{ carrierProgress[section].primaryIcon }}
								</v-icon>
								<!-- IF SECTION IS INCOMPLETE, DISPLAY GREY LOADING ICON -->
								<v-icon
									v-if="!carrierProgress[section].valid && currentPage === section"
									style="font-size: 26px"
									class="mt-2"
									color="#BDBDBD"
								>
									fa-solid fa-spinner
								</v-icon>
								<!-- IF SECTION IS COMPLETE AND THE CURRENT PAGE IS NOT GENERAL, DISPLAY GREEN CHECKMARK ICON -->
								<v-icon v-if="carrierProgress[section].valid && currentPage !== 'general'"
								 style="font-size: 26px"
								 class="mt-2"
								 color="#59b200"
								>
									{{ carrierProgress[section].secondaryIcon }}
								</v-icon>
								<!-- IF CURRENT PAGE IS GENERAL OR ADDRESSES, DISPLAY GREY LOADING ICON -->
								<!-- ADDRESS INFO IS USUALLY AUTO FILLED WHEN ACCOUNT IS CREATED, SO IT'S USUALLY "COMPLETED" BEFORE THE FIRST STEP -->
								<!-- WE DON'T WANT TO CONFUSE THE CARRIERS, SO WE WILL FORCE THE LOADING ICON TO DISPLAY AS LONG AS THE FIRST STEP IS STILL THE CURRENT PAGE -->
								<!-- <v-icon v-if="carrierProgress[section].valid && (currentPage === 'general' || currentPage === 'addresses')"
								 style="font-size: 26px"
								 class="mt-2"
								 color="#BDBDBD"
								>
									fa-solid fa-spinner
								</v-icon> -->
							</v-row>
						</v-col>

						<v-col class="lgFlex">
							<v-row>
								<span class="title">
									{{ carrierProgress[section].title }}
								</span>
							</v-row>
							<v-row class="py-1">
								<span class="description"> - {{ carrierProgress[section].description }} </span>
							</v-row>
						</v-col>

						<v-col style="cursor: pointer" class="smFlex text-center px-0" @click="routeBtn(section)">
							<v-row style="height: 100%" justify="end" align="center">
								<!-- IF SECTION IS COMPLETED, DISPLAY GREY EDIT ICON, UNLESS THE CURRENT PAGE IS GENERAL -->
								<v-btn small color="primary" icon v-if="carrierProgress[section].valid && currentPage !== 'general'">
									<v-icon style="font-size: 20px" color="#BDBDBD">fa-solid fa-pencil</v-icon>
								</v-btn>
								<!-- IF GENERAL INFO NOT COMPLETED AND CURRENT PAGE IS GENERAL, DISPLAY "BEGIN HERE" -->
								<v-btn
									small
									color="primaryVariant"
									v-if="!carrierProgress[section].valid && currentPage === section && section === 'general'"
								>
									BEGIN HERE
								</v-btn>
								<!-- IF SECTION NOT COMPLETED, IS THE NEXT STEP, AND IS NOT GENERAL INFO, DISPLAY CONTINUE -->
								<v-btn
									small
									color="primaryVariant"
									v-if="!carrierProgress[section].valid && currentPage === section && section != 'general'"
								>
									CONTINUE
								</v-btn>
							</v-row>
						</v-col>

						<v-col cols="12" class="pa-0">
							<v-divider></v-divider>
						</v-col>
					</v-row>
				</v-col>
			</template>
		</v-row>
		<!--
			CARRIER STATUS IS SIGNED || COMPLETE || DENIED
		-->
		<v-row v-if="carrierStatus != 'incomplete'">
			<template v-for="(section, index) in Object.keys(carrierProgress)">
				<!--
					WILL EVENTUALLY CHANGE THIS v-if="section != 'contract'" piece to check status and allow
					carriers to return and manage their contract upload
				-->
				<v-col cols="12" :key="`sectionComp${index}`" class="pa-0" v-if="section != 'contract'">
					<v-row>
						<v-col cols="12" class="pa-0" v-if="index === 0">
							<v-divider></v-divider>
						</v-col>

						<v-col class="xsFlex text-center px-0">
							<v-row align="start" justify="center">
								<v-icon
									v-if="carrierProgress[section].valid"
									color="#BDBDBD"
									style="font-size: 26px"
									class="mt-2"
								>
									{{ carrierProgress[section].secondaryIcon }}
								</v-icon>
								<v-icon
									v-else
									color="error"
									style="font-size: 26px"
									class="mt-2"
								>
									fa-solid fa-spinner
								</v-icon>
							</v-row>
						</v-col>

						<v-col class="lgFlex">
							<v-row>
								<span class="title">
									{{ carrierProgress[section].title }}
								</span>
							</v-row>
							<v-row class="py-1">
								<span class="description"> - {{ carrierProgress[section].descriptionComp }} </span>
							</v-row>
						</v-col>

						<v-col style="cursor: pointer" class="smFlex text-center px-0" @click="routeBtn(section)">
							<v-row style="height: 100%" justify="end" align="center">
								<!-- IF SECTION IS COMPLETED, DISPLAY GREY EDIT ICON -->
								<v-btn small color="primary" text v-if="carrierProgress[section].valid">
									<span style="color: #BDBDBD">COMPLETED</span>
									<v-icon style="font-size: 20px" color="#BDBDBD" class="ml-1">fa-solid fa-pencil</v-icon>
								</v-btn>
								<!-- IF SECTION IS NOT COMPLETED, DISPLAY RED EDIT ICON -->
								<v-btn small color="primary" text v-if="!carrierProgress[section].valid">
									<v-icon style="font-size: 20px" color="error">fa-solid fa-pencil</v-icon>
								</v-btn>
							</v-row>
						</v-col>

						<v-col cols="12" class="pa-0">
							<v-divider></v-divider>
						</v-col>
					</v-row>
				</v-col>
			</template>
			<v-col cols="12" class="pa-0">
				<v-row>
					<v-col cols="12" class="pa-0">
						<v-divider></v-divider>
					</v-col>

					<v-col class="xsFlex text-center px-0">
						<v-row align="start" justify="center">
							<v-icon
								color="#BDBDBD"
								style="font-size: 26px"
								class="mt-2"
							>
								mdi-cog
							</v-icon>
						</v-row>
					</v-col>

					<v-col class="lgFlex">
						<v-row>
							<span class="title">
								Manage Offered Services
							</span>
						</v-row>
						<v-row class="py-1">
							<span class="description"> - Apply for service offerings such as Power Only, Carb, and more. </span>
						</v-row>
					</v-col>

					<v-col style="cursor: pointer" class="smFlex text-center px-0" @click="routeBtn('services')">
						<v-row style="height: 100%" justify="end" align="center">
							<v-btn small color="primary" text>
								<v-icon style="font-size: 20px" color="#BDBDBD">fa-solid fa-pencil</v-icon>
							</v-btn>
						</v-row>
					</v-col>

					<v-col cols="12" class="pa-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</v-col>

		</v-row>
	</v-col>
</template>
<script>
export default {
	name: 'Dashboard_Links',
	props: {
		carrierProgress: { type: Object, default: () => {} },
		currentPage: { type: String, default: null }
	},
	mixins: [],
	data() {
		return {

		};
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status
		}
	},
	methods: {
		routeBtn(section) {
			if (section === 'contract') {
				let result = 0;
				Object.entries(this.carrierProgress).forEach(([key, value]) => {
					if( key !== 'contract' && value.valid === true) { return result++ }
				})
				if(result === 5) {
					this.$router.push(`/${section}`)
				}
			} else {
				this.$router.push(`/${section}`);
			}
		}
	}
};
</script>

<style scoped>
/**/
</style>
