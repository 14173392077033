<template>
	<v-dialog :value="value" persistent :width="dialogWidth">
		<v-card v-show="!addFleetAnimation && !addFleetSuccess" id="fleetDialog">
			<v-card-title>
				<v-row justify="space-between" align="start" class="fill-height">
					<v-col cols="11">
						<span v-if="dialogType === 'add'">
							Add / Update {{ keyFilter(selectedKey) }} Information
						</span>
						<span v-if="dialogType === 'assistance'">
							{{ keyFilter(selectedKey) }} Assistance
						</span>
					</v-col>
					<v-col cols="1">
						<v-btn @click="closeDialog" icon>
							<v-icon color="primary darken-1">mdi-close-thick</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<!-- MESSAGE SECTION -->
				<v-row v-if="dialogType === 'add'">
					<v-col cols="12">
						<AddMessage :selectedKey="selectedKey"></AddMessage>
					</v-col>
				</v-row>
				<v-row v-if="dialogType === 'assistance'">
					<v-col cols="12" v-if="selectedKey === 'power'">
						At least 1 Power Unit, either company or non-owned, is required in order to
						complete the onboarding process.
					</v-col>
					<v-col cols="12" v-else-if="selectedKey === 'drivers'">
						At least 1 Driver, either company or hired-leased, is required in order to
						complete the onboarding process.
					</v-col>
					<v-col cols="12" v-else>
						Providing your number of trailers and additional trailer details is
						optional. When trailer information is provided you will have access to more
						freight options.
					</v-col>
				</v-row>
				<!-- DIVIDER -->
				<v-row v-if="dialogType != 'assistance'">
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
				</v-row>
				<!-- CONTENT -->
				<v-row v-if="dialogType === 'add'">
					<v-col cols="12">
						<v-form v-model="$formValid.fleet" ref="fleetForm">
							<v-row>
								<v-col cols="12">
									<v-select
										:label="fleetLabel"
										:items="ownershipItems"
										v-model="ownership"
										color="primary"
										required
										:rules="[$formRules.required]"
									></v-select>
								</v-col>
								<v-col cols="12">
									<v-select
										:label="`${selectNumFilter(selectedKey)}`"
										:items="numberArray"
										v-model="numberSelected"
										color="primary"
										required
										:rules="[$formRules.required]"
									></v-select>
								</v-col>
								<v-col cols="12" v-if="selectedKey === 'other'">
									<v-row>
										Please submit details for only
										<b class="mx-1">one</b> "other" type.
									</v-row>
								</v-col>
								<v-col cols="12" v-if="selectedKey === 'other'">
									<v-text-field
										label="Other Trailer Description"
										v-model="otherDesc"
										color="primary"
										required
										:rules="[$formRules.required]"
									></v-text-field>
								</v-col>
								<v-col cols="12" v-if="selectedKey === 'flatbed'">
									<v-row>
										<v-col cols="12" class="pa-0">
											<v-row> Are any of these trailers 48ft? </v-row>
											<v-row>
												<v-radio-group
													row
													v-model="flat48"
													required
													:rules="[$formRules.required]"
												>
													<v-radio label="Yes" value="true"></v-radio>
													<v-radio label="No" value="false"></v-radio>
												</v-radio-group>
											</v-row>
										</v-col>
										<v-col cols="12" class="pa-0">
											<v-row> Are any of these trailers 53ft? </v-row>
											<v-row>
												<v-radio-group
													row
													v-model="flat53"
													required
													:rules="[$formRules.required]"
												>
													<v-radio label="Yes" value="true"></v-radio>
													<v-radio label="No" value="false"></v-radio>
												</v-radio-group>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions
				v-if="dialogType != 'assistance'"
				class="cardActions"
				:style="cardActionsStyle"
				@click="handler"
			>
				<span v-if="dialogType === 'add'">
					Add / Update {{ keyFilter(selectedKey) }}s
				</span>
			</v-card-actions>
		</v-card>
		<v-card v-show="addFleetAnimation" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Updating Information</p>
					<v-progress-circular
						indeterminate
						color="primary darken-2"
						size="44"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-card>
		<v-card v-show="addFleetSuccess" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Success!</p>
					<!-- <p>
						You may now enter in more {{ keyFilter(selectedKey) }} information or close
						this dialog
					</p> -->
				</v-col>
			</v-row>
		</v-card>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		/>
	</v-dialog>
</template>

<script>
// mixins
import basicDialogMixin from '@/mixins/basicDialogMixin.js';

import AddMessage from './AddMessage';

export default {
	name: 'Fleet_Manager_Dialog',
	props: {
		value: { type: Boolean, default: false },
		addFleetAnimation: { type: Boolean, default: false },
		addFleetSuccess: { type: Boolean, default: false },
		dialogType: { type: String, default: 'add' },
		selectedKey: { type: String, default: null },
	},
	components: {
		AddMessage,
	},
	mixins: [basicDialogMixin],
	data() {
		return {
			dialogHeight: 0,
			//
			numberArray: [],
			numberSelected: null,
			// when "other" type is selected
			otherDesc: null,
			// when "flatbed" type is selected
			flat48: null,
			flat53: null,
			// "company" || "owner"
			ownership: null,
		};
	},
	methods: {
		handler() {
			const dialog = document.querySelector('#fleetDialog');
			this.dialogHeight = dialog.clientHeight;
			// console.log(this.dialogHeight, "dialog height");

			if (this.preventAction) {
				let payload = {};

				if (this.selectedKey === 'other') {
					payload.otherDesc = this.otherDesc;
				}
				if (this.selectedKey === 'flatbed') {
					payload.flat48 = this.flat48;
					payload.flat53 = this.flat53;
				}
				payload.equipKey = this.selectedKey;
				payload.amount = this.numberSelected;
				payload.ownership = this.ownership;

				this.$emit('addFleet', payload);
			}
		},
		closeDialog() {
			if (this.dialogType === 'add') this.$refs.fleetForm.reset();

			this.$nextTick(() => {
				this.$emit('closeFleetDialog');
			});
		},
		keyFilter(item) {
			let text;
			switch (item) {
				case 'power':
					text = 'Power Unit';
					break;
				case 'drivers':
					text = 'Driver';
					break;
				case 'dry':
					text = 'Dry Van';
					break;
				case 'flatbed':
					text = 'Flatbed';
					break;
				case 'reefer':
					text = 'Refrigerated';
					break;
				case 'carHaul':
					text = 'Car Hauler';
					break;
				case 'highCube':
					text = 'High Cube Dray';
					break;
				case 'other':
					text = 'Other Trailer';
					break;
				default:
					text = item;
			}
			return text;
		},
		selectNumFilter(item) {
			let text;
			switch (item) {
				case 'power':
					text = 'Type or Select Number of Power Units';
					break;
				case 'drivers':
					text = 'Type or Select Number of Drivers';
					break;
				case 'dry':
					text = 'Type or Select Number of Dry Vans';
					break;
				case 'flatbed':
					text = 'Type or Select Number of Flatbeds';
					break;
				case 'reefer':
					text = 'Type or Select Number of Refrigerateds';
					break;
				case 'carHaul':
					text = 'Type or Select Number of Car Haulers';
					break;
				case 'highCube':
					text = 'Type or Select Number of High Cube Drays';
					break;
				case 'other':
					text = 'Type or Select Number of Other Trailers';
					break;
				default:
					text = item;
			}
			return text;
		},
		buildArray() {
			this.numberArray = [];
			for (let i = 0; i <= 400; i++) {
				this.numberArray.push(i);
			}
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		dialogWidth() {
			let style;
			if (this.$vuetify.breakpoint.smAndDown) {
				style = '80vw';
			} else {
				style = '30vw';
			}
			return style;
		},
		cardActions() {
			let style;
			let clickAction;

			if (!this.$formValid.fleet) {
				style = { 'background-color': '#282828', color: '#424242' };
				clickAction = false;
			}
			if (this.$formValid.fleet) {
				style = { 'background-color': '#673aa7', cursor: 'pointer', color: '#FFF' };
				clickAction = true;
			}

			return { style, clickAction };
		},
		cardActionsStyle() {
			return this.cardActions.style;
		},
		preventAction() {
			return this.cardActions.clickAction;
		},
		fleetLabel() {
			if (this.selectedKey === 'power') {
				return 'Is this Company or Non-Owned?';
			} else if (this.selectedKey === 'drivers') {
				return 'Is this a company driver or a hired-leased driver?';
			} else {
				return null;
			}
		},
		ownershipItems() {
			if (this.selectedKey === 'drivers') {
				return [
					{ text: 'Company Driver', value: 'company' },
					{ text: 'Hired-Leased On Driver', value: 'owner' },
				];
			} else {
				return [
					{ text: 'Company Owned', value: 'company' },
					{ text: 'Non-Owned', value: 'owner' },
				];
			}
		},
	},
	mounted() {
		this.buildArray();
	},
	watch: {
		addFleetSuccess(val) {
			if (val) {
				if (this.dialogType === 'add') this.$refs.fleetForm.reset();
				setTimeout(() => {
					this.$emit('resetSuccess');
					this.closeDialog();
				}, 2000);
			}
		},
	},
};
</script>
<style scoped>
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
</style>
