<template>
	<v-row>
		<v-col cols="12" class="text-right">
			Last Updated: {{selectedServiceData.lastUpdateTime | momentShortDate}}
		</v-col>
		<v-col cols="12">
			<v-row v-if="selectedServiceData.internal.notesToCarrier.length > 0">
				<template v-for="(item, index) in selectedServiceData.internal.notesToCarrier">
					<v-col cols="12" :key="`note${index}`">
						<v-row>
							{{item}}
						</v-row>
					</v-col>
				</template>
			</v-row>
			<v-row v-if="selectedServiceData.internal.notesToCarrier.length === 0">
				<v-col cols="12">
					No notes from carrier onboarding at this time
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12">
			<v-divider></v-divider>
		</v-col>
		<v-col cols="12">
			Your Application Details:
		</v-col>
		<v-col cols="12" v-if="selectedServiceKey === 'carb'">
			<v-row>
				Do you haul freight in the state of California?
				<span v-if="selectedServiceData.runCali" class="ml-2"> - Yes </span>
				<span v-else class="ml-2"> - No </span>
			</v-row>
		</v-col>
		<v-col cols="12" v-if="selectedServiceKey === 'hazm'">
			<v-row>
				Hazmat Registration #:
				<span v-if="selectedServiceData.hazmatRegNo" class="ml-2">{{selectedServiceData.hazmatRegNo}}</span>
			</v-row>
			<v-row>
				Hazmat Expiration Date:
				<span v-if="selectedServiceData.hazmatExpDate" class="ml-2">{{selectedServiceData.hazmatExpDate | momentShortDate}}</span>
			</v-row>
		</v-col>
		<v-col cols="12" class="text-right">
			<v-btn
				@click="$emit('viewDocument', selectedServiceData.documentId)"
				rounded
				small
				dark
				color="secondary"
				outlined
			>
				View My {{subTitleFilter(selectedServiceKey)}} Document
			</v-btn>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Service_Dialog_ServiceDetails',
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null }
	},
	data() {
		return {
			//
		}
	},
	methods: {
		subTitleFilter(item) {
			let text;
			switch(item) {
				// case 'fsmaRegulated':
				// 	text = 'FSMA';
				// 	break;
				case 'hazm':
					text = 'Hazmat';
					break;
				case 'liftGate':
					text = 'Lift Gate';
					break;
				case 'tanker':
					text = 'Tanker';
					break;
				case 'dropTrailers':
					text = 'Drop Trailer';
					break;
				case 'intermodal':
					text = 'Intermodal';
					break;
				case 'powerOnly':
					text = 'Power Only';
					break;
				case 'teamExpedited':
					text = 'Expedited';
					break;
				case 'carb':
					text = 'CARB';
					break;
				case 'smartway':
					text = 'Smartway';
					break;
				case 'canada':
					text = 'Canada';
					break;
				case 'mexico':
					text = 'Mexico';
					break;
				case 'gps':
					text = 'GPS';
					break;
				case 'truckerTools':
					text = 'Trucker Tools';
					break;
				case 'twic':
					text = 'TWIC';
					break;
				case 'macropoint':
					text = 'Macropoint';
					break;
				default:
					text = item;
			}
			return text;
		},
	},
	computed: {
		//
	},
};
</script>

<style scoped>
.carbDocText {
	color: var(--v-secondary-base);
}
</style>