<template>
	<v-col cols="12" class="px-2">
		<v-row class="fill-height topLevel mt-6" justify="center">
			<!-- TOOLBAR -->
			<v-col cols="12" xl="11" class="py-0 px-5">
				<v-card class="px-4">
					<v-row :style="titleFont" class="py-2">
						<v-col order="2" order-md="1" cols="12" md="8">
							<v-row>
								<v-col cols="12" class="pa-0">
									<span class="grey--text text--lighten5"> {{ greeting }}, </span>
								</v-col>
								<v-col cols="12" class="pa-0" style="margin-top: -12px">
									<span>
										{{ user.firstName | capitalize }}
									</span>
								</v-col>
							</v-row>
						</v-col>
						<v-col order="1" order-md="2" cols="12" md="4" class="pa-0">
							<v-row class="fill-height" align="center" justify="end">
								<v-chip color="primary" outlined>
									Status: {{onboardingInfo.status | capitalize}}
								</v-chip>
							</v-row>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<!-- MAIN DASHBOARD -->
			<v-col cols="12" xl="11">
				<v-row justify="space-between">
					<v-col cols="12" lg="6">
						<!-- PROGRESS TRACKER/STEP COUNTER -->
						<v-card class="pa-5">
							<span>To complete your application, you'll need to complete all the steps below.</span>
							<ProgressTracker :carrierProgress="carrierProgress" :currentPage="currentPage"></ProgressTracker>
						</v-card>
						<!-- CARRIER SIGNUP STEPS -->
						<v-row>
							<DashboardLinks
							 :carrierProgress="carrierProgress"
							 :currentPage="currentPage"></DashboardLinks>
						</v-row>
					</v-col>
					<!-- FAQ SECTION / HELP TOPICS -->
					<v-col cols="12" lg="5">
						<!-- IMPORTANT INFORMATION - POWER ONLY/IRON SERVICES/ONBOARDING PROCESS -->
						<ImportantLinks
						 @openPowerOnlyModal="openPowerOnlyModal"
						 @openIronServicesModal="openIronServicesModal"
						 @openOnboardingProcessModal="openOnboardingProcessModal"></ImportantLinks>
						<v-card class="mt-4">
							<v-toolbar dark flat>
								<v-row align="center" justify="space-between" class="mx-2">
									<span xs12 class="helpTagTitle">
										FAQ / Help Topics
									</span>
									<span xs12>
										<v-btn
										 icon
										 @click="openHelpModal">
											<v-icon>
												mdi-account-question
											</v-icon>
										</v-btn>
									</span>
								</v-row>
								<template v-slot:extension>
									<v-tabs v-model="faqModel" slider-color="#fff">
										<v-tab v-for="item in faqTabs" :key="item">
											{{ item }}
										</v-tab>
									</v-tabs>
								</template>
							</v-toolbar>
							<v-tabs-items v-model="faqModel">
								<v-tab-item v-for="item in faqTabs" :key="item">
									<v-card v-if="item === 'Onboarding Assistance'" flat>
										<v-card-text class="list-scroll">
											<v-row wrap v-if="!selectedHelpTopic">
												<v-col cols="12">
													<span style="font-weight: bold">Please click on the links below to learn more about the onboarding process:</span>
												</v-col>
												<v-col cols="12" class="py-1">
													<v-divider></v-divider>
												</v-col>
												<v-col cols="12" v-for="(item, index) in Object.keys(helpTopics)" :key="`helpTopic${index}`">
													<div class="d-inline-flex">
														<span class="helpTopicItems" @click="changeHelpTopic(item)">
															{{helpTopics[item].text}}
														</span>
													</div>
												</v-col>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'General Information'">
												<GeneralAssistance></GeneralAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Diversity Reporting'">
												<DiversityAssistance></DiversityAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Address Information'">
												<AddressAssistance></AddressAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Fleet Information'">
												<FleetAssistance></FleetAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Uploading Documents'">
												<UploadAssistance></UploadAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Managing Payments'">
												<PaymentAssistance></PaymentAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Signing Contracts'">
												<ContractAssistance></ContractAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic === 'Managing Services'">
												<ServicesAssistance></ServicesAssistance>
											</v-row>
											<v-row wrap v-if="selectedHelpTopic" class="pt-3">
												<v-btn @click="closeHelpTopic">
													Back
												</v-btn>
											</v-row>
										</v-card-text>
									</v-card>
								</v-tab-item>
							</v-tabs-items>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog" />
		<HelpDialog v-model="helpDialogBool"></HelpDialog>
		<PowerOnlyDialog v-model="powerOnlyDialogBool"></PowerOnlyDialog>
		<IronServicesDialog v-model="ironServicesDialogBool"></IronServicesDialog>
		<OnboardingProcessDialog v-model="onboardingProcessDialogBool"></OnboardingProcessDialog>
	</v-col>
</template>
<script>
// mixins
import basicDialogMixin from '../../mixins/basicDialogMixin.js';

//components
import ProgressTracker from './ProgressTracker';
import DashboardLinks from './DashboardLinks.vue';
import ImportantLinks from './ImportantLinks.vue';
import GeneralAssistance from './onboardingAssistance/generalAssistance.vue';
import DiversityAssistance from './onboardingAssistance/diversityAssistance.vue';
import ContractAssistance from './onboardingAssistance/contractAssistance.vue';
import FleetAssistance from './onboardingAssistance/fleetAssistance.vue';
import PaymentAssistance from './onboardingAssistance/paymentAssistance.vue';
import ServicesAssistance from './onboardingAssistance/servicesAssistance.vue';
import UploadAssistance from './onboardingAssistance/uploadAssistance.vue';
import AddressAssistance from './onboardingAssistance/addressAssistance.vue';
import HelpDialog from './helpDialog';
import PowerOnlyDialog from './powerOnlyDialog';
import IronServicesDialog from './ironServicesDialog';
import OnboardingProcessDialog from './onboardingProcessDialog';

export default {
	name: 'Dashboard',
	components: {
		ProgressTracker,
		DashboardLinks,
		ImportantLinks,
		GeneralAssistance,
		DiversityAssistance,
		ContractAssistance,
		FleetAssistance,
		PaymentAssistance,
		ServicesAssistance,
		UploadAssistance,
		AddressAssistance,
		HelpDialog,
		PowerOnlyDialog,
		IronServicesDialog,
		OnboardingProcessDialog
	},
	props: [],
	mixins: [basicDialogMixin],
	data() {
		return {
			faqModel: null,
			faqTabs: ['Onboarding Assistance'],
			currentLocalTime: new Date().getHours(),
			helpTopics: {
				general: {
					text: 'General Information'
				},
				minority: {
					text: 'Diversity Reporting'
				},
				address: {
					text: 'Address Information'
				},
				fleet: {
					text: 'Fleet Information'
				},
				documents: {
					text: 'Uploading Documents'
				},
				payments: {
					text: 'Managing Payments'
				},
				contract: {
					text: 'Signing Contracts'
				},
				services: {
					text: 'Managing Services'
				}
			},
			selectedHelpTopic: null,
			//Carousel dialog Boolean
			helpDialogBool: false,
			powerOnlyDialogBool: false,
			ironServicesDialogBool: false,
			onboardingProcessDialogBool: false,
			totalSteps: 6,
			completedSteps: 0,
			prevUrl: null,
		};
	},
	methods: {
		checkProgress() {
			if (this.currentPage === 'general') {
				// update the store to mark the addresses section as incomplete
				this.$store.commit('updateAddressStatus');
			}
		},
		changeHelpTopic(item) {
			this.selectedHelpTopic = this.helpTopics[item].text;
		},
		closeHelpTopic(item) {
			this.selectedHelpTopic = null;
		},
		closeDialog() {
			this.insModal = false;
		},
		openHelpModal() {
			this.$background.blur = true;
			this.helpDialogBool = true;
		},
		openPowerOnlyModal() {
			this.$background.blur = true;
			this.powerOnlyDialogBool = true;
		},
		openIronServicesModal() {
			this.$background.blur = true;
			this.ironServicesDialogBool = true;
		},
		openOnboardingProcessModal() {
			this.$background.blur = true;
			this.onboardingProcessDialogBool = true;
		},
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});

				if (data) this.$store.commit('updateServices', data);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
	},
	sockets: {
		updateNotificationOnboarding(val) {
			if (val.uuid === this.auth.uuid) {
				this.$store.commit('updateNotificationOnboarding', val);
			}
		},
		updateServiceOnboarding(val) {
			if (val.uuid === this.auth.uuid) {
				this.getCarrierServices();
				this.$store.commit('socketedUpdateServices', val);
			}
		}
	},
	mounted() {
		this.prevUrl = this.$router.history.current.matched[0].from;
		if(this.prevUrl === '/loadinginformation' && this.onboardingInfo.status === 'incomplete') {
			this.openHelpModal();
		}
		else if(this.prevUrl === '/loadinginformation' && this.onboardingInfo.status === 'signed') {
			this.openHelpModal();
		}
		
	},
	created() {
		this.getCarrierServices();
		this.checkProgress();
	},
	computed: {
		user() {
			return this.$store.state.userInfo;
		},
		notifications() {
			return this.store.state.notifications;
		},
		auth() {
			return this.$store.state.auth;
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
		carrierProgress() {
			return this.$store.state.progress;
		},
		currentPage() {
			return this.$store.state.currentPage;
		},
		greeting() {
			if (this.currentLocalTime < 12) return 'Good Morning';
			if (this.currentLocalTime >= 12 && this.currentLocalTime <= 16) return 'Good Afternoon';
			return 'Good Evening';
		},
		titleFont() {
			let style;
			if (this.$vuetify.breakpoint.smAndDown) style = { 'font-size': '20px' };
			if (this.$vuetify.breakpoint.mdOnly) style = { 'font-size': '22px' };
			if (this.$vuetify.breakpoint.lgAndUp) style = { 'font-size': '24px' };
			return style;
		}
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'LoadingInformation') {
			next((vm) => {
				if (!vm.$store.state.onboardingInfo.firstTimeVisited) {
					vm.helpDialogBool = true
					vm.$background.blur = true;
				}
			});
		} else {
			next()
		}
	},
};
</script>
<style>
.topLevel {
	position: relative;
	z-index: 2;
	width: 100%;
}

.title {
	color: #bdbdbd;
	font-size: 20px;
	font-weight: 300 !important;
}

.description {
	color: #939393;
	font-size: 13.5px;
}

.xsFlex {
	flex-basis: 5%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 5%;
}

.smFlex {
	flex-basis: 15%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 15%;
}

.lgFlex {
	flex-basis: 80%;
	-webkit-box-flex: 0;
	flex-grow: 0;
	max-width: 80%;
}

.helpTopicItems {
	cursor: pointer;
}

.helpTopicItems:hover {
	color: white;
}

.helpTopicItems::after {
	margin-top: 3px;
	content: '';
	display: block;
	width: 0;
	height: 2px;
	background: #9f6cdf;
	transition: width .3s;
}

.helpTopicItems:hover::after {
	width: 100%;
}

.helpTagTitle {
	font-size: 1.25rem;
	line-height: 1.5;
	font-weight: 400;
}

/*.stepCounter {
	height:  15px;
	width: 16px;
	background-color: rebeccapurple;
	border:  1px solid white;
}*/
</style>