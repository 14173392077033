<template>
	<div>
		<v-card-title>Reset Your Password</v-card-title>
		<v-card-text class="py-2">
			<v-row class="justify-center align-center">
				<v-col cols="12" lg="10">
					<v-row>
						<v-col cols="12" class="px-0">
							New Password
						</v-col>
					</v-row>
					<v-text-field dark color="primary" :type="showPassword ? 'text' : 'password'" v-model="newPassword"
                    :rules="[this.$formRules.passwordValid]"></v-text-field>
					<v-row>
						<v-col cols="6" class="px-0">
							Confirm Password
						</v-col>
					</v-row>
					<v-text-field
						:type="showPassword ? 'text' : 'password'"
						dark
						color="primary"
						v-model="confirmPassword"
						:append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                        :rules="[this.$formRules.passwordValid, this.passMatch]"
						@click:append="showPassword = !showPassword"
					></v-text-field>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row class="justify-center align-center">
				<v-col cols="12" lg="10">
					<v-btn dark color="primary darken-3" :disabled="!newPassword || !confirmPassword || loading" block @click="resetPassword">
						Submit
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog" />
	</div>
</template>

<script>
import basicDialogMixin from '../../../mixins/basicDialogMixin';
import hashPasswordMixin from '../../../mixins/hashPassword';

export default {
	data: () => ({
		loading: false,
		//
		newPassword: null,
		confirmPassword: null,
		showPassword: false,
	}),
	mixins: [hashPasswordMixin, basicDialogMixin],
	computed: {
		encObj() {
			return this.$router.currentRoute.params.encObj;
		},
        passMatch() {
			return this.newPassword === this.confirmPassword || 'Passwords Do Not Match'
		},
	},
	methods: {
		//
		async resetPassword() {
			// validate the form here
			const hashedPassword = this.hashPassword(this.newPassword);

			const data = {
				password: hashedPassword,
				encodedObj: this.encObj,
			};

			const response = await this.$axios({
				method: 'post',
				url: `${this.$hostName.apiBase}/onboarding/resetpassword/reset`,
				data,
			});
            this.$router.push('/login');
		},
	},
};
</script>

<style scoped>
/* */
</style>
