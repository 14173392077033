<template>
	<v-col cols="12" lg="10" class="px-0 mx-0">
		<v-card class="px-0 mx-0">
			<v-row align="center">
				<v-col cols="12" md="7" lg="8" xl="9" class="d-flex justify-start">
					<span class="px-2">
						<v-btn @click="backButton" icon>
							<v-icon medium>fa-arrow-left</v-icon>
						</v-btn>
					</span>
					<span class="px-2" :style="titleFont">{{title}}</span>
					<!-- this is where a potential step counter would live -->
					<span class="mt-2 px-2" style="color: #BB86FC">{{ this.page }} / 6</span>
				</v-col>
				<v-col cols="12" md="5" lg="4" xl="3" class="pa-0">
					<v-row justify="center" align="center" class="text-center pa-0">
						<!--
						-->
						<v-col v-if="options.status === 'incomplete'" cols="12">
							<v-row justify="end">
								<v-col cols="6" md="5" class="py-0">
									<v-btn
										class="ma-1"
										:color="options.colorTwo"
										:outlined="options.outlineTwo"
										block
										@click="$router.push('/dashboard')"
									>
										Return Later
									</v-btn>
								</v-col>
								<v-col cols="6" md="5" class="py-0">
									<v-btn
										class="ma-1"
										:color="options.colorOne"
										:disabled="!options.validation"
										:outlined="options.outlineOne"
										block
										@click="$emit('saveSignupInfo', 'continue')"
									>
										Save/Continue
									</v-btn>
								</v-col>
							</v-row>
						</v-col>


						<v-col v-else cols="12">
							<v-row justify="end">
								<v-col cols="6" md="5" class="py-0">
									<v-btn
										:color="options.colorTwo"
										:outlined="options.outlineTwo"
										rounded
										class="ma-1"
										block
										@click="$router.push('/dashboard')"
									>
										Home
									</v-btn>
								</v-col>
								<v-col cols="6" md="5" class="py-0">
									<v-btn
										:color="options.colorOne"
										:disabled="!options.validation"
										:outlined="options.outlineOne"
										rounded
										class="ma-1"
										block
										@click="$emit('saveSignupInfo', 'return')"
									>
										Save Changes
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
						<!--



						-->
					</v-row>
				</v-col>
			</v-row>
		</v-card>
	</v-col>
</template>

<script>
export default {
	name: "Navigation-Buttons",
	props: {
		options: { type: Object, default: () => {} },
		title: { type: String, default: null }
	},
	data() {
		return {
			page: 1,
		}
	},

		// }
	methods: {
		//
		backButton() {
			console.log("this is the page: ", this.options.currPage)
			if(this.options.currPage === 'general') {
				this.$router.push('/dashboard')
			}
			if(this.options.currPage === 'physical') {
				this.$router.push('/general')
			}
			if(this.options.currPage === 'payments') {
				this.$router.push('/addresses')
			}
			if(this.options.currPage === 'fleet') {
				this.$router.push('/payments')
			}
			if(this.options.currPage === 'documents') {
				this.$router.push('/fleet')
			}
			if(this.options.currPage === 'services') {
				this.$router.push('/documents')
			}
		},
		pageNumber() {
			if(this.options.currPage === 'physical') {
				this.page = 2;
				return this.page;
			}
			else if(this.options.currPage === 'payments') {
				this.page = 3;
				return this.page;
			}
			else if(this.options.currPage === 'fleet') {
				this.page = 4;
				return this.page;
			}
			else if(this.options.currPage === 'documents') {
				this.page = 5;
				return this.page;
			}
			else if(this.options.currPage === 'services') {
				this.page = 6;
				return this.page;
			}
			else { return this.page }

		},
	},
	computed: {
		
		blockBoolean() {
			let bool;
			if (this.$vuetify.breakpoint.smAndDown) bool = true;
			if (this.$vuetify.breakpoint.mdAndUp) bool = false;
			return bool
		},
		btnTextClass() {
			let titleClass;
			if (this.$vuetify.breakpoint.smAndDown) titleClass = "text-center py-0";
			if (this.$vuetify.breakpoint.mdAndUp) titleClass = "text-right py-0";
			return titleClass
		},
		titleFont() {
			let style;
			if (this.$vuetify.breakpoint.smAndDown) style = {'font-size': '20px'};
			if (this.$vuetify.breakpoint.mdOnly) style = {'font-size': '22px'};
			if (this.$vuetify.breakpoint.lgAndUp) style = {'font-size': '24px'};
			return style;
		}
	}, 
	mounted() {
		this.pageNumber();

	}
};
</script>