<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="Payments Information"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>

		<v-col cols="12" md="10" align-self="start" class="pa-0">
			<v-row justify="space-between">
				<v-col cols="12" md="6" class="justify-center pa-1">
					<v-card height="100%">
						<v-card-text>
							<v-row justify="center" align="center">
								<v-col cols="12">
									<v-row align="center">
										<v-switch
											v-model="paymentsData.usePhysical"
											@click="toggleSwitch"
										></v-switch>
										Use Physical Address Information
									</v-row>
									<v-form v-model="$formValid.payments" ref="payments">
										<v-row>
											<!--
											-->
											<v-col cols="12" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.address1"
													:maxlength="maxLength('address1')"
													:label="labelFilter('address1')"
													:rules="ruleMethod('address1')"
													persistent-hint
													hint="Required"
													required
													outlined
													filled
													autocomplete="off"
												></v-text-field>
											</v-col>
											<!--
											-->
											<v-col cols="9" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.address2"
													:maxlength="maxLength('address2')"
													:label="labelFilter('address2')"
													:rules="ruleMethod('address2')"
													required
													persistent-hint
													outlined
													filled
													hint="Optional"
													autocomplete="off"
												></v-text-field>
											</v-col>
											<!--
											-->
											<v-col cols="3" class="py-2 px-2">
												<v-select
													v-model="paymentsData.country"
													:items="paymentCountry"
													:label="labelFilter('country')"
													:rules="ruleMethod('country')"
													required
													outlined
													filled
													persistent-hint
													hint="Required"
													autocomplete="off"
												></v-select>
											</v-col>
											<!--
											-->
											<v-col cols="12" md="4" class="py-2 px-2">
												<v-combobox
													v-model="paymentsData.city"
													:items="paymentCityItems"
													:search-input.sync="citySync"
													:label="labelFilter('city')"
													:rules="ruleMethod('city')"
													clearable
													required
													outlined
													filled
													persistent-hint
													hint="Required"
													autocomplete="off"
												>
												</v-combobox>
											</v-col>
											<!--
											-->
											<v-col cols="6" md="4" class="py-2 px-2">
												<v-select
													v-model="paymentsData.state"
													:items="states"
													:label="labelFilter('state')"
													:rules="ruleMethod('state')"
													clearable
													required
													outlined
													filled
													persistent-hint
													hint="Required"
													autocomplete="off"
												>
												</v-select>
											</v-col>
											<!--
											-->
											<v-col cols="6" md="4" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.postalCode"
													:maxlength="maxLength('postalCode')"
													:label="labelFilter('postalCode')"
													:rules="ruleMethod('postalCode')"
													required
													persistent-hint
													outlined
													filled
													hint="Required"
													@keypress="numOnly($event, 'postalCode')"
													autocomplete="off"
												></v-text-field>
											</v-col>
											<!--
											-->
											<v-col cols="12" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.email"
													:maxlength="maxLength('email')"
													label="Contact Email"
													:rules="ruleMethod('emailAddress')"
													required
													persistent-hint
													outlined
													filled
													hint="Required"
													autocomplete="off"
												></v-text-field>
											</v-col>
											<!--
											-->
											<v-col cols="9" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.phone"
													:maxlength="maxLength('telephone')"
													:label="labelFilter('phone')"
													:rules="ruleMethod('telephone')"
													required
													@keypress="numOnly($event, 'telephone')"
													autocomplete="off"
													persistent-hint
													outlined
													filled
													hint="Required"
												></v-text-field>
											</v-col>
											<!--
											-->
											<v-col cols="3" class="py-2 px-2">
												<v-text-field
													v-model="paymentsData.extension"
													:maxlength="maxLength('extension')"
													:label="labelFilter('extension')"
													@keypress="numOnly($event, 'extension')"
													autocomplete="off"
													persistent-hint
													outlined
													filled
													hint="Optional"
												></v-text-field>
											</v-col>
											<!--
											-->
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6" class="justify-center pa-1">
					<v-card >
						<v-card-text>
							<v-row justify="center" align="center">
								<v-col cols="12">
									<v-form v-model="$formValid.factoring" ref="factoring">
										<v-row>
											<v-col cols="12" class="py-2 px-2">
												<v-checkbox
													v-model="paymentsData.isFactoring"
													:items="factoringItems"
													item-text="text"
													item-value="selection"
													label="Do you use a factoring company?"
													autocomplete="off"
													persistent-hint
													outlined
													filled
													hint="Optional"
												>
												</v-checkbox>
											</v-col>
											<!--
											-->
											<v-col
												cols="12"
												class="my-2 py-2 px-2"
												v-if="paymentsData.isFactoring"
											>
												<v-row>
													<v-col cols="12" md="6" class="pa-0">
														<v-text-field
															v-model="paymentsData.name"
															:maxlength="maxLength('name')"
															:label="labelFilter('name')"
															:rules="ruleMethod('name')"
															required
															persistent-hint
															outlined
															filled
															hint="Required"
															autocomplete="off"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>	
													<v-col cols="12" md="6" class="my-2 pa-0">
														<v-row>
															<v-col
																cols="12"
																class="pa-0 text-center text-md-right"
															>
																<v-btn
																	@click="openUploadDialog = true"
																	block
																	dark
																	color="primary"
																	:disabled="addDocAnimation"
																	outlined
																>
																	Select your Notice of Assignment
																</v-btn>
															</v-col>
															<v-col cols="12" class="pa-0 py-2">
																<v-row
																	justify="center"
																	justify-md="start"
																>
																	<v-icon
																		v-if="!noaUploaded"
																		small
																		color="#BDBDBD"
																	>
																		mdi-checkbox-blank-circle-outline
																	</v-icon>
																	<v-icon
																		v-if="noaUploaded"
																		small
																		color="primary"
																	>
																		mdi-check-circle
																	</v-icon>
																	<span class="ml-2"
																		>Selected NOA Document</span
																	>
																</v-row>
																<v-text-field
																	v-if="paymentsData.isFactoring"
																	v-model="noaUploaded"
																	style="display: none"
																	required
																	persistent-hint
																	hint="Required"
																	:rules="[$formRules.required]"
																></v-text-field>
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
										<v-row class="my-3" dense>
											<v-col cols="12" class="text-center">
												<!-- As you complete a step the next step becomes unlocked. You must complete a step in order to move onto the next. -->
												If you have any issues or questions, please
												check out the FAQ card on the dashboard or
												<span
												style="color: #bb86fc; cursor: pointer; text-decoration: underline"
												@click="routeContact">contact us</span>
												directly.
											</v-col>
										</v-row>
									</v-form>
										
											
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" class="gradientBg pa-0">
				</v-col>
			</v-row>
		</v-col>
		<UploadDialog
			v-model="openUploadDialog"
			:addDocAnimation="addDocAnimation"
			:docInfoSuccess="docInfoSuccess"
			@addDocument="addDocument"
			@closeUploadDialog="closeUploadDialog"
		></UploadDialog>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-row>
</template>
<script>
import UploadDialog from './uploadDialog.vue';

import basicDialogMixin from '@/mixins/basicDialogMixin.js';
import getCarrierProgress from '@/mixins/getCarrierProgress.js';

import numOnly from '@/mixins/formBuilding/numOnly.js';
import labelFilter from '@/mixins/formBuilding/labelFilter.js';
import columnSize from '@/mixins/formBuilding/columnSize.js';
import maxLength from '@/mixins/formBuilding/maxLength.js';
import ruleMethod from '@/mixins/formBuilding/ruleMethod.js';

export default {
	name: 'Payments',
	mixins: [
		basicDialogMixin,
		getCarrierProgress,
		numOnly,
		labelFilter,
		columnSize,
		maxLength,
		ruleMethod,
	],
	components: {
		UploadDialog,
	},
	data() {
		return {
			paymentsData: {},
			//
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
			//
			openUploadDialog: false,
			addDocAnimation: false,
			docInfoSuccess: false,
			paymentCityItems: [],
			paymentCountry: ['USA', 'CA', 'MX'],
			citySync: null,
			noaUploaded: false,
			factoringItems: [
				{ text: 'Yes', selection: true },
				{ text: 'No', selection: false },
			],
		};
	},
	computed: {
		states() {
			if(this.paymentsData.country === 'MX') return this.$store.state.mexicoProvinces;
			else if(this.paymentsData.country === 'CA') return this.$store.state.canadaProvinces;
			else return this.$store.state.states;
		},
		auth() {
			return this.$store.state.auth;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status;
		},
		physicalAddress() {
			return this.$store.state.onboardingInfo.addresses.physical;
		},
		paymentInfo() {
			return this.$store.state.payments;
		},
		docInfo() {
			return this.$store.state.onboardingInfo.documents;
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'payments',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.$formValid.payments && this.$formValid.factoring,
			};
		},
	},
	methods: {
		async saveSignupHandler(saveType) {
			let pageType = 'payments';
			let nextPage = 'fleet';

			let payload = {};

			if (!this.paymentsData.isFactoring) {
				const { name, ...theRest } = this.paymentsData;
				payload = theRest;
			} else {
				payload = this.paymentsData;
			}

			const zipCode = /^\d{5}$/;
			const canadianPostcode = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;

			try {
				
				if(this.paymentsData.country !== 'CA' && !zipCode.test(this.paymentsData.postalCode)) {
					throw new Error('For addresses in the USA or Mexico, zip codes must be 5 digits long and only include numbers.');
				}

				if(this.paymentsData.country === 'CA' && !canadianPostcode.test(this.paymentsData.postalCode)) {
					throw new Error('For addresses in Canada, postal codes must be 6 alphanumeric characters long in the following format - A1B2C3.');
				}
				
				
				const { data } = await this.$axios({
					method: 'put',
					url: `${this.$hostName.apiBase}/onboarding/payment`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
				});



				this.$store.commit('updatePayments', data);

				await this.getCarrierProgress();

				if (saveType === 'continue') {
					this.$router.push(`/${nextPage}`);
				} else {
					this.$router.push('/dashboard');
				}
			} catch (error) {
				let errorMsg = error.message || `Unabled to save ${pageType} information - please try again later.`;
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: errorMsg,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		async querySelections(v) {
			const response = await this.$axios({
				method: 'get',
				url: `${this.$hostName.autocompletePath}/api/v0/locations/city?chars=${v}`,
			});
			this.paymentCityItems = response.data.result;
		},
		closeUploadDialog() {
			this.$background.blur = false;
			this.openUploadDialog = false;
			this.addDocAnimation = false;
		},
		async addDocument(val) {
			const formData = new FormData();

			formData.append('Notice of Assignment', val);

			try {
				const response = await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/documents/add`,
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
						Accept: 'application/json',
						Authorization: 'Bearer ' + this.auth.token,
					},
				});
				this.addDocAnimation = true;
				this.noaUploaded = true;

				setTimeout(() => {
					this.addDocAnimation = false;
					this.docInfoSuccess = true;
				}, 2000);

				setTimeout(() => {
					this.docInfoSuccess = false;
					this.closeUploadDialog();
				}, 5000);
			} catch (error) {
				this.noaUploaded = false;
				console.log(error);
			}
		},
		toggleSwitch() {
			if (this.paymentsData.usePhysical) {
				Object.entries(this.physicalAddress).forEach(([key, value]) => {
					if (key === 'city') this.paymentCityItems.push(value);
					if (key === 'zip') {
						this.paymentsData.postalCode = value;
					} else {
						this.paymentsData[key] = value;
					}
				});
			} else {
				const skip = ['isFactoring', 'name', 'email', 'phone', 'extension', 'usePhysical'];
				Object.entries(this.paymentsData).forEach(([key]) => {
					if (key === 'usePhysical') this.paymentsData[key] = false;
					if (!skip.includes(key)) this.paymentsData[key] = '';
				});
			}
		},
		routeContact() {
			this.$router.push('/contact');
		},
	},
	watch: {
		citySync(val) {
			val && val !== this.paymentsData.city && this.querySelections(val);
		},
		'paymentsData.phone': {
			deep: true,
			immediate: true,
			handler(numberInput) {
				numberInput += '';
				numberInput = numberInput.replace(/[^\d\(\)]/g, '');
				numberInput = numberInput
					.replace(
						/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/,
						'$2-$3-$4'
					)
					.replace(/-+$/, '');
				this.paymentsData.phone = numberInput;
			},
		},
	},
	mounted() {
		this.paymentsData = Object.assign({}, this.paymentInfo);
		if (this.paymentsData.city) this.paymentCityItems.push(this.paymentsData.city);
	},
};
</script>

<style scoped>
.gradientBg {
	opacity: 0.3;
	background: linear-gradient(90deg, #121212, #1e1e1e);
	border-radius: 10px;
}
</style>
