<template>
	<v-row style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; height: 100vh; width: 100vw;">
		<v-col cols="12" style="position: relative">
			<v-row class="header__bg">
				<v-col cols="6">
					<v-row>
						<v-col cols="1" class="thickStripe primary lighten-1"></v-col>
						<v-col cols="12" class="thinStripe"></v-col>
						<v-col cols="3" class="thickStripe primary"></v-col>
						<v-col cols="12" class="thickStripe"></v-col>
						<v-col cols="12" class="thickStripe"></v-col>
						<v-col cols="12" class="thickStripe"></v-col>
						<v-col cols="12" class="thickStripe"></v-col>
					</v-row>
				</v-col>
				<v-col cols="6">
					<v-row>
						<v-col cols="10" class="thickStripe"></v-col>
						<v-col cols="2" class="thickStripe grey darken-3"></v-col>
						<v-col cols="12" class="thinStripe"></v-col>
						<v-col cols="7" class="thickStripe"></v-col>
						<v-col cols="5" class="thickStripe primary darken-4"></v-col>
						<v-col cols="12" class="thinStripe"></v-col>
						<v-col cols="8" class="thickStripe primaryBorder"></v-col>
						<v-col cols="4" class="thickStripe"></v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>
<script>

export default {
	name: 'Hero_Image',
	components: {
	},
	props: [],
	mixins: [],
	data() {
		return {

		}
	},
	computed: {

	}
};

</script>
<style scoped>
.col {
	padding: 0px !important;
}
.headerContainer {
	position: relative;
	height: 50vh;
}

.header__bg {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-end;
	align-items: flex-end;
	background-color: #121212;
	transform: skewY(-19deg);
	transform-origin: top left;
}

.thickStripe {
	height: 75px;
}
.thinStripe {
	height: 37.5px;
}
.primaryBorder {
	border: 3px solid #bb86fc;
}
.numberDiv {
	border: 3px solid #fff;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	font-size: 16px;
	display: flex;
	flex-direction: row;
	flex-wrap: 'no-wrap';
	align-content: center;
	align-items: center;
	justify-content: center;
}
.topLevel {
	position: relative;
	z-index: 2;
	width: 100%;
}
</style>