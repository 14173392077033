<template>
	<v-card-text flat>
		<v-container grid-list-md>
			<v-col cols="12">
				<v-row>
					<v-col cols="12" lg="10">
						Please follow these steps:
					</v-col>
					<v-col cols="4">
						<v-card class="glowingCard" min-height="205px" max-height="205px">
							<v-card-title>
								Step 1
							</v-card-title>
							<v-card-text class="text-center">
								<v-icon x-large>mdi-download</v-icon>
								<br>
								Download the contract
							</v-card-text>
							<v-card-actions>
							  <v-btn
								block
								href="assets/Knight-Swift_Logistics_Broker-Carrier_Transportation_Agreement.pdf"
								color="primary darken-4"
								dark
								download
							>
								Download the Contract
								<v-icon class="ml-2">mdi-download</v-icon>
							</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card class="glowingCard" min-height="205px" max-height="205px">
							<v-card-title>
								Step 2
							</v-card-title>
							<v-card-text class="text-center">
								<v-icon x-large>mdi-signature-freehand</v-icon>
								<br>
								Sign the contract
							</v-card-text>
							<v-card-actions class="text-center">
							  Please have the owner or an authorized person sign the contract.
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card class="glowingCard" min-height="205px" max-height="205px">
							<v-card-title>
								Step 3
							</v-card-title>
							<v-card-text class="text-center">
								<v-icon x-large>mdi-upload</v-icon>
								<br>
								Upload the signed file
							</v-card-text>
							<v-card-actions>
								<v-file-input
									v-model="generalContract.file"
									required
									:rules="[$formRules.docValid]"
									show-size
									accept="image/*"
									:label="fileUploadLabel"
									prepend-icon="mdi-upload"
									dense
									dark
									color="primary darken-4"
									hint="Click to select a file"
									persistent-hint
									@change="fileUploadedCheck"
									:clearable="noClear"
								>
									<template v-slot:selection="{ text }">
									  <v-chip
										small
										label
										color="primary darken-4"
									  >
										{{ text }}
									  </v-chip>
									</template>
								</v-file-input>
							</v-card-actions>
						</v-card>
					</v-col>
					<!-- <v-col cols="12" lg="4">
						<v-file-input
							v-model="generalContract.file"
							required
							:rules="[$formRules.docValid]"
							show-size
							accept="image/*"
							label="Please Upload Signed Contract"
							prepend-icon=""
							prepend-inner-icon="mdi-paperclip"
							color="primary"
							hint="Click to Upload Document"
							persistent-hint
							@change="fileUploadedCheck"
							:clearable="noClear"
						>
							<template v-slot:selection="{}">
								<v-chip
									label
									color="primary darken-4"
									slot="activator"
								>
									Signed Contract
								</v-chip>
							</template>
						</v-file-input>
					</v-col> -->
					<v-col cols="12" xl="2" offset-xl="10">
						<v-btn
							block
							color="primary darken-3"
							:disabled="!generalContract.file"
							:loading="loading"
							@click="completeOnboarding"
						>
							Complete the Onboarding Process
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-container>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeDialog"
		/>
	</v-card-text>
</template>
<script>
import basicDialogMixin from "../../../../mixins/basicDialogMixin";
import getCarrierProgress from "@/mixins/getCarrierProgress";

export default {
	mixins: [basicDialogMixin, getCarrierProgress],
	data: () => ({
		generalContract: {
			file: null,
		},
		noClear: false,
		signedDialog: false,
		loading: false
	}),
	computed: {
		//
		auth() {
			return this.$store.state.auth;
		},
		fileUploadLabel() {
			if (!this.generalContract.file) {
				return "No file chosen";
			} else {
				return "";
			}
		}
	},
	methods: {
		fileUploadedCheck(val) {
			const acceptedFileTypes = [
				"image/jpg",
				"image/jpeg",
				"image/png",
				"application/pdf",
			];
			if (val && !acceptedFileTypes.includes(val.type)) {
				this.generalContract.file = null;
				return (this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text:
						"Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.",
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Close",
						color: "#CF6679",
					},
					width: "350",
				});
			}
			if (val) {
				this.fileUploadLabel = "";
			}
		},
		//
		async completeOnboarding() {
			try {
				this.loading = true;
				const formData = new FormData();
				formData.append("file", this.generalContract.file);

				await this.$axios({
					method: "post",
					url: `${this.$hostName.apiBase}/onboarding/contract/complete`,
					data: formData,
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "application/json",
						Authorization: "Bearer " + this.auth.token,
					},
				});

				this.getCarrierProgress();
				this.$store.commit("updateOnboardingStatus", "signed");
				setTimeout(() => {
					this.$emit("signedDialog");
					this.loading = false;
				}, 2000);
			} catch (error) {
				this.loading = false;
				let message = `Signing contract has failed. Please try again later.`;

				if (
					error.response &&
					error.response.data &&
					error.response.data.Error
				) {
					message = error.response.data.Error;
				}
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: message,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Close",
						color: "#CF6679",
					},
					width: "350",
				};
			}
		},
		closeDialog() {
			this.closeBasicDialog();
		},
	},
};
</script>
<style scoped>
.glowingCard {
	box-shadow: 0px 0px 5px 1px #BDBDBD !important;
}
</style>
