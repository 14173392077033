<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="General Information"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>
		<v-col cols="12" md="10" align-self="start" class="pa-0">
			<v-row justify="space-between" align="stretch">
				<v-col :cols="generalColSize" class="justify-center pa-1">
					<v-card height="100%">
						<v-card-text>
							<v-row justify="center" align="start">
								<v-col cols="12">
									<v-form v-model="$formValid.general" ref="general">
										<v-row>
											<template
												v-for="(item, index) in Object.keys(generalData)"
											>
												<v-col
													v-if="item != 'alternates'"
													:key="index"
													:cols="columnSize(item)"
													:class="generalAltCol(item)"
												>
													<v-row
														v-if="
															item != 'intraState' &&
																item !== 'alternates' &&
																item === 'minority'
														"
													>
														<v-select
															v-model="generalData[item]"
															clearable
															:label="labelFilter(item)"
															:items="minorityOptions"
															:rules="selectRule"
															item-text="text"
															item-value="value"
															autocomplete="off"
															persistent-hint
															outlined
															multiple
															filled
															hint="*Required"
														>
														</v-select>
													</v-row>
													<v-text-field
														v-if="
															item != 'intraState' &&
																item !== 'alternates' &&
																item != 'minority'
														"
														v-model="generalData[item]"
														:required="checkRequired(item)"
														persistent-hint
														:hint="
															checkRequired(item)
																? '*Required'
																: 'Optional'
														"
														:rules="ruleMethod(item)"
														:label="labelFilter(item)"
														:maxlength="maxLength(item)"
														@keypress="numOnly($event, item)"
														autocomplete="off"
														outlined
														filled
													>
													</v-text-field>
												</v-col>
											</template>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col :cols="generalColSize" class="justify-center pa-1">
					<v-card height="100%">
						<v-card-text>
							<v-row justify="center" align="start">
								<v-col cols="12">
									<v-form v-model="$formValid.contact" ref="contact">
										<v-row>
											<v-col cols="12" class="text-left pt-0 ma-0 my-3">
												<span class="altContactStyle">
													Owner/Executive Information
												</span>
											</v-col>
											<template
												v-for="(item, index) in Object.keys(
													generalAlternates('corporate')
												)"
											>
												<v-col
													:key="`corporate${index}`"
													:cols="altColSize(item)"
													:class="generalAltCol(item)"
												>
													<v-text-field
														v-if="
															item != 'contactType' &&
																generalData.alternates &&
																item != 'phone'
														"
														v-model="generalData.alternates[0][item]"
														:required="checkRequired(item)"
														persistent-hint
														:hint="
															checkRequired(item)
																? '*Required'
																: 'Optional'
														"
														:rules="ruleMethod(item)"
														:label="altLabelFilter(item, 'corporate')"
														:maxlength="maxLength(item)"
														@keypress="numOnly($event, item)"
														autocomplete="off"
														outlined
														filled
													>
													</v-text-field>
													<v-text-field
														v-if="
															item === 'phone' &&
																generalData.alternates
														"
														v-model="generalData.alternates[0][item]"
														:required="checkRequired(item)"
														persistent-hint
														:hint="
															checkRequired(item)
																? '*Required'
																: 'Optional'
														"
														:rules="ruleMethod(item)"
														:label="altLabelFilter(item, 'corporate')"
														:maxlength="maxLength(item)"
														@keypress="
															changeNum(
																generalData.alternates[0][item],
																'corporate'
															)
														"
														autocomplete="off"
														outlined
														filled
													>
													</v-text-field>
												</v-col>
											</template>
											<v-col cols="6" class="text-left pt-5">
												<span class="altContactStyle">
													Dispatch Information
												</span>
											</v-col>
											<v-col
												cols="6"
												class="pa-0 ma-0"
												v-if="generalData.alternates"
											>
												<v-row align="end" justify="end" class="pa-0 ma-0">
													<v-switch
														v-model="useCorporate"
														@click="toggleSwitch"
														hide-details
													></v-switch>
													Use Corporate Information
												</v-row>
											</v-col>
											<template
												v-for="(item, index) in Object.keys(
													generalAlternates('dispatch')
												)"
											>
												<v-col
													:key="`dispatch${index}`"
													:cols="altColSize(item)"
													:class="generalAltCol(item)"
												>
													<v-text-field
														v-if="
															item != 'contactType' &&
																generalData.alternates &&
																item != 'phone'
														"
														v-model="generalData.alternates[1][item]"
														:required="checkRequired(item)"
														persistent-hint
														:hint="
															checkRequired(item)
																? '*Required'
																: 'Optional'
														"
														:rules="ruleMethod(item)"
														:label="altLabelFilter(item, 'dispatch')"
														:maxlength="maxLength(item)"
														@keypress="numOnly($event, item)"
														autocomplete="off"
														outlined
														filled
													>
													</v-text-field>
													<v-text-field
														v-if="
															item === 'phone' &&
																generalData.alternates
														"
														v-model="generalData.alternates[1][item]"
														:required="checkRequired(item)"
														persistent-hint
														:hint="
															checkRequired(item)
																? '*Required'
																: 'Optional'
														"
														:rules="ruleMethod(item)"
														:label="altLabelFilter(item, 'dispatch')"
														:maxlength="maxLength(item)"
														@keypress="
															changeNum(
																generalData.alternates[1][item],
																'dispatch'
															)
														"
														autocomplete="off"
														outlined
														filled
													>
													</v-text-field>
												</v-col>
											</template>
											<v-col cols="12" class="text-center pt-2 ma-0">
												<span class="hintText">
													TO ADD ADDITIONAL CARRIER CONTACTS/CONTACT
													INFORMATION, PLEASE SEND AN EMAIL TO:
													<a
														href="mailto:CARRIERS@KNIGHTSWIFTLOGISTICS.COM"
														>CARRIERS@KNIGHTSWIFTLOGISTICS.COM</a
													>
												</span>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<!-- What is this for (not sure at the moment)-->
				<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" class="pa-0 gradientBg">
				</v-col>
			</v-row>
		</v-col>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-row>
</template>
<script>
import basicDialogMixin from '@/mixins/basicDialogMixin.js';
import getCarrierProgress from '@/mixins/getCarrierProgress.js';

import numOnly from '@/mixins/formBuilding/numOnly.js';
import labelFilter from '@/mixins/formBuilding/labelFilter.js';
import columnSize from '@/mixins/formBuilding/columnSize.js';
import maxLength from '@/mixins/formBuilding/maxLength.js';
import ruleMethod from '@/mixins/formBuilding/ruleMethod.js';
import { mapState } from 'vuex';
export default {
	name: 'General',
	mixins: [
		basicDialogMixin,
		getCarrierProgress,
		numOnly,
		labelFilter,
		columnSize,
		maxLength,
		ruleMethod,
	],
	data() {
		return {
			generalData: this.$store.state.onboardingInfo.general, //initialize the generalData
			oldGeneralData: JSON.parse(JSON.stringify(this.$store.state.onboardingInfo.general)),
			// oldGeneralData: Object.assign({}, this.$store.state.onboardingInfo.general), // clone obj for comparison on save
			minorityOptions: [
				{ text: 'Veteran', value: 'Veteran' },
				{ text: 'Hispanic', value: 'Hispanic' },
				{ text: 'Pacific Islander', value: 'Pacific Islander' },
				{ text: 'African American', value: 'African American' },
				{ text: 'Asian', value: 'Asian' },
				{ text: 'Native American', value: 'Native American' },
				{ text: 'Women-Owned', value: 'Women-Owned' },
				{ text: 'LGBTQ', value: 'LGBTQ' },
				{ text: 'No, I am not a Minority', value: 'notMinority' },
			],
			//
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
			useCorporate: false,
			infoChanges: null,
			selectRule: [
				(v) =>  v.length > 0 || "Required.",
			],
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status;
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'general',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.$formValid.general && this.$formValid.contact,
			};
		},
		generalColSize() {
			let colSize;
			if (this.$vuetify.breakpoint.smAndDown) colSize = '12';
			if (this.$vuetify.breakpoint.mdAndUp) colSize = '6';
			return colSize;
		},
		generalFax() {
			return this.generalData.fax;
		},
		generalTelephone() {
			return this.generalData.telephone;
		},
	},
	methods: {
		// this isnt going to work for updating mcNumber requirement
		checkRequired(item) {
			let required = false;
			if (
				item != 'website' &&
				item != 'fax' &&
				item != 'scacNo' &&
				item != 'doingBusinessAsName' &&
				item != 'extension'
			) {
				required = true;
			} else {
				required = false;
			}
			return required;
		},
		async saveSignupHandler(saveType) {
			const pageType = 'general';
			const nextPage = 'addresses';
			const payload = {
				contact: {
					emailAddress: this.generalData.emailAddress,
					legalName: this.generalData.legalName,
					telephone: this.generalData.telephone,
					doingBusinessAsName: this.generalData.doingBusinessAsName,
					extension: this.generalData.extension,
					fax: this.generalData.fax,
					website: this.generalData.website,
					alternates: this.generalData.alternates,
				},
				info: {
					minority: this.generalData.minority,
					taxId: this.generalData.taxId,
					scacNo: this.generalData.scacNo,
				},
			};
			try {
				// COMPARE OLD VS NEW INFO AND TRACK CHANGES TO BE ADDED TO ACTIVITY LOG
				this.infoChanges = this.compareGeneral(this.oldGeneralData, this.generalData).join('<br/>');

				const { data } = await this.$axios({
					method: 'put',
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
				});

				data.general = { ...data.contact, ...data.info };

				this.$store.commit('updateGeneralInfo', data.general);

				// IF CARRIER HAS ALREADY SIGNED CONTRACT
				// LOG THE UPDATE TO THEIR ACTIVITY LOG IN LOOP
				if (this.carrierStatus !== 'incomplete') {
					try {
						let activityData = {
							uuid: this.auth.uuid,
							username: this.userInfo.email.toUpperCase(),
							displayName: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
							activityType: 'CARRIER UPDATE',
							notes: this.infoChanges
						};

						const response = await this.$axios({
							method: 'POST',
							url: `${this.$hostName.apiBase}/onboarding/loop/activities/create`,
							data: activityData
						})
						this.infoChanges = null;
					} catch (err) {
						console.error(err);
						throw new Error(err);
					}
				}

				await this.getCarrierProgress();

				// IF SUCCESS PUSH FWD
				if (saveType === 'continue') {
					this.$router.push(`/${nextPage}`);
				} else if (saveType === 'nothing') {
					// do nothing
				} else {
					this.$router.push('/dashboard');
				}
			} catch (error) {
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: `Unable to save ${pageType} information - please try again later.`,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		generalAlternates(type) {
			// CHECK IF ALTERNATE CONTACTS EXIST
			if (this.generalData.alternates && this.generalData.alternates.length > 0) {
				const [corporate, dispatch] = this.generalData.alternates;
				if (type === 'dispatch') return dispatch;
				if (type === 'corporate') return corporate;
			} else {
				// IF NOT, CREATE EMPTY OBJECTS
				const corporateObj = {
					contactType: 'Corporate',
					title: '',
					name: '',
					email: '',
					phone: '',
				};
				const dispatchObj = {
					contactType: 'Dispatch',
					name: '',
					email: '',
					phone: '',
				};
				this.generalData.alternates.push(corporateObj);
				this.generalData.alternates.push(dispatchObj);
			}
		},
		generalAltCol(key) {
			if (key === 'contactType' || key === 'alternates') return 'pa-0 ma-0';
			return 'py-2 px-2';
		},
		altLabelFilter(item, altType) {
			if (altType === 'dispatch') {
				let text;
				switch (item) {
					case 'name':
						text = 'Dispatch Name';
						break;
					case 'email':
						text = 'Dispatch Email';
						break;
					case 'phone':
						text = 'Dispatch Phone';
						break;
					default:
						text = item;
				}
				return text;
			}
			if (altType === 'corporate') {
				let text;
				switch (item) {
					case 'name':
						text = 'Corporate Name';
						break;
					case 'title':
						text = 'Title (CEO, President, etc)';
						break;
					case 'email':
						text = 'Corporate Email';
						break;
					case 'phone':
						text = 'Corporate Phone';
						break;
					default:
						text = item;
				}
				return text;
			}
			return item;
		},
		altColSize(item) {
			let column = '12';
			switch (item) {
				case 'name':
					column = '12';
					break;
				case 'title':
					column = '12';
					break;
				case 'email':
					column = '6';
					break;
				case 'phone':
					column = '6';
					break;
				default:
					column = item;
			}
			return column;
		},
		changeNum(number, altType) {
			number += '';
			number = number.replace(/[^\d\(\)]/g, '');
			number = number
				.replace(/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/, '$2-$3-$4')
				.replace(/-+$/, '');
			if (altType === 'corporate') this.$set(this.generalData.alternates[0], 'phone', number);
			if (altType === 'dispatch') this.$set(this.generalData.alternates[1], 'phone', number);
		},
		toggleSwitch() {
			const [corporate, dispatch] = this.generalData.alternates;
			if (this.useCorporate) {
				if (corporate.name) dispatch.name = corporate.name;
				if (corporate.phone) dispatch.phone = corporate.phone;
				if (corporate.email) dispatch.email = corporate.email;
			} else {
				dispatch.name = '';
				dispatch.phone = '';
				dispatch.email = '';
			}
		},
		compareGeneral(oldData, newData) {
			let diff = [];
			var i = 0;
			for (i in oldData) {
				// Handle general data - NOT minority or alternates contact info
				if (i !== 'alternates' && i !== 'minority') {
					if (oldData[i] != newData[i]) {
						diff.push(`Updated ${i} from: "${oldData[i]}" to: "${newData[i]}"`);
					}
				// Handle minority/diversity info
				} else if (i === 'minority') {
					let oldMinority = JSON.stringify(oldData['minority']);
					let newMinority = JSON.stringify(newData['minority']);
					// check if the minority values are different
					if (oldMinority !== newMinority) {
						diff.push(`Updated diversity from: "${oldMinority}" to: "${newMinority}"`);
					}
				}
				// Handle nested alternates contact info
				 else {
				 	let key;
					let oldAlternates = oldData['alternates'];
					let newAlternates = newData['alternates'];

					// loop through each key and compare values
					for (key in oldAlternates[0]) {
						// Compare old vs new corporate info
						if (oldAlternates[0][key] != newAlternates[0][key]) {
							diff.push(`Updated Corporate ${key} from ${oldAlternates[0][key]} to ${newAlternates[0][key]}`);
						}
					}

					// loop through each key and compare values
					for (key in oldAlternates[0]) {
						// Compare old vs new dispatch info
						if (oldAlternates[1][key] != newAlternates[1][key]) {
							diff.push(`Updated Dispatch ${key} from ${oldAlternates[1][key]} to ${newAlternates[1][key]}`);
						}
					}
				}
			}
			return diff;
		},
	},
	watch: {
		generalTelephone(numberInput) {
			//number input is initially null
			if (numberInput) {
				numberInput = numberInput.replace(/[^\d\(\)]/g, '');
				numberInput = numberInput
					.replace(
						/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/,
						'$2-$3-$4'
					)
					.replace(/-+$/, '');
				this.generalData.telephone = numberInput;
			}
		},
		generalFax(numberInput) {
			// numberInput += '';
			if (numberInput) {
				numberInput = numberInput.replace(/[^\d\(\)]/g, '');
				numberInput = numberInput
					.replace(
						/^\s*(1?)[-\s\(]*(\d{0,3})[\)-\s]*(\d{0,3})[-\s]*(\d{0,4}).*$/,
						'$2-$3-$4'
					)
					.replace(/-+$/, '');
				this.generalData.fax = numberInput;
			}
		},
	}
};
</script>
<style scoped>
.gradientBg {
	opacity: 0.3;
	background: linear-gradient(90deg, #121212, #1e1e1e);
	border-radius: 10px;
}

.altContactStyle {
	font-weight: 600;
	font-size: 1.4rem;
}

.hintText {
	color: #bb86fc;
}
</style>
