<template>
	<v-row>
		Apply to provide this service and gain access to more freight options. Our carrier relations team will recieve and review your request. After review we will notify you of your approval status.
	</v-row>
</template>

<script>
export default {
	name: 'Service_Dialog_AddServiceText',
	props: {
	},
	data() {
		return {
			//
		}
	},
	methods: {
		//
	},
	computed: {
		//
	},
};
</script>

<style scoped>
/**/
</style>