<template>
	<v-col>
		<v-row>
			<v-col cols="12">
				<span>
					Information:
				</span>
			</v-col>
		</v-row>
		<v-row>		
			<v-col cols="12">
				<ul>
					<li class="mt-1">
						Please note that only personel with the title <b>owners</b>,
						<b>corporate manager</b>, or <b>operation manager</b> has the
						authority to sign the contract.
					</li>
					<li class="mt-2">
						If you are not an <b>owner</b>, <b>corporate manager</b>, or
						<b>operations manager</b>, please download the contract and have
						it signed by a person of authority. Once signed, please upload
						the contract so we may begin proccessing the document.
					</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
	name: "Signing_Contracts_Assistance",
	data() {
		return {};
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>
