<template>
	<v-form v-model="$formValid.powerOnly" ref="powerOnlyValid">
		<!-- POWERONLY VALIDATION {{$formValid.powerOnly}} -->
		<v-row>
			<v-col cols="12" class="pt-1 px-0">
				<v-row v-if="authorized">
					* In order to be eligible for this service you must review
					and sign our Power Only - Equipment Rental Agreement.
				</v-row>
				<v-row v-else>
					* In order to be eligible for this service you must review
					and sign our Power Only - Equipment Rental Agreement.
					Please download the contract pdf for an authorized user to
					sign, once signed please upload your document to complete
					the onboarding proccess.
				</v-row>
			</v-col>
			<v-col cols="12" class="px-0 pt-1">
				<p class="font-weight-bold grey darken-3">
					* In addition to the required cargo & liability coverage, if
					your entity hauls power-only we strongly suggest you reach
					out to your insurance agent to assess towing, storage &
					recovery coverages as your entity will be responsible for
					this in the event of a claim.
				</p>
			</v-col>

			<!--
				Authorized users company role is owner, manager, operations etc
			-->
			<v-col cols="12" class="px-0" v-if="authorized">
				<v-row class="pt-3 pb-1" justify="end">
					<v-col cols="12" class="pa-0">
						<ContractText
							:day="day"
							:month="month"
							:year="year"
							:carrierInfo="carrierInfo"
							@viewPowerOnly="$emit('viewPowerOnly', $event)"
						></ContractText>
					</v-col>
					<v-col cols="12">
						<v-divider></v-divider>
					</v-col>
					<v-col cols="12" class="pa-0">
						<v-row>
							<v-col cols="12" class="pa-0 ma-0 pt-4">
								<v-checkbox
									v-model="acceptedTerms"
									:rules="[$formRules.required]"
									required
									@input="
										updatePowerOnlyFields(
											$event,
											'acceptedTerms'
										)
									"
									label="I accept these terms"
									class="pa-0 ma-0"
								>
								</v-checkbox>
							</v-col>
							<v-col cols="12" md="4" class="pa-0 px-1">
								<v-text-field
									v-model="firstName"
									:rules="[$formRules.required]"
									label="Legal First Name"
									required
									@input="
										updatePowerOnlyFields(
											$event,
											'firstName'
										)
									"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4" class="pa-0 px-1">
								<v-text-field
									v-model="middleInit"
									maxlength="1"
									label="Legal Middle Ini."
									hint="Optional"
									@input="
										updatePowerOnlyFields(
											$event,
											'middleInit'
										)
									"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" md="4" class="pa-0 px-1">
								<v-text-field
									v-model="lastName"
									:rules="[$formRules.required]"
									label="Legal Last Name"
									required
									@input="
										updatePowerOnlyFields(
											$event,
											'lastName'
										)
									"
								>
								</v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>

			<!--
				Unauthorized users company role is not owner, manager, operations etc
			-->
			<v-col cols="12" class="px-0" v-else>
				<v-row>
					<v-btn
						block
						rounded
						outlined
						href="assets/K-S Logistics - Equipment Rental Agreement.pdf"
						color="primary"
						download
					>
						Download Contract PDF
						<v-icon class="ml-2">mdi-cloud-download</v-icon>
					</v-btn>
				</v-row>
				<v-row class="mt-8" justify="end">
					<v-btn
						@click="initFileUpload()"
						rounded
						small
						dark
						color="secondary"
						outlined
					>
						Upload Document
					</v-btn>
				</v-row>
				<v-row
					class="pt-3"
					justify="start"
					v-if="file && file.size < 19922944"
				>
					<v-icon v-if="!selectedFile" small color="#BDBDBD"
						>mdi-checkbox-blank-circle-outline</v-icon
					>
					<v-icon v-if="selectedFile" small color="secondary"
						>mdi-check-circle</v-icon
					>
					<span class="ml-2">Selected Power Only Contract</span>
				</v-row>
				<v-row
					class="pt-3"
					justify="start"
					v-if="file && file.size > 19922944"
				>
					<v-icon color="#FFA12F" class="mr-2">mdi-alert</v-icon>
					<span>Selected File is too Large - Max File size 19MB</span>
				</v-row>
			</v-col>
		</v-row>
		<input
			@input="selectFile()"
			type="file"
			accept="image/png, image/jpeg, application/pdf"
			id="uploadNewDoc"
			style="display: none"
			name="TEST"
		/>
		<v-text-field
			v-model="selectedFile"
			style="display: none"
			:required="!authorized ? true : false"
			:rules="!authorized ? [$formRules.required] : []"
		></v-text-field>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-form>

	<!-- @click="signTrailerContract" <<< this was the method called to have auth sign -->
	<!-- @click="completePoaSignature" <<< this was the method called to have unauth sign-->
</template>

<script>
import moment from "moment";
import basicDialogMixin from "@/mixins/basicDialogMixin";

import ContractText from "./PowerOnlyText";

export default {
	name: "Service_Dialog_PowerOnlyPrompt",
	mixins: [basicDialogMixin],
	props: {
		authorized: { type: Boolean, default: false },
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
		serviceDialogType: { type: String, default: null },
	},
	components: {
		ContractText,
	},
	data() {
		return {
			file: null,
			selectedFile: false,
			acceptedTerms: false,
			firstName: null,
			middleInit: null,
			lastName: null,
			day: moment().format("Do"),
			month:moment().format("MMMM"),
			year: moment().format("YYYY"),
		};
	},
	methods: {
		initFileUpload() {
			const input = document.getElementById("uploadNewDoc");
			input.click();
		},
		selectFile() {
			const acceptedFileTypes = [
				"image/jpg",
				"image/jpeg",
				"image/png",
				"application/pdf",
			];
			this.file = document.getElementById("uploadNewDoc").files[0];

			if (!acceptedFileTypes.includes(this.file.type)) {
				this.file = null;
				this.selectedFile = false;
				document.getElementById("uploadNewDoc").value = "";
				return (this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text:
						"Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.",
					hasAction: true,
					actionButton: {
						outlined: true,
						action: "close",
						text: "Close",
						color: "#CF6679",
					},
					width: "350",
				});
			}

			if (this.file.size < 19922944) {
				this.selectedFile = true;
				this.$emit("selectedFile", this.file);
			} else {
				this.selectedFile = false;
				this.$emit("fileTooLarge");
			}
		},
		updatePowerOnlyFields() {
			this.$emit("updatePowerOnlyFields", {
				firstName: this.firstName,
				middleInit: this.middleInit,
				lastName: this.lastName,
				acceptedTerms: this.acceptedTerms,
			});
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		carrierInfo() {
			return this.$store.state.onboardingInfo;
		}
	},
};
</script>

<style scoped>
.carbDocText {
	color: var(--v-secondary-base);
}
</style>
