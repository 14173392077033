<template>
	<v-dialog
		:value="value"
		persistent
		:fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
		:width="$vuetify.breakpoint.mdAndUp ? '50vw' : ''"
	>
		<v-row style="background-color: #1e1e1e" class="fill-height">
			<v-col cols="12" align-self="start">
				<v-row justify="space-between" align="center">
					<v-col cols="11" :style="titleFontSize">
						<span>Power Only Information</span>
					</v-col>
					<v-col cols="1" class="pa-0 ma-0 text-right">
						<v-btn class="pa-0 ma-0" @click="closeHelpDialog" icon>
							<v-icon color="primary darken-1">mdi-close-thick</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" align-self="end">
				<v-col cols="12">
					For detailed information on how to haul Power Only, please view one of the videos below.
				</v-col>
				<v-col cols="12">
					<a href="https://youtu.be/doLjnQ67heA" target="_blank">Power Only (English)</a>
				</v-col>
				<v-col cols="12">
					<a href="https://youtu.be/EWqcOx6t91c" target="_blank">Power Only (Spanish)</a>
				</v-col>
				<v-col cols="12">
					<v-row> Thank you, </v-row>
					<v-row class="ml-2"> - Knight-Swift Carrier Relations </v-row>
				</v-col>
			</v-col>
		</v-row>
	</v-dialog>
</template>
<script>
export default {
	props: {
		value: { type: Boolean, default: false },
	},
	components: {
		//
	},
	data() {
		return {
			//
		};
	},
	computed: {
		titleFontSize() {
			let style = {};
			if (this.$vuetify.breakpoint.xsOnly) style['font-size'] = '18px';
			if (this.$vuetify.breakpoint.smOnly) style['font-size'] = '28px';
			if (this.$vuetify.breakpoint.mdOnly) style['font-size'] = '30px';
			if (this.$vuetify.breakpoint.lgAndUp) style['font-size'] = '32px';

			return style;
		},
	},
	methods: {
		async closeHelpDialog() {
			this.$background.blur = false;
			this.$emit('input');
		}
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped></style>
