<template>
	<v-card-text flat>
		<v-container grid-list-md>
			<ContractTemplate @viewContract="viewContract"/>
			<v-row>
				<v-col cols="12">
					<v-form ref="form" v-model="formValid">
						<v-row>
							<v-col cols="12" class="pb-2">
								<ul>
									<li>
										<span style="font-weight: 600">
											Only Owners can e-Sign contract with carriers that have less than 20 trucks.*
										</span>
									</li>
									<li>
										<span style="font-weight: 600">
											Owner or Ops Manager/Director can e-sign contract with carriers that have 20 or more trucks.*
										</span>
									</li>
								</ul>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" lg="3">
								<v-text-field
									type="text"
									hint="*Required"
									class="pa-0 ma-0"
									label="First Name"
									required
									outlined
									filled
									:rules="[$formRules.required]"
									v-model="firstName"
									autocomplete="off"
									persistent-hint
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="2" xl="1">
								<v-text-field
									type="text"
									persistent-hint
									hint="(Optional)"
									class="pa-0 ma-0"
									label="Middle Initial"
									outlined
									filled
									maxlength="1"
									v-model="middleInitial"
									autocomplete="off"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									type="text"
									hint="*Required"
									class="pa-0 ma-0"
									label="Last Name"
									required
									outlined
									filled
									:rules="[$formRules.required]"
									v-model="lastName"
									autocomplete="off"
									persistent-hint
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3" xl="2">
								<v-text-field
									type="text"
									hint="*Required"
									class="pa-0 ma-0"
									label="Title"
									required
									outlined
									filled
									:rules="[$formRules.required]"
									v-model="title"
									autocomplete="off"
									persistent-hint
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field class="pa-0 ma-0" label="Today's Date" :value="today" readonly disabled></v-text-field>
							</v-col>
							<v-col cols="6" class="d-flex justify-end">
								<v-checkbox
									label="I accept the terms and conditions."
									required
									v-model="acceptTerms"
									:rules="[$formRules.required]"
								></v-checkbox>
							</v-col>
							<v-col cols="12" xl="2" offset-xl="10">
								<v-btn
									block
									color="primary darken-3"
									:disabled="!formValid"
									:loading="loading"
									@click="signContract"
								>
									Click to E-Sign
									<v-icon class="ml-2">mdi-file-document-edit-outline</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
        <basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeDialog" />
	</v-card-text>
</template>

<script>
import moment from 'moment'

import basicDialogMixin from '@/mixins/basicDialogMixin'
import getCarrierProgress from '@/mixins/getCarrierProgress'

import ContractTemplate from './contractTemplate'

export default {
    components: {
		ContractTemplate
	},
    mixins: [
    	basicDialogMixin,
    	getCarrierProgress
    ],
	data: () => ({
		formValid: false,
		acceptTerms: false,
		title: null,
		firstName: null,
		middleInitial: null,
		lastName: null,
		today: moment().format('MM/DD/YYYY'),
		dialogNav: true,
		noClear: false,
		loading: false
	}),
	computed: {
		auth() {
			return this.$store.state.auth;
		},
	},
	methods: {
		async signContract() {
			try {
				this.loading = true;

				let postObj = {
					acceptedTerms: this.acceptTerms,
					firstName: this.firstName,
					middleInit: this.middleInitial || null,
					lastName: this.lastName,
					signDate: new Date().toISOString(),
					title: this.title
				};

				const response = await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/contract/sign`,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
					data: postObj,
				});

				this.getCarrierProgress();
				this.$store.commit('updateOnboardingStatus', 'signed');
				setTimeout(() => {
					this.$emit('signedDialog');
					this.loading = false;
				}, 2000)


			} catch (error) {
				this.loading = false;
				let message = `Signing contract has failed. Please try again later.`;

				if (error.response && error.response.data && error.response.data.Error) {
					message = error.response.data.Error;
				}
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: message,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		viewContract(val) {
			this.$emit('viewContract', val)
		},
		closeDialog() {
            this.closeBasicDialog();
        }
	}
};
</script>

<style scoped>
/* */
</style>
