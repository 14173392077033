<template>
	<v-row>
		<v-col cols="12" class="py-0 px-5">

			<!-- title section -->
			<v-row justify="center">
				<v-col cols="12" sm="10">
					<v-card class="px-4">
						<v-row>
							<v-col class="text-center">
								<v-row>
									<v-col cols="12" class="text-left" style="font-size: 2em">
										<span >Have a</span>
										<span class="ml-2" style="color: #BB86FC;">Question?</span>
									</v-col>
									<v-col cols="12" class="text-left">
										<span>Contact Us at Knight-Swift Logistics</span>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>


			<!-- content section -->
			<v-row justify="center">
				<v-col cols="12" sm="10">
					<v-card class="px-4">
						<v-card-text>
							<v-row>
								<v-col cols="12" md="6" :style="borderStyle" class="px-0">
									<v-row align="center" justify="center">
										<template v-for="(item, index) in items">
											<v-col :key="index" cols="12" class="pa-0">
												<v-row>
													<v-col cols="1" class="pa-0">
														<v-row justify="center" align="center" class="fill-height">
															<v-icon v-if="item.type === 'email'" :small="$vuetify.breakpoint.smAndDown">fas fa-paper-plane</v-icon>
															<v-icon v-else :small="$vuetify.breakpoint.smAndDown">fas fa-phone-volume</v-icon>
														</v-row>
													</v-col>
													<v-col cols="11" class="px-0">
														<v-row>
															<v-col cols="12" class="pa-0" style="font-size: 1.5em">
																<a v-if="item.type === 'phone'" :href="'tel:+' + item.contact">{{ item.contact }}</a>
																<span v-if="item.type === 'email'">{{ item.contact }}</span>
															</v-col>
															<v-col cols="12" class="pa-0">
																{{ item.name }}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</v-col>
										</template>
									</v-row>
								</v-col>
								<v-col cols="12" md="6" class="px-0">
									<v-form v-model="valid" ref="emailForm">
										<v-row justify="center">
											<v-col cols="10" class="px-0">
												<v-row style="font-size: 1.5em">Send Us a Message...</v-row>
												<v-row v-if="this.authorized === false">
													<v-col cols="6" class="pl-0">
														<v-text-field
															v-model="name"
															label="Name"
															required
															:rules="[$formRules.required]"
														></v-text-field>
													</v-col>
													<v-col cols="6" class="pr-0">
														<v-text-field
															v-model="cc"
															label="Email"
															required
															:rules="[$formRules.required, $formRules.email]"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-text-field
														v-model="subject"
														label="Subject"
														required
														:rules="[$formRules.required]"
													></v-text-field>
												</v-row>
												<v-row>
													<v-textarea
														v-model="text"
														required
														:rules="[$formRules.required]"
														label="Questions, comments, concerns ..."
													></v-textarea>
												</v-row>
												<v-row justify="end">
													<v-btn
														:disabled="!valid || loading"
														:loading="loading"
														@click="sendTheEmail"
														color="primary darken-2"
													>Send Mail</v-btn>
												</v-row>
											</v-col>
										</v-row>
									</v-form>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog"  @close="closeBasicDialog" />
	</v-row>
</template>

<script>
import basicDialogMixin from '@/mixins/basicDialogMixin.js';

export default {

	data: () => ({
		subject: '',
		text: '',
		name: '',
		cc: '',
		loading: false,
		valid: false,
		items: [
			{
				name: 'Knight Swift Carrier Relations',
				contact: '(800) 558-5623',
				type: 'phone'
			},
			{
				name: 'Carrier Relations E-Mail',
				contact: 'carriers@knightswiftlogistics.com',
				type: 'email'
			},
		],
	}),
	mixins: [basicDialogMixin],
	computed: {
		//
		borderStyle() {
			let style;
			if(this.$vuetify.breakpoint.mdAndUp) {
				style = {
					"border-right": "1px solid #bdbdbd"
				}
			} else {
				style = { "border-bottom": "1px solid #bdbdbd" }
			}
			return style
		},
		auth() {
			return this.$store.state.auth;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		authorized() {
			let authorized;
			if (this.auth.loggedIn) authorized = true;
			else authorized = false;

			return authorized;
		},
	},
	methods: {
		//
		highlightColor(type) {
			let style;
			if(type === 'email') {
				style = {
					color: '#bdbdbd'
				}
			}
			return style;
		},
		async sendTheEmail() {

			let title;
			let text;
			this.loading = true;

			try {

				if(this.auth.loggedIn === true) {
					this.name = this.userInfo.firstName;
					this.cc = this.userInfo.email;
				}
				const payload = {
					subject: this.subject,
					text: this.text + ' - ' + this.name,
					cc: this.cc
				}

				await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/contactUs`,
					data: payload
				})

				this.$refs.emailForm.reset();

				title = "Your message has been sent!";
				text = "Thank you for reaching out! You will be contacted as soon as possible."

			} catch (error) {

				title = "We\'ve encountered a problem";
				text = "We apologize for the inconvenience. Please try again later";
				console.log(error)

			} finally {
				this.basicDialog.value = true;
				this.basicDialog.title = title;
				this.basicDialog.text = text;
				this.basicDialog.width = "350";
				setTimeout(() => {
					this.basicDialog.value = false;
					this.basicDialog.title = null;
					this.basicDialog.text = null;
				}, 5000)
				this.loading = false;
			}
		},
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>

<style scoped>
/* */
a:link {
	text-decoration: none;
}
a:hover {
	text-decoration: underline;
}
</style>
