<template>
	<v-row class="contractScroll text-justify" style="border: 3px solid #000">
		<v-col cols="12" class="font-weight-bold">
			BROKER/CARRIER TRANSPORTATION AGREEMENT
		</v-col>
		<v-col cols="12" class="pt-4">
			THIS BROKER/CARRIER TRANSPORTATION AGREEMENT, including Appendix A
			and any subsequent appendices, addenda, exhibits or schedules
			(together, the "AGREEMENT"), is made and entered on
			{{ today }} by and between {{ onboardingInfo.general.legalName }}
			("CARRIER") and Knight Logistics, LLC and Swift Logistics, LLC
			(individually as applicable "BROKER").
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong>RECITALS</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			WHEREAS, CARRIER is a motor carrier of property duly authorized by
			all applicable state, provincial, or federal authorities to provide
			compensated contract carriage of property for shippers and receivers
			of regulated and nonregulated property and provides transportation
			services and related services in the U.S. and, where applicable,
			Canada or Mexico
		</v-col>
		<v-col cols="12" class="pt-4">
			WHEREAS, BROKER is a transportation broker, duly authorized by the
			U.S. Department of Transportation ("DOT"), to arrange for the
			transportation of property by contract motor carriers on behalf of
			shippers and receivers.
		</v-col>
		<v-col cols="12" class="pt-4">
			WHEREAS, CARRIER recognizes the special, distinct, varying and
			continuing transportation needs of BROKER and its shippers, vendors
			and receivers ("CUSTOMERS"), and in order to serve a portion of those
			transportation needs, CARRIER desires to provide contract carriage
			and related services to BROKER pursuant to the terms of this
			AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong>AGREEMENT</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			1. EFFECTIVE DATE AND TERM. This AGREEMENT is to become effective on
			the date first written above, or to the extent applicable, upon the
			date which CARRIER and BROKER commenced doing business together,
			whichever is earlier, and shall remain in effect for a period of one
			year from such date, and shall automatically renew from year to year
			thereafter, subject to the right of either party to terminate this
			AGREEMENT at any time upon thirty (30) days advance written notice
			to the other party, except as otherwise provided herein.
		</v-col>
		<v-col cols="12" class="pt-4">
			2. SCOPE OF AGREEMENT. This AGREEMENT shall govern all shipments
			tendered to CARRIER by BROKER (or upon BROKER's instructions), and
			accepted by CARRIER, whether regulated or non-regulated property, in
			interstate, intrastate, or international transportation. The Parties
			expressly acknowledge and agree that the terms of this AGREEMENT,
			Knight-Swift Logistics Accessorial Charges Exhibit and any addendums
			incorporated herein, shall apply to all shipments tendered to
			CARRIER and shall control over any conflicting terms contained in:
			(i) the CARRIER's tariffs, circulars, rate sheets or service guides;
			or (ii) any bill of lading, shipping document, receipt or other
			transportation document issued for any shipment tendered by BROKER.
		</v-col>
		<v-col cols="12" class="pt-4">
			3. STATUS OF PARTIES. The relationship of CARRIER to BROKER shall,
			at all times, be that of an independent contractor. Nothing herein
			shall be construed as establishing an agency, partnership, joint
			venture, hiring or any form of employer-employee relationship between
			BROKER and CARRIER. Neither party shall be responsible for any debts,
			obligations or liabilities incurred by the other in performance of
			its business activities, except as expressly provided herein. CARRIER
			assumes full responsibility for the payment of all: (i) wages, fees,
			local, state, federal, and provincial payroll taxes, (ii)
			contributions or taxes for unemployment insurance, workers'
			compensation insurance and/or claims, (iii) pensions, and other social
			security or related protection, and (iv) any and all other expenses
			with respect to the persons engaged by CARRIER in the performance of
			transportation and related services in connection with this AGREEMENT
			("Expenses"). CARRIER shall indemnify, defend and hold BROKER and its
			CUSTOMERs harmless against all claims related to or arising from such
			Expenses. Notwithstanding the foregoing provisions, BROKER shall be
			the agent for the CARRIER for the limited and express purpose of
			billing and collecting freight charges and fees from shippers and
			receivers, and CARRIER hereby appoints BROKER as its agent for such
			express and limited purpose. CARRIER further agrees that a Shipper's
			insertion of BROKER's name as the carrier on a bill of lading shall
			be for the shipper's convenience only and shall not change BROKER's
			status as a property broker nor CARRIER's status as a motor carrier.
			This AGREEMENT does not bind either party to mutually exclusive
			services with each other. Both the BROKER and CARRIER understand
			and agree that BROKER will enter similar agreements with other
			carriers, and CARRIER may enter similar agreements with other brokers
			and/or shippers.
		</v-col>
		<v-col cols="12" class="pt-4">
			4. FREIGHT RATES. For all shipments tendered by BROKER and accepted by
			CARRIER under this AGREEMENT, the rates, charges, and fees for the
			transportation and services shall be set forth in a Load or Rate
			Confirmation Sheet or similar document ("Confirmation Sheet") in a form
			provided by BROKER. Each Confirmation Sheet shall be issued by BROKER,
			shall be deemed accepted by CARRIER upon the written (includes
			electronic means of transmission) response by the CARRIER, and shall be
			incorporated into this AGREEMENT, provided; however, that if the terms
			and conditions specified in this AGREEMENT are different from the terms
			and conditions contained in the Confirmation Sheet, the terms and
			conditions of this AGREEMENT shall prevail. CARRIER agrees and
			acknowledges that CARRIER's dispatchers and other personnel are
			authorized to enter into Confirmations with BROKER. The rates and
			charges included in the Confirmation Sheet shall be CARRIER's sole and
			exclusive compensation for rendering the services. CARRIER agrees that
			any other rates, tariffs, circulars, pricing authorities or other
			similar documents published or offered by CARRIER shall not apply to
			the performance of services under this AGREEMENT. Such written
			Confirmation Sheet shall include the charges for the shipment and shall
			also contain, as applicable, the conditions and any additional or
			accessorial services required to be performed. The Confirmation Sheet
			shall be sent by BROKER to CARRIER prior to CARRIER picking up a
			shipment. CARRIER represents and warrants that there are no other
			applicable rates or charges applicable to the Services herein,
			including those contained in any tariff, terms and conditions, or bill
			of lading, except those established in this AGREEMENT or any
			Confirmation Sheet. CARRIER shall advise BROKER at or before incurring
			Detention, Accessorial (including Lumpers), and/or additional charges
			("Charges"), otherwise the CARRIER forfeits its right to collect
			such Charges. Such Charges may be established verbally in order to
			meet specific shipping schedules, but must be confirmed in writing
			within 24 hours, via fax or email, in order for the CARRIER to
			receive compensation for the Charges. CARRIER hereby waives its right
			to obtain copies of BROKER's records as provided for under 49 C.F.R.
			Part 371. Notwithstanding the foregoing, to the extent that CARRIER
			obtains records set forth in 49 C.F.R. § 371.3 by any means
			whatsoever, CARRIER agrees to refrain from utilizing such records in
			negotiating for the provision of services with any third party,
			including CUSTOMERS.  CARRIER further agrees and understands that all
			such records comprise BROKER's confidential information and
			trade-secrets.  Nothing in this section is intended to relieve CARRIER
			of any other obligations imposed upon it by this Agreement, or to limit
			any rights of BROKER to enforce such obligations.
		</v-col>
		<v-col cols="12" class="pt-4">
			5. PAYMENT. BROKER shall pay CARRIER for the transportation of
			property under this AGREEMENT in accordance with the shipping rates
			as established in any Confirmation Sheet no later than thirty (30) days
			from receipt by BROKER of CARRIER's (1) Confirmation Sheet, (2)
			uncontested invoice, (3) bill of lading, and (4) proof of delivery
			covering such transportation and services.
			CARRIER agrees that it shall bill BROKER for all
			services in a timely, accurate and complete manner. If, after
			shipment of property under this AGREEMENT, the party responsible for
			payment of freight charges and fees defaults on its obligation to
			pay BROKER for freight charges and fees which BROKER has already
			paid to CARRIER, CARRIER agrees that all its right, title and
			interest in such charges and fees shall be, and hereby are,
			transferred and assigned to BROKER for purposes of collection and
			recovery from the responsible party(s). CARRIER shall look solely to
			BROKER for payment of freight bills and agrees to hold the CUSTOMER
			harmless therefrom, and CARRIER agrees to refrain from all
			collection efforts against any other party unless Broker has not
			paid Carrier an undisputed invoice in accordance with terms and
			conditions contained herein. The parties shall process all overcharge,
			undercharge, and duplicate payments as provided in 49 C.F.R. §378 and
			49 U.S.C. §13710, as amended from time to time.
		</v-col>

		<v-col cols="12" class="pt-4">
			6. SERVICES. Subject to specific shipment instructions, CARRIER shall
			provide motor transportation services for BROKER and its CUSTOMERS, as
			a motor carrier operating under CARRIER’s DOT motor carrier authority.
			CARRIER shall transport freight with reasonable dispatch in accordance
			with the terms of the Confirmation Sheet in a safe, competent, and
			efficient manner. CARRIER will promptly notify BROKER of any delay.
			CARRIER will protect and preserve all cargo in accordance with prudent
			industry standards. CARRIER will be responsible for any charges imposed
			by a CUSTOMER that were caused by actions of the CARRIER, including
			charges for unreasonable delay.
		</v-col>
		<v-col cols="12" class="pt-4">
			7. EQUIPMENT INTERCHANGE COMPLIANCE. CARRIER warrants that prior to
			entering a port facility, off site container yard or rail yard to pick
			up or deliver equipment ("Interchanged Equipment") in the performance
			of services under this AGREEMENT, it shall have a properly executed
			equipment interchange agreement with the applicable ocean carrier, rail
			yard, chassis pool or equipment provider. CARRIER shall assume sole
			responsibility and care of all interchanged equipment. CARRIER agrees
			to store Interchanged Equipment in a secured and gated facility with
			24-hour surveillance and is subject to pre-approval by BROKER. CARRIER
			further warrants and represents that it is, and will at all times,
			remain in compliance with the terms and conditions of any interchange
			agreement, including all applicable circulars, tariffs or rules which
			may be referenced therein. CARRIER agrees to indemnify, defend and hold
			harmless BROKER from any liability, loss or expense arising from CARRIER's
			failure to comply with the terms and conditions of any interchange
			agreement. CARRIER must provide BROKER with evidence of any equipment
			interchange agreement upon request. CARRIER shall be liable to BROKER
			for any charges incurred by, charged to, or asserted against BROKER due
			to CARRIER's failure to comply with the terms of this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			8. BILLS OF LADING. CARRIER shall issue a bill of lading in its own
			name, or sign a bill of lading, produced by shipper in compliance with
			49 C.F.R. §373.101 (and any amendments thereto) ("Bill of Lading"),
			showing the kind, condition and numerical quantity of the property
			received and delivered by CARRIER at the loading and unloading points.
			SHIPPER's insertion of BROKER's name on the bill of lading shall be for
			SHIPPER convenience only and shall not change BROKER's status as a property
			broker. CARRIER shall assume full and complete responsibility and liability
			for all loss and/or damage to, or delay of, any shipment of property while
			in the possession or control of CARRIER, provided, however, that if the
			terms and conditions specified in this AGREEMENT are different from the
			terms and conditions contained in the Bill of Lading, the terms and
			conditions of this AGREEMENT shall prevail. Failure to issue a bill of
			lading or sign a bill of lading acknowledging receipt of the cargo, by
			CARRIER, shall not affect the liability of CARRIER.
		</v-col>

		<v-col cols="12" class="pt-4">
			9. EQUIPMENT & LABOR. CARRIER, at its sole cost and expense, shall
			furnish all equipment required for transportation and services hereunder
			and shall maintain all equipment in clean condition, good repair and
			working order, and meet all minimum government vehicle standards. CARRIER
			shall employ only competent and properly licensed personnel, who shall be
			well-trained in the care, safety procedures applicable to shipments being
			handled and transported. CARRIER has exclusive management, control, and
			direction of its drivers and acknowledges that BROKER has no control or
			input on such decisions. All trailers furnished by CARRIER shall meet the
			specifications described and identified in the applicable Confirmation
			Sheet and shall be clean, dry and free of any defects or contaminating
			odor and must be suitable in all respects for the transportation of the
			property of CUSTOMER tendered to CARRIER. CARRIER represents and warrants
			that the trailers used to provide services under this AGREEMENT shall not
			have been used to transport garbage, hazardous waste, solid waste or toxic
			materials.
		</v-col>
		<v-col cols="12" class="pt-4">
			10. COMPLIANCE WITH LAW. CARRIER shall comply with all applicable DOT
			and FMCSA laws and regulations as well as any other federal, state,
			and provincial laws, regulations and ordinances applicable to the
			operations of a motor carrier. CARRIER represents and warrants that,
			by accepting tender of a shipment under this AGREEMENT, the time between
			time of tender and the delivery date designated by BROKER or shipper is
			reasonable and can be performed by CARRIER and its drivers without
			violating any speed, safety, hours of service, or other related regulations.
			CARRIER will promptly notify BROKER in the event that (i) any designated
			delivery date cannot be legally met due to compliance with federal
			regulations, or (ii) any accident, theft or other occurrence that impairs
			the safety of or delays the delivery of the shipment. CARRIER further
			represents and warrants that it shall ensure all equipment used to provide
			services under this AGREEMENT is compliant with each state law, including,
			but not limited to, all regulations and requirements under the California
			Air Resources Board's ("CARB") Transport Refrigeration Unit ("TRU"),
			Airborne Toxic Control Measure ("ATCM"), Truck and Bus Regulation and
			Greenhouse Gas regulation ("GHG"). If applicable, CARRIER agrees to
			implement and adhere to Customs Trade Partnership Against Terrorism ("CTPAT")
			security criteria applicable to highway carriers published at www.cbp.gov.
			CARRIER shall be liable for and agrees to indemnify, defend, and hold BROKER
			and its CUSTOMERS harmless for any penalties or other liabilities imposed
			upon BROKER and its CUSTOMER(s) as a result of CARRIER's use of equipment
			not compliant with any laws, statutes, regulations, or requirements,
			including but not limited to those set forth above. Upon BROKER's request,
			CARRIER shall provide proof of CARRIER's compliance with any such laws,
			statutes, regulations, or requirements.
		</v-col>
		<v-col cols="12" class="pt-4">
			11. SAFETY & COMPLIANCE STATUS. CARRIER shall maintain appropriate
			governmental authority during the term of this AGREEMENT. CARRIER shall
			immediately provide BROKER with written notification if, at any time,
			CARRIER's safety rating issued by any applicable authority is amended or
			changed to, or, in the case of an initial rating, is first assigned as
			"Conditional" or "Unsatisfactory". CARRIER shall also provide immediate
			written notice if it receives an out-of-service order issued by the DOT
			or any other governmental agency. In the event CARRIER receives notice of
			a "Conditional" rating or an out-of-service order, CARRIER shall provide
			BROKER with the corrective action plan CARRIER has implemented to correct
			the change in CARRIER'S safety rating. Upon such notice or if BROKER
			independently determines that CARRIER does not meet BROKER's qualifications
			as a CARRIER, BROKER may elect to take any action necessary at BROKER's
			sole discretion, including, but not limited to, (1) cease using the
			services of CARRIER, or (2) immediately terminate this AGREEMENT. CARRIER
			agrees to immediately notify BROKER in the event that substantially all of
			the assets of  CARRIER are sold or the CARRIER undergoes a change in control
			or ownership.
		</v-col>

		<v-col cols="12" class="pt-4">
			12. SANITARY FOOD TRANSPORTATION REQUIREMENTS. Where applicable,
			CARRIER shall comply with all laws and regulations governing the
			safe and secure transportation of food that will ultimately be
			consumed by humans or animals. CARRIER acknowledges and agrees
			that the temperature of the goods is a material condition of this
			AGREEMENT during the transportation of Food Shipments, as defined
			in the Food Safety Addendum. Where applicable, CARRIER shall comply
			with all laws and regulations governing the safe and secure
			transportation of food that will ultimately be consumed by humans
			or animals including, but not limited to, the Food Safety Modernization
			Act (21 U.S.C. § 2201, et seq.), the Food, Drug and Cosmetic Act
			(21 U.S.C. § 341, et seq.) ("FD&C Act"), the Sanitary Food
			Transportation Act (49 U.S.C. 5701 et seq.), and the U.S. Food and Drug
			Administration's Final Rule on the Sanitary Transportation of Human
			and Animal Food (21 C.F.R. § 1.900 et seq.), collectively
			(the "Food Safety Laws"). CARRIER agrees that food that has been
			transported or offered for transport, pursuant to this AGREEMENT, under
			conditions that are not in compliance with the customer's instructions
			as provided to CARRIER by the CUSTOMER, through BROKER or otherwise,
			will be considered "adulterated" within the meaning of the FD&C Act
			(21 U.S.C. §§ 342(a)(i)(4), 342(i)). CARRIER understands that
			adulterated shipments may be refused by the CUSTOMER, consignee or
			receiver upon their tender for delivery at destination, as set forth
			in the attached Food Safety Addendum, which is incorporated herein
			by reference and made a part hereof.
		</v-col>
		<v-col cols="12" class="pt-4">
			13. SUBCONTRACT PROHIBITION. CARRIER shall not re-broker, co-broker,
			subcontract, assign, interline, warehouse, or transfer the movement
			of shipments hereunder to any other persons or entity conducting
			business under a different operating authority, without prior written
			consent of BROKER. If CARRIER receives BROKER's consent, CARRIER
			shall maintain ANY AUTO or HIRED + SCHEDULED AUTO coverage pursuant
			to a written operating agreement with any carrier, subcontractor, or
			owner operator moving the shipment ("Subcontractor"). CARRIER shall
			ensure that any Subcontractor complies with the terms of this Agreement,
			any CUSTOMER specifications, and applicable DOT regulations. If requested
			by BROKER, CARRIER shall provide information regarding each Subcontractor
			within 24 hours of BROKER's request. Additionally, CARRIER's liabilities
			and obligations under this Agreement will supersede any conflicting
			language in any agreement between CARRIER and Subcontractor. It is
			expressly understood that CARRIER's violation of this Section shall be
			deemed a material breach of this AGREEMENT and shall nullify any limitation
			of liabilities or damages afforded CARRIER in this AGREEMENT or otherwise.
			If CARRIER breaches this provision, BROKER, may in its sole discretion,
			pay the delivering carrier, in lieu of payment to CARRIER. Upon BROKER's
			payment to delivering carrier, CARRIER shall not be released from any
			liability to BROKER under this AGREEMENT. Whether CARRIER obtains BROKER's
			consent or not, CARRIER shall be liable to BROKER or any third party,
			including the CUSTOMER, shipper, consignee or any third-party payor, for
			any and all liabilities for losses, damages or delays resulting in any
			way from the actions of the Subcontractor.
		</v-col>
		<v-col cols="12" class="pt-4">
			14. INSURANCE. During the term of this AGREEMENT, CARRIER shall
			procure and maintain, at its sole cost and expense, the following
			minimum insurance coverages with properly licensed and reputable
			insurance companies.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(a)	Commercial Automobile Liability Insurance covering all vehicles
			utilized by the CARRIER with the minimum combined single limit for
			bodily injury, death and property damage of $1 million per occurrence
			(or additional coverage as necessary pursuant to the Confirmation
			Sheet or 49 CFR Part 387) covering all vehicles used by CARRIER in
			performing the services set forth in this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(b)	Motor Truck Cargo Legal Liability Insurance in an amount equal
			to the full value of the property to be transferred by CARRIER under
			this AGREEMENT, but in no event less than $100,000 per occurrence.
			Such cargo liability policy must include coverage for unattended
			vehicles and shall have no other exclusions or restrictions of any
			type that would foreseeably preclude coverage related to any cargo
			loss, damage, or delay claim. In no event shall a rejection of any
			claim by the CARRIER's insurance alleviate the CARRIER's responsibility
			for full liability of loss. If CARRIER is transporting
			temperature-controlled loads, this insurance must contain an endorsement
			extending coverage to cargo loss or damage occurring due to malfunction
			of the temperature control equipment.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(c)	Statutory Workers' Compensation Insurance as required by applicable
			state law. If CARRIER is not required to maintain such insurance and
			elects not to purchase such coverage, it shall execute the Workers'
			Compensation Insurance Addendum, attached hereto. If CARRIER is
			self-insured, a certificate of the state of domicile must be furnished
			by such state agency directly to BROKER.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(d)	Any other insurance or surety bond as agreed upon by CARRIER and
			BROKER from time to time to meet special insurance requirements of
			CUSTOMERS or as may be required under the laws, ordinances, and
			regulations of any governmental authority. At all times during the
			terms of this AGREEMENT, CARRIER shall comply with the financial
			responsibility requirements of federal, state, and provincial
			departments and agencies through which it is regulated and authorized
			to operate.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(e)	Prior to performing services under this AGREEMENT, CARRIER shall
			furnish to BROKER written certificates obtained from CARRIER's
			insurance provider showing that the insurance coverages required in
			this AGREEMENT have been procured, are being properly maintained,
			stating the expiration date, and specifying that written notice of
			cancellation or modification of the policy shall be given to BROKER
			at least thirty (30) days prior to such cancellation or modification.
			CARRIER shall also send notification to BROKER upon the cancellation
			or modification of any insurance coverage. Upon request, CARRIER shall
			provide BROKER with copies of all applicable insurance policies.
			CARRIER's cargo and liability insurance shall comply with DOT requirements
			in all respects. BROKER reserves the right to determine the qualifications
			for the insurance company utilized by the CARRIER and may accept or
			reject the insurance company provided by the CARRIER at its sole discretion.
			The policy limits of an insurance policy shall not limit the amount for
			which the CARRIER is ultimately liable in any claim or action.
		</v-col>
		<v-col cols="12" class="pt-4">
			15. INDEMNIFICATION. To the maximum extent provided by law, CARRIER
			shall defend, indemnify and hold BROKER its affiliates,  the respective
			directors, officers, employees, agents and representatives of each of
			the foregoing  and its  CUSTOMERS harmless from and against all losses,
			liabilities, damages, claims, judgments, fines, penalties, interest,
			costs or expenses, including reasonable attorney's fees arising from (i)
			CARRIER's or its employees, agents, or Subcontractors breach of this
			AGREEMENT (ii) loss or damage to any property in the possession and/or
			control of CARRIER or a Subcontractor, and (iii) the acts or omissions
			of CARRIER or its employees, agents, or Subcontractors. CARRIER agrees
			that its obligation to defend, indemnify and hold harmless the BROKER
			and its CUSTOMERS from and against any and all claims and liabilities
			resulting from or arising out of transportation operations and services
			under this AGREEMENT shall survive any termination of this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			16. LIABILITY FOR CARGO CLAIMS. CARRIER shall maintain sole and
			exclusive care, custody, and control of all shipments from the time
			the shipment is picked up by CARRIER for movement until delivery to
			the consignee accompanied by the appropriate delivery receipt signed
			by consignee as specified in this AGREEMENT. CARRIER assumes the
			liability of a common carrier under 49 U.S.C. §14706 for any loss,
			delay, damage to any shipments under CARRIER's care, custody, or
			control. CARRIER will process cargo claims in accordance with 49 CFR
			Part 370.
		</v-col>
		<v-col cols="12" class="pt-4">
			17.	SALVAGE CLAIMS. CARRIER shall waive any and all right of salvage
			value or resale of any of a CUSTOMER'S damaged goods and shall, at
			BROKER's reasonable request and direction, promptly return or dispose,
			at CARRIER's cost, any and all of a CUSTOMER'S damaged and overage
			goods shipped by CARRIER. CARRIER shall not under any circumstance,
			without BROKER's prior written consent, allow a CUSTOMER'S goods to
			be sold or made available for sale or otherwise disposed of in any
			salvage markets, employee stores, or any other secondary outlets.
			In the event that the CUSTOMER or BROKER realizes any salvage value
			for damaged goods, CARRIER shall receive a credit for the actual
			salvage value of such goods.
		</v-col>
		<v-col cols="12" class="pt-4">
			18. NO LIENS. CARRIER shall have no right to assert any lien on or
			against any property transported under this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			19. NON-SOLICITATION COVENANTS. CARRIER and BROKER agree that BROKER,
			at great expense and effort, has developed a broad base of CUSTOMERS
			that is essential to the successful operations of the BROKER. CARRIER
			and BROKER agree that disclosure of the identity of one or more of
			BROKER's CUSTOMERS to CARRIER constitutes valuable consideration.
			During the term of this AGREEMENT and for a period of one (1) year
			after its termination, CARRIER shall not, directly or indirectly,
			solicit or provide   transportation or warehousing services to any
			CUSTOMERS who are serviced by CARRIER under this AGREEMENT unless
			otherwise agreed to by BROKER in writing. Solicitation prohibited
			under this AGREEMENT means participation in any conduct, whether
			direct or indirect, the purpose of which involves transportation
			and/or handling of property by CARRIER for which CARRIER does, or
			did in the past, provide such services for that CUSTOMER under
			arrangements first made or procured by BROKER. Solicitation includes
			conduct initiated or induced by CARRIER or accepted by CARRIER upon
			inducement by CUSTOMER. If CARRIER should perform services of a
			transportation or warehousing nature for compensation for any CUSTOMER
			without prior documented authorization from BROKER during the applicable
			time period in violation of this AGREEMENT, CARRIER shall pay to BROKER
			within ten (10) days of each such violation an amount equal to
			twenty-five percent (25%) of all revenues invoiced by CARRIER to the
			CUSTOMER, together with any and all costs of collection, including
			reasonable attorney fees, incurred by BROKER in enforcing this provision.
			BROKER shall identify its CUSTOMERS to CARRIER as each first load from
			each CUSTOMER is tendered to CARRIER. CARRIER's acceptance of the load
			will acknowledge that this new customer is a CUSTOMER.
		</v-col>
		<v-col cols="12" class="pt-4">
			20. CONFIDENTIALITY. CARRIER shall limit disclosure of all
			Confidential Information defined below in or related to this AGREEMENT,
			only to CARRIER's agents, employees, and Subcontractors involved in
			its execution and performance or who have a need to know the
			Confidential Information . Throughout the term of this AGREEMENT and
			for three (3) years thereafter, CARRIER specifically agrees to keep
			confidential all of BROKER's and its CUSTOMERS' technical and business
			information which CARRIER has received or may receive as a result of
			this AGREEMENT,  CARRIER's rates and charges, the Rate Confirmation
			Sheets, and the performance thereof ("Confidential Information"), and
			not to reveal or to divulge any Confidential  Information to third
			parties or to use or publish Confidential  Information in any manner
			whatsoever, without obtaining BROKER's prior written consent; provided,
			however, that CARRIER shall not be bound to keep confidential any
			Confidential Information (i) which was known to CARRIER prior to the
			date of the applicable Rate Confirmation Sheets from sources other
			than BROKER or its CUSTOMERS (ii) which is, or becomes, available to
			the public without fault on CARRIER's part, or (iii) which is disclosed
			to CARRIER by a party not related, directly or indirectly, to BROKER or
			its CUSTOMERS, and such party has a rightful claim to such information.
			CARRIER shall only use BROKER's and its CUSTOMER'S technical and business
			information to provide the transportation and related services required
			under this AGREEMENT and the Rate Confirmation Sheets. As set forth in
			Appendix A, in the section entitled INFORMATION SHARING PROHIBITION,
			CARRIER shall not share or distribute BROKER's rates, rate confirmations,
			communications or user login info or data from any of BROKER's technology
			platforms with any third-party.  will not (a) share its login credentials
			to the Select Powered by Knight-Swift Transportation website
			(the "Select Site"), or to any BROKER website or application, with any
			third parties, (b) permit any access to the Select Site or any BROKER
			website or applications to any third-parties, and (c) share any content
			contained on the Select Site, or any BROKER website or application,
			either directly or indirectly with any third party.
		</v-col>
		<v-col cols="12" class="pt-4">
			21. RESOLUTION OF DISPUTES. The parties desire that the provisions
			of this AGREEMENT will have precedence over any federal or state
			provisions governing or dealing with the specific provisions of
			this AGREEMENT. The parties agree that pursuant to 49 U.S.C.
			§ 14101(b)(1) they expressly waive only those rights and remedies
			under the Interstate Commerce Commission Termination Act and
			Interstate Commerce Act as amended, and regulations promulgated
			thereunder, including Part B of Subtitle IV Interstate Transportation,
			49 U.S.C. § 13101, et seq., (the "Acts") that conflict with the
			provisions of this Agreement. No Party shall challenge any provision
			of this AGREEMENT on the ground that any such provision or provisions
			violates the waived rights and remedies under the Acts. To the extent
			not governed by applicable federal law, the laws of the State of
			Arizona will govern the validity, construction, and performance of
			this Agreement. All controversies, claims, suits, actions, or
			proceedings arising hereunder shall be adjudicated in the state and
			federal courts located in Maricopa County, Arizona and the parties
			expressly waive any objection thereto on the basis of personal
			jurisdiction or venue.
		</v-col>
		<v-col cols="12" class="pt-4">
			22. ENTIRE CONTRACT. The provisions contained in this AGREEMENT
			properly express and memorialize the complete understanding and
			agreement between the parties, including those contained in all
			prior agreements, both verbal or written, and there are no other
			agreements or understandings between the parties, express or
			implied, except as set forth herein.
		</v-col>
		<v-col cols="12" class="pt-4">
			23. LOGO/TRADEMARK. CARRIER shall not use the name, logo, trademarks
			or trade names of BROKER in publicity releases, promotional material,
			customer lists, advertising, marketing or business generating efforts,
			whether written or oral, without obtaining BROKER's prior written
			consent, which consent shall be given at BROKER's sole discretion.
		</v-col>
		<v-col cols="12" class="pt-4">
			24. CAPTIONS. The descriptive heading of the sections and
			subsections of this AGREEMENT are for convenience only and do not
			constitute a part of this AGREEMENT nor do they affect this
			AGREEMENT's construction or interpretation.
		</v-col>
		<v-col cols="12" class="pt-4">
			25. AMENDMENTS. This AGREEMENT may not be modified or amended
			except by a subsequent written amendment signed by both parties.
			The AGREEMENT may not be modified by "course of performance,"
			"course of dealing," "usage of trade" or in any other manner
			than as described.
		</v-col>
		<v-col cols="12" class="pt-4">
			26. WAIVERS. No provision of this AGREEMENT or any Confirmation
			Sheet shall be waived by any party unless such waiver is in
			writing and executed by an authorized representative of the party
			against whom such waiver is sought to be enforced. Waiver by either
			party of any failure to comply with any provision of this AGREEMENT
			by the other party shall not be construed as or constitute a
			continuing waiver of such provision of a waiver of any other breach
			of or failure to comply with any other provision of this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			27. ASSIGNABILITY. CARRIER is expressly prohibited from assigning
			any of their rights or delegating any of their obligations under
			this AGREEMENT to any third parties (such as sub-haulers, sub-brokers
			and any other form of substituted person or entity), unless the express
			written consent to such assignment or delegation is first obtained
			from the other party. Any assignment of this AGREEMENT or any Rate
			Confirmation Sheets, in whole or in part, by CARRIER without the prior
			written consent of BROKER shall be void and of no effect. BROKER, in
			its sole discretion, may assign this Agreement to a parent, subsidiary,
			or affiliate. Affiliate is defined as a party controlling, controlled
			by, or under common control with BROKER.
		</v-col>
		<v-col cols="12" class="pt-4">
			28. ELECTRONIC AND FAX COMMUNICATIONS. During the term of this
			AGREEMENT, the parties anticipate that they will exchange materials
			and information in electronic form (collectively "Electronic
			Materials") either through the other party's websites, e-mail other
			electronic means (collectively "Electronic Connections") and via
			fax. BROKER and its affiliates take reasonable steps to protect
			Electronic Materials resident on its networks, stored in its
			electronic media, or available on its websites, and take reasonable
			steps to prevent harm arising from Electronic Connections. Due to
			the nature of Electronic Connections and the Internet, BROKER and
			its affiliates do not provide, and expressly disclaim, any warranty
			(i) that Electronic Materials received by the CARRIER will be free
			of computer viruses or (ii) that Electronic Connections with the
			CARRIER will be free from harmful effects. It is the CARRIER's
			responsibility (i) to take reasonable steps to protect Electronic
			Materials resident on its networks, stored in its electronic media,
			or available on its websites, (ii) to take reasonable steps to
			prevent harm arising from Electronic Connections, and (iii) to
			perform any anti-virus scanning, data backup, security, and other
			precautions reasonably necessary to safeguard against computer
			viruses, worms, and other intrusive or damaging code (collectively
			"Computer Viruses") and other threats posed by Electronic Materials
			and Electronic Connections. Under no circumstances will BROKER or
			its affiliates be responsible for, and CARRIER hereby waives and
			releases BROKER and its affiliates from, any liability for any loss
			or damage caused by Computer Viruses, the CARRIER's receipt of
			Electronic Materials from BROKER or its affiliates or Electronic
			Connections between BROKER and its affiliates and the CARRIER.
		</v-col>
		<v-col cols="12" class="pt-4">
			29. BINDING EFFECT. This AGREEMENT shall be binding upon CARRIER
			and CARRIER's successors and assigns, and shall inure to the
			benefit of the parties and their representatives, successors
			and authorized assigns.  CARRIER and BROKER acknowledge and agree
			that CUSTOMERS are intended third-party beneficiaries to this
			AGREEMENT and are entitled to the rights and benefits hereunder
			and may enforce the provisions hereof as if it were a party hereto.
		</v-col>
		<v-col cols="12" class="pt-4">
			30. SEVERABILITY. If any provision of this AGREEMENT is determined
			by a court of competent jurisdiction to be contrary to the laws or
			regulations of any applicable jurisdiction, then such invalid
			provision shall be severed from this AGREEMENT; however, such
			determination shall not affect the validity of any other provisions
			of this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			31. AUTHORITY OF REPRESENTATIVES TO BIND PARTIES. It is agreed and
			warranted by the parties that the persons signing this AGREEMENT
			respectively for CARRIER and BROKER are authorized to do so. No
			further proof of authorization is or shall be required.
		</v-col>
		<v-col cols="12" class="pt-4">
			32. COUNTERPARTS. This AGREEMENT may be executed in any number of
			identical counterparts and each such executed counterpart shall be
			deemed a duplicate original hereof.
		</v-col>
		<v-col cols="12" class="pt-4">
			33. INTERPRETATION. The language used in all parts of this AGREEMENT
			shall be construed, in all cases, according to its fair and plain
			meaning. The parties acknowledge that each party and its counsel
			have had an opportunity to review this AGREEMENT, and that any rule
			of construction to the effect that ambiguities are to be resolved
			against the drafter of the agreement shall not be employed in the
			interpretation of this AGREEMENT.
		</v-col>
		<v-col cols="12" class="pt-4">
			34. NOTICES. Any notices required or permitted to be given under
			this AGREEMENT shall be in writing and shall be addressed to the
			other party at the contact information listed below, as updated
			from time to time.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong>CARB COMPLIANCE SUPPLEMENT</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			Carrier warrants that, to the extent that any shipments tendered to
			Carrier by Knight Logistics, LLC and/or Swift Logistics, LLC are
			transported on a highway within the State of California, it is in
			full compliance with 17 CCR §§ 95300-95312, and that all 53
			foot trailers, including both dry-van and refrigerated equipment,
			and the equipment used to haul them within California are in
			compliance with the California Air Resources Board (CARB) heavy Duty
			Vehicle Greenhouse Gas (Tractor-Trailer GHG) Emission Reduction
			Regulations. The Carrier further warrants that:
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(a)	All refrigerated equipment it operates within California under
			this agreement is in full compliance with the California Air
			Resources Board (CARB) Transport Refrigerated Unit (TRU) Airborne
			Toxic Control Measure (ATCM) in-use regulations.
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(b) All tractors and 53-foot dry van and refrigerated trailers it
			operates within California are equipped with low rolling resistance
			(LRR) tires and aerodynamic technologies; and,
		</v-col>
		<v-col cols="12" class="pl-8 pt-4">
			(c) If Carrier is hauling in California, Carrier is in compliance
			with California Truck and Bus Regulation (a 2010 model year emissions
			equivalent engine after January 1, 2023). Carrier is registered with
			the ARB Drayage Truck Registry.
		</v-col>
		<v-col cols="12" class="pt-4">
			Carrier shall be liable to Broker for any penalties, or any other
			liability, imposed on, or assumed by Broker due to penalties imposed
			on any CUSTOMER due to Carrier's use of non-compliant equipment.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong>WORKERS' COMPENSATION INSURANCE ADDENDUM</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			CARRIER hereby represents and warrants that, under the workers'
			compensation laws of the state of
			{{ onboardingInfo.addresses.physical.state }}, it does not have any
			employees subject to Workers Compensation insurance and is not
			required to purchase such insurance. CARRIER also represents and
			warrants that it has elected not to purchase Workers Compensation
			insurance. If CARRIER becomes subject to compliance with Workers
			Compensation laws or elects to comply with said laws, it shall
			immediately provide BROKER a Workers Compensation insurance
			certificate evidencing compliance. CARRIER understands that it is
			required to maintain adequate workers compensation coverage and
			that CARRIER assumes full responsibility for any injury.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong>FOOD SAFETY MODERNIZATION ACT (FSMA)</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			<strong style="text-decoration: underline;">
				FOOD SAFETY BEST PRACTICE PROCEDURES - CARRIER RESPONSIBILITIES
			</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			<ul>
				<li>
					Carrier (driver) must ensure equipment meets shipper's
					specifications and is appropriate to prevent the food from
					becoming unsafe.
				</li>
				<li>
					Carrier (driver) must pre-cool each mechanical refrigerated
					cold storage compartment as specified by the shipper (at
					least 20 minutes continuous and set for the lowest
					temperature required for the product to be loaded) before
					offering it for transport of food requiring temperature
					control. The only exception shall include a "drop and hook"
					situation where Carrier is picking up an interchanged
					trailer loaded and ready for transport.
				</li>
				<li>
					Carrier (driver) must verify temperature of the product at
					time of loading. If driver is not allowed on dock, notify
					Broker and verify temperature after leaving the dock and
					before the trailer doors are closed.
				</li>
				<li>
					Carrier (driver) must verify the refrigeration thermostat is
					set to Shipper's specified temperature in "continuous mode"
					as noted on Bill of Lading. Failure to maintain specified
					temperatures, whether human error or mechanical breakdown is
					the responsibility of the Carrier (driver) and will be held
					liable for all claims.
				</li>
				<li>
					Carrier (driver) will follow shipper or loader's
					instructions for segregation of goods. If Carrier (driver)
					fails to follow shipper or loader's instruction Carrier
					(driver) is liable for cross contamination.
				</li>
				<li>
					Carrier (driver) will seal or verify installation of the
					seal and note the seal # on the BOL.
				</li>
				<li>
					Carrier (driver) will put their own padlock on the door for
					additional security.
				</li>
				<li>
					Carrier (driver) will check trailer temperature and
					thermostat setting at least every four (4) hours during
					transit. This data must be documented. This record must
					include the date, time, set point and temperature. This
					information must be turned in with your paperwork/BOLs.
				</li>
				<li>
					Carrier (driver) must report any and all refrigeration unit
					malfunctions and breakdowns to Broker while in transit.
				</li>
				<li>
					Carrier (driver) must report any delays at receivers when
					trailer doors are open and no progress in the unloading
					process is being made.
				</li>
				<li>
					Carrier (driver) should report to Broker if receiver is
					unloading temperature-controlled product in a
					non-temperature-controlled environment.
				</li>
				<li>
					If a Carrier (driver) becomes aware of a possible material
					failure of temperature control or other conditions that may
					render the food unsafe, he must notify Broker immediately.
					The food must not be sold or otherwise distributed until it
					is determined by a "qualified person" that the temperature
					deviation or other condition did not render the food unsafe.
				</li>
				<v-col cols="12" class="pl-10">
					(a) Definition of "Qualified Person or Qualified Individual"
					is someone who is qualified by training or experience to
					make such a determination. He/she should have a scientific
					understanding of how temperature deviation could affect the
					growth of pathogens or production of toxins in food.
				</v-col>
				<v-col cols="12" class="pl-10">
					(b) Carrier (driver) must provide verifiable data as proof
					of temperature integrity inside the trailer compartment
					while in-transit upon request from consignee or Broker.
					Failure to do so may result in complete liability and
					responsibility for any resulting claim for product refused.
				</v-col>
				<li>
					Carrier (driver) must report to dispatcher and Broker any
					temperature issues or concerns as they develop at the
					Receiver, including but not limited to temperature deviation
					+/- 5 degrees.
				</li>
				<li>
					Carrier (driver) must report to dispatcher and Broker any
					O.S. & D. issues immediately
				</li>
			</ul>
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong style="text-decoration: underline;">
				WASHING AND SANITIZING TRAILERS
			</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			All transportation equipment must be maintained in such sanitary
			condition, by washing and sanitizing the trailers on a regular
			schedule, for their intended use to prevent food from becoming
			unsafe.
		</v-col>
		<v-col cols="12" class="pt-4">
			<strong style="text-decoration: underline;">
				INSPECTION OF EQUIPMENT
			</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			When the equipment is empty inspect your trailer for cleanliness
			including:
		</v-col>
		<v-col cols="12" class="pt-4">
			<ul>
				<li>Inspect overall trailer condition</li>
				<li>Ensure floor is free of debris, product residue, or insects</li>
				<li>Ensure walls are free of damage, product residue, or tape</li>
				<li>Ensure ceiling is undamaged</li>
				<li>Confirm air delivery chute is intact and functional</li>
				<li>Confirm door seals are intact & in good repair</li>
				<li>Confirm door(s) are undamaged</li>
				<li>Confirm walls & wall insulation is undamaged</li>
				<li>Confirm floors are in good repair</li>
			</ul>
			The inspection of the transportation equipment is to ensure that it
			does not cause the food that it transports to become unsafe.
		</v-col>
		<v-col cols="12" class="pt-4">
			Vehicle and transportation equipment must be stored in a manner that
			prevents incursion or harborage of pests or becoming contaminated in
			any manner that could result in food becoming unsafe.
		</v-col>
		<v-col cols="12" class="pt-4">
			*When a CUSTOMER and/or BROKER requests confirmation of recent washout
			or sanitizing of transportation equipment, Carrier (driver) is required
			to provide the appropriate documents.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong style="text-decoration: underline;">CLEANING GUIDE</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			As a cleaning guide, CARRIER (driver) must inspect trailer, broom
			sweep as necessary and remove all debris. Any dry material unable to
			be swept from trailer must be washed out with water. If the presence
			of blood and/or other residue or stains in the trailer or
			objectionable odor is apparent, the equipment must be washed out
			with hot soapy water and refrigeration unit should be operated at 65
			degrees to allow the interior to dry thoroughly prior to loading.
		</v-col>
		<v-col cols="12" class="pt-4">
			I have read and understand all of the information in this document.
			All CARRIER personnel have been trained; documentation can be provided
			and will ensure full compliance with Sanitary Transportation of Human
			and Animal Food regulations.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong style="text-decoration: underline;">INFORMATION SHARING PROHIBITION</strong>
		</v-col>
		<v-col cols="12" class="pt-4">
			CARRIER shall not share or distribute BROKER's rates, rate confirmations,
			communications or user login info or data from any of BROKER's technology
			platforms with any third-party. CARRIER, as provided  in Section 20 of
			this AGREEMENT, will not (a) share its login credentials to the Select
			Powered by Knight-Swift Transportation website (the "Select Site"), or to
			any BROKER website or application, with any third parties, (b) permit any
			access to the Select Site or any BROKER website or applications to any
			third-parties, and (c) share any content contained on the Select Site, or
			any BROKER website or application, either directly or indirectly with any
			third party.
		</v-col>
		<v-col cols="12" class="pt-8">
			<strong style="text-decoration: underline;">
				ACCESSORIAL CHARGES
			</strong>
		</v-col>
		<v-col cols="12" class="pt-0">
			1-800-558-5623 Rev.1.1.22
		</v-col>
		<v-col cols="12" class="pt-2">
			<span style="text-decoration: underline;">
				General Terms and Charges
			</span>
		</v-col>
		<v-col
			cols="12"
			class="pt-0"
			v-for="(item, index) in accessorialInfo"
			:key="index"
		>
			<strong>{{ item.term }}:</strong> {{ item.description }}
		</v-col>
		<v-col cols="12" class="pt-4">
			You acknowledge the terms and accessorials above unless indicated
			otherwise in writing by an authorized manager of Knight Swift
			Logistics.
		</v-col>
	</v-row>
</template>

<script>
import moment from "moment";

export default {
	data() {
		return {
			today: moment().format("MM/DD/YYYY"),
			accessorialInfo: [
				{
					term: "Detention w/ Power",
					description:
						"2 hours free time, $50 per hour for each hour thereafter with a maximum charge of $300 per day. Applies to all loading and unloading stops as well as OS&D issues.",
				},
				{
					term: "Detention w/o Power (TRAILER)",
					description: "$50/day",
				},
				{
					term: "Truck Ordered Not Used (TONU)",
					description:
						"Loads cancelled within 4 hours of scheduled pickup are subject to a charge of $150.",
				},
				{
					term: "Fuel Surcharge",
					description:
						"N/A-all in rate includes linehaul and fuel compensation",
				},
				{
					term: "Layover",
					description:
						"$300 per day for a single driver, $500 per day for a team.",
				},
				{
					term: "Load/Unload",
					description:
						"SL&C and consignee unload. All lumping reimbursements must be submitted with proof of lumping receipt from load/unload location.",
				},
				{
					term: "OS&D",
					description:
						"All claims will be filed and processed in accordance with 49 CFR Part 370. Carrier shall be liable for cargo loss and damage in accordance with federal law (49 U.S.C. § 14706) with respect to all shipments.",
				},
				{
					term: "Out of Route Miles",
					description: "$2.00 per mile (PC Miler Practical)",
				},
				{
					term: "Payment Terms",
					description:
						"Payment will be issued within 30 days of delivery date with all load documentation provided including valid Proof of Delivery.",
				},
				{
					term: "Proof of Delivery",
					description:
						"Every effort must be made to provide a signed BOL. When a BOL is not available for any reason, a satellite POD or other proof must be provided. Lack of a BOL or POD required in order for Knight-Swift to receive timely payment from Customer, may result in delay of payment to Carrier until such time as an acceptable POD is presented allowing Knight-Swift to receive payment.",
				},
				{
					term: "Redelivery/Reconsignment",
					description:
						"When a shipment is tendered for delivery and through no fault of the carrier the original delivery cannot be accomplished, a charge of $2.00 per mile, min. of $75 plus applicable detention.",
				},
				{
					term: "Stops in Transit",
					description:
						"$50 / stop unless specified at load negotiation otherwise with rate confirmation",
				},
				{
					term: "Toll Surcharge",
					description: "Carriers are responsible for tolls",
				},
			],
		};
	},
	computed: {
		onboardingInfo() {
			return this.$store.state.onboardingInfo;
		},
	},
};
</script>

<style scoped>
.contractScroll::-webkit-scrollbar {
	width: 11px;
	background: transparent;
	border-radius: 10px;
}
.contractScroll::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}
.contractScroll::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-base);
	border-radius: 10px;
}
.contractScroll {
	height: 300px;
	overflow-y: scroll;
}
</style>
