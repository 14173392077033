<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="Fleet Management"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>

		<v-col cols="12" lg="10" class="justify-center pa-0 mt-5" align-self="start">
			<v-card class="px-4">
				<v-col cols="12">
					<v-row class="px-4 pt-4">
						My Fleet Details
					</v-row>
					<v-row class="mb-2" justify="end">
						<div
							class="pb-1 mx-2"
							:class="selectedType === 'company' ? 'selectFilter' : ''"
						>
							<v-btn @click="selectedType = 'company'" small text color="secondary">
								Company Details
							</v-btn>
						</div>
						<div
							class="pb-1 mx-2"
							:class="selectedType === 'owner' ? 'selectFilter' : ''"
						>
							<v-btn @click="selectedType = 'owner'" small text color="secondary">
								Owner Operator Details
							</v-btn>
						</div>
					</v-row>

					<v-row class="myServicesScroll px-3">
						<v-col cols="12" class="pa-0">
							<v-row class="pa-0 my-1 rowBackground">
								<v-col cols="12" md="4" class="text-center">
									Power Units:
									<span class="ml-2">{{ fleetData[selectedType].power }}</span>
								</v-col>
								<v-col cols="12" md="4" class="text-center">
									Drivers:
									<span class="ml-2">{{ fleetData[selectedType].drivers }}</span>
								</v-col>
								<v-col cols="12" md="4" class="text-center">
									Total Trailers:
									<span class="ml-2">{{
										fleetData[selectedType].numTrailers
									}}</span>
								</v-col>
							</v-row>
							<v-row
								:justify="$vuetify.breakpoint.smAndDown ? 'space-between' : 'start'"
							>
								<template
									v-for="(item, index) in Object.entries(fleetData[selectedType])"
								>
									<v-col
										:key="`fleetRow${index}`"
										class="pa-0 my-1 mx-2"
										v-if="checkExists(item[0], item[1])"
										cols="5"
										md="3"
										:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUpTrailer' : ''"
									>
										<FleetDetailRow
											:selectedType="selectedType"
											:data="item[1]"
											:dataKey="item[0]"
											@deleteTrailer="deleteTrailer"
										>
										</FleetDetailRow>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-col>

		<v-col cols="12" lg="10" class="justify-center align-center ma-0 pa-0 mt-5">
			<v-row class="px-4 py-4">
				<v-col cols="12" class="pa-0">
					Add / Update Fleet Details
				</v-col>
			</v-row>
			<v-row justify="center" align="start">
				<v-col cols="12" md="6" class="pa-0">
					<v-row>
						<template v-for="(item, index) in Object.entries(fleetData.company)">
							<v-col
								:key="`fleet${index}`"
								v-if="item[0] === 'power' || item[0] === 'drivers'"
								cols="6"
								md="4"
								:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUp' : ''"
							>
								<FleetCard
									:numberOnly="item[1]"
									:detailKey="item[0]"
									@openFleet="openFleet"
									@selectAssistance="selectAssistance"
								></FleetCard>
							</v-col>
						</template>

						<v-col cols="12">
							<v-checkbox v-model="powerOnlyCheckbox" class="ma-0 pa-0" hide-details>
								<div slot="label">
									Check here if you have no trailers in your fleet and are power
									only
								</div>
							</v-checkbox>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="6" class="pa-0">
					<v-row justify="end">
						<template v-for="(item, index) in Object.entries(fleetData.company)">
							<v-col
								:key="`fleet${index}`"
								v-if="
									item[0] != 'power' &&
										item[0] != 'drivers' &&
										item[0] != 'numTrailers'
								"
								cols="6"
								:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUp' : ''"
							>
								<FleetCard
									:details="item[1]"
									:detailKey="item[0]"
									@openFleet="openFleet"
									@selectAssistance="selectAssistance"
								></FleetCard>
							</v-col>
						</template>
					</v-row>
				</v-col>
			</v-row>
		</v-col>
		<FleetDialog
			v-model="openFleetDialog"
			:dialogType="dialogType"
			:selectedKey="selectedKey"
			:addFleetAnimation="addFleetAnimation"
			:addFleetSuccess="addFleetSuccess"
			@addFleet="addFleet"
			@closeFleetDialog="closeFleetDialog"
			@resetSuccess="addFleetSuccess = false"
		></FleetDialog>
		<!--

		 -->
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-row>
</template>

<script>
import basicDialogMixin from '@/mixins/basicDialogMixin.js';
import getCarrierProgress from '@/mixins/getCarrierProgress.js';
import { mapGetters } from 'vuex';
import FleetCard from './FleetCard.vue';
import FleetDetailRow from './FleetDetailRow.vue';
import FleetDialog from './FleetDialog.vue';

export default {
	name: 'Fleet',
	components: {
		FleetCard,
		FleetDetailRow,
		FleetDialog,
	},
	mixins: [basicDialogMixin, getCarrierProgress],
	data() {
		return {
			// owner || company
			selectedType: 'company',
			// power || driver || trailer
			selectedKey: null,
			// v-model for open fleet dialog
			openFleetDialog: false,
			// loading animation and success
			addFleetAnimation: false,
			addFleetSuccess: false,
			// prop to control which content is shown in fleet dialog
			dialogType: 'add',
			//
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
			powerOnlyCheckbox: false,
		};
	},
	methods: {
		async saveSignupHandler(saveType) {
			// console.log('fleet index saveType: ', saveType);
			const pageType = 'fleet';
			const nextPage = 'documents';

			if (this.fleetValidation) {
				// IF SUCCESS PUSH FWD
				if (saveType === 'continue') {
					this.$router.push(`/${nextPage}`);
				} else if (saveType === 'nothing') {
					// do nothing
				} else {
					this.$router.push('/dashboard');
				}
			}
		},
		async deleteTrailer({ dataKey, selectedType }) {
			if (this.generalContract) return this.contractSigned();
			const payload = {
				amount: 0,
				equipKey: dataKey,
				ownership: selectedType,
			};
			if (dataKey === 'other') {
				payload.otherDesc = '';
			}
			try {
				const { data } = await this.$axios({
					method: 'put',
					url: `${this.$hostName.apiBase}/onboarding/fleet`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
				});
				this.$store.commit('updateFleet', data);
				this.getCarrierProgress();
			} catch (error) {
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: `Unabled to remove ${this.keyFilter(
						this.selectedKey
					)} information - please try again later.`,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		// add/update fleet information
		async addFleet(payload) {
			try {
				const { data } = await this.$axios({
					method: 'put',
					url: `${this.$hostName.apiBase}/onboarding/fleet`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
				});
				this.addFleetAnimation = true;
				this.$store.commit('updateFleet', data);
				this.getCarrierProgress();

				setTimeout(() => {
					this.addFleetAnimation = false;
					this.addFleetSuccess = true;
				}, 1500);
			} catch (error) {
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: `Unabled to add ${this.keyFilter(
						this.selectedKey
					)} information - please try again later.`,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		// close fleet dialog and reset values
		closeFleetDialog() {
			this.selectedKey = null;
			this.dialogType = 'add';
			this.openFleetDialog = false;
			this.$background.blur = false;
		},
		// sets selected values and opens fleet dialog (type add)
		openFleet({ detailKey }) {
			// console.log('detailKey:' , detailKey);
			if (this.generalContract) return this.contractSigned();
			this.selectedKey = detailKey;
			this.dialogType = 'add';
			this.openFleetDialog = true;
			this.$background.blur = true;
		},
		contractSigned() {
			return (this.basicDialog = {
				value: true,
				title: 'Contract Signed',
				text: `Once General Contract has been signed, fleet size cannot be edited.  Please contact our carrier representative or technical analyst for more information.`,
				hasAction: true,
				actionButton: {
					outlined: true,
					action: 'close',
					text: 'Close',
					color: '#CF6679',
				},
				width: '350',
			});
		},
		selectAssistance({ detailKey }) {
			this.selectedKey = detailKey;
			this.dialogType = 'assistance';
			this.openFleetDialog = true;
			this.$background.blur = true;
		},
		// bool toggle v-if on the fleet row data (in table)
		checkExists(key, value) {
			return key != 'power' && key != 'drivers' && key != 'numTrailers' && value.amount != 0;
		},
		keyFilter(item) {
			let text;
			switch (item) {
				case 'power':
					text = 'Power Unit';
					break;
				case 'drivers':
					text = 'Driver';
					break;
				case 'dry':
					text = 'Dry Van';
					break;
				case 'flatbed':
					text = 'Flatbed';
					break;
				case 'reefer':
					text = 'Refrigerated';
					break;
				case 'carHaul':
					text = 'Car Hauler';
					break;
				case 'highCube':
					text = 'High Cube Dray';
					break;
				case 'other':
					text = 'Other Trailer';
					break;
				default:
					text = item;
			}
			return text;
		},
	},
	computed: {
		...mapGetters(['fleetHasAtleastOneDriverOrPowerUnit', 'selectedAtleastOneTrailer']),
		fleetData() {
			return this.fleetInfo;
		},
		auth() {
			return this.$store.state.auth;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status;
		},
		fleetInfo() {
			return this.$store.state.onboardingInfo.fleet;
		},
		generalContract() {
			return this.$store.state.onboardingInfo.status !== 'incomplete';
			// return this.$store.state.progress.contract.valid;
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'fleet',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: this.fleetValidation,
				saveType: 'continue',
			};
		},
		// built in col sizing failing - needs custom (trailer details in table)
		columnSizing() {
			let style;
			if (this.$vuetify.breakpoint.smAndDown) {
				style = {
					'-webkit-box-flex': '0',
					'-ms-flex': '0 0 46%',
					flex: '0 0 46%',
					'max-width': '46%',
				};
			} else {
				style = {
					'-webkit-box-flex': '0',
					'-ms-flex': '0 0 25%',
					flex: '0 0 25%',
					'max-width': '25%',
				};
			}
			return style;
		},
		fleetValidation() {
			let isValid = this.fleetHasAtleastOneDriverOrPowerUnit;
			if (!this.powerOnlyCheckbox) {
				isValid = isValid && this.selectedAtleastOneTrailer;
			}
			return isValid;
		},
	},
	filters: {
		equipFilter(item) {
			let unitLabel;
			switch (item) {
				case 'dry':
					unitLabel = 'Dry Van';
					break;
				case 'flatbed':
					unitLabel = 'Flatbed';
					break;
				case 'reefer':
					unitLabel = 'Refrigerated';
					break;
				case 'carHaul':
					unitLabel = 'Car Hauler';
					break;
				case 'highCube':
					unitLabel = 'High Cube Dray';
					break;
				case 'other':
					if (this.selectedType === 'company') {
						unitLabel = this.fleetData.company.other.description;
					} else if (this.selectedType === 'owner') {
						unitLabel = this.fleetData.owner.other.description;
					} else {
						unitLabel = 'Other';
					}
					break;
				default:
					unitLabel = item;
					break;
			}
			return unitLabel;
		},
	},
};
</script>
<style scoped>
.lgAndUp {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	max-width: 30%;
}
.selectFilter {
	border-bottom: 1px solid var(--v-secondary-base);
}

.myServicesScroll::-webkit-scrollbar {
	width: 8px;
	background: transparent;
	border-radius: 10px;
}
.myServicesScroll::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}
.myServicesScroll::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-base);
	border-radius: 10px;
}
.myServicesScroll {
	min-height: 140px;
	max-height: 140px;
	overflow-y: scroll;
	border-radius: 10px;
	background: repeating-linear-gradient(
		-55deg,
		#121212,
		#121212 2.5px,
		#1e1e1e 2.5px,
		#1e1e1e 5px
	);
}
.serviceTotals {
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;
	position: absolute;
	right: -6px;
	top: -10px;
	font-size: 12px;
	color: var(--v-secondary-base);
	background-color: #121212;
	height: 21px;
	width: 21px;
	border-radius: 50%;
}
.rowBackground {
	background-color: #121212;
	border-radius: 10px;
}
.mySerivcesHeaders {
	font-size: 14px;
	color: #bdbdbd;
}
.lgAndUpTrailer {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 15.5%;
	flex: 0 0 15.5%;
	max-width: 15.5%;
}
</style>
