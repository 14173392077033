var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('NavigationButtons',{attrs:{"options":_vm.navButtonData,"title":"General Information"},on:{"saveSignupInfo":_vm.saveSignupHandler}}),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"10","align-self":"start"}},[_c('v-row',{attrs:{"justify":"space-between","align":"stretch"}},[_c('v-col',{staticClass:"justify-center pa-1",attrs:{"cols":_vm.generalColSize}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"general",model:{value:(_vm.$formValid.general),callback:function ($$v) {_vm.$set(_vm.$formValid, "general", $$v)},expression:"$formValid.general"}},[_c('v-row',[_vm._l((Object.keys(_vm.generalData)),function(item,index){return [(item != 'alternates')?_c('v-col',{key:index,class:_vm.generalAltCol(item),attrs:{"cols":_vm.columnSize(item)}},[(
														item != 'intraState' &&
															item !== 'alternates' &&
															item === 'minority'
													)?_c('v-row',[_c('v-select',{attrs:{"clearable":"","label":_vm.labelFilter(item),"items":_vm.minorityOptions,"rules":_vm.selectRule,"item-text":"text","item-value":"value","autocomplete":"off","persistent-hint":"","outlined":"","multiple":"","filled":"","hint":"*Required"},model:{value:(_vm.generalData[item]),callback:function ($$v) {_vm.$set(_vm.generalData, item, $$v)},expression:"generalData[item]"}})],1):_vm._e(),(
														item != 'intraState' &&
															item !== 'alternates' &&
															item != 'minority'
													)?_c('v-text-field',{attrs:{"required":_vm.checkRequired(item),"persistent-hint":"","hint":_vm.checkRequired(item)
															? '*Required'
															: 'Optional',"rules":_vm.ruleMethod(item),"label":_vm.labelFilter(item),"maxlength":_vm.maxLength(item),"autocomplete":"off","outlined":"","filled":""},on:{"keypress":function($event){return _vm.numOnly($event, item)}},model:{value:(_vm.generalData[item]),callback:function ($$v) {_vm.$set(_vm.generalData, item, $$v)},expression:"generalData[item]"}}):_vm._e()],1):_vm._e()]})],2)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"justify-center pa-1",attrs:{"cols":_vm.generalColSize}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"contact",model:{value:(_vm.$formValid.contact),callback:function ($$v) {_vm.$set(_vm.$formValid, "contact", $$v)},expression:"$formValid.contact"}},[_c('v-row',[_c('v-col',{staticClass:"text-left pt-0 ma-0 my-3",attrs:{"cols":"12"}},[_c('span',{staticClass:"altContactStyle"},[_vm._v(" Owner/Executive Information ")])]),_vm._l((Object.keys(
												_vm.generalAlternates('corporate')
											)),function(item,index){return [_c('v-col',{key:("corporate" + index),class:_vm.generalAltCol(item),attrs:{"cols":_vm.altColSize(item)}},[(
														item != 'contactType' &&
															_vm.generalData.alternates &&
															item != 'phone'
													)?_c('v-text-field',{attrs:{"required":_vm.checkRequired(item),"persistent-hint":"","hint":_vm.checkRequired(item)
															? '*Required'
															: 'Optional',"rules":_vm.ruleMethod(item),"label":_vm.altLabelFilter(item, 'corporate'),"maxlength":_vm.maxLength(item),"autocomplete":"off","outlined":"","filled":""},on:{"keypress":function($event){return _vm.numOnly($event, item)}},model:{value:(_vm.generalData.alternates[0][item]),callback:function ($$v) {_vm.$set(_vm.generalData.alternates[0], item, $$v)},expression:"generalData.alternates[0][item]"}}):_vm._e(),(
														item === 'phone' &&
															_vm.generalData.alternates
													)?_c('v-text-field',{attrs:{"required":_vm.checkRequired(item),"persistent-hint":"","hint":_vm.checkRequired(item)
															? '*Required'
															: 'Optional',"rules":_vm.ruleMethod(item),"label":_vm.altLabelFilter(item, 'corporate'),"maxlength":_vm.maxLength(item),"autocomplete":"off","outlined":"","filled":""},on:{"keypress":function($event){return _vm.changeNum(
															_vm.generalData.alternates[0][item],
															'corporate'
														)}},model:{value:(_vm.generalData.alternates[0][item]),callback:function ($$v) {_vm.$set(_vm.generalData.alternates[0], item, $$v)},expression:"generalData.alternates[0][item]"}}):_vm._e()],1)]}),_c('v-col',{staticClass:"text-left pt-5",attrs:{"cols":"6"}},[_c('span',{staticClass:"altContactStyle"},[_vm._v(" Dispatch Information ")])]),(_vm.generalData.alternates)?_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"6"}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"end","justify":"end"}},[_c('v-switch',{attrs:{"hide-details":""},on:{"click":_vm.toggleSwitch},model:{value:(_vm.useCorporate),callback:function ($$v) {_vm.useCorporate=$$v},expression:"useCorporate"}}),_vm._v(" Use Corporate Information ")],1)],1):_vm._e(),_vm._l((Object.keys(
												_vm.generalAlternates('dispatch')
											)),function(item,index){return [_c('v-col',{key:("dispatch" + index),class:_vm.generalAltCol(item),attrs:{"cols":_vm.altColSize(item)}},[(
														item != 'contactType' &&
															_vm.generalData.alternates &&
															item != 'phone'
													)?_c('v-text-field',{attrs:{"required":_vm.checkRequired(item),"persistent-hint":"","hint":_vm.checkRequired(item)
															? '*Required'
															: 'Optional',"rules":_vm.ruleMethod(item),"label":_vm.altLabelFilter(item, 'dispatch'),"maxlength":_vm.maxLength(item),"autocomplete":"off","outlined":"","filled":""},on:{"keypress":function($event){return _vm.numOnly($event, item)}},model:{value:(_vm.generalData.alternates[1][item]),callback:function ($$v) {_vm.$set(_vm.generalData.alternates[1], item, $$v)},expression:"generalData.alternates[1][item]"}}):_vm._e(),(
														item === 'phone' &&
															_vm.generalData.alternates
													)?_c('v-text-field',{attrs:{"required":_vm.checkRequired(item),"persistent-hint":"","hint":_vm.checkRequired(item)
															? '*Required'
															: 'Optional',"rules":_vm.ruleMethod(item),"label":_vm.altLabelFilter(item, 'dispatch'),"maxlength":_vm.maxLength(item),"autocomplete":"off","outlined":"","filled":""},on:{"keypress":function($event){return _vm.changeNum(
															_vm.generalData.alternates[1][item],
															'dispatch'
														)}},model:{value:(_vm.generalData.alternates[1][item]),callback:function ($$v) {_vm.$set(_vm.generalData.alternates[1], item, $$v)},expression:"generalData.alternates[1][item]"}}):_vm._e()],1)]}),_c('v-col',{staticClass:"text-center pt-2 ma-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"hintText"},[_vm._v(" TO ADD ADDITIONAL CARRIER CONTACTS/CONTACT INFORMATION, PLEASE SEND AN EMAIL TO: "),_c('a',{attrs:{"href":"mailto:CARRIERS@KNIGHTSWIFTLOGISTICS.COM"}},[_vm._v("CARRIERS@KNIGHTSWIFTLOGISTICS.COM")])])])],2)],1)],1)],1)],1)],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pa-0 gradientBg",attrs:{"cols":"12","md":"6"}}):_vm._e()],1)],1),_c('basic-dialog',{attrs:{"options":_vm.basicDialog},on:{"close":_vm.closeBasicDialog},model:{value:(_vm.basicDialog.value),callback:function ($$v) {_vm.$set(_vm.basicDialog, "value", $$v)},expression:"basicDialog.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }