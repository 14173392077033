
<template>
	<v-col :style="flexSize" class="pa-0 px-2 py-1 my-2 mx-1 rowBackground">
		<v-row align="end">
			<v-col cols="12" md="6" class="py-0">
				<v-row>
					<v-col cols="12" class="tinyText pa-0 pb-1">
						<span v-if="rowData.value">Most Recent File:</span>
						<span v-if="rowData.docClass">Archived:</span>
					</v-col>
					<v-col cols="12" class="pa-0">
						{{docText(rowData)}}
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="4" class="py-0">
				{{rowData.createdAt | momentShortDate}}
			</v-col>
			<v-col cols="12" md="2" class="py-0 text-right">
				<v-btn
					icon
					color="primary darken-2"
					class="mx-1"
					@click="$emit('viewDocument', rowData.documentId)"
				>
					<v-icon>mdi-file-eye</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	name: 'Documents_MyDocuments_RowCard',
	props: {
		rowData: { type: Object, default: () => {} }
	},
	data() {
		return {

		}
	},
	methods: {
		docText({value, docClass}) {
			if (value === 'MC Letter of Authority') return "Letter of Authority";
			return value || docClass
		},
		async viewDocument(item) {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId: this.documentInfo[item].documentId,
					},
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
					responseType: 'blob',
				});

				this.documentBlob = URL.createObjectURL(response.data);

				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.target = '_blank';
				link.href = this.documentBlob;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (error) {
				console.error('viewDocument error:', error);
			}
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		documentInfo() {
			return this.$store.state.documents
		},
		rowCardStyle() {
			let color;
			if (this.index % 2 === 0) color= '#1c1c1c'
			else color = '#1E1E1E'
			return color;
		},
		flexSize() {
			let size;
			if (this.$vuetify.breakpoint.smAndDown) {
				size = {
					'-webkit-box-flex': '0',
					'-ms-flex': '0 0 100%',
					'flex': '0 0 100%',
					'max-width': '100%'
				}
			}
			if (this.$vuetify.breakpoint.mdAndUp) {
				size = {
					'-webkit-box-flex': '0',
					'-ms-flex': '0 0 45%',
					'flex': '0 0 45%',
					'max-width': '45%'
				}
			}
			return size;
		}
	},
};
</script>

<style scoped>
.rowBackground {
	background-color: #121212;
	border-radius: 10px;
}
.tinyText {
	font-size: 12px;
}
</style>