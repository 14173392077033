<template>
	<v-col cols="12" class="overflowContainer">

		<v-row class="fill-height" align="center" justify="center" v-if="notifications.list.length === 0">
			<!-- <img src="../../../assets/undraw_well_done_i2wr.svg" style="width: 100%; height: 30vh"/> -->
			<span style="color: #bdbdbd">There are no new notifications at the moment</span>
		</v-row>
		<v-row px-2 v-if="notifications.list.length">
			<v-col
				cols="12"
				v-for="(item, index) in notifications.list"
				:key="`notif${item, index}`"
				class="innerNotesContainer mb-3 pa-0 elevation-8"
			>
				<v-row align="center" class="upperSection">
					<v-col cols="12" class="pa-0 px-4 py-0">
						<v-row>
							<!-- DATE -->
							<v-col cols="12" class="pa-0">
								<span style="border: 1px solid #424242; border-radius: 3px" class="px-2">{{item.createdAt | momentDate}}</span>
							</v-col>
							<!-- SERVICE MESSAGE / NOTES -->
							<v-col cols="12" class="pa-0 py-0">
								<v-row align="center">
									<v-col cols="8">
										<v-icon color="primary" class="noteRoundedIcon mr-2">mdi-square-rounded</v-icon>
										<span
											class="objectValueItem"
										>
											{{humanReadableStatus(item.activityType)}}
										</span>
										<br>
										<span v-if="item.activityType === 'SERVICE NMI'">{{item.notes}}</span>
										<span v-if="item.activityType === 'STATUS CMP'">Filler You did a thing! You now have access to the service page yay! :) </span>
									</v-col>
									<v-col cols="4" class="text-right">
										<v-tooltip top>
											 <template v-slot:activator="{ on }">
												<v-btn
													@click="clear(item)"
													@mouseover="clearSetIndex(index)"
													@mouseout="removeSetIndex"
													small
													v-on="on"
													icon
												>
													<v-icon small v-if="clearNotifToggle != `clearNotifToggle${index}`">
														fas fa-bell
													</v-icon>
													<v-icon small v-if="clearNotifToggle === `clearNotifToggle${index}`">
														fas fa-bell-slash
													</v-icon>
												</v-btn>
											 </template>
											 <span>Clear</span>
										</v-tooltip>
									</v-col>

								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12" class="pa-0 px-4 py-2">
						<v-row>
							<v-col cols="12" class="pa-0">
								<v-row justify="space-between">
									<span class="statusLabel mr-2">
										{{humanReadableStatusLabel(item.activityType)}}
									</span>
									<Timer
										:createdAt="item.createdAt"
									></Timer>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import moment from 'moment'
import Timer from './timer'


export default {
	name: 'Notification_List',
	props: {
		notifications: Object
	},
	components: {Timer},
	data: () => ({
		//
		clearNotifToggle: null

	}),
	methods: {
		humanReadableStatus(item) {
			let text;
			switch(item) {
				case 'VOICEMAIL':
					text = 'We have left a voicemail for you.'
					break;
				case 'SERVICE APR':
					text = 'Your request to provide a service has been approved.'
					break;
				case 'SERVICE NMI':
					text = 'Carrier Onboarding needs more information to process your request.'
					break;
				case 'SERVICE DNY':
					text = 'Your request to provide a service has been denied.'
					break;
				default:
					text = item
			}
			return text
		},
		humanReadableStatusLabel(item) {
			let text;
			switch(item) {
				case 'VOICEMAIL':
					text = 'VOICEMAIL'
					break;
				case 'SERVICE APR':
					text = 'SERVICE UPDATE'
					break;
				case 'SERVICE DNY':
					text = 'SERVICE UPDATE'
					break;
				case 'SERVICE NMI':
					text = 'SERVICE UPDATE'
					break;
				case 'STATUS CMP':
					text = 'ONBOARDING COMPLETE'
					break;
				default:
					text = item
			}
			return text
		},
		// switchIcon(item) {
		// 	let text;
		// 	switch(item) {
		// 		case 'NOTE':
		// 			text = { icon: 'mdi-note-text-outline', color: '#FFD740' }
		// 			break;
		// 		case 'VOICEMAIL':
		// 			text = { icon: 'mdi-phone-message-outline ', color: '#307697' }
		// 			break;
		// 		case 'CLAIM':
		// 			text = { icon: 'mdi-handshake-outline', color: '#90EE90' }
		// 			break;
		// 		case 'DENIED':
		// 			text = { icon: 'mdi-close-octagon-outline ', color: '#E43935' }
		// 			break;
		// 		case 'RELEASE':
		// 			text = { icon: 'mdi-rocket-launch-outline', color: '#B0BEC5' }
		// 			break;
		// 		case 'SIGNED':
		// 			text = { icon: 'mdi-text-box-check-outline', color: '#BF90EE' }
		// 			break;
		// 		case 'PENDING':
		// 			text = { icon: 'mdi-account-clock-outline' , color: '#FFA12F' }
		// 			break;
		// 		case 'COMPLETE':
		// 			text = { icon: 'mdi-check-bold', color: '#FFBF00' }
		// 			// text = { icon: 'mdi-check-bold', color: '#00897B' }
		// 			break;
		// 		case 'PROCESS':
		// 			text = { icon: 'mdi-account-reactivate-outline', color: '#2069E0' }
		// 			break;
		// 		case 'STATUS UPDATE':
		// 			text = { icon: 'mdi-update', color: '#2069E0' }
		// 			break;
		// 		default:
		// 			text = item
		// 	}
		// 	return text
		// },
		clear({activityUUID}) {
			this.$emit('clear', activityUUID)
		},
		clearSetIndex(idx) {
			this.clearNotifToggle = `clearNotifToggle${idx}`
		},
		removeSetIndex(idx) {
			this.clearNotifToggle = null
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
	}
};
</script>
<style scoped>
.upperSection {
	background-color: #2d2e33 !important;
	border-radius: 15px 15px 0 0;
}
.noteRoundedIcon {
	font-size: 10px;
}
.statusLabel {
	background-color: #222326;
	padding-left: 8px;
	padding-right: 8px;
	color: #BB86FC !important;
	font-size: 12px;
	border-radius: 50px;
	font-weight: 600;
}
.overflowContainer {
	min-height: 400px;
	max-height: 400px;
	overflow-y: scroll;
}
.overflowContainer::-webkit-scrollbar {
	width: 10px;
	background: transparent;
}
.overflowContainer::-webkit-scrollbar-thumb {
	width: 10px;
	background: #3bc1e6;
	border-radius: 15px;
}

.noteWrapper {
	color: #BDBDBD;
	max-height: 50px;
	overflow-y: scroll;
}
.noteWrapper::-webkit-scrollbar {
	width: 10px;
	background: transparent;
}
.noteWrapper::-webkit-scrollbar-thumb {
	width: 10px;
	background: #8e86f9;
	border-radius: 15px;
}

.innerNotesContainer {
/*	min-height: 80px;
	max-height: 80px;
*/	border-radius: 10px;
	background-color:#1E1F22 !important;
}
.titleStyle{
	color:#e0e0e0;
	font-weight: 300;
	font-size: 1.6em
}
.objectKeyItem {
	/* font-weight: 900; */
	color:#757575;
}
.objectValueItem {
	color: #BDBDBD;
}
</style>