const fn = (string) => {
	if (!string) return null;
	return string
		.split(" ")
		.filter(word => !!word)
		.map(word => {
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
		.join(" ")
		.trim();
};

export default fn;