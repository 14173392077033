module.exports = `
    <h3 style="padding-bottom: 15px; font-weight: 400;">This voluntary public-private program aims to:</h3>
    <ul style="list-style: circle;">
        <li style="padding-bottom: 10px;">
            provide a comprehensive and well-recognized system for tracking, documenting and sharing information about fuel use and freight emissions across supply chains.
        </li>
        <li style="padding-bottom: 10px;">
        help companies identify and select more efficient freight carriers, transport modes, equipment, and operational strategies to improve supply chain sustainability and lower costs from goods movement.
        </li>
        <li style="padding-bottom: 10px;">
        support global energy security and offsets environmental risk for companies and countries.
        </li>
        <li style="padding-bottom: 10px;">
        reduce freight transportation-related emissions by accelerating the use of advanced fuel-saving technologies.
        </li>
    </ul>
`