<template>
	<v-container class="transparent">
		<span>{{ completedSteps }} of {{ totalSteps }} steps completed</span>
		<v-row class="mt-2" no-gutters>
			<v-col
			 v-for="step in carrierProgress"
			 :key="step.title"
			 cols="2">
				<v-card max-height="10px" class="ma-1" :class="step.valid ? 'primary' : 'grey'">
					<v-card-text></v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	name: 'Progress_Tracker',
	props: {
		carrierProgress: { type: Object, default: () => {} },
		currentPage: { type: String, default: null }
	},
	data() {
		return {
			totalSteps: 6
		};
	},
	computed: {
		completedSteps() {
			let carrierProgress = this.carrierProgress;
			let completed = 0;
			for ( const key in carrierProgress) {
				if (carrierProgress[key].valid) {
					++completed;
				}
			}

			// // IF CURRENT PAGE IS 'GENERAL' SUBTRACT 1 FROM THE TOTAL BECAUSE THE 'ADDRESSES' SECTION IS TECHNICALLY ALREADY VALID UPON SIGNUP
			// if (this.currentPage === 'general') {
			// 	completed -= 1;
			// }

			return completed;
		}
	}
};
</script>
<style scoped>

</style>