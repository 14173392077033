<template>
	<v-card class="mt-2">
		<v-toolbar dark flat>
		<v-toolbar-title>FAQ</v-toolbar-title>
			<template v-slot:extension>
				<v-tabs
					v-model="faqTabModel.value"
					slider-color="#fff"
				>
					<v-tab
						v-for="item in faqTabs"
						:key="item"
					>
						{{item}}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>

		<v-tabs-items v-model="faqTabModel.value">
			<v-tab-item
				v-for="item in faqTabs"
				:key="item"
			>
				<v-card v-if="item === 'Carb'" flat>
					<v-card-text class="list-scroll">
						<div class="title">What is CARB and do I need to prove compliance?</div>
						<br />
						<div><span class="font-weight-bold">CARB</span> stands for <span class="font-weight-bold">California Air Resource Board</span>. If you run in or out of California, you must be able to prove CARB Compliance. If your truck is <span class="font-weight-bold">2010 or newer</span> and/or you have updated your engine with the Gen Modifier/Adapter, you should be in compliance. However, you will need to go to the <a href="https://arber.arb.ca.gov/Welcome.arb" target="_blank">ARB website</a>, create an account and document your VIN numbers to obtain your certification and <span class="font-italic">prove</span> compliance.</div>
						<br />
						<div class="subheading">Please review the <a href="https://www.arb.ca.gov/msprog/onrdiesel/documents/multirule.pdf" target="_blank">ARB Resource Document</a> for more information.</div>
						<br />
						<v-container
							fluid
							grid-list-sm
							class="pa-0">
							<v-layout row wrap>
								<v-col>
									<v-card color="swiftBlue" class="white--text">
										<v-card-text>
											<div class="subheading font-weight-bold pb-2">CARB Compliance may be proven with one or more of the following:</div>
											<div class="pb-2">
												<span>
													<v-icon dark small class="px-1">fas fa-check</v-icon>
												</span>CARB Certificate for all trucks
											</div>
											<div class="pb-2">
												<span>
													<v-icon dark small class="px-1">fas fa-check</v-icon>
												</span>Copy for ARB Board information confirming certification of VIN numbers
											</div>
											<div class="pb-2">
												<span>
													<v-icon dark small class="px-1">fas fa-check</v-icon>
												</span>Gen Adapter/PM Install Info and Documentation
											</div>
											<div class="pb-2">
												<span>
													<v-icon dark small class="px-1">fas fa-check</v-icon>
												</span>List of Scheduled Autos (from your agent) showing the year, VIN number, make and model
											</div>
											<div class="pb-2">
												<span>
													<v-icon dark small class="px-1">fas fa-check</v-icon>
												</span>Cab Cards showing the year, VIN number, make and model
											</div>
										</v-card-text>
									</v-card>
								</v-col>
							</v-layout>
						</v-container>
					</v-card-text>
				</v-card>
				<v-card v-if="item === 'Hazmat'" flat>
					<v-card-text class="list-scroll">
						<div class="title">Hazmat</div>
						<br />
						Activating your hazmat certification requires the following information to be provided:
						<br />
						<v-container
							fluid
							grid-list-sm
							class="pa-0">
							<v-layout row wrap>
								<v-col>
									<v-card color="swiftBlue" class="white--text">
										<v-card-text>
											some stuff
										</v-card-text>
									</v-card>
								</v-col>
							</v-layout>
						</v-container>
					</v-card-text>
				</v-card>
				<v-card v-if="item === 'Power Only'" flat>
					<v-card-text class="list-scroll">
						<div class="title">Power Only</div>
						<br />
						some stuff about Power Only
						<br />
						<v-container
							fluid
							grid-list-sm
							class="pa-0">
							<v-layout row wrap>
								<v-col>
									<v-card color="swiftBlue" class="white--text">
										<v-card-text>
											some stuff
										</v-card-text>
									</v-card>
								</v-col>
							</v-layout>
						</v-container>
					</v-card-text>
				</v-card>
				<v-card v-if="item === 'How To'" flat>
					<v-card-text class="list-scroll">
						<div class="title">Service instructions</div>
						<br />
						some service Stuff
						<br />
					</v-card-text>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>
<script>
export default {
	name: 'Service_Faq',
	props: {
		faqModel: Object
	},
	mixins: [
	],
	components: {
	},
	data() {
		return {
			faqTabs:['How To','Carb', 'Hazmat', 'Power Only'],
		}
	},
	computed: {
		faqTabModel: {
			get: function () {
				return this.faqModel;
			},
			set: function (newValue) {
				this.faqTabModel.value = newValue;
			}
		}
	}
};
</script>
