<template>
	<v-card flat>
		<v-card-text class="list-scroll">
			<v-row class="title" align="center">
				<v-col class="pl-0">
					<v-row>
						What is a certificate of insurance and what's needed to haul for Knight/Swift?
					</v-row>
				</v-col>
			</v-row>
			<v-row class="mt-3">
				<div class="subheading">
					<span class="font-weight-black">What is a Certificate of Insurance?:&nbsp;</span>
					<br />
					It's the signed certificate issued by an insurance agent showing&nbsp;<span class="font-weight-black">Auto ($1 Million minimum)</span>&nbsp;&amp;&nbsp;<span class="font-weight-black">Cargo liability ($100K minimum)</span>&nbsp; all deductibles with other additional insurance (trucks/vehicle &amp; trailers) for the carrier insured.</div>
					<br />
					<span class="primary--text">**Must be 30 Days or newer from insurance agent with <span class="font-weight-black">Knight Swift Logistics</span> listed as certificate holder.**</span>
				
				<!-- <div class="body-2">Certificate Holder:&nbsp;<span class="font-weight-black">KNIGHT/SWIFT LOGISTICS</span></div>
				<div class="body-2">Address:&nbsp;<span class="font-weight-black">20002 N. 19th Ave. Phoenix, AZ OR 2200 S. 75th Ave. Phoenix, AZ 85043</span></div> -->
				<br />
				<div class="subheading">
					<span class="font-weight-black">What is needed to haul for KNIGHT/SWIFT?&nbsp;</span>
					<br />
					Please request an updated certificate with today's date per the following Certificate Holder listed as:&nbsp;
					<span class="font-weight-black">KNIGHT/SWIFT LOGISTICS</span>&nbsp;with address:<br />
					<span class="font-weight-black">20002 N. 19th Ave. Phoenix, AZ OR 2200 S. 75th Ave. Phoenix, AZ 85043</span>
					<!-- <router-link to="/documents">{{ `Documents page` }}</router-link> -->
				</div>
			</v-row>
			<v-row row wrap class="mt-3">
				<v-col class="pl-0 mb-3">
					<span>
						*Please have agent notate in "comments" field any coverage approvals if carrier is allowed to haul: electronics, beer, wine, liquor, tobacco &amp; copper.
					</span>
				</v-col>
				<div class="subheading">
					<span class="font-weight-black">We do not accept the following cargo underwriters:</span>&nbsp;
				</div>
				<br />
				<ul style="list-style-type: none; padding-left: 0;">
					<li>Great Lakes</li>
					<li>PRIME</li>
					<li>ITMA</li>
					<li>Qualitas</li>
					<li>Pennsylvania manufacturing (also known as PMA or PENN MFG)</li>
				</ul>
				<br/>
				<div class="subheading" >
					<span class="font-weight-black">
						Please upload this certificate on the SELECT "Documents" page once received from your agent, thank you!
					</span>
				</div>
			</v-row>
			<v-row justify="start" class="mt-5">
				<v-btn color="secondary" rounded @click="openInsuranceDialog" small outlined>
					Request COI From Agent
				</v-btn>
			</v-row>
		</v-card-text>


		<v-dialog v-model="insModal" scrollable persistent max-width="500px" transition="dialog-transition">
			<v-card>
				<v-card-title class="grey darken-4">
					<v-row justify="space-between">
						<span class="headline white--text">Request Insurance</span>
						<v-icon color="primary darken-1" @click="closeModel" >mdi-close-thick</v-icon>
					</v-row>
				</v-card-title>
				<v-form v-model="insFormValid" ref="insForm" v-on:submit.prevent>
					<v-card-text>
						<v-col col="12">
							<v-text-field
								label="Insurance Email"
								v-model="insuranceEmail"
								persistent-hint
								hint="*Required"
								clearable
								required
								:rules="[$formRules.required, $formRules.email]"
								@keyup.enter="sendInsRequest"
							></v-text-field>
						</v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<!-- <v-btn color="primary" outlined @click="insModal = false">Cancel</v-btn> -->
						<v-btn color="primary" @click="sendInsRequest()" :disabled="!insFormValid">
							<span v-if="!loading">
								Send <v-icon class="ml-2">mdi-email-outline</v-icon>
							</span>
							<span v-else>
								<v-icon>fas fa-spinner fa-pulse</v-icon>
							</span>
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog"  @close="closeBasicDialog" />
	</v-card>
</template>
<script>
import basicDialogMixin from '@/mixins/basicDialogMixin.js';

export default {
	name: 'Insurance_Faq',
	props: {
	},
	mixins: [
		basicDialogMixin
	],
	components: {
	},
	data() {
		return {
			insuranceEmail: null,
			insFormValid: false,
			insModal: false,
			loading: false,
		}
	},
	methods: {
		openInsuranceDialog() {
			this.insModal = true;
		},
		closeModel() {
			this.insModal = false;
		},
		async sendInsRequest() {
			try{
				this.loading = true;
				await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/insurance/email`,
					data: {email: this.insuranceEmail},
					headers: {
						Authorization: 'Bearer ' + this.auth.token
					},
				});
				this.loading = false
				this.basicDialog = {
					value: true,
					title: 'Success!',
					text: 'An insurance request has been sent to your provider, they will reach out to us.  Thank you!',
					hasAction: false,
					width: '400',
				};
				setTimeout(() => {
					this.closeBasicDialog();
					this.insModal = false;
				}, 5000);
			} catch(error) {
				this.loading = false
				this.basicDialog = {
					value: true,
					title: 'We\'ve encountered a problem',
					text: 'It appears that we do not have insurance information in our database for you, please contact your provider to request an insurance certificate. Once received please email it directly to carriers@knightswiftlogistics.com',
					hasAction: false,
					width: '400',
				};
				setTimeout(() => {
					this.closeBasicDialog();
				}, 6000);
			}
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
	}
};
</script>