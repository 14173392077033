<template>
	<v-container fill-height fluid>
		<HeroImage></HeroImage>
		<v-row align="center" justify="center" class="mt-0">
			<v-col md="12" lg="12">
				<v-row align="center" justify="center" >
					<v-col cols="12" sm="10" md="7" lg="6" xl="4" style="z-index:5;">
						<img src="../../assets/blackWhiteLogo.svg" />
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="10" md="7" lg="6" xl="4" justify-self="center">
				<v-card class="elevation-12 pb-4">
					<LoginCard v-if="!showForm" @loginIsValid="showForm = true" />
					<SignUpForm v-if="showForm" />
					<v-row>
						<v-col cols="12" class="text-center">
							<span class="text-caption primary--text pointer" @click="$router.push('/')">
								Back to Home
							</span>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
// components
import HeroImage from '../HeroImage';
import LoginCard from './components/login';
import SignUpForm from './components/signUpForm';

export default {
	name: 'Login',
	components: {
		HeroImage,
		LoginCard,
		SignUpForm
	},
	data() {
		return {
			showForm: false
		};
	},
	methods: {
		//
	},
	computed: {
		//
	},
	mounted() {
		//
	},
};
</script>

<style scoped>
img {
	z-index: 10;
	height: 75px;
}
.pointer {
	cursor: pointer;
}
</style>
