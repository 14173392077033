<template>
	<v-card flat>
		<v-card-text class="list-scroll">
			Internal Revenue Service (IRS) W8 forms are for foreign individuals/entities file to verify their country of residence and confirm if they qualify for a lower withholding tax rate. Forms are submitted only to payers/withholding agents, not to the IRS. Failure to submit a W-8 form may result in a withholding at a full rate applicable to foreign entities. This form must be signed by an Owner or Corp Manager authorized to sign on behalf of the company legally.
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'WEight_Assistance'
};
</script>