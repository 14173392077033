import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const options = {
	breakpoint: {
		thresholds: {
			xl: 2400,
		},
	},
	icons: {
		iconfont: 'fa' || 'mdi',
	},
	theme: {
		dark: true,
		themes: {
			light: {
				primary: '#0069bd',
				secondary: '#424242',
				error: '#CF6679',
				info: '#0069bd',
				success: '#4CAF50',
				warning: '#FFC107',
				anchor: '#8c9eff',
			},
			dark: {
				// primary: '#8121F3',
				primary: '#BB86FC',
				primaryVariant: '#3700B3',
				// secondary: '#03DAC6',
				secondary: '#3BC1E6',
				error: '#FF5252',
				info: '#1E272E',
				success: '#1DB954',
				warning: '#FFA12F',
				anchor: '#BB86FC',
			},
		},
		options: { customProperties: true },
	},
};

export default new Vuetify(options);
