<template>
	<v-card class="elevation-8 mt-2" :img="require('../../../../assets/service.svg')" :class="{glowingCard : serviceKey == 'powerOnly'}">
		<v-row>
			<v-col cols="12">
				<v-row justify="center">
					<v-col cols="12" class="pa-0 text-right">
						<v-btn
							fab
							small
							color="#8353c3"
							@click="$emit('selectService', { serviceData, serviceKey })"
						>
							<!-- color="#333333" -->
							<v-icon> mdi-plus </v-icon>
						</v-btn>
					</v-col>
					<v-col cols="12" class="pa-0 text-center">
						<img
							:alt="`${subTitleFilter(serviceKey)} Icon`"
							:src="require(`../../../../assets/${iconParser(serviceKey)}`)"
							height="40px"
						/>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" class="px-2 py-1 pb-3">
				<v-row justify="center">
					<span class="truncate">
						{{ subTitleFilter(serviceKey) }}
					</span>
				</v-row>
			</v-col>

			<v-col cols="12" class="pa-0 py-2 cardAction">
				<v-row justify="space-between" align="center">
					<v-col cols="12" class="cardActionContent text-center pa-0">
						<v-row
							align="center"
							justify="center"
							@click="$emit('selectAssistance', { serviceData, serviceKey })"
						>
							<span style="color: #ffa12f">Description</span>
							<v-icon color="warning" class="ml-2" small
								>mdi-help-circle-outline</v-icon
							>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'Service_Cards',
	props: {
		serviceData: { type: Object, default: () => {} },
		serviceKey: { type: String, default: null },
	},
	data() {
		return {};
	},
	methods: {
		iconParser(service) {
			let fileName;
			switch (service) {
				// case 'fsmaRegulated':
				// 	fileName = 'serviceFsma.svg';
				// 	break;
				case 'hazm':
					fileName = 'serviceHazmat.svg';
					break;
				case 'liftGate':
					fileName = 'serviceLiftGate.svg';
					break;
				case 'tanker':
					fileName = 'serviceTanker.svg';
					break;
				case 'dropTrailers':
					fileName = 'serviceDropTrailer.svg';
					break;
				case 'intermodal':
					fileName = 'serviceIntermodal.svg';
					break;
				case 'teamExpedited':
					fileName = 'serviceExpedited.svg';
					break;
				case 'carb':
					fileName = 'serviceCarb.svg';
					break;
				case 'smartway':
					fileName = 'serviceSmartWay.svg';
					break;
				case 'mexico':
					fileName = 'serviceMexico.svg';
					break;
				case 'canada':
					fileName = 'serviceMexico.svg';
					break;
				case 'gps':
					fileName = 'serviceGPS.svg';
					break;
				case 'truckerTools':
					fileName = 'serviceTanker.svg';
					break;

				case 'reefer':
					fileName = 'serviceDropTrailer.svg';
					break;
				case 'twic':
					fileName = 'serviceTwic.svg';
					break;
				case 'macropoint':
					fileName = 'serviceMacropoint.svg';
					break;
				case 'powerOnly':
					fileName = 'powerOnly.svg';
					break;
				default:
					fileName = service;
			}
			return fileName;
		},
		setActionIconColor(item) {
			let color = '#424242';
			if (!item) {
				color = '#424242';
			} else {
				color = '#BDBDBD';
			}
			return color;
		},
		setActionTextColor(item) {
			let style = { color: '#424242' };
			if (!item) {
				style = { color: '#424242' };
			} else {
				style = { color: '#BDBDBD' };
			}
			return style;
		},
		subTitleFilter(item) {
			let text;
			switch (item) {
				// case 'fsmaRegulated':
				// 	text = 'FSMA';
				// 	break;
				case 'hazm':
					text = 'Hazmat';
					break;
				case 'liftGate':
					text = 'Lift Gate';
					break;
				case 'tanker':
					text = 'Tanker';
					break;
				case 'dropTrailers':
					text = 'Drop Trailer';
					break;
				case 'intermodal':
					text = 'Intermodal';
					break;
				case 'powerOnly':
					text = 'Power Only';
					break;
				case 'teamExpedited':
					text = 'Expedited';
					break;
				case 'carb':
					text = 'CARB';
					break;
				case 'smartway':
					text = 'Smartway';
					break;
				case 'canada':
					text = 'Canada';
					break;
				case 'mexico':
					text = 'Mexico';
					break;
				case 'gps':
					text = 'GPS';
					break;
				case 'truckerTools':
					text = 'Trucker Tools';
					break;
				case 'twic':
					text = 'TWIC';
					break;
				case 'reefer':
					text = 'Refrigerated Trailer';
					break;
				case 'macropoint':
					text = 'Macropoint';
					break;
				default:
					text = item;
			}
			return text;
		},
		async viewDocument(item) {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/view`,
					params: {
						documentId: this.documentInfo[item].documentId,
					},
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
					responseType: 'blob',
				});

				this.documentBlob = URL.createObjectURL(response.data);

				const link = document.createElement('a');
				link.setAttribute('type', 'hidden');
				link.target = '_blank';
				link.href = this.documentBlob;
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (error) {
				console.error('viewDocument error:', error);
			}
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		serviceInfo() {
			return this.$store.state.services;
		},
		documentInfo() {
			return this.$store.state.documents;
		},
	},
};
</script>

<style scoped>
.cardAction {
	background-color: #282828;
	border-radius: 0 0 10px 10px;
}
.cardActionContent {
	font-size: 12px;
	cursor: pointer;
}
.active {
	cursor: pointer;
}
.iconClass {
	color: #bdbdbd;
}
.glowingCard {
	box-shadow: 0px 0px 25px 5px #FFA12F !important;

	/*color changing text animation*/
	/*color: #fff;
	border-radius: 5px;
	animation: blinkingBackground 6s infinite !important;
	}
	@keyframes blinkingBackground{
	0% { color: #BB86FC;}
	25% { color: #3700B3;}
	50% { color: #3BC1E6;}
	75% { color: #b809b8;}
	100% { color: #05e6b1;}*/
}
</style>
