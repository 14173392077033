<template>
	<v-card class="px-5">
		<v-row>
			<v-col class="xsFlex text-center px-0">
				<v-row align="start" justify="center">
					<v-icon
					 color="#BDBDBD"
					 style="font-size: 26px"
					 class="mt-2">
						mdi-truck
					</v-icon>
				</v-row>
			</v-col>
			<v-col class="lgFlex">
				<v-row>
					<span class="title">
						Interested in Hauling Power Only?
					</span>
				</v-row>
				<v-row class="py-1">
					<span class="description"> - Learn more here! </span>
				</v-row>
			</v-col>
			<v-col style="cursor: pointer" class="smFlex text-center px-0">
				<v-row style="height: 100%" justify="end" align="center">
					<v-btn @click="openPowerOnlyModal" small color="#BDBDBD" text>
						<v-icon style="font-size: 20px" color="#BDBDBD">fa-solid fa-chevron-right</v-icon>
					</v-btn>
				</v-row>
			</v-col>
			<v-col cols="12" class="pa-0">
				<v-divider></v-divider>
			</v-col>
			<v-col class="xsFlex text-center px-0">
				<v-row align="start" justify="center">
					<v-img :src="require('@/assets/ITS_logo.png')" width="100" id="ironServicesLogo"></v-img>
				</v-row>
			</v-col>
			<v-col class="lgFlex">
				<v-row>
					<span class="title">
						Interested in insurance, fuel savings, maintenance, equipment sales & rentals and more?
					</span>
				</v-row>
				<v-row class="py-1">
					<span class="description"> - Learn more here! </span>
				</v-row>
			</v-col>
			<v-col style="cursor: pointer" class="smFlex text-center px-0">
				<v-row style="height: 100%" justify="end" align="center">
					<v-btn @click="openIronServicesModal" small color="#BDBDBD" text>
						<v-icon style="font-size: 20px" color="#BDBDBD">fa-solid fa-chevron-right</v-icon>
					</v-btn>
				</v-row>
			</v-col>
			<v-col cols="12" class="pa-0">
				<v-divider></v-divider>
			</v-col>
			<v-col class="xsFlex text-center px-0">
				<v-row align="start" justify="center">
					<v-icon
					 color="#BDBDBD"
					 style="font-size: 26px"
					 class="mt-2">
						mdi-lightbulb
					</v-icon>
				</v-row>
			</v-col>
			<v-col class="lgFlex">
				<v-row>
					<span class="title">
						Need Help with the Onboarding Process?
					</span>
				</v-row>
				<v-row class="py-1">
					<span class="description"> - Click here for video tutorials </span>
				</v-row>
			</v-col>
			<v-col style="cursor: pointer" class="smFlex text-center px-0">
				<v-row style="height: 100%" justify="end" align="center">
					<v-btn @click="openOnboardingProcessModal" small color="#BDBDBD" text>
						<v-icon style="font-size: 20px" color="#BDBDBD">fa-solid fa-chevron-right</v-icon>
					</v-btn>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
export default {
	name: 'Important_Links',
	props: {},
	mixins: [],
	data() {
		return {
			//
		};
	},
	computed: {
		//
	},
	methods: {
		openPowerOnlyModal() {
			this.$emit('openPowerOnlyModal');
		},
		openIronServicesModal() {
			this.$emit('openIronServicesModal');
		},
		openOnboardingProcessModal() {
			this.$emit('openOnboardingProcessModal');
		}
	}
};
</script>
<style scoped>
#ironServicesLogo {
	background-color: #FFF;
}
</style>