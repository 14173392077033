<template>
    <v-col cols="12">
        If you get interrupted at any point during the onboarding process, you may log in and return to where you left off.
    </v-col>
</template>
<script>
export default {
	data() {
		return {};
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>
