<template>
	<v-dialog
	 :value="value"
	 persistent
	 transition="dialog-transition"
	 width="900">
		<v-card class="pa-3">
			<v-card-title class="headline" >
				<v-row align="center" >
					<span style="word-break: break-word;">
						Application Submitted Successfully!
					</span>
				</v-row>
			</v-card-title>
			<v-card-text class="subtitle-1">
				<v-row class="mt-3">
					We have received your application! While our review process is taking place, please head back to the dashboard where you will now have access to manage your offered services. Once we have finished reviewing your application, you will receive a notification via email and in your notifications panel.
				</v-row>
				<v-row class="mt-3">
					*Please note that this DOT is not confirmed eligible to haul freight or equipment with Knight or Swift until you receive a rate confirmation and confirmation from a Knight or Swift representative.
				</v-row>
			</v-card-text>
			<v-card-actions class="cardBtnActions my-4" :style="cardActionsStyle" @click="goToServices">
				Interested in hauling Power Only?
				<v-icon small class="ml-1">fa-solid fa-hand-point-right</v-icon>
			</v-card-actions>
			<v-card-actions class="cardBtnActions my-4" style="cursor: pointer; border: 1px solid #A1A2A3;" @click="closeDialog">
				Continue to Dashboard
			</v-card-actions>
			<v-card-text class="subtitle-1">
				<v-row class="mt-3">
					<span>
						If you have any issues or questions, check out the FAQ/Help Topics card on the Dashboard page or&nbsp;<v-btn @click="goToContact" text class="pa-0" :style="buttonStyle">Contact Us</v-btn>&nbsp;directly.
					</span>
				</v-row>
				<v-row class="mt-3">
					Thank you,
				</v-row>
				<v-row class="ml-2 my-2">
					- Knight-Swift Carrier Relations
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	name: "Contract_Signed_Dialog",
	props: { value: Boolean },
	data() {
		return {};
	},
	methods: {
		closeDialog() {
			this.$emit('input');
			this.$background.blur = false;
			this.$router.push('/dashboard');
		},
		goToServices() {
			this.$background.blur = false;
			this.$router.push('/services');
		},
		goToContact() {
			this.$background.blur = false;
			this.$router.push('/contact');
		}
	},
	computed: {
		cardActionsStyle() {
			return { 'background-color': '#673aa7', 'cursor': 'pointer', 'color': '#FFF' }
		},
		buttonStyle() {
			return { 'color': '#BB86FC', 'text-decoration': 'underline', 'cursor': 'pointer', 'font-size': '16px' }
		}
	},
	watch: {
		value(bool) {
			if (bool) this.$background.blur = true;
		},
	},
};
</script>
<style >
.cardBtnActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0;
	margin-left: 20%;
	margin-right: 20%;
}
</style>