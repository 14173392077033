import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import store from '../store';

const debug = false;

const instance = Vue.use(new VueSocketIO({
	debug,
	connection: `${process.env.VUE_APP_ONBOARDING_SOCKETS}`,
	vuex: {
		store,
		actionPrefix: 'SOCKET_',
		mutationPrefix: 'SOCKET_'
	},
	options: { path: `/${process.env.VUE_APP_SOCKETS_PATH}/socket.io/onboarding` }
}));

export default instance;
