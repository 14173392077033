<template>
	<v-navigation-drawer :width="drawerWidth" :value="value" absolute app temporary close @input="$emit('input')">
		<v-row class="pa-3">
			<router-link :to="routeDashboard()">
				<img alt="Knight Swift Transportation Logo" src="../../../assets/blackWhiteLogo.svg" style="cursor: pointer" height="40px"/>
			</router-link>
			<v-spacer></v-spacer>
			<v-col class="pa-0">
				<v-row class="fill-height" align="start" justify="end">
					<v-icon @click="$emit('input', false)">mdi-close</v-icon>
				</v-row>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<v-list-item-group id="listGroup" active-class="primary--text text--accent-2">
			<v-list-item class="py-2" v-for="(item, index) in filteredMenuItems" :key="index" @click="$emit('input', false)" :to="`${item.route}`">
				<v-list-item-title>
					<v-row align="center">
						<v-icon color="primary">{{  item.icon  }}</v-icon>
						<v-col cols="11" md="4" sm="11">
							{{ item.title }}
						</v-col>
					</v-row>
					<v-divider></v-divider>
				</v-list-item-title>
			</v-list-item>
		</v-list-item-group>

		<template v-slot:append>
			<v-row class="justify-center">
				<v-col cols="10">
					<v-btn block color="primary darken-4" @click="routeMe">
						{{ $store.state.auth.loggedIn ? 'Logout' : 'Login' }}
					</v-btn>
				</v-col>
			</v-row>
			<v-divider class="mt-3"></v-divider>
			<Footer/>
		</template>
	</v-navigation-drawer>
</template>

<script>
import Footer from '../../Footer';
import MenuData from './menuData';

export default {
	components: { Footer },
	props: {
		value: Boolean,
	},
	data: () => ({
		//
		menuItems: MenuData,
	}),
	computed: {
		//
		auth() {
			return this.$store.state.auth;
		},
		drawerWidth() {
			if (this.$vuetify.breakpoint.smAndDown) return '100%';
			return '300px';
		},
		filteredMenuItems() {
			const filtered = this.menuItems.filter((item) => {
				if (item.requireLoggedIn && !this.$store.state.auth.loggedIn) return false;
				return true;
			});
			return filtered;
		},
	},
	methods: {
		//
		routeDashboard() {
			if (this.auth.token) {
				if (this.$route.path !== "/dashboard") return '/dashboard';
			}
			return ''
		},
		routeMe() {
			if (this.$store.state.auth.loggedIn) return this.$router.push('/logout');
			this.$router.push('/login');
		},
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>

<style scoped>
/* */
.menu-link {
	text-decoration: none;
	color: white;
}
</style>
