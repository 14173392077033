<template>
	<v-row align="center" justify="center">
		<NavigationButtons
			:options="navButtonData"
			title="Service Management"
			@saveSignupInfo="saveSignupHandler"
		></NavigationButtons>

		<v-col cols="12" lg="10" class="justify-center align-center ma-0 pa-0 mt-5">
			<v-row class="px-4 py-4">
				<v-col cols="12" class="pa-0"> Add Services </v-col>
			</v-row>
			<v-row justify="center" align="center">
				<v-col cols="12" class="pa-0">
					<v-row v-if="Object.entries(inactiveServiceData).length != 0">
						<template v-for="(item, index) in Object.entries(inactiveServiceData)">
							<v-col
								:key="`service${index}`"
								v-if="
									item[1].external.status === null ||
									item[1].external.status === 'nint'
								"
								cols="6"
								md="3"
								:class="$vuetify.breakpoint.lgAndUp ? 'lgAndUp' : ''"
							>
								<ServiceCard
									:serviceData="item[1]"
									:serviceKey="item[0]"
									@viewServiceNotes="openNotesDialog"
									@selectService="openServiceDialog"
									@selectAssistance="openAssistanceDialog"
								></ServiceCard>
							</v-col>
						</template>
					</v-row>
					<v-row class="px-4 py-0" v-else>
						<v-col> You have signed up for all services! </v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-col>

		<v-col cols="12" lg="10" class="justify-center pa-0 mt-5" align-self="start">
			<v-card class="px-4">
				<v-col cols="12">
					<v-row class="px-4 pt-4"> My Services </v-row>
					<v-row class="mb-2" justify="end">
						<div
							style="position: relative"
							class="pb-1 mx-2"
							:class="selectedStatus === 'int' ? 'selectFilter' : ''"
						>
							<div class="serviceTotals">
								<span>{{ totalPending }}</span>
							</div>
							<v-btn @click="selectedStatus = 'int'" small text color="secondary"
								>Pending Approval</v-btn
							>
						</div>
						<div
							style="position: relative"
							class="pb-1 mx-2"
							:class="selectedStatus === 'apr' ? 'selectFilter' : ''"
						>
							<div class="serviceTotals">
								<span>{{ totalApproved }}</span>
							</div>
							<v-btn @click="selectedStatus = 'apr'" small text color="secondary"
								>Approved</v-btn
							>
						</div>
						<div
							style="position: relative"
							class="pb-1 mx-2"
							:class="selectedStatus === 'dny' ? 'selectFilter' : ''"
						>
							<div class="serviceTotals">
								<span>{{ totalDenied }}</span>
							</div>
							<v-btn @click="selectedStatus = 'dny'" small text color="secondary"
								>Denied</v-btn
							>
						</div>
						<div
							style="position: relative"
							class="pb-1 mx-2"
							:class="selectedStatus === 'nmi' ? 'selectFilter' : ''"
						>
							<div class="serviceTotals">
								<span>{{ totalNMI }}</span>
							</div>
							<v-btn @click="selectedStatus = 'nmi'" small text color="secondary"
								>Need More Info</v-btn
							>
						</div>
					</v-row>
					<v-row>
						<v-col cols="12" class="pa-0 px-3 pr-4">
							<v-row>
								<v-col cols="3" class="mySerivcesHeaders pa-0"> Service </v-col>
								<v-col cols="3" class="mySerivcesHeaders pa-0"> Status </v-col>
								<v-col cols="3" class="mySerivcesHeaders pa-0"> Updated </v-col>
								<v-col cols="3" class="text-center mySerivcesHeaders pa-0">
									Actions
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="myServicesScroll px-3">
						<v-col
							cols="12"
							class="pa-0"
							v-if="Object.entries(activeServiceData).length != 0"
						>
							<template v-for="(item, index) in Object.entries(activeServiceData)">
								<v-row
									:key="index"
									class="pa-0 my-1 rowBackground"
									v-if="selectedStatus === item[1].currentStatus"
								>
									<RowCard
										:rowData="item[1]"
										:rowKey="item[0]"
										:index="index"
										@viewServiceNotes="openNotesDialog"
										@selectService="openServiceUpdateDialog"
										@viewService="openServiceViewDialog"
										@stopService="openServiceStopDialog"
									></RowCard>
								</v-row>
							</template>
						</v-col>
						<v-col
							v-if="totalPending === 0 && selectedStatus === 'int'"
							align-self="center"
						>
							<v-row align="center" justify="center">
								No services are pending for approval
							</v-row>
						</v-col>
						<v-col
							v-if="totalApproved === 0 && selectedStatus === 'apr'"
							align-self="center"
						>
							<v-row align="center" justify="center">
								No services have been approved
							</v-row>
						</v-col>
						<v-col
							v-if="totalDenied === 0 && selectedStatus === 'dny'"
							align-self="center"
						>
							<v-row align="center" justify="center">
								No services have been denied
							</v-row>
						</v-col>
						<v-col
							v-if="totalNMI === 0 && selectedStatus === 'nmi'"
							align-self="center"
						>
							<v-row align="center" justify="center">
								No services need more information
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-col>

		<ServiceDialog
			v-model="serviceDialog"
			:selectedServiceData="selectedServiceData"
			:selectedServiceKey="selectedServiceKey"
			:serviceDialogType="serviceDialogType"
			:serviceAnimation="serviceAnimation"
			:serviceSuccess="serviceSuccess"
			:carrierInfo="carrierInfo"
			@closeServiceDialog="closeServiceDialog"
			@updateService="updateService"
		></ServiceDialog>
		<basic-dialog
			v-model="basicDialog.value"
			:options="basicDialog"
			@close="closeBasicDialog"
		></basic-dialog>
	</v-row>
</template>
<script>
import basicDialogMixin from '@/mixins/basicDialogMixin';

import FAQ from './faq.vue';
import ServiceCard from './serviceCard.vue';
import RowCard from './rowCard.vue';
import ServiceDialog from './serviceDialog.vue';

import labelFilter from '../../../../mixins/formBuilding/labelFilter.js';
import columnSize from '../../../../mixins/formBuilding/columnSize.js';
import ruleMethod from '../../../../mixins/formBuilding/ruleMethod.js';

export default {
	name: 'Services',
	mixins: [basicDialogMixin, labelFilter, columnSize, ruleMethod],
	components: {
		// FAQ,
		ServiceCard,
		RowCard,
		ServiceDialog,
	},
	data() {
		return {
			selectedStatus: 'int',
			//
			basicDialog: {
				value: false,
				title: null,
				text: null,
				hasAction: false,
				actionButton: {
					action: null,
					text: null,
					color: null,
				},
				width: null,
			},
			//
			serviceDialog: false,
			serviceDialogType: null,
			//
			powerOnlyDialog: false,
			miniDialogOpen: false,
			//
			selectedServiceData: null,
			selectedServiceKey: null,
			selectedCat: null,
			actionText: null,
			insModal: false,
			//
			serviceAnimation: false,
			serviceSuccess: false,
		};
	},
	sockets: {
		updateNotificationOnboarding(val) {
			if (val.uuid === this.auth.uuid) {
				this.$store.commit('updateNotificationOnboarding', val);
			}
		},
		updateServiceOnboarding(val) {
			if (val.uuid === this.auth.uuid) {
				this.getCarrierServices();
				this.$store.commit('socketedUpdateServices', val);
			}
		},
	},
	methods: {
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					},
				});

				if (data) this.$store.commit('updateServices', data);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async saveSignupHandler() {
			this.$router.push(`/dashboard`);
		},
		openServiceViewDialog({ rowData, rowKey }) {
			this.selectedServiceData = rowData;
			this.selectedServiceKey = rowKey;
			this.serviceDialogType = 'view';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		openServiceStopDialog({ rowData, rowKey }) {
			this.selectedServiceData = rowData;
			this.selectedServiceKey = rowKey;
			this.serviceDialogType = 'stop';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		openServiceUpdateDialog({ rowData, rowKey }) {
			this.selectedServiceData = rowData;
			this.selectedServiceKey = rowKey;
			this.serviceDialogType = 'add';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		openNotesDialog({ rowData, rowKey }) {
			this.selectedServiceData = rowData;
			this.selectedServiceKey = rowKey;
			this.serviceDialogType = 'notes';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		openServiceDialog({ serviceData, serviceKey }) {
			this.selectedServiceData = serviceData;
			this.selectedServiceKey = serviceKey;
			this.serviceDialogType = 'add';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		openAssistanceDialog({ serviceData, serviceKey }) {
			this.selectedServiceData = serviceData;
			this.selectedServiceKey = serviceKey;
			this.serviceDialogType = 'assistance';
			this.serviceDialog = true;
			this.$background.blur = true;
		},
		closeServiceDialog() {
			this.serviceDialog = false;
			this.$background.blur = false;
		},
		async updateService(payload) {
			try {
				const { data } = await this.$axios({
					method: 'post',
					url: `${this.$hostName.apiBase}/onboarding/service`,
					data: payload,
					headers: {
						Authorization: 'Bearer ' + this.auth.token,
					},
				});

				this.$store.commit('updateServices', data);
				this.serviceAnimation = true;

				setTimeout(() => {
					this.serviceAnimation = false;
					this.serviceSuccess = true;
				}, 2000);

				setTimeout(() => {
					this.serviceSuccess = false;
					this.closeServiceDialog();
				}, 5000);
			} catch (error) {
				this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: `Adding ${this.subTitleFilter(
						this.selectedServiceKey
					)} service has failed. Please try again later.`,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				};
			}
		},
		subTitleFilter(item) {
			let text;
			switch (item) {
				// case 'fsmaRegulated':
				// 	text = 'FSMA';
				// 	break;
				case 'hazm':
					text = 'Hazmat';
					break;
				case 'liftGate':
					text = 'Lift Gate';
					break;
				case 'tanker':
					text = 'Tanker';
					break;
				case 'dropTrailers':
					text = 'Drop Trailer';
					break;
				case 'reefer':
					text = 'Refrigerator';
					break;
				case 'intermodal':
					text = 'Intermodal';
					break;
				case 'powerOnly':
					text = 'Power Only';
					break;
				case 'teamExpedited':
					text = 'Expedited';
					break;
				case 'carb':
					text = 'CARB';
					break;
				case 'smartway':
					text = 'Smartway';
					break;
				case 'canada':
					text = 'Canada';
					break;
				case 'mexico':
					text = 'Mexico';
					break;
				case 'gps':
					text = 'GPS';
					break;
				case 'truckerTools':
					text = 'Trucker Tools';
					break;
				case 'twic':
					text = 'TWIC';
					break;
				case 'macropoint':
					text = 'Macropoint';
					break;
				default:
					text = item;
			}
			return text;
		},
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		carrierInfo() {
			return this.$store.state.onboardingInfo;
		},
		carrierStatus() {
			return this.$store.state.onboardingInfo.status;
		},
		inactiveServiceInfo() {
			return this.$store.state.services.inactive;
		},
		activeServiceInfo() {
			return this.$store.state.services.active;
		},
		inactiveServiceData() {
			return this.inactiveServiceInfo;
		},
		activeServiceData() {
			return this.activeServiceInfo;
		},
		totalPending() {
			return this.$store.state.services.totalPending;
		},
		totalApproved() {
			return this.$store.state.services.totalApproved;
		},
		totalDenied() {
			return this.$store.state.services.totalDenied;
		},
		totalNMI() {
			return this.$store.state.services.totalNMI;
		},
		documentInfo() {
			return this.$store.state.documents;
		},
		navButtonData() {
			return {
				status: this.carrierStatus,
				currPage: 'services',
				colorOne: 'primary darken-3',
				colorTwo: 'primary',
				outlineOne: false,
				outlineTwo: true,
				validation: true,
			};
		},
	},
	created() {
		this.getCarrierServices();
	},
};
</script>

<style scoped>
.lgAndUp {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 15%;
	flex: 0 0 15%;
	max-width: 15%;
}
.selectFilter {
	border-bottom: 1px solid var(--v-secondary-base);
}

.myServicesScroll::-webkit-scrollbar {
	width: 8px;
	background: transparent;
	border-radius: 10px;
}
.myServicesScroll::-webkit-scrollbar-track {
	background: #121212;
	border-radius: 10px;
}
.myServicesScroll::-webkit-scrollbar-thumb {
	background-color: var(--v-secondary-base);
	border-radius: 10px;
}
.myServicesScroll {
	min-height: 140px;
	max-height: 140px;
	overflow-y: scroll;
	border-radius: 10px;
	background: repeating-linear-gradient(
		-55deg,
		#121212,
		#121212 2.5px,
		#1e1e1e 2.5px,
		#1e1e1e 5px
	);
}
.serviceTotals {
	z-index: 2;
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-items: center;
	align-items: center;
	align-content: center;
	position: absolute;
	right: -6px;
	top: -10px;
	font-size: 12px;
	color: var(--v-secondary-base);
	background-color: #121212;
	height: 21px;
	width: 21px;
	border-radius: 50%;
}
.rowBackground {
	background-color: #121212;
	border-radius: 10px;
}
.mySerivcesHeaders {
	font-size: 14px;
	color: #bdbdbd;
}
</style>
