<template>
	<v-col>
		<v-row wrap>
			<v-col cols="12">
				<span>
					You must upload the following documents in order to move onto the next section.
				</span>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<span>
					Required Documents:
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12" class="pt-0">
				<ul>
					<li>Letter of Authority</li>
					<li>W-9 Form</li>
					<li>Certificate Of Insurance</li>
				</ul>
			</v-col>
		</v-row>
		<v-row align="center">
			<v-icon class="pl-3 pr-1" color="#F25454" dark>mdi-alert-octagon</v-icon>
			<v-col class="pa-0">
				<span>
					Document file size cannot be more than 19mb and must be file type PDF, JPEG, and PNG.
				</span>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'Upload_Document_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>