<template>
	<v-card class="elevation-8 mt-2" :img="require('../../../../assets/service.svg')">
		<v-row>
			<v-col cols="12">
				<v-row justify="center">
					<v-col cols="12" class="pa-0 text-right">
						<v-btn
							fab
							small
							:color="setColor(detailKey)"
							@click="$emit('openFleet', { detailKey })"
						>
							<v-icon>
								mdi-plus
							</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="12" class="pa-0 text-center">
						<img
							:alt="`${keyFilter(detailKey)} Icon`"
							:src="require(`../../../../assets/${iconParser(detailKey)}`)"
							height="40px"
						>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" class="px-2 py-1 pb-3">
				<v-row justify="center">
					<span class="truncate">
						{{keyFilter(detailKey)}}
						<!-- {{subTitleFilter(serviceKey)}} -->
					</span>
				</v-row>
			</v-col>

			<v-col cols="12" class="pa-0 py-2 cardAction">
				<v-row justify="space-between" align="center">
					<v-col
						cols="12"
						class="cardActionContent text-center pa-0"
					>
						<v-row align="center" justify="center" @click="$emit('selectAssistance', { detailKey })">
							<span style="color: #FFA12F" v-if="['carHaul', 'highCube'].includes(detailKey)">Assistance</span>
							<span style="color: #FFA12F" v-else>More Info</span>
							<v-icon color="warning" class="ml-2" small>mdi-help-circle-outline</v-icon>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: 'Fleet_Cards',
	props: {
		detailKey: { type: String, default: null }
	},
	data() {
		return {

		}
	},
	methods: {
		setColor(item) {
			let color;
			if (item === 'power' || item === 'drivers') {
				color = '#8353c3'
			} else {
				color = '#434343'
			}
			return color;
		},
		iconParser(service) {
			let fileName;
			switch(service) {
				case 'power':
					fileName = 'powerOnly.svg'
					break;
				case 'drivers':
					fileName = 'fleetDriver.svg'
					break;
				default:
					fileName = 'serviceDropTrailer.svg';
			}
			return fileName;
		},
		keyFilter(item) {
			let text;
			switch(item) {
				case 'power':
					text = 'Power Unit';
					break
				case 'drivers':
					text = 'Driver';
					break
				case 'dry':
					text = 'Dry Van';
					break
				case 'flatbed':
					text = 'Flatbed';
					break
				case 'reefer':
					text = 'Refrigerated';
					break
				case 'carHaul':
					text = 'Car Hauler';
					break
				case 'highCube':
					text = 'High Cube Dray';
					break
				case 'other':
					text = 'Other Trailer';
					break
				default:
					text = item;
			}
			// console.log(text)
			return text;
		}
	},
	computed: {
		auth() {
			return this.$store.state.auth;
		},
		fleetInfo() {
			return this.$store.state.onboardingInfo.fleet
		}
	},
};
</script>

<style scoped>
.cardAction {
	background-color: #282828;
	border-radius: 0 0 10px 10px;
}
.cardActionContent {
	font-size: 12px;
	cursor: pointer;
}
.active {
	cursor: pointer;
}
</style>