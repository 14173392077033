const menuData = [
    {
        title: 'Dashboard',
        route: '/dashboard',
        icon: 'mdi-home',
        requireLoggedIn: true,
    },
    {
        title: 'Contact',
        route: '/contact',
        icon: 'mdi-account',
        requireLoggedIn: false,
    }
];

export default menuData;