import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	key: 'knight-swift-logistics',
	storage: window.localStorage
  })

import state from './state';
import mutations from './mutations';
import getters from './getters';

export default new Vuex.Store({
	state,
	mutations,
	getters,
	plugins: [vuexLocal.plugin],
});
