<template>
	<div>
		<v-card-title
			>Forgot your password?
			<v-spacer></v-spacer>
			<span
				class="text-caption primary--text pointer"
				@click="$emit('toggleLogin')"
			>
				Back To Sign In
			</span>
		</v-card-title>
		<v-card-text class="py-2">
			<v-form v-on:submit.prevent="resetPassword">
				<v-row class="justify-center align-center">
					<v-col cols="12" lg="10">
						<v-row>
							<v-col cols="12" class="px-0">
								Email
							</v-col>
						</v-row>
						<v-text-field
							dark
							color="primary"
							v-model="username"
							autocomplete="off"
						></v-text-field>
						<v-row>
							<v-col cols="12" class="px-0 text-right"> </v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-row class="justify-center align-center">
				<v-col cols="12" lg="10">
					<v-btn
						dark
						color="primary darken-3"
						block
						:disabled="loading || !username"
						@click.stop.prevent="resetPassword"
					>
						Reset Password
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" />
	</div>
</template>

<script>
import basicDialogMixin from "../../../mixins/basicDialogMixin";

export default {
	data() {
		return {
			username: null,
			loading: false,
		};
	},
	mixins: [basicDialogMixin],
	methods: {
		//
		async resetPassword() {
			this.loading = true;
			let title;
			let text;
			try {
				await this.$axios({
					method: "post",
					url: `${this.$hostName.apiBase}/onboarding/resetpassword/request`,
					data: {
						username: this.username,
					},
				});
				title = "Password Reset";
				text = `A reset link has been sent to ${this.username}. That link expires in 30mins.`;
			} catch (error) {
				if (error.response.status === 404) {
					title = `Oops, we can't find your account.`;
					text = `A user with user name ${this.username} could not be found in our system. Please try again with the correct username.`;
				} else {
					title = "We've encountered a problem";
					text = `There was an error sending a reset link. Please try again later. `;
				}
			} finally {
				this.loading = false;
				this.basicDialog = {
					value: true,
					title,
					text,
					hasAction: false,
					width: "350",
				};
				setTimeout(() => {
					this.username = null;
					this.closeBasicDialog();
					this.$emit("toggleLogin");
				}, 3000);
			}
		},
	},
};
</script>

<style scoped>
/* */
.pointer:hover {
	cursor: pointer;
}
</style>
