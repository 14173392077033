<template>
	<v-col>
		<v-row>
			<v-col cols="12">
				<span>
					You must fill out all <b>required</b> fields to save and move onto the next section.
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					Required Fields:
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<ul>
					<li>Street Address</li>
					<li>City  </li>
					<li>Company Email </li>
					<li>Telephone </li>
					<li>Postal Code </li>
					<li>State </li>
					<li>Country </li>
				</ul>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				If you use a factoring company to process payments the following fields are required:
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<ul>
					<li>Name of company</li>
					<li>Notice of Assignment Document</li>
				</ul>
			</v-col>
		</v-row>
		
	</v-col>
</template>
<script>
export default {
    name: 'Payment_Assistance',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>