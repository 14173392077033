<template>
	<v-container class="fill-height">
		<v-row class="fill-height" align="center">
			<v-col cols="12" class="text-center">
				<p class="mb-2">{{ infoPrompt }}</p>
				<v-progress-circular
					indeterminate
					color="primary darken-2"
					size="44"
				></v-progress-circular>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data: () => ({
		infoPrompt: null,
	}),
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {},
	beforeRouteEnter(to, from, next) {
		if (from.fullPath === '/createaccount') {
			next((vm) => {
				vm.infoPrompt = 'Please wait a moment while the account is being created!';
			});
		} else if (from.fullPath === '/login') {
			next((vm) => {
				vm.infoPrompt = 'LOGGING IN';
			});
		} else {
			next();
		}
	},
};
</script>

<style scoped>
/* */
</style>
