export default {
	methods: {
		async getCarrierProgress() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/progress`,
					headers: {
						Authorization: `Bearer ${this.auth.token}`,
					}
				});

				const storeObj = {
					general: data.generalInfoComplete,
					addresses: data.mailingInfoComplete,
					payments: data.paymentInfoComplete,
					fleet: data.fleetInfoComplete,
					documents: data.documentsComplete,
					contract: data.signContractComplete
				}

				this.$store.commit('updateProgress', storeObj);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		}
	}
}
