export default {
	/////////////////////////////
	// INITIAL LOGIN MUTATIONS //
	/////////////////////////////
	updateAuth(state, obj) {
		state.auth = obj;
	},
	updateUser(state, obj) {
		state.userInfo = obj;
	},
	updateOrg(state, obj) {
		state.orgInfo = obj;
	},
	updatePermissions(state, obj) {
		state.permissions = obj;
	},
	updateOnboardingInfo(state, obj) {
		state.onboardingInfo = Object.assign({}, obj);
	},
	updateFirstTimeVisited(state, obj) {
		state.onboardingInfo.firstTimeVisited = obj;
	},
	updateDocuments(state, obj) {
		Object.keys(obj).forEach((key) => {
			state.documents[key] = obj[key];
		})
	},
	///////////////////
	// NOTIFICATIONS //
	///////////////////
	updateNotifications(state, obj) {
		state.notifications.list = [...obj.list];
		state.notifications.total = obj.total;
	},
	notificationsRead(state, obj) {
		state.notifications.total = 0;
	},
	updateNotificationOnboarding(state, obj) {
		state.notifications.list.unshift(obj);
		state.notifications.total = state.notifications.total + 1;
	},
	/////////////////////////////////////////
	// INDIVIDUAL PAGE UPDATES AFTER LOGIN //
	/////////////////////////////////////////
	updateGeneralInfo(state, obj) {
		state.onboardingInfo.general = Object.assign({}, obj);
	},
	updateAddressInfo(state, obj) {
		state.onboardingInfo.addresses = Object.assign({}, obj);
	},
	updateFleet(state, obj) {
		state.onboardingInfo.fleet = Object.assign({}, obj);
	},
	updatePayments(state, obj) {
		state.payments = Object.assign({}, obj);
	},
	updateServices(state, obj) {
		state.services = Object.assign({}, obj);
	},
	// used in contract sign
	updateOnboardingStatus(state, obj){
		state.onboardingInfo.status = obj;
	},
	///////////////////////////////////////////////////////////////////////
	// UPDATING PROGRESS - USED ON LOGIN AND THROUGHOUT SITE INTERACTION //
	///////////////////////////////////////////////////////////////////////
	updateProgress(state, obj) {
		if (Object.keys(obj).length) {
			Object.entries(obj).forEach(([key, value]) => {
				state.progress[key].valid = value;
			})
		} else {
			state.progress = obj;
		}
		Object.entries(obj).every(([key, value]) => {
			if (state.progress[key].valid) {
				return true
			} else {
				state.currentPage = key;
				return false
			}
		})
	},
	updateAddressStatus(state) {
		state.progress['addresses'].valid = false;
	},
	////////////////////////
	// SOCKET MUTATATIONS //
	////////////////////////

	// THIS SOCKET IS USED ONLY FOR UPDATES ON SERVICES COMING FROM LOOP/NEXUS
	socketedUpdateServices(state, obj) {
		// ONLY UPDATE NOTIFICATION IF UUID MATCHES
		if (state.orgInfo.uuid === obj.uuid) {
			let newState = state.services;

			if (newState.active[obj.serviceType]) {
				newState.active[obj.serviceType].currentStatus = obj.active[obj.serviceType].currentStatus;
				newState.active[obj.serviceType].lastUpdateTime = obj.active[obj.serviceType].lastUpdateTime;
			}

			newState.totalPending = obj.totalPending;
			newState.totalApproved = obj.totalApproved;
			newState.totalDenied = obj.totalDenied;
			newState.totalNMI = obj.totalNMI;
			state.services = Object.assign({}, newState);
		}
	},
	//////////////////////////////////////
	// RESET MUTATIONS - USED ON LOGOUT //
	//////////////////////////////////////
	resetOnboarding(state) {
		state.onboardingInfo = {};
	},
	resetDocuments(state) {
		state.documents = {};
	},
	resetPayments(state) {
		state.payments = {};
	},
	resetServices(state) {
		state.services = {};
	},
	resetProgress(state) {
		Object.keys(state.progress).forEach((key) => {
			state.progress[key].valid = false;
		})
		state.currentPage = null;
	},
};