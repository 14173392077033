<template>
	<v-card flat>
		<v-card-text class="list-scroll">
			A W-9 FORM is used to provide your correct Taxpayer Identification Number (TIN) which is either an EIN# or a SSN# to the entity who is required to file an information return with the IRS to report ex. income paid to a carrier. 
			This form must be signed by an Owner or Corp Manager authorized to sign on behalf of the company legally.
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: 'WNine_Assistance'
};
</script>