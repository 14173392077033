<template>
	<v-row>
		<v-flex v-if="selectedServiceData.internal.notesToCarrier.length > 0">
			<template v-for="(item, index) in selectedServiceData.internal.notesToCarrier">
				<v-layout :key="`notesToCarrier${index}`" wrap>
					<v-flex xs12>
						<span class="momentDates">
							{{item.time | momentDate}}
						</span>
					</v-flex>
					<v-flex xs12>
						<span class="notesDisplayName">
							{{item.displayName}}
						</span>
					</v-flex>
					<v-flex xs12>
						<span class="noteText">
							{{item.text}}
						</span>
					</v-flex>
					<v-flex xs12 class="my-1">
						<v-divider></v-divider>
					</v-flex>
				</v-layout>
			</template>
		</v-flex>
		<v-flex v-else>
			<span class="noteText">
				No Notes!
			</span>
		</v-flex>
	</v-row>
</template>
<script>
export default {
	name: 'Service_Dialog_ViewNotes',
	props: {
		selectedServiceData: { type: Object, default: () => {} },
		selectedServiceKey: { type: String, default: null },
	},
	data() {
		return {
			//
		};
	},
	methods: {
		//
	},
	computed: {
		// serviceInfo() {
		// 	return this.$store.state.services;
		// },
	},
};
</script>
<style scoped>
.momentDates{
	font-size: 11px;
	color:#757575;
}
.notesDisplayName {
	font-weight: 700;
	color: #BDBDBD;
}
.noteText {
	display:block;
	color: #BDBDBD;
	word-wrap: break-word;
	margin: 5px 5px 5px 0px;
}
</style>