<template>
	<v-dialog
		:value="value"
		scrollable
		persistent
		:width="$vuetify.breakpoint.smAndDown ? '70vw' : '35vw'"
		transition="dialog-transition"
	>
		<v-card>
			<v-card-title>
				<v-row justify="space-between" align="center" class="fill-height">
					{{assistTitleSwitch(assistDocument)}} Assistance
					<v-btn @click="$emit('closeAssistanceDialog')" icon>
						<v-icon color="primary darken-1">mdi-close-thick</v-icon>
					</v-btn>
				</v-row>
			</v-card-title>

			<v-card-text>
				<v-row>
					<v-col cols="12">
						<v-row>
							<Insurance v-if="assistDocument === 'Certificate of Insurance'"></Insurance>
							<WNine v-if="assistDocument === 'W9 Tax Form'"></WNine>
							<WEight v-if="assistDocument === 'W8 Tax Form'"></WEight>
							<ScheduledAutos v-if="assistDocument === 'Scheduled Autos'"></ScheduledAutos>
							<MCLetter v-if="assistDocument === 'MC Letter of Authority'"></MCLetter>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Insurance from './AssistanceDocs/Insurance.vue';
import WNine from './AssistanceDocs/WNine.vue';
import WEight from './AssistanceDocs/WEight.vue';
import ScheduledAutos from './AssistanceDocs/ScheduledAutos.vue';
import MCLetter from './AssistanceDocs/MCLetter.vue';

export default {
	props: {
		value: { type: Boolean, default: false },
		assistDocument: { type: String, default: null },
	},
	components: {
		Insurance,
		WNine,
		WEight,
		ScheduledAutos,
		MCLetter
	},
	data() {
		return {

		}
	},
	methods: {
		assistTitleSwitch(item) {
			let text;
			switch(item) {
				case 'MC Letter of Authority':
					text = 'Letter of Authority';
					break
				default:
					text = item;
			}
			return text;
		}
	},
	computed: {

	},
	watch: {

	}
};
</script>

<style scoped>
.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
</style>