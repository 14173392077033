import store from '../store';

import LandingPage from '../components/LandingPage';
import Login from '../components/Login';
import LoadingInformation from '../components/Login/components/loginAnimation';
import Logout from '../components/Logout';
import ResetPassword from '../components/ResetPassword'
import Dashboard from '../components/Dashboard';
import Contact from '../components/Contact';

// Signup Pages
import SignUp from '../components/VerifyCompany';
import ExistingCarrier from '../components/ExistingCarrier';

// Dashboard Pages
import GeneralInformation from '../components/Dashboard/dashboardPages/General';
import Addresses from '../components/Dashboard/dashboardPages/Addresses';
import Fleet from '../components/Dashboard/dashboardPages/Fleet';
import Documents from '../components/Dashboard/dashboardPages/Documents';
import Payments from '../components/Dashboard/dashboardPages/Payments';
import Services from '../components/Dashboard/dashboardPages/services';
import Contract from '../components/Dashboard/dashboardPages/contract';

export default [
	// these routes are ok for logged out users
	{
		path: '/',
		name: 'LandingPage',
		component: LandingPage,
		meta: { public: true },
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: { public: true },
	},
	{
		path: "/reset/:encObj",
		name: "ResetPassword",
		component: ResetPassword,
		meta: { public: true }
	},
	{
		path: '/createaccount',
		name: 'SignUp',
		component: SignUp,
		meta: { public: true },
		beforeEnter: (to, from, next) => {
			if (from.name != 'LandingPage') next({ name: 'LandingPage' })
			else next();
		}
	},
	{
		path: '/existingCarrier',
		name: 'ExistingCarrier',
		component: ExistingCarrier,
		meta: { public: true },
		beforeEnter: (to, from, next) => {
			if (from.name != 'LandingPage') next({ name: 'LandingPage' })
			else next();
		}
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: { public: true },
	},
	{
		path: '/loadinginformation',
		name: 'LoadingInformation',
		component: LoadingInformation,
		meta: { public: true },
		beforeEnter: (to, from, next) => {
			if (from.name != 'SignUp' && from.name != 'Login' && from.name != 'ExistingCarrier') next({ name: 'LandingPage' })
			else next();
		}
	},
	// these routes must be logged in to view
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: { public: false },
	},
	{
		path: '/general',
		name: 'General',
		component: GeneralInformation,
		meta: { public: false },
	},
	{
		path: '/addresses',
		name: 'Addresses',
		component: Addresses,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.general.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/payments',
		name: 'Payments',
		component: Payments,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.addresses.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/fleet',
		name: 'Fleet',
		component: Fleet,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.payments.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/documents',
		name: 'Documents',
		component: Documents,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.fleet.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/contract',
		name: 'Contract',
		component: Contract,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.documents.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/services',
		name: 'Services',
		component: Services,
		meta: { public: false },
		beforeEnter: (to, from, next) => {
			if (!store.state.progress.contract.valid) next({ name: 'Dashboard' })
			else next();
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout,
		meta: { public: false },
	},
];
