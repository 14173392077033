import { render, staticRenderFns } from "./ViewNotes.vue?vue&type=template&id=167c7a93&scoped=true&"
import script from "./ViewNotes.vue?vue&type=script&lang=js&"
export * from "./ViewNotes.vue?vue&type=script&lang=js&"
import style0 from "./ViewNotes.vue?vue&type=style&index=0&id=167c7a93&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167c7a93",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VFlex,VLayout,VRow})
