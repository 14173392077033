<template>
    <span class="timePassedLabel">{{time}}</span>
</template>
<script>
import moment from 'moment'
export default {
    props: ['createdAt'],
	data(){
		return {}
    },
	computed: {
        time() {
            return moment(this.createdAt).fromNow();
        }
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
.timePassedLabel {
	color: #757575;
	font-size: 11px;
}
</style>
