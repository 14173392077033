export default {
	methods: {
		columnSize(key) {
			let cols;
			switch (key) {
				// general
				case 'telephone':
					cols = '8';
					break;
				case 'extension':
					cols = '4';
					break;
				case 'fax':
					cols = '8';
					break;
				case 'intraState':
					cols = '6';
					break;
				case 'taxId':
					cols = '6';
					break;
				case 'minority':
					cols = '6';
					break;
				case 'scacNo':
					cols = '4';
					break;
				case 'mcNumber':
					cols = '6';
					break;
				// addresses
				case 'city':
					cols = '12';
					break;
				case 'state':
					cols = '4';
					break;
				case 'country':
					cols = '4';
					break;
				case 'zip':
					cols = '4';
					break;
				// payments
				case 'phone':
					cols = '8';
					break;
				case 'isFactoring':
					cols='6';
					break;
				case 'paymentEmail':
					cols='8';
					break;
				case 'paymentCountry':
					cols='4';
					break;
				default:
					cols = '12';
			}
			return cols
		},
	}
}
