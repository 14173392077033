<template>
	<v-col>
		<v-row>
			<v-col cols="12">
				<span>
					You must fill out all <b>required</b> fields to save and move onto the next section for both <b>Physical</b> and <b>Mailing</b> Address.
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<span>
					Required Fields:
				</span>
			</v-col>
		</v-row>
		<v-row>	
			<v-col cols="12">
				<ul>
					<li>Street Address</li>
					<li>City</li>
					<li>Country</li>
					<li>State</li>
					<li>Postal Code</li>
				</ul>
			</v-col>
		</v-row>
	</v-col>
</template>
<script>
export default {
    name: 'Address',
	data(){
		return {}
	},
	computed: {
		//
	},
	methods: {
		//
	},
	mounted() {
		//
	},
	watch: {
		//
	},
};
</script>
<style scoped>
/* */
</style>