<template>
	<div>
		<v-card-title>Sign in to your account</v-card-title>
		<v-card-text class="py-2">
			<v-row class="justify-center align-center">
				<v-col cols="12" lg="10">
					<v-form>
						<v-row>
							<v-col cols="12" class="px-0">
								Email
							</v-col>
						</v-row>
						<v-text-field dark color="primary" v-model="username" autocomplete="off" :hint="emailHint()" @input="email"></v-text-field>
						<v-row>
							<v-col cols="6" class="px-0">
								Password
							</v-col>
							<v-col cols="6" class="px-0 text-right">
								<span class="text-caption primary--text pointer" @click="$emit('toggleLogin')">
									Forgot Password?
								</span>
							</v-col>
						</v-row>
						<v-text-field
							:type="showPassword ? 'text' : 'password'"
							dark
							color="primary"
							v-model="password"
							:append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
							@click:append="showPassword = !showPassword"
							@keypress.enter="loginCarrier"
							autocomplete="off"
						></v-text-field>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-row class="justify-center align-center">
				<v-col cols="12" lg="10">
					<v-btn dark color="primary darken-3" :disabled="!username || !password || loading || !validEmail" block @click="loginCarrier">
						Login
					</v-btn>
				</v-col>
			</v-row>
		</v-card-actions>
		<basic-dialog v-model="basicDialog.value" :options="basicDialog" @close="closeBasicDialog" />
	</div>
</template>

<script>
// libraries
import jwt from 'jsonwebtoken';
// mixins
import hashMixin from '../../../mixins/hashPassword';
import basicDialogMixin from '../../../mixins/basicDialogMixin';

export default {
	data() {
		return {
			authToken: null,
			// 
			username: null,
			password: null,
			showPassword: false,
			loading: false,
			emailValid: [this.$formRules.required, this.$formRules.email],
			passwordValid: [this.$formRules.required, this.$formRules.passwordValid],
			validEmail: false,
			fleetDefaultObject: {
				company: {
					power: 0,
					drivers: 0,
					numTrailers: 0,
					dry: {
						amount: 0,
						hasCurtainSide: false,
						hasOpentop: false,
						hasAirRide: false,
						hasPlate: false,
						hasDry: false,
						hasLiftGate: false,
					},
					flatbed: {
						amount: 0,
						has53: false,
						has48: false,
						hasStepDeck: false,
						hasLowboy: false,
						hasConestoga: false,
						hasDropTrailers: false,
					},
					reefer: {
						amount: 0,
						hasMultiTemp: false,
						hasRemoteTemp: false,
						hasRgn: false,
					},
					other: {
						description: null,
						amount: 0,
					},
					carHaul: {
						amount: 0
					},
					highCube: {
						amount: 0
					},
				},
				owner: {
					drivers: 0,
					power: 0,
					numTrailers: 0,
					dry: {
						amount: 0,
						hasCurtainSide: false,
						hasOpentop: false,
						hasAirRide: false,
						hasPlate: false,
						hasDry: false,
						hasLiftGate: false,
					},
					flatbed: {
						amount: 0,
						has53: false,
						has48: false,
						hasStepDeck: false,
						hasLowboy: false,
						hasConestoga: false,
						hasDropTrailers: false,
					},
					reefer: {
						amount: 0,
						hasMultiTemp: false,
						hasRemoteTemp: false,
						hasRgn: false,
					},
					other: {
						description: null,
						amount: 0,
					},
					carHaul: {
						amount: 0
					},
					highCube: {
						amount: 0
					},
				},
			}
		};
	},
	computed: {
		accountInfo() {
			return this.$store.state.accountInfo;
		},
		auth() {
			return this.$store.state.auth;
		},
		userInfo() {
			return this.$store.state.userInfo;
		},
		docInfo() {
			return this.$store.state.documents;
		},
		paymentInfo() {
			return this.$store.state.payments;
		},
		serviceInfo() {
			return this.$store.state.services;
		}
	},
	mixins: [
		hashMixin,
		basicDialogMixin
	],
	methods: {
		email(valid) {
			if( /^[a-zA-Z0-9\.!#$%&''*+\-\/=?^_`{|}~;]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]+)*\.[a-zA-Z0-9]+$/.test(valid) ) {
				this.validEmail = true
			} else {
				this.validEmail = false
			}
		},
		emailHint() {
			return this.validEmail ? "" : "Please Enter A Valid Email"
		},
		async loginCarrier() {
			this.loginError = false;
			this.loginErrorMsg = null;
			this.loading = true;

			const postObj = {
				username: this.auth.username || this.username,
				password: this.auth.hashedPass || this.hashPassword(this.password),
				source: 'onboarding'
			};

			try {
				const response = await this.$axios({
					method: 'post',
					url: `${this.$hostName.selectProfiles}/api/v0/unprotected/authUser/login`,
					data: postObj,
				});
				this.$router.push('/loadinginformation');

				const { authToken: token, user, companyRole } = response.data;
				// parse JWT
				const decoded = jwt.decode(token);
				// build an auth
				const authObj = {
					token,
					uuid: decoded.organization.uuid,
					companyRole: decoded.companyRole,
					expTime: decoded.exp,
					refreshTime: Date.now() / 1000 + 60 * 15,
					loggedIn: true,
				};

				this.authToken = token;

				// build a userObj
				const userObj = {
					username: user.username,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					companyRole: user.companyRole,
				};

				// put important stuff into the store.
				this.$store.commit('updateAuth', authObj);
				this.$store.commit('updateUser', userObj);
				this.$store.commit('updatePermissions', decoded.permissions);
				this.$store.commit('updateOrg', decoded.organization);

				// retrieve saved carrier info
				await this.getCarrierInfo();
				await this.getDocInfo();
				await this.getCarrierProgress();
				await this.getCarrierPayments();
				await this.getCarrierServices();
				await this.getCarrierNotifications();

				this.$router.push('/dashboard');
			} catch (error) {
				// GENERIC ERROR MESSAGE
				let title = 'We\'ve encountered a problem';
				let text = 'There was an error when logging in. Please try again later.';

				// IF THE USER OR PASS IS WRONG MP PROFILES THROWS SPECIFIC MESSAGE
				if (error.response && error.response.data && error.response.data.message) {
					if (error.response.data.message.includes('Incorrect')){
						text = 'We can\'t find an account with this username / password - Please try again or reset your password.'
					}
				}
				this.basicDialog = {
					value: true,
					title,
					text,
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Ok',
						color: 'error',
					},
					width: '400',
				};
			} finally {
				this.loading = false;
			}
		},
		async getCarrierNotifications() {
			const { data } = await this.$axios({
				method: 'get',
				url: `${this.$hostName.apiBase}/onboarding/notifications`,
				params: {
					limit: 15,
					skip: 0,
					sortby: 'createdAt',
					descending: true,
					externalRead: false
				},
				headers: {
					Authorization: `Bearer ${this.authToken}`,
				},
			});
			this.$store.commit('updateNotifications', data);
		},
		async getCarrierInfo() {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/carrier`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				const formData = response.data;
				formData.general = { ...formData.contact, ...formData.info };
				delete formData.general.mcNumber;
				delete formData.general.intraState;

				const mailingData = {};
				const physicalData = {};
				const generalData = {};
				const companyFleetData = {};
				const ownerFleetData = {};

				Object.entries(formData.mailing).forEach(([key, value]) => {
					mailingData[key] = value;
				});
				Object.entries(formData.physical).forEach(([key, value]) => {
					physicalData[key] = value;
				});
				Object.entries(formData.general).forEach(([key, value]) => {
					generalData[key] = value;
				});

				if (formData.fleet) {
					if (formData.fleet.company) {
						Object.entries(formData.fleet.company).forEach(([key, value]) => {
							companyFleetData[key] = value;
						});
					}
					if (formData.fleet.owner) {
						Object.entries(formData.fleet.owner).forEach(([key, value]) => {
							ownerFleetData[key] = value;
						});
					}
				} else {
					Object.entries(this.fleetDefaultObject.company).forEach(([key, value]) => {
						companyFleetData[key] = value;
					});
					Object.entries(this.fleetDefaultObject.owner).forEach(([key, value]) => {
						ownerFleetData[key] = value;
					});
				}
				const storeObj = {
					firstTimeVisited: formData.firstTimeVisited,
					dotNumber: formData.dotNumber,
					carrierCode: formData.carrierCode,
					status: formData.status,
					addresses: {
						mailing: {...mailingData},
						physical: {...physicalData},
					},
					general: {...generalData},
					fleet: {
						company: {...companyFleetData},
						owner: {...ownerFleetData}
					},

				}

				this.$store.commit('updateOnboardingInfo', storeObj);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getDocInfo() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/documents/list`,
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (data) {
					this.$store.commit('updateDocuments', data)
				}

			} catch (err) {
				console.log('error', err);
				throw new Error(err);
			}
		},
		async getCarrierPayments() {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/payment`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const payments = response.data;
				const storeObj = {};
				if (payments) {
					// call map function to map to $payments
					Object.entries(payments).forEach(([key, value]) => {
						storeObj[key] = value;
					});
					this.$store.commit('updatePayments', payments);
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierServices() {
			try {
				const { data } = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/service`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});

				if (data) this.$store.commit('updateServices', data);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
		async getCarrierProgress() {
			try {
				const response = await this.$axios({
					method: 'get',
					url: `${this.$hostName.apiBase}/onboarding/progress`,
					headers: {
						Authorization: `Bearer ${this.authToken}`,
					},
				});
				const formData = response.data;

				const storeObj = {
					general: formData.generalInfoComplete,
					addresses: formData.mailingInfoComplete,
					payments: formData.paymentInfoComplete,
					fleet: formData.fleetInfoComplete,
					documents: formData.documentsComplete,
					contract: formData.signContractComplete,
				}

				// console.log('getCarrierProgress line 333 --- storeObj: ', storeObj);

				this.$store.commit('updateProgress', storeObj);

			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		},
	},
};
</script>

<style scoped>
/* */
.pointer:hover {
	cursor: pointer;
}
</style>
