<template>
	<v-dialog
	 :value="value"
	 scrollable
	 persistent
	 :width="$vuetify.breakpoint.smAndDown ? '70vw' : '30vw'"
	 transition="dialog-transition">
		<v-card v-show="!addDocAnimation && !docInfoSuccess" id="documentCardId">
			<v-card-title>
				<v-row justify="end" align="center" class="fill-height">
					<v-btn @click="$emit('closeUploadDialog')" icon>
						<v-icon color="primary darken-1">mdi-close-thick</v-icon>
					</v-btn>
				</v-row>
			</v-card-title>
			<v-card-text class="px-5 pb-8">
				<v-row justify="center" align="center">
					<v-col cols="12" class="text-center pt-7">
						<v-btn @click="initFileUpload()" rounded dark color="primary darken-3">
							Select your {{ docType | shortDocClass }}
						</v-btn>
					</v-col>
				</v-row>
				<v-row v-if="file">
					<v-col cols="12">
						<p class="text-center">file selected: {{ file.name }}</p>
					</v-col>
				</v-row>
				<v-row class="py-3" justify="center" v-if="file && file.size > 19922944">
					<v-icon color="#FFA12F" class="mr-2">mdi-alert</v-icon>
					<span>Selected File is too Large - Max File size 19MB</span>
				</v-row>
				<v-row v-if="docType === 'Certificate of Insurance'">
					<v-col cols="1" class="pa-0">
						<v-checkbox class="ma-0" v-model="checkbox"></v-checkbox>
					</v-col>
					<v-col cols="11" class="pa-0">
						Each carrier is responsible for notifying Knight Swift Logistics on any
						changes to their insurance prior to expiration of their coverage. You
						acknowledge that is your responsibility and a certificate of insurance will
						be obtained & required accordingly.
						<br />
						<span class="primary--text">Must be 30 Days or newer from insurance agent with
							<span class="font-weight-black">Knight Swift Logistics</span> listed as
							certificate holder.</span>
					</v-col>
				</v-row>
				<input
				 @input="selectFile()"
				 type="file"
				 accept="image/png, image/jpeg, application/pdf"
				 id="uploadNewDoc"
				 required
				 style="display: none"
				 name="TEST" />
			</v-card-text>
			<v-card-actions class="cardActions" :style="cardActionsStyle" @click="uploadButton">
				Upload
			</v-card-actions>
		</v-card>
		<v-card v-show="addDocAnimation" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Updating Information</p>
					<v-progress-circular
					 indeterminate
					 color="primary darken-2"
					 size="44"></v-progress-circular>
				</v-col>
			</v-row>
		</v-card>
		<v-card v-show="docInfoSuccess" :style="`height: ${dialogHeight}px`">
			<v-row style="height: 100%" align="center">
				<v-col cols="12" class="text-center">
					<p class="mb-2">Success!</p>
					<p>{{ docType | shortDocClass }} document submitted.</p>
				</v-col>
			</v-row>
		</v-card>
		<basic-dialog
		 v-model="basicDialog.value"
		 :options="basicDialog"
		 @close="closeBasicDialog"></basic-dialog>
	</v-dialog>
</template>
<script>
import basicDialogMixin from '@/mixins/basicDialogMixin';

export default {
	name: 'document_upload_Dialog_Box',
	props: ['value', 'docType', 'addDocAnimation', 'docInfoSuccess'],
	mixins: [basicDialogMixin],
	data() {
		return {
			file: null,
			selectedFile: false,
			checkbox: false,
			dialogHeight: 0,
		};
	},
	methods: {
		initFileUpload() {
			const input = document.getElementById('uploadNewDoc');
			input.click();
		},
		selectFile() {
			const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
			this.file = document.getElementById('uploadNewDoc').files[0];
			if (!acceptedFileTypes.includes(this.file.type)) {
				this.file = null;
				this.selectedFile = false;
				document.getElementById('uploadNewDoc').value = '';
				return (this.basicDialog = {
					value: true,
					title: "We've encountered a problem",
					text: 'Please be sure uploaded file type is either a PDF, JPG, JPEG, or a PNG file.',
					hasAction: true,
					actionButton: {
						outlined: true,
						action: 'close',
						text: 'Close',
						color: '#CF6679',
					},
					width: '350',
				});
			}

			if (this.file.size < 19922944) {
				this.selectedFile = true;
			} else {
				this.selectedFile = false;
			}
		},
		cancelButton() {
			this.checkbox = false;
			this.selectedFile = false;
			this.$emit('closeDocumentDialog');
		},
		uploadButton() {
			const dialog = document.querySelector('#documentCardId');
			this.dialogHeight = dialog.clientHeight;
			// console.log(this.dialogHeight, "docmument height");

			if (this.selectedFile && this.docType != 'Certificate of Insurance') {
				this.$emit('uploadButton', this.file);
				//fileList must be cleared or your can't upload another doc unless you refresh the page
				document.getElementById('uploadNewDoc').value = '';
			}
			if (this.docType === 'Certificate of Insurance' && this.selectedFile && this.checkbox) {
				this.$emit('uploadButton', this.file);
				//fileList must be cleared or your can't upload another doc unless you refresh the page
				document.getElementById('uploadNewDoc').value = '';
			}
		},
	},
	computed: {
		cardActionsStyle() {
			let style;
			if (!this.selectedFile && this.docType != 'Certificate of Insurance') {
				style = { 'background-color': '#282828', color: '#424242' };
			} else if (this.selectedFile && this.docType != 'Certificate of Insurance') {
				style = { 'background-color': '#673aa7', cursor: 'pointer', color: '#FFF' };
			} else if (!this.selectedFile && this.docType === 'Certificate of Insurance') {
				style = { 'background-color': '#282828', color: '#424242' };
			} else if (
				this.selectedFile &&
				this.docType === 'Certificate of Insurance' &&
				this.checkbox
			) {
				style = { 'background-color': '#673aa7', cursor: 'pointer', color: '#FFF' };
			} else {
				style = { 'background-color': '#282828', color: '#424242' };
			}

			return style;
		},
	},
	filters: {
		shortDocClass(val) {
			let docClass;
			switch (val) {
				case 'Certificate of Insurance':
					docClass = 'Certificate of Insurance';
					break;
				case 'Scheduled Autos':
					docClass = 'Scheduled Autos';
					break;
				case 'Revised Contract':
					docClass = 'Revised Contract';
					break;
				case 'CARB':
					docClass = 'CARB';
					break;
				case 'PO Agreement':
					docClass = 'PO Agreement';
					break;
				case 'MC Letter of Authority':
					docClass = 'Letter of Authority';
					break;
				case 'W9 Tax Form':
					docClass = 'W9 Tax Form';
					break;
				case 'Reefer TRU':
					docClass = 'Reefer TRU';
					break;
				// case 'FSMA Addendum':
				// 	docClass = 'FSMA Addendum';
				// 	break;
				case 'W8 Tax Form':
					docClass = 'W8 Tax Form';
					break;
				case 'Affadavit':
					docClass = 'Affadavit';
					break;
				case 'NOA':
					docClass = 'NOA';
					break;
				case 'HAZMAT':
					docClass = 'HAZMAT';
					break;
				case 'Other':
					docClass = 'Other';
					break;
				case 'Minority Certification':
					docClass = 'Diversity Certificate';
					break;
				default:
					docClass = val;
			}
			return docClass;
		},
	},
	watch: {
		value(val) {
			if (!val) {
				this.checkbox = false;
				this.selectedFile = false;
				this.file = null;
				//fileList must be cleared or your can't upload another doc unless you refresh the page
				if (document.getElementById('uploadNewDoc').value)
					document.getElementById('uploadNewDoc').value = '';
			}
		},
	},
};
</script>
<style scoped>
#uploadNewDoc {
	position: absolute !important;
	width: 0.1px !important;
	height: 0.1px !important;
}

.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-content: center;
	border-radius: 0 0 10px 10px;
}
</style>